/* tslint:disable */
/* eslint-disable */
/**
 * CenterLine\'s Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActionTakenType {
    AmendAsNoted = 'amend_as_noted',
    NoExceptionsTaken = 'no_exceptions_taken',
    ReviseAndResubmit = 'revise_and_resubmit',
    SeeSubmittalComments = 'see_submittal_comments',
    SeeTransmittalComments = 'see_transmittal_comments',
    SubmitSpecifiedItem = 'submit_specified_item',
    RemoveFromPackage = 'remove_from_package',
    ForInformationOnly = 'for_information_only',
    Withdrawn = 'withdrawn'
}

/**
 * 
 * @export
 * @interface AddDocumentToPackageProps
 */
export interface AddDocumentToPackageProps {
    /**
     * 
     * @type {string}
     * @memberof AddDocumentToPackageProps
     */
    packageDocumentId: string;
}
/**
 * 
 * @export
 * @interface AddUserParams
 */
export interface AddUserParams {
    /**
     * 
     * @type {string}
     * @memberof AddUserParams
     */
    securityGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserParams
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserParams
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface AdditionalConsultantProps
 */
export interface AdditionalConsultantProps {
    /**
     * 
     * @type {string}
     * @memberof AdditionalConsultantProps
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalConsultantProps
     */
    consultantUserId: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalConsultantProps
     */
    dueDate: string;
}
/**
 * 
 * @export
 * @interface AdditionalConsultantResponse
 */
export interface AdditionalConsultantResponse {
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof AdditionalConsultantResponse
     */
    additionalReview: INumberedDocumentView;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof AdditionalConsultantResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface AdditionalReviewProps
 */
export interface AdditionalReviewProps {
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalReviewProps
     */
    isDraft?: boolean;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof AdditionalReviewProps
     */
    recommendedActionTaken?: ActionTakenType;
    /**
     * 
     * @type {string}
     * @memberof AdditionalReviewProps
     */
    comment?: string;
    /**
     * 
     * @type {Array<AdditionalReviewRowProps>}
     * @memberof AdditionalReviewProps
     */
    packageAdditionalReview?: Array<AdditionalReviewRowProps>;
}
/**
 * 
 * @export
 * @interface AdditionalReviewRowProps
 */
export interface AdditionalReviewRowProps {
    /**
     * 
     * @type {string}
     * @memberof AdditionalReviewRowProps
     */
    comment?: string;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof AdditionalReviewRowProps
     */
    recommendedActionTaken?: ActionTakenType;
    /**
     * 
     * @type {string}
     * @memberof AdditionalReviewRowProps
     */
    documentId: string;
}
/**
 * 
 * @export
 * @interface AllProjectSummaryType
 */
export interface AllProjectSummaryType {
    /**
     * 
     * @type {number}
     * @memberof AllProjectSummaryType
     */
    numProjectsConstruction: number;
    /**
     * 
     * @type {number}
     * @memberof AllProjectSummaryType
     */
    numProjectsBidding: number;
    /**
     * 
     * @type {number}
     * @memberof AllProjectSummaryType
     */
    numProjectsDesign: number;
    /**
     * 
     * @type {number}
     * @memberof AllProjectSummaryType
     */
    numProjects: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementBroadcastOption {
    AllUsers = 'all_users',
    AdministrationOnly = 'administration_only'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementEmailOption {
    AllUsers = 'all_users',
    AdministrationOnly = 'administration_only',
    NoEmail = 'no_email'
}

/**
 * 
 * @export
 * @interface AnticipatedSubmissionDateChangeRequest
 */
export interface AnticipatedSubmissionDateChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof AnticipatedSubmissionDateChangeRequest
     */
    anticipatedSubmissionDate: string;
}
/**
 * 
 * @export
 * @interface ArchiveFileCategory
 */
export interface ArchiveFileCategory {
}
/**
 * 
 * @export
 * @interface AssignByDomainRequest
 */
export interface AssignByDomainRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignByDomainRequest
     */
    excludedUserIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignByDomainRequest
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof AssignByDomainRequest
     */
    companyId: string;
}
/**
 * 
 * @export
 * @interface AuthenticatedDownloadRequest
 */
export interface AuthenticatedDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedDownloadRequest
     */
    fullKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedDownloadRequest
     */
    formatted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedDownloadRequest
     */
    ownerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedDownloadRequest
     */
    isForViewing: boolean;
}
/**
 * 
 * @export
 * @interface AuthenticatedOtherFileSystemRequest
 */
export interface AuthenticatedOtherFileSystemRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedOtherFileSystemRequest
     */
    fullKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedOtherFileSystemRequest
     */
    formatted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedOtherFileSystemRequest
     */
    ownerId?: string;
}
/**
 * 
 * @export
 * @interface AuthenticatedUploadRequest
 */
export interface AuthenticatedUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUploadRequest
     */
    fullKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedUploadRequest
     */
    formatted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUploadRequest
     */
    ownerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedUploadRequest
     */
    useMultiPartUpload: boolean;
}
/**
 * 
 * @export
 * @interface BaseFileUploadParams
 */
export interface BaseFileUploadParams {
    /**
     * 
     * @type {boolean}
     * @memberof BaseFileUploadParams
     */
    useMultiPartUpload: boolean;
}
/**
 * 
 * @export
 * @interface BidBIMImportFileCategory
 */
export interface BidBIMImportFileCategory {
}
/**
 * 
 * @export
 * @interface BidBIMImportParams
 */
export interface BidBIMImportParams {
    /**
     * 
     * @type {string}
     * @memberof BidBIMImportParams
     */
    description?: string;
    /**
     * 
     * @type {BimTreeNode}
     * @memberof BidBIMImportParams
     */
    bimNode: BimTreeNode;
    /**
     * 
     * @type {BidBIMImportFileCategory}
     * @memberof BidBIMImportParams
     */
    fileCategory: BidBIMImportFileCategory;
}
/**
 * 
 * @export
 * @interface BidConnectFileDeliveryEmailRequest
 */
export interface BidConnectFileDeliveryEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    projectDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    estimatedCost?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    ownerProjectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    architectProjectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    preBidDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    bidDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    requestDueDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    allowElectronicSubstitutionRequests?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    allowElectronicBidding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    liveDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    instructions?: string;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    customLink?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BidConnectFileDeliveryEmailRequest
     */
    objectKeys?: Array<string>;
}
/**
 * 
 * @export
 * @interface BidConnectFileDeliveryUploadRequest
 */
export interface BidConnectFileDeliveryUploadRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BidConnectFileDeliveryUploadRequest
     */
    useMultiPartUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof BidConnectFileDeliveryUploadRequest
     */
    filename: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BillingPeriod {
    Monthly = 'monthly',
    Yearly = 'yearly'
}

/**
 * 
 * @export
 * @interface BimTreeNode
 */
export interface BimTreeNode {
    /**
     * 
     * @type {string}
     * @memberof BimTreeNode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BimTreeNode
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BimTreeNode
     */
    type: BimTreeNodeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BimTreeNode
     */
    children: boolean;
    /**
     * 
     * @type {any}
     * @memberof BimTreeNode
     */
    fileAttributes?: any | null;
}

/**
    * @export
    * @enum {string}
    */
export enum BimTreeNodeTypeEnum {
    File = 'FILE',
    Folder = 'FOLDER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CalendarEventType {
    Event = 'event',
    Meeting = 'meeting'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CognitoUserStatus {
    Unconfirmed = 'UNCONFIRMED',
    Confirmed = 'CONFIRMED',
    Archived = 'ARCHIVED',
    Compromised = 'COMPROMISED',
    Unknown = 'UNKNOWN',
    ResetRequired = 'RESET_REQUIRED',
    ForceChangePassword = 'FORCE_CHANGE_PASSWORD'
}

/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponse
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    id: number;
}
/**
 * 
 * @export
 * @interface CompleteMultiPartUploadRequest
 */
export interface CompleteMultiPartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteMultiPartUploadRequest
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteMultiPartUploadRequest
     */
    uploadId: string;
    /**
     * 
     * @type {Array<CompleteMultiPartUploadRequestParts>}
     * @memberof CompleteMultiPartUploadRequest
     */
    parts: Array<CompleteMultiPartUploadRequestParts>;
}
/**
 * 
 * @export
 * @interface CompleteMultiPartUploadRequestParts
 */
export interface CompleteMultiPartUploadRequestParts {
    /**
     * 
     * @type {string}
     * @memberof CompleteMultiPartUploadRequestParts
     */
    ETag: string;
    /**
     * 
     * @type {number}
     * @memberof CompleteMultiPartUploadRequestParts
     */
    PartNumber: number;
}
/**
 * 
 * @export
 * @interface CompleteReviewResponse
 */
export interface CompleteReviewResponse {
    /**
     * 
     * @type {Array<INumberedDocumentView>}
     * @memberof CompleteReviewResponse
     */
    revisedDocuments: Array<INumberedDocumentView>;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof CompleteReviewResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ConformingCenterConflictResolutionType {
    NoConflict = 'no_conflict',
    Overwrite = 'overwrite',
    Revision = 'revision',
    NotAllowed = 'not_allowed'
}

/**
 * 
 * @export
 * @interface ConsultantNotificationRequest
 */
export interface ConsultantNotificationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConsultantNotificationRequest
     */
    reviewDocumentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateFolderResponse
 */
export interface CreateFolderResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderResponse
     */
    sanitizedFullKey: string;
}
/**
 * 
 * @export
 * @interface CreateLinkedDocumentsForAssignedUsersRequest
 */
export interface CreateLinkedDocumentsForAssignedUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLinkedDocumentsForAssignedUsersRequest
     */
    userIds: Array<string>;
}
/**
 * 
 * @export
 * @interface CropConfig
 */
export interface CropConfig {
    /**
     * 
     * @type {number}
     * @memberof CropConfig
     */
    resolution: number;
    /**
     * 
     * @type {Array<CropConfigCrops>}
     * @memberof CropConfig
     */
    crops: Array<CropConfigCrops>;
}
/**
 * 
 * @export
 * @interface CropConfigCrops
 */
export interface CropConfigCrops {
    /**
     * 
     * @type {boolean}
     * @memberof CropConfigCrops
     */
    skip?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CropConfigCrops
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof CropConfigCrops
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof CropConfigCrops
     */
    W: number;
    /**
     * 
     * @type {number}
     * @memberof CropConfigCrops
     */
    H: number;
    /**
     * 
     * @type {number}
     * @memberof CropConfigCrops
     */
    p: number;
}
/**
 * 
 * @export
 * @interface CustomInsertionBid
 */
export interface CustomInsertionBid {
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionBid
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionBid
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionBid
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof CustomInsertionBid
     */
    bidNumber?: number | null;
}
/**
 * 
 * @export
 * @interface CustomInsertionPhysicalBuilding
 */
export interface CustomInsertionPhysicalBuilding {
    /**
     * 
     * @type {Array<CustomInsertionPhysicalBuildingAllOfFloors>}
     * @memberof CustomInsertionPhysicalBuilding
     */
    floors: Array<CustomInsertionPhysicalBuildingAllOfFloors>;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionPhysicalBuilding
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionPhysicalBuilding
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionPhysicalBuilding
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionPhysicalBuilding
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface CustomInsertionPhysicalBuildingAllOf
 */
export interface CustomInsertionPhysicalBuildingAllOf {
    /**
     * 
     * @type {Array<CustomInsertionPhysicalBuildingAllOfFloors>}
     * @memberof CustomInsertionPhysicalBuildingAllOf
     */
    floors: Array<CustomInsertionPhysicalBuildingAllOfFloors>;
}
/**
 * 
 * @export
 * @interface CustomInsertionPhysicalBuildingAllOfFloors
 */
export interface CustomInsertionPhysicalBuildingAllOfFloors {
    /**
     * 
     * @type {number}
     * @memberof CustomInsertionPhysicalBuildingAllOfFloors
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CustomInsertionPhysicalBuildingAllOfFloors
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CustomOptionalPhysicalBuilding
 */
export interface CustomOptionalPhysicalBuilding {
    /**
     * 
     * @type {Array<CustomInsertionPhysicalBuildingAllOfFloors>}
     * @memberof CustomOptionalPhysicalBuilding
     */
    floors?: Array<CustomInsertionPhysicalBuildingAllOfFloors>;
    /**
     * 
     * @type {string}
     * @memberof CustomOptionalPhysicalBuilding
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DeleteDocumentsRequest
 */
export interface DeleteDocumentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteDocumentsRequest
     */
    documentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteParsedFilesRequest
 */
export interface DeleteParsedFilesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteParsedFilesRequest
     */
    fileIds: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteTrashItemsRequest
 */
export interface DeleteTrashItemsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteTrashItemsRequest
     */
    s3Keys: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteTrashItemsResponse
 */
export interface DeleteTrashItemsResponse {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof DeleteTrashItemsResponse
     */
    isSuccessfullyDeleted: Array<boolean>;
}
/**
 * 
 * @export
 * @interface DeleteUserNotificationsParams
 */
export interface DeleteUserNotificationsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteUserNotificationsParams
     */
    userNotificationIds: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DesignActionTakenType {
    UploadedFile = 'uploaded_file',
    CreatedFolder = 'created_folder',
    DownloadedFile = 'downloaded_file',
    ViewedFile = 'viewed_file',
    DeletedNode = 'deleted_node',
    SharedNode = 'shared_node',
    SentDownloadLink = 'sent_download_link',
    SentPublicFolderAccessLink = 'sent_public_folder_access_link',
    ModifiedNode = 'modified_node',
    AccessNotification = 'access_notification'
}

/**
 * 
 * @export
 * @interface DesignFileActionResponse
 */
export interface DesignFileActionResponse {
    /**
     * 
     * @type {IPaginatedIDesignFileAction}
     * @memberof DesignFileActionResponse
     */
    actions: IPaginatedIDesignFileAction;
    /**
     * 
     * @type {IDesignFileAction}
     * @memberof DesignFileActionResponse
     */
    creationAction?: IDesignFileAction | null;
}
/**
 * 
 * @export
 * @interface DesignFileDownloadResponse
 */
export interface DesignFileDownloadResponse {
    /**
     * 
     * @type {string}
     * @memberof DesignFileDownloadResponse
     */
    downloadLink: string;
    /**
     * 
     * @type {IFile}
     * @memberof DesignFileDownloadResponse
     */
    file?: IFile;
    /**
     * 
     * @type {string}
     * @memberof DesignFileDownloadResponse
     */
    sanitizedFullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignFileDownloadResponse
     */
    isThumbnailFile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DesignFileDownloadResponse
     */
    sanitizedFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileDownloadResponse
     */
    originalFullKey?: string;
    /**
     * 
     * @type {FileNode}
     * @memberof DesignFileDownloadResponse
     */
    updatedFileNode?: FileNode;
}
/**
 * 
 * @export
 * @interface DesignFileUploadResponse
 */
export interface DesignFileUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    uploadLink?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    uploadId?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    s3Key: string;
    /**
     * 
     * @type {IFile}
     * @memberof DesignFileUploadResponse
     */
    file?: IFile;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    sanitizedFullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignFileUploadResponse
     */
    isThumbnailFile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    sanitizedFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUploadResponse
     */
    originalFullKey?: string;
    /**
     * 
     * @type {FileNode}
     * @memberof DesignFileUploadResponse
     */
    updatedFileNode?: FileNode;
}
/**
 * 
 * @export
 * @interface DesignFileUserPermissionsSimplified
 */
export interface DesignFileUserPermissionsSimplified {
    /**
     * 
     * @type {Array<FilePermissionType>}
     * @memberof DesignFileUserPermissionsSimplified
     */
    permissions: Array<FilePermissionType>;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUserPermissionsSimplified
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUserPermissionsSimplified
     */
    user_email: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUserPermissionsSimplified
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof DesignFileUserPermissionsSimplified
     */
    user_id: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DisciplineType {
    Architectural = 'architectural',
    Geotechnical = 'geotechnical',
    Civil = 'civil',
    Process = 'process',
    Electrical = 'electrical',
    FireProtection = 'fire_protection',
    General = 'general',
    HazardousMaterials = 'hazardous_materials',
    Interior = 'interior',
    Landscape = 'landscape',
    Mechanical = 'mechanical',
    Operations = 'operations',
    Plumbing = 'plumbing',
    Equipment = 'equipment',
    Resource = 'resource',
    Structural = 'structural',
    Telecommunications = 'telecommunications',
    SurveyMapping = 'survey_mapping',
    OtherDisciplines = 'other_disciplines',
    ContractorShopDrawings = 'contractor_shop_drawings'
}

/**
 * 
 * @export
 * @interface DocumentAssociationModification
 */
export interface DocumentAssociationModification {
    /**
     * 
     * @type {PickIDocumentId}
     * @memberof DocumentAssociationModification
     */
    simplifiedDocument: PickIDocumentId;
    /**
     * 
     * @type {string}
     * @memberof DocumentAssociationModification
     */
    userFriendlyIdentifier: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentAssociationModification
     */
    isRemoval: boolean;
}
/**
 * 
 * @export
 * @interface DocumentAssociationModifications
 */
export interface DocumentAssociationModifications {
    /**
     * 
     * @type {Array<DocumentAssociationModification>}
     * @memberof DocumentAssociationModifications
     */
    modifications: Array<DocumentAssociationModification>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentAssociationType {
    Default = 'default',
    Combined = 'combined'
}

/**
 * 
 * @export
 * @interface DocumentConformingCenterTemplateType
 */
export interface DocumentConformingCenterTemplateType {
}
/**
 * 
 * @export
 * @interface DocumentFileUploadRequest
 */
export interface DocumentFileUploadRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFileUploadRequest
     */
    useMultiPartUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileUploadRequest
     */
    fullFileName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileUploadRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileUploadRequest
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileUploadRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileUploadRequest
     */
    fullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFileUploadRequest
     */
    isPublic?: boolean;
    /**
     * 
     * @type {FileCategoryType}
     * @memberof DocumentFileUploadRequest
     */
    fileType: FileCategoryType;
    /**
     * 
     * @type {IInsertionComment}
     * @memberof DocumentFileUploadRequest
     */
    comment?: IInsertionComment;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFileUploadRequest
     */
    enableCommentNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFileUploadRequest
     */
    skipThumbnailGeneration?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentFlattenResponse
 */
export interface DocumentFlattenResponse {
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof DocumentFlattenResponse
     */
    document: INumberedDocumentView;
    /**
     * 
     * @type {IFile}
     * @memberof DocumentFlattenResponse
     */
    flattenedFile: IFile;
}
/**
 * 
 * @export
 * @interface DocumentNotificationFollowerUpdate
 */
export interface DocumentNotificationFollowerUpdate {
    /**
     * 
     * @type {Array<DocumentNotificationFollowerUpdateUserGroups>}
     * @memberof DocumentNotificationFollowerUpdate
     */
    userGroups: Array<DocumentNotificationFollowerUpdateUserGroups>;
    /**
     * 
     * @type {Array<DocumentNotificationFollowerUpdateUsers>}
     * @memberof DocumentNotificationFollowerUpdate
     */
    users: Array<DocumentNotificationFollowerUpdateUsers>;
}
/**
 * 
 * @export
 * @interface DocumentNotificationFollowerUpdateUserGroups
 */
export interface DocumentNotificationFollowerUpdateUserGroups {
    /**
     * 
     * @type {string}
     * @memberof DocumentNotificationFollowerUpdateUserGroups
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentNotificationFollowerUpdateUserGroups
     */
    id: string;
}
/**
 * 
 * @export
 * @interface DocumentNotificationFollowerUpdateUsers
 */
export interface DocumentNotificationFollowerUpdateUsers {
    /**
     * 
     * @type {string}
     * @memberof DocumentNotificationFollowerUpdateUsers
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentNotificationFollowerUpdateUsers
     */
    id: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentPriorityType {
    ForInformationOnly = 'for_information_only',
    Low = 'low',
    Medium = 'medium',
    High = 'high'
}

/**
 * 
 * @export
 * @interface DocumentS3UploadResponse
 */
export interface DocumentS3UploadResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentS3UploadResponse
     */
    uploadLink?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentS3UploadResponse
     */
    uploadId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentS3UploadResponse
     */
    s3Key: string;
    /**
     * 
     * @type {IFile}
     * @memberof DocumentS3UploadResponse
     */
    file?: IFile;
    /**
     * 
     * @type {string}
     * @memberof DocumentS3UploadResponse
     */
    sanitizedFullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentS3UploadResponse
     */
    isThumbnailFile?: boolean;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof DocumentS3UploadResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface DocumentS3UploadResponseAllOf
 */
export interface DocumentS3UploadResponseAllOf {
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof DocumentS3UploadResponseAllOf
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface DocumentSummaryType
 */
export interface DocumentSummaryType {
    /**
     * 
     * @type {number}
     * @memberof DocumentSummaryType
     */
    numOverdue: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSummaryType
     */
    numUnderReview: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSummaryType
     */
    numNew: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentSupertype {
    Simple = 'Simple',
    SubmittalWorkflow = 'SubmittalWorkflow',
    SubmittalPackageWorkflow = 'SubmittalPackageWorkflow',
    SimpleWorkflow = 'SimpleWorkflow',
    SimpleWorkflowWithResponse = 'SimpleWorkflowWithResponse',
    AdditionalReview = 'AdditionalReview'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTemplateType {
    BidAdvertisement = 'Bid Advertisement',
    BidTabulation = 'Bid Tabulation',
    InformationalItems = 'Informational Items',
    ProjectManual = 'Project Manual',
    SubstitutionRequests = 'Substitution Requests',
    RegulatoryApprovals = 'Regulatory Approvals',
    BidderRfIs = 'Bidder RFIs',
    Addenda = 'Addenda',
    BidDrawings = 'Bid Drawings',
    AsiDocuments = 'ASI Documents',
    ChangeOrders = 'Change Orders',
    ConstructionChangeDirectives = 'Construction Change Directives',
    ContractorDailyLogs = 'Contractor Daily Logs',
    FieldReports = 'Field Reports',
    MeetingMinutes = 'Meeting Minutes',
    MiscellaneousDocuments = 'Miscellaneous Documents',
    PayApplications = 'Pay Applications',
    PotentialChangeOrders = 'Potential Change Orders',
    RequestsForChange = 'Requests for Change',
    RequestsForInformation = 'Requests for Information',
    Schedules = 'Schedules',
    Submittals = 'Submittals',
    Testing = 'Testing',
    WorkChangeProposalRequests = 'Work Change Proposal Requests',
    AsBuilt = 'As-built',
    CertificateOfSubstantialCompletion = 'Certificate of Substantial Completion',
    ClearLp = 'Clear L&P',
    CloseoutSubmittals = 'Closeout Submittals',
    OperationsAndMaintenanceData = 'Operations and Maintenance Data',
    PunchList = 'Punch List',
    WarrantyItems = 'Warranty Items',
    Drawings = 'Drawings',
    Specifications = 'Specifications',
    Task = 'Task',
    AdditionalReview = 'Additional Review',
    SubmittalPackages = 'Submittal Packages',
    CloseoutSubmittalPackages = 'Closeout Submittal Packages',
    PlanholderList = 'Planholder List',
    AsBuiltPackages = 'As-built Packages',
    DesignPackages = 'Design Packages'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTemplateTypeAsBuilt {
    AsBuilt = 'As-built'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTemplateTypeDrawings {
    Drawings = 'Drawings'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTemplateTypeSpecifications {
    Specifications = 'Specifications'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTemplateTypeSubmittals {
    Submittals = 'Submittals'
}

/**
 * 
 * @export
 * @interface DrawingsParserRequest
 */
export interface DrawingsParserRequest {
    /**
     * 
     * @type {string}
     * @memberof DrawingsParserRequest
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof DrawingsParserRequest
     */
    originalFileId: string;
    /**
     * 
     * @type {CropConfig}
     * @memberof DrawingsParserRequest
     */
    titleBlockCropConfig: CropConfig;
    /**
     * 
     * @type {CropConfig}
     * @memberof DrawingsParserRequest
     */
    sheetNameCropConfig: CropConfig;
}
/**
 * 
 * @export
 * @interface DrawingsPreprocessorRequest
 */
export interface DrawingsPreprocessorRequest {
    /**
     * 
     * @type {string}
     * @memberof DrawingsPreprocessorRequest
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof DrawingsPreprocessorRequest
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface EditBidSetupResponse
 */
export interface EditBidSetupResponse {
    /**
     * 
     * @type {IBidSetup}
     * @memberof EditBidSetupResponse
     */
    bidSetup: IBidSetup;
    /**
     * 
     * @type {IProjectView}
     * @memberof EditBidSetupResponse
     */
    project: IProjectView;
}
/**
 * 
 * @export
 * @interface EditFavoriteProjectParams
 */
export interface EditFavoriteProjectParams {
    /**
     * 
     * @type {boolean}
     * @memberof EditFavoriteProjectParams
     */
    isFavorite: boolean;
}
/**
 * 
 * @export
 * @interface EditFileParams
 */
export interface EditFileParams {
    /**
     * 
     * @type {string}
     * @memberof EditFileParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFileParams
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface EditManyPhysicalFloorsRequest
 */
export interface EditManyPhysicalFloorsRequest {
    /**
     * 
     * @type {IOptionalPhysicalFloor}
     * @memberof EditManyPhysicalFloorsRequest
     */
    patch: IOptionalPhysicalFloor;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditManyPhysicalFloorsRequest
     */
    floorIds: Array<string>;
}
/**
 * 
 * @export
 * @interface EditUserNotificationsParams
 */
export interface EditUserNotificationsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof EditUserNotificationsParams
     */
    userNotificationIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserNotificationsParams
     */
    isRead: boolean;
}
/**
 * 
 * @export
 * @interface EmailRequest
 */
export interface EmailRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailRequest
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EmailStatus {
    UnsentWaiting = 'unsent_waiting',
    Sending = 'sending',
    Delivery = 'delivery',
    Bounce = 'bounce',
    Complaint = 'complaint',
    OtherFailure = 'other_failure'
}

/**
 * 
 * @export
 * @interface ErrorEmail
 */
export interface ErrorEmail {
    /**
     * 
     * @type {string}
     * @memberof ErrorEmail
     */
    message: string;
}
/**
 * 
 * @export
 * @interface FavoriteProjectsResponse
 */
export interface FavoriteProjectsResponse {
    /**
     * 
     * @type {Array<ProjectSummaryType>}
     * @memberof FavoriteProjectsResponse
     */
    projectSummaries: Array<ProjectSummaryType>;
    /**
     * 
     * @type {Array<IProjectView>}
     * @memberof FavoriteProjectsResponse
     */
    favoriteProjects: Array<IProjectView>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryType {
    Specifications = 'specifications',
    Drawings = 'drawings',
    PreprocessedDrawings = 'preprocessed_drawings',
    SpecificationSection = 'specification_section',
    SpecificationTableOfContents = 'specification_table_of_contents',
    DrawingsSection = 'drawings_section',
    BidSpecifications = 'bid_specifications',
    BidDrawings = 'bid_drawings',
    BidAddendas = 'bid_addendas',
    Bid = 'bid',
    Thumbnail = 'thumbnail',
    Bax = 'bax',
    DocumentAttachments = 'document_attachments',
    DocumentBase = 'document_base',
    DocumentBluebeamStudioLog = 'document_bluebeam_studio_log',
    DocumentUploads = 'document_uploads',
    DocumentGeneralContractorApproved = 'document_general_contractor_approved',
    DocumentArchitectApproved = 'document_architect_approved',
    DocumentConsultantUpload = 'document_consultant_upload',
    DocumentFinalExecuted = 'document_final_executed',
    DocumentFinalDeliverable = 'document_final_deliverable',
    CompletedBidTabulation = 'completed_bid_tabulation',
    ProjectArchiveJson = 'project_archive_json',
    ProjectArchiveZip = 'project_archive_zip',
    ProjectBidJson = 'project_bid_json',
    ProjectBidZip = 'project_bid_zip',
    PunchListLocation = 'punch_list_location',
    PunchListFloorPlan = 'punch_list_floor_plan',
    PunchListFloorPlanDrawing = 'punch_list_floor_plan_drawing'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeBidAddendas {
    BidAddendas = 'bid_addendas'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeBidDrawings {
    BidDrawings = 'bid_drawings'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeBidSpecifications {
    BidSpecifications = 'bid_specifications'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeCompletedBidTabulation {
    CompletedBidTabulation = 'completed_bid_tabulation'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeDrawings {
    Drawings = 'drawings'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeProjectArchiveJson {
    ProjectArchiveJson = 'project_archive_json'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeProjectArchiveZip {
    ProjectArchiveZip = 'project_archive_zip'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeProjectBidJson {
    ProjectBidJson = 'project_bid_json'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeProjectBidZip {
    ProjectBidZip = 'project_bid_zip'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileCategoryTypeSpecifications {
    Specifications = 'specifications'
}

/**
 * 
 * @export
 * @interface FileItem
 */
export interface FileItem {
    /**
     * 
     * @type {string}
     * @memberof FileItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FileItem
     */
    extension: string;
    /**
     * 
     * @type {string}
     * @memberof FileItem
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof FileItem
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof FileItem
     */
    lastModified?: string;
    /**
     * 
     * @type {string}
     * @memberof FileItem
     */
    fullPrefix: string;
}
/**
 * 
 * @export
 * @interface FileNode
 */
export interface FileNode {
    /**
     * 
     * @type {string}
     * @memberof FileNode
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof FileNode
     */
    relativeKey?: string;
    /**
     * 
     * @type {string}
     * @memberof FileNode
     */
    fullKey: string;
    /**
     * 
     * @type {FileItem}
     * @memberof FileNode
     */
    file?: FileItem;
    /**
     * 
     * @type {string}
     * @memberof FileNode
     */
    ownerId: string;
    /**
     * 
     * @type {Array<FileNodePermissions>}
     * @memberof FileNode
     */
    fileNodePermissions?: Array<FileNodePermissions>;
    /**
     * 
     * @type {string}
     * @memberof FileNode
     */
    type: FileNodeTypeEnum;
    /**
     * 
     * @type {Array<FileNode>}
     * @memberof FileNode
     */
    children?: Array<FileNode>;
}

/**
    * @export
    * @enum {string}
    */
export enum FileNodeTypeEnum {
    File = 'FILE',
    Folder = 'FOLDER'
}

/**
 * 
 * @export
 * @interface FileNodePermissions
 */
export interface FileNodePermissions {
    /**
     * 
     * @type {Array<FilePermissionType>}
     * @memberof FileNodePermissions
     */
    permissions: Array<FilePermissionType>;
    /**
     * 
     * @type {boolean}
     * @memberof FileNodePermissions
     */
    shareable: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileNodePermissions
     */
    parentFolderSharedName?: string;
    /**
     * 
     * @type {ShareFileSystemTarget}
     * @memberof FileNodePermissions
     */
    shareTarget: ShareFileSystemTarget;
    /**
     * 
     * @type {string}
     * @memberof FileNodePermissions
     */
    userIdTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof FileNodePermissions
     */
    userGroupIdTarget?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileNodePermissions
     */
    shareToEveryone?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FilePermissionType {
    Read = 'read',
    Modify = 'modify',
    Delete = 'delete'
}

/**
 * 
 * @export
 * @interface FileUploadRequestParams
 */
export interface FileUploadRequestParams {
    /**
     * 
     * @type {boolean}
     * @memberof FileUploadRequestParams
     */
    useMultiPartUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequestParams
     */
    fullFileName: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequestParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequestParams
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequestParams
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequestParams
     */
    fullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileUploadRequestParams
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface GenerateAsBuiltDocumentParams
 */
export interface GenerateAsBuiltDocumentParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateAsBuiltDocumentParams
     */
    followerUserIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateAsBuiltDocumentParams
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateAsBuiltDocumentParams
     */
    associatedDocumentIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface GenerateAsBuiltDocumentsRequest
 */
export interface GenerateAsBuiltDocumentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateAsBuiltDocumentsRequest
     */
    excludedDocumentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface GeneratePublicUserFileSystemLinkRequest
 */
export interface GeneratePublicUserFileSystemLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratePublicUserFileSystemLinkRequest
     */
    fullKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePublicUserFileSystemLinkRequest
     */
    formatted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneratePublicUserFileSystemLinkRequest
     */
    designFileShareId: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePublicUserFileSystemLinkRequest
     */
    publicAccessKey: string;
}
/**
 * 
 * @export
 * @interface GetAssociatedDocumentsResponse
 */
export interface GetAssociatedDocumentsResponse {
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof GetAssociatedDocumentsResponse
     */
    associatedDocumentsList: Array<IDocumentDocument>;
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof GetAssociatedDocumentsResponse
     */
    reverseAssociatedDocumentsList: Array<IDocumentDocument>;
}
/**
 * 
 * @export
 * @interface GetDocumentByKeyResponse
 */
export interface GetDocumentByKeyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDocumentByKeyResponse
     */
    isInteractive: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentByKeyResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentByKeyResponse
     */
    email: string;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof GetDocumentByKeyResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface GetDocumentPublicLinkResponse
 */
export interface GetDocumentPublicLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentPublicLinkResponse
     */
    link: string;
    /**
     * 
     * @type {IDocumentUserPublicKey}
     * @memberof GetDocumentPublicLinkResponse
     */
    publicDocumentKey: IDocumentUserPublicKey;
}
/**
 * 
 * @export
 * @interface GetManyDocumentLinksRequest
 */
export interface GetManyDocumentLinksRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetManyDocumentLinksRequest
     */
    documentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface GetManyDocumentPublicLinksResponse
 */
export interface GetManyDocumentPublicLinksResponse {
    /**
     * 
     * @type {string}
     * @memberof GetManyDocumentPublicLinksResponse
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof GetManyDocumentPublicLinksResponse
     */
    link: string;
    /**
     * 
     * @type {IDocumentUserPublicKey}
     * @memberof GetManyDocumentPublicLinksResponse
     */
    publicDocumentKey: IDocumentUserPublicKey;
}
/**
 * 
 * @export
 * @interface GetProjectZipResponse
 */
export interface GetProjectZipResponse {
    /**
     * 
     * @type {S3DownloadResponse}
     * @memberof GetProjectZipResponse
     */
    s3Response?: S3DownloadResponse;
    /**
     * 
     * @type {IFile}
     * @memberof GetProjectZipResponse
     */
    file?: IFile;
    /**
     * 
     * @type {IProjectArchiveJobStatus}
     * @memberof GetProjectZipResponse
     */
    jobStatus: IProjectArchiveJobStatus;
}
/**
 * 
 * @export
 * @interface GetSuggestedProjectEmailSlugRequest
 */
export interface GetSuggestedProjectEmailSlugRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSuggestedProjectEmailSlugRequest
     */
    emailSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSuggestedProjectEmailSlugRequest
     */
    projectName: string;
}
/**
 * 
 * @export
 * @interface GetTrashItem
 */
export interface GetTrashItem {
    /**
     * 
     * @type {string}
     * @memberof GetTrashItem
     */
    s3Key: string;
    /**
     * 
     * @type {string}
     * @memberof GetTrashItem
     */
    fullKey: string;
    /**
     * 
     * @type {string}
     * @memberof GetTrashItem
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof GetTrashItem
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetTrashItem
     */
    lastModified?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTrashItem
     */
    size?: number;
}
/**
 * 
 * @export
 * @interface GetTrashItemRequest
 */
export interface GetTrashItemRequest {
    /**
     * 
     * @type {string}
     * @memberof GetTrashItemRequest
     */
    s3Key: string;
}
/**
 * 
 * @export
 * @interface GetTrashItemsResponse
 */
export interface GetTrashItemsResponse {
    /**
     * 
     * @type {Array<GetTrashItem>}
     * @memberof GetTrashItemsResponse
     */
    trashItems: Array<GetTrashItem>;
    /**
     * 
     * @type {string}
     * @memberof GetTrashItemsResponse
     */
    continuationToken?: string;
}
/**
 * 
 * @export
 * @interface GetUserDocumentPermissionResponse
 */
export interface GetUserDocumentPermissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDocumentPermissionResponse
     */
    isAssignableToDocument: boolean;
    /**
     * 
     * @type {IDocumentUserPermissionView}
     * @memberof GetUserDocumentPermissionResponse
     */
    permission: IDocumentUserPermissionView | null;
}
/**
 * 
 * @export
 * @interface IAddNewUser
 */
export interface IAddNewUser {
    /**
     * 
     * @type {ICustomInsertionUser}
     * @memberof IAddNewUser
     */
    user: ICustomInsertionUser;
    /**
     * 
     * @type {string}
     * @memberof IAddNewUser
     */
    securityGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddNewUser
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface IAddress
 */
export interface IAddress {
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IAddress
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    stateOrProvinceId: string;
    /**
     * 
     * @type {ICompany}
     * @memberof IAddress
     */
    company?: ICompany | null;
    /**
     * 
     * @type {IStateOrProvince}
     * @memberof IAddress
     */
    stateOrProvince?: IStateOrProvince | null;
}
/**
 * 
 * @export
 * @interface IAnnouncement
 */
export interface IAnnouncement {
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IAnnouncement
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    postTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    lastTimestamp?: string | null;
    /**
     * 
     * @type {AnnouncementBroadcastOption}
     * @memberof IAnnouncement
     */
    broadcastTo: AnnouncementBroadcastOption;
    /**
     * 
     * @type {AnnouncementEmailOption}
     * @memberof IAnnouncement
     */
    emailTo: AnnouncementEmailOption;
    /**
     * 
     * @type {string}
     * @memberof IAnnouncement
     */
    creatorUserId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IAnnouncement
     */
    creatorUser?: IUser | null;
}
/**
 * 
 * @export
 * @interface IApiOutboundEmail
 */
export interface IApiOutboundEmail {
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    createdOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IApiOutboundEmail
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    beganSendingOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    sentOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    sendError?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IApiOutboundEmail
     */
    messageId?: string | null;
    /**
     * 
     * @type {IEmailLog}
     * @memberof IApiOutboundEmail
     */
    emailLog?: IEmailLog | null;
}
/**
 * 
 * @export
 * @interface IAuthorizeParams
 */
export interface IAuthorizeParams {
    /**
     * 
     * @type {string}
     * @memberof IAuthorizeParams
     */
    code: string;
}
/**
 * 
 * @export
 * @interface IBid
 */
export interface IBid {
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IBid
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    projectId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IBid
     */
    isRetracted: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBid
     */
    openedOn?: string | null;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IBid
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {IProjectUser}
     * @memberof IBid
     */
    projectUser?: IProjectUser | null;
    /**
     * 
     * @type {number}
     * @memberof IBid
     */
    bidNumber?: number | null;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof IBid
     */
    notifications?: Array<INotification>;
}
/**
 * 
 * @export
 * @interface IBidSetup
 */
export interface IBidSetup {
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IBidSetup
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    allowInterestedPartiesToViewBidTabulation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    allowElectronicSubstitutionRequestSubmittals?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    allowElectronicBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    requireElectronicBidding?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IBidSetup
     */
    estimatedConstructionCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    allowElectronicRfiSubmittals: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    bidLocationCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    hasPreBidConference: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    liveAt: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    requestDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    preDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    addendaDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBidSetup
     */
    shouldSetPrivate: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    contactUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    alternativeContactId?: string | null;
    /**
     * 
     * @type {IAddress}
     * @memberof IBidSetup
     */
    address?: IAddress | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    stateOrProvinceId?: string | null;
    /**
     * 
     * @type {IStateOrProvince}
     * @memberof IBidSetup
     */
    stateOrProvince?: IStateOrProvince | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetup
     */
    bidNotificationGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IBidSetup
     */
    bidNotificationGroup?: IUserGroup | null;
    /**
     * 
     * @type {IUser}
     * @memberof IBidSetup
     */
    contactUser?: IUser | null;
    /**
     * 
     * @type {IBidSetupAlternativeContact}
     * @memberof IBidSetup
     */
    alternativeContact?: IBidSetupAlternativeContact | null;
    /**
     * 
     * @type {IProject}
     * @memberof IBidSetup
     */
    project?: IProject | null;
    /**
     * 
     * @type {Array<ISimplifiedBidSetupActivityLogEntry>}
     * @memberof IBidSetup
     */
    simplifiedActivityLog?: Array<ISimplifiedBidSetupActivityLogEntry>;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IBidSetup
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof IBidSetup
     */
    notifications?: Array<INotification>;
}
/**
 * 
 * @export
 * @interface IBidSetupAlternativeContact
 */
export interface IBidSetupAlternativeContact {
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IBidSetupAlternativeContact
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    firm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBidSetupAlternativeContact
     */
    addressId?: string | null;
    /**
     * 
     * @type {IAddress}
     * @memberof IBidSetupAlternativeContact
     */
    address?: IAddress | null;
}
/**
 * 
 * @export
 * @interface IComment
 */
export interface IComment {
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IComment
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    creatorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IComment
     */
    consultantParentDocumentId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IComment
     */
    creatorUser?: IUser | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IComment
     */
    document?: IDocument | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IComment
     */
    consultantParentDocument?: IDocument | null;
    /**
     * 
     * @type {IProject}
     * @memberof IComment
     */
    project?: IProject | null;
    /**
     * 
     * @type {boolean}
     * @memberof IComment
     */
    isCopiedFromConsultantReview?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IComment
     */
    isOnProcore?: boolean | null;
}
/**
 * 
 * @export
 * @interface ICompany
 */
export interface ICompany {
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof ICompany
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICompany
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {Array<IAddress>}
     * @memberof ICompany
     */
    addresses?: Array<IAddress>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof ICompany
     */
    assignedDocuments?: Array<IDocument>;
    /**
     * 
     * @type {Array<IPhoneNumber>}
     * @memberof ICompany
     */
    phoneNumbers?: Array<IPhoneNumber>;
    /**
     * 
     * @type {Array<IUser>}
     * @memberof ICompany
     */
    users?: Array<IUser>;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    website?: string | null;
}
/**
 * 
 * @export
 * @interface IConformingCenterCategorizedDocument
 */
export interface IConformingCenterCategorizedDocument {
    /**
     * 
     * @type {DocumentConformingCenterTemplateType}
     * @memberof IConformingCenterCategorizedDocument
     */
    documentType: DocumentConformingCenterTemplateType;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof IConformingCenterCategorizedDocument
     */
    document: INumberedDocumentView;
    /**
     * 
     * @type {boolean}
     * @memberof IConformingCenterCategorizedDocument
     */
    isConflicting: boolean;
    /**
     * 
     * @type {ConformingCenterConflictResolutionType}
     * @memberof IConformingCenterCategorizedDocument
     */
    conflictType: ConformingCenterConflictResolutionType;
    /**
     * 
     * @type {boolean}
     * @memberof IConformingCenterCategorizedDocument
     */
    isGoodMatch: boolean;
}
/**
 * 
 * @export
 * @interface IConformingCenterProjectSummary
 */
export interface IConformingCenterProjectSummary {
    /**
     * 
     * @type {number}
     * @memberof IConformingCenterProjectSummary
     */
    numSubmittalsNotPublishedWithPublishedSpecification: number;
    /**
     * 
     * @type {number}
     * @memberof IConformingCenterProjectSummary
     */
    numAsBuiltsBeforeUnpublishedWithPublishedDrawing: number;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummary
     */
    specificationSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummary
     */
    submittalSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummary
     */
    drawingSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummary
     */
    asBuiltSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {{ [key: string]: IConformingCenterProjectSummarySummaryByParsedFromFileId; }}
     * @memberof IConformingCenterProjectSummary
     */
    summaryByParsedFromFileId: { [key: string]: IConformingCenterProjectSummarySummaryByParsedFromFileId; };
}
/**
 * 
 * @export
 * @interface IConformingCenterProjectSummarySummaryByParsedFromFileId
 */
export interface IConformingCenterProjectSummarySummaryByParsedFromFileId {
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummarySummaryByParsedFromFileId
     */
    asBuiltSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummarySummaryByParsedFromFileId
     */
    drawingSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummarySummaryByParsedFromFileId
     */
    submittalSummary: IConformingDocumentsSummary;
    /**
     * 
     * @type {IConformingDocumentsSummary}
     * @memberof IConformingCenterProjectSummarySummaryByParsedFromFileId
     */
    specificationSummary: IConformingDocumentsSummary;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummary
 */
export interface IConformingDocumentsSummary {
    /**
     * 
     * @type {IConformingDocumentsSummaryPublished}
     * @memberof IConformingDocumentsSummary
     */
    published: IConformingDocumentsSummaryPublished;
    /**
     * 
     * @type {IConformingDocumentsSummaryUnpublished}
     * @memberof IConformingDocumentsSummary
     */
    unpublished: IConformingDocumentsSummaryUnpublished;
    /**
     * 
     * @type {IConformingDocumentsSummaryBeforeUnpublished}
     * @memberof IConformingDocumentsSummary
     */
    beforeUnpublished: IConformingDocumentsSummaryBeforeUnpublished;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummaryBeforeUnpublished
 */
export interface IConformingDocumentsSummaryBeforeUnpublished {
    /**
     * 
     * @type {number}
     * @memberof IConformingDocumentsSummaryBeforeUnpublished
     */
    numDocuments: number;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummaryPublished
 */
export interface IConformingDocumentsSummaryPublished {
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryPublished
     */
    documentIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IConformingDocumentsSummaryPublished
     */
    numDocuments: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryPublished
     */
    uniqSpecSections: Array<string>;
    /**
     * 
     * @type {Array<IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers>}
     * @memberof IConformingDocumentsSummaryPublished
     */
    uniqDrawingsIdentifiers: Array<IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers>;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers
 */
export interface IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers {
    /**
     * 
     * @type {string}
     * @memberof IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers
     */
    simplePackage?: string;
    /**
     * 
     * @type {string}
     * @memberof IConformingDocumentsSummaryPublishedUniqDrawingsIdentifiers
     */
    sheetNumber: string;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummaryUnpublished
 */
export interface IConformingDocumentsSummaryUnpublished {
    /**
     * 
     * @type {IConformingDocumentsSummaryUnpublishedPoorMatch}
     * @memberof IConformingDocumentsSummaryUnpublished
     */
    poorMatch: IConformingDocumentsSummaryUnpublishedPoorMatch;
    /**
     * 
     * @type {IConformingDocumentsSummaryUnpublishedPoorMatch}
     * @memberof IConformingDocumentsSummaryUnpublished
     */
    goodMatch: IConformingDocumentsSummaryUnpublishedPoorMatch;
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryUnpublished
     */
    uniqueSheetNumbers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryUnpublished
     */
    uniqueSpecSections: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IConformingDocumentsSummaryUnpublished
     */
    numDocuments: number;
}
/**
 * 
 * @export
 * @interface IConformingDocumentsSummaryUnpublishedPoorMatch
 */
export interface IConformingDocumentsSummaryUnpublishedPoorMatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryUnpublishedPoorMatch
     */
    nonConflictingDocumentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IConformingDocumentsSummaryUnpublishedPoorMatch
     */
    conflictingDocumentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface ICountry
 */
export interface ICountry {
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof ICountry
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICountry
     */
    phoneCountryCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICountry
     */
    visible?: boolean | null;
    /**
     * 
     * @type {Array<IPhoneNumber>}
     * @memberof ICountry
     */
    phoneNumbers?: Array<IPhoneNumber>;
    /**
     * 
     * @type {Array<IStateOrProvince>}
     * @memberof ICountry
     */
    statesOrProvinces?: Array<IStateOrProvince>;
}
/**
 * 
 * @export
 * @interface ICustomInsertionBidSetup
 */
export interface ICustomInsertionBidSetup {
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    allowInterestedPartiesToViewBidTabulation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    allowElectronicSubstitutionRequestSubmittals?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    allowElectronicBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    requireElectronicBidding?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionBidSetup
     */
    estimatedConstructionCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    allowElectronicRfiSubmittals: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    bidLocationCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionBidSetup
     */
    hasPreBidConference: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    liveAt: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    requestDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    preDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    addendaDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    contactUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    alternativeContactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    stateOrProvinceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionBidSetup
     */
    bidNotificationGroupId?: string | null;
}
/**
 * 
 * @export
 * @interface ICustomInsertionProject
 */
export interface ICustomInsertionProject {
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    ownerProjectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    architectProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    ownerProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    defaultGeneralContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    defaultArchitectUserId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    liquidatedDamagesPerDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    documentReviewPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    rfiReviewPeriod?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    noticeToProceedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    scheduledEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    substantialCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    oneYearInspectionAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    warrantyPeriodStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    warrantyPeriodEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionProject
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionProject
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    lastArchivedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    bimOwnerUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    preferredBimProject?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    procoreProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomInsertionProject
     */
    procoreCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    bidTabulationsFileId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionProject
     */
    isProcoreIntegrationEnabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    procoreOauthId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    punchListNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    defaultNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    emailSlug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    procoreSubmittalsLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    procoreRfisLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    procoreSubmittalPackagesLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionProject
     */
    subscriberId?: string;
}
/**
 * 
 * @export
 * @interface ICustomInsertionUser
 */
export interface ICustomInsertionUser {
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    contractorLicenseNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isRegistered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isDisabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isSiteAdmin?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    website?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomInsertionUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    adminOfSubscriberId?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomInsertionUser
     */
    disciplineNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    impersonationUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    publicCompanyName?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomInsertionUser
     */
    reminderNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isRegistrationReminderDisabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomInsertionUser
     */
    bimOauthId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInsertionUser
     */
    isLettermansImport?: boolean | null;
    /**
     * 
     * @type {Array<IUserInsertionUserDiscipline>}
     * @memberof ICustomInsertionUser
     */
    userDisciplineList?: Array<IUserInsertionUserDiscipline>;
}
/**
 * 
 * @export
 * @interface ICustomOptionalBidSetup
 */
export interface ICustomOptionalBidSetup {
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    allowInterestedPartiesToViewBidTabulation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    allowElectronicSubstitutionRequestSubmittals?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    allowElectronicBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    requireElectronicBidding?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ICustomOptionalBidSetup
     */
    estimatedConstructionCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    allowElectronicRfiSubmittals?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    bidLocationCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalBidSetup
     */
    hasPreBidConference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    liveAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    requestDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    preDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    addendaDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    contactUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    alternativeContactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    stateOrProvinceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalBidSetup
     */
    bidNotificationGroupId?: string | null;
}
/**
 * 
 * @export
 * @interface ICustomOptionalUser
 */
export interface ICustomOptionalUser {
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    contractorLicenseNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isRegistered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isDisabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isSiteAdmin?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    website?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomOptionalUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    adminOfSubscriberId?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomOptionalUser
     */
    disciplineNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    impersonationUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    publicCompanyName?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof ICustomOptionalUser
     */
    reminderNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isRegistrationReminderDisabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ICustomOptionalUser
     */
    bimOauthId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomOptionalUser
     */
    isLettermansImport?: boolean | null;
    /**
     * 
     * @type {Array<IUserInsertionUserDiscipline>}
     * @memberof ICustomOptionalUser
     */
    userDisciplineList?: Array<IUserInsertionUserDiscipline>;
}
/**
 * 
 * @export
 * @interface IDeleteUsersByEmailParams
 */
export interface IDeleteUsersByEmailParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof IDeleteUsersByEmailParams
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface IDesignFile
 */
export interface IDesignFile {
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFile
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDesignFile
     */
    size?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDesignFile
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IDesignFile
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFile
     */
    creatorUserId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFile
     */
    creatorUser?: IUser | null;
    /**
     * 
     * @type {Array<IDesignFileShare>}
     * @memberof IDesignFile
     */
    shares?: Array<IDesignFileShare>;
}
/**
 * 
 * @export
 * @interface IDesignFileAction
 */
export interface IDesignFileAction {
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFileAction
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IDesignFileAction
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    ownerUserId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFileAction
     */
    ownerUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    fullKey: string;
    /**
     * 
     * @type {DesignActionTakenType}
     * @memberof IDesignFileAction
     */
    actionTaken: DesignActionTakenType;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    initiatorUserId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFileAction
     */
    initiatorUser?: IUser | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IDesignFileAction
     */
    sentToEmails?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    previousFullKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileAction
     */
    newFullKey?: string | null;
    /**
     * 
     * @type {Array<DesignFileUserPermissionsSimplified>}
     * @memberof IDesignFileAction
     */
    filePermissions?: Array<DesignFileUserPermissionsSimplified> | null;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof IDesignFileAction
     */
    notifications?: Array<INotification>;
}
/**
 * 
 * @export
 * @interface IDesignFileShare
 */
export interface IDesignFileShare {
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFileShare
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    designFileId: string;
    /**
     * 
     * @type {IDesignFile}
     * @memberof IDesignFileShare
     */
    designFile?: IDesignFile | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    userId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDesignFileShare
     */
    user?: IUser | null;
    /**
     * 
     * @type {DisciplineType}
     * @memberof IDesignFileShare
     */
    discipline?: DisciplineType | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    userGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IDesignFileShare
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDesignFileShare
     */
    sharedToEveryone?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    publicAccessKey?: string | null;
    /**
     * 
     * @type {Array<IFilePermission>}
     * @memberof IDesignFileShare
     */
    permissions?: Array<IFilePermission>;
    /**
     * 
     * @type {string}
     * @memberof IDesignFileShare
     */
    externalEmail?: string | null;
}
/**
 * 
 * @export
 * @interface IDocument
 */
export interface IDocument {
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    creatorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    documentTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    projectId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    titleConfidence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    referenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    cost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    approvedCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    requestedAdditionalDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    coNumber?: number | null;
    /**
     * 
     * @type {ImpactType}
     * @memberof IDocument
     */
    impacts?: ImpactType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    proposedAction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    proposedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    approvedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    proposedTimeImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    approvedTimeImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    reportedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    ballInCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    architectUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    architectUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    generalContractorUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    generalContractorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    subcontractorUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    subcontractorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateSentOut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    changeOrdersValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    changeOrdersDaysAdded?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    receivedFrom?: string | null;
    /**
     * 
     * @type {TypeOfTestingType}
     * @memberof IDocument
     */
    typeOfTesting?: TypeOfTestingType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    typeOfTestingOther?: string | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IDocument
     */
    actionTaken?: ActionTakenType | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IDocument
     */
    recommendedAction?: ActionTakenType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalSection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalParagraphNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalSubparagraphNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    revisionNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    request?: string | null;
    /**
     * 
     * @type {WorkflowStatusType}
     * @memberof IDocument
     */
    workflowStatus?: WorkflowStatusType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isWorkflowInverted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    anticipatedInitialSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    actualSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateReturnedToContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateSubmitted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    additionalReviewRecommendedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    reviewCompleteOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    additionalReviewForDocumentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    paragraphNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    proposedSubstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    substitutionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    substitutionAffectsDrawing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    substitutionAffectsTrades?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    substitutionAffectedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    substitutionDifferences?: string | null;
    /**
     * 
     * @type {ManufacturersWarrantyType}
     * @memberof IDocument
     */
    manufacturersWarranties?: ManufacturersWarrantyType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    manufacturersWarrantiesExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    informationAvailable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalPackageDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocument
     */
    submittalPackageDocument?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalSectionFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IDocument
     */
    submittalSectionFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    drawingsSectionFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IDocument
     */
    drawingsSectionFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    completedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    subcontractorAssignedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalUploadedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    creatorUser?: IUser | null;
    /**
     * 
     * @type {IDocumentTemplate}
     * @memberof IDocument
     */
    documentTemplate?: IDocumentTemplate | null;
    /**
     * 
     * @type {IProject}
     * @memberof IDocument
     */
    project?: IProject | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    additionalReviewDocuments?: Array<IDocument>;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    additionalReviewRecommendedBy?: IUser | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocument
     */
    additionalReviewForDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IDocument
     */
    comments?: Array<IComment>;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IDocument
     */
    consultantComments?: Array<IComment>;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IDocument
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {Array<IDocumentUser>}
     * @memberof IDocument
     */
    documentUserList?: Array<IDocumentUser>;
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof IDocument
     */
    associatedDocumentsList?: Array<IDocumentDocument>;
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof IDocument
     */
    reverseAssociatedDocumentsList?: Array<IDocumentDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    submittalChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    numberOfSheets?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    shouldSyncProcore?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateApproved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    agency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateContractorCompleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof IDocument
     */
    notifications?: Array<INotification>;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    contactCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isFinalExecuted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateReturnedToReviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    addendumNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isDraft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    weather?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IDocument
     */
    partiesPresent?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    customArchitect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    customContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    currentWorkInProgress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    documentNumber?: number | null;
    /**
     * 
     * @type {ResponsiblePartyType}
     * @memberof IDocument
     */
    responsibleParty?: ResponsiblePartyType | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocument
     */
    assignedToUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    assignedToUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    reviewComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateOfObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    parsedFromFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IDocument
     */
    parsedFromFile?: IFile | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    procoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    sheetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    sheetNumberConfidence?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isFileLocked?: boolean | null;
    /**
     * 
     * @type {Array<IDocumentUserGroup>}
     * @memberof IDocument
     */
    documentUserGroupList?: Array<IDocumentUserGroup>;
    /**
     * 
     * @type {Array<IDocumentUserGroupUserView>}
     * @memberof IDocument
     */
    documentUserGroupUserViewList?: Array<IDocumentUserGroupUserView>;
    /**
     * 
     * @type {INotification}
     * @memberof IDocument
     */
    lockedNotificationJson?: INotification | null;
    /**
     * 
     * @type {IDocumentConsultantReviewSummaryView}
     * @memberof IDocument
     */
    consultantSummary?: IDocumentConsultantReviewSummaryView | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    additionalReviewForPackageDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocument
     */
    additionalReviewForPackageDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    reviewChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isProcoreOriginated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isResponseOnProcore?: boolean | null;
    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof IDocument
     */
    procoreDocumentType?: ProcoreDocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreCreatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreCreatedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreCreatedByEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    procoreCreatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    procoreBallInCourtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreBallInCourtName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreBallInCourtEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    procoreResponsibleContractorId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    procoreResponsibleContractorName?: string | null;
    /**
     * 
     * @type {PunchListStatusType}
     * @memberof IDocument
     */
    punchListStatus?: PunchListStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    physicalLocationId?: string | null;
    /**
     * 
     * @type {IPhysicalLocation}
     * @memberof IDocument
     */
    physicalLocation?: IPhysicalLocation | null;
    /**
     * 
     * @type {ItemCoordinates}
     * @memberof IDocument
     */
    markedLocation?: ItemCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalExtendedIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    submittalDivision?: string | null;
    /**
     * 
     * @type {PublishStatusType}
     * @memberof IDocument
     */
    publishStatus?: PublishStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    parsedParentDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocument
     */
    parsedParentDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    parsedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    linkedParentDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocument
     */
    linkedParentDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    linkedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocument
     */
    notGeneratedParsedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    willPublishResponse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    datePublished?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateFinalExecuted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    dateOfLastUpdate?: string | null;
    /**
     * 
     * @type {DocumentPriorityType}
     * @memberof IDocument
     */
    priority?: DocumentPriorityType | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    simplePackage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    expectedCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    bluebeamStudioSessionId?: string | null;
}
/**
 * 
 * @export
 * @interface IDocumentConsultantReviewSummaryView
 */
export interface IDocumentConsultantReviewSummaryView {
    /**
     * 
     * @type {string}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    documentId: string;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviews: number;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviewsSubmittedForReview: number;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviewsUnderReview: number;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviewsReviewComplete: number;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviewsResolved: number;
    /**
     * 
     * @type {number}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    numConsultantReviewsResubmitted: number;
    /**
     * 
     * @type {any}
     * @memberof IDocumentConsultantReviewSummaryView
     */
    summaryByReview: any | null;
}
/**
 * 
 * @export
 * @interface IDocumentDocument
 */
export interface IDocumentDocument {
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentDocument
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    baseDocumentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentDocument
     */
    associatedDocumentId: string;
    /**
     * 
     * @type {DocumentAssociationType}
     * @memberof IDocumentDocument
     */
    associationType?: DocumentAssociationType | null;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof IDocumentDocument
     */
    baseDocument?: INumberedDocumentView | null;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof IDocumentDocument
     */
    associatedDocument?: INumberedDocumentView | null;
}
/**
 * 
 * @export
 * @interface IDocumentField
 */
export interface IDocumentField {
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentField
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentField
     */
    displayName: string;
}
/**
 * 
 * @export
 * @interface IDocumentTemplate
 */
export interface IDocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentTemplate
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplate
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentTemplate
     */
    isHidden: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentTemplate
     */
    isPublic?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentTemplate
     */
    isPublicWhenLocked?: boolean | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocumentTemplate
     */
    documents?: Array<IDocument>;
    /**
     * 
     * @type {Array<IDocumentTemplateDocumentField>}
     * @memberof IDocumentTemplate
     */
    documentTemplateDocumentFieldList?: Array<IDocumentTemplateDocumentField>;
}
/**
 * 
 * @export
 * @interface IDocumentTemplateDocumentField
 */
export interface IDocumentTemplateDocumentField {
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentTemplateDocumentField
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    documentTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentTemplateDocumentField
     */
    documentFieldId: string;
    /**
     * 
     * @type {number}
     * @memberof IDocumentTemplateDocumentField
     */
    position?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentTemplateDocumentField
     */
    isRequired: boolean;
    /**
     * 
     * @type {IDocumentTemplate}
     * @memberof IDocumentTemplateDocumentField
     */
    documentTemplate?: IDocumentTemplate | null;
    /**
     * 
     * @type {IDocumentField}
     * @memberof IDocumentTemplateDocumentField
     */
    documentField?: IDocumentField | null;
}
/**
 * 
 * @export
 * @interface IDocumentUser
 */
export interface IDocumentUser {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUser
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUser
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUser
     */
    isOnNotificationList: boolean;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IDocumentUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUser
     */
    disableReminders?: boolean | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocumentUser
     */
    document?: IDocument | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUser
     */
    user?: IUser | null;
}
/**
 * 
 * @export
 * @interface IDocumentUserGroup
 */
export interface IDocumentUserGroup {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserGroup
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    documentId: string;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocumentUserGroup
     */
    document?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroup
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IDocumentUserGroup
     */
    userGroup?: IUserGroup | null;
}
/**
 * 
 * @export
 * @interface IDocumentUserGroupUserView
 */
export interface IDocumentUserGroupUserView {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroupUserView
     */
    documentId: string;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocumentUserGroupUserView
     */
    document?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroupUserView
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IDocumentUserGroupUserView
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserGroupUserView
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserGroupUserView
     */
    user?: IUser | null;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUserGroupUserView
     */
    isIncludedByCompany: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUserGroupUserView
     */
    isIncludedByUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUserGroupUserView
     */
    isIncludedByDiscipline: boolean;
}
/**
 * 
 * @export
 * @interface IDocumentUserPermissionView
 */
export interface IDocumentUserPermissionView {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserPermissionView
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPermissionView
     */
    securityGroupId: string;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocumentUserPermissionView
     */
    document?: IDocument | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserPermissionView
     */
    user?: IUser | null;
    /**
     * 
     * @type {ISecurityGroup}
     * @memberof IDocumentUserPermissionView
     */
    securityGroup?: ISecurityGroup | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IDocumentUserPermissionView
     */
    securityPermissionLevel?: SecurityPermissionLevel | null;
}
/**
 * 
 * @export
 * @interface IDocumentUserPublicKey
 */
export interface IDocumentUserPublicKey {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserPublicKey
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    documentId: string;
    /**
     * 
     * @type {IDocument}
     * @memberof IDocumentUserPublicKey
     */
    document?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IDocumentUserPublicKey
     */
    user?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDocumentUserPublicKey
     */
    isInteractive: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUserPublicKey
     */
    expires?: string | null;
}
/**
 * 
 * @export
 * @interface IDrawingsParserJobInput
 */
export interface IDrawingsParserJobInput {
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobInput
     */
    objectKey: string;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobInput
     */
    originalObjectKey: string;
    /**
     * 
     * @type {CropConfig}
     * @memberof IDrawingsParserJobInput
     */
    titleBlockCropConfig?: CropConfig;
    /**
     * 
     * @type {CropConfig}
     * @memberof IDrawingsParserJobInput
     */
    sheetNameCropConfig?: CropConfig;
}
/**
 * 
 * @export
 * @interface IDrawingsParserJobStatus
 */
export interface IDrawingsParserJobStatus {
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IDrawingsParserJobStatus
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {JobStatusType}
     * @memberof IDrawingsParserJobStatus
     */
    status: JobStatusType;
    /**
     * 
     * @type {JobType}
     * @memberof IDrawingsParserJobStatus
     */
    jobType: JobType;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {IDrawingsParserJobStatusProgress}
     * @memberof IDrawingsParserJobStatus
     */
    progress: IDrawingsParserJobStatusProgress;
    /**
     * 
     * @type {number}
     * @memberof IDrawingsParserJobStatus
     */
    percentComplete?: number | null;
    /**
     * 
     * @type {IDrawingsParserJobInput}
     * @memberof IDrawingsParserJobStatus
     */
    input: IDrawingsParserJobInput;
    /**
     * 
     * @type {IDrawingsParserJobStatusOutput}
     * @memberof IDrawingsParserJobStatus
     */
    output: IDrawingsParserJobStatusOutput;
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatus
     */
    error?: string | null;
}
/**
 * 
 * @export
 * @interface IDrawingsParserJobStatusOutput
 */
export interface IDrawingsParserJobStatusOutput {
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatusOutput
     */
    processedFileId: string;
}
/**
 * 
 * @export
 * @interface IDrawingsParserJobStatusProgress
 */
export interface IDrawingsParserJobStatusProgress {
    /**
     * 
     * @type {string}
     * @memberof IDrawingsParserJobStatusProgress
     */
    stage: IDrawingsParserJobStatusProgressStageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IDrawingsParserJobStatusProgressStageEnum {
    Optimizing = 'optimizing',
    Parsing = 'parsing'
}

/**
 * 
 * @export
 * @interface IEditDocumentSettings
 */
export interface IEditDocumentSettings {
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IEditDocumentSettings
     */
    notificationSetting?: NotificationSettingType | null;
}
/**
 * 
 * @export
 * @interface IEmailLog
 */
export interface IEmailLog {
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    createdOn: string;
    /**
     * 
     * @type {any}
     * @memberof IEmailLog
     */
    response?: any | null;
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    messageNotificationType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    sourceEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmailLog
     */
    intendedRecipientEmail?: string | null;
}
/**
 * 
 * @export
 * @interface IFaq
 */
export interface IFaq {
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IFaq
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    faqCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof IFaq
     */
    answer: string;
    /**
     * 
     * @type {number}
     * @memberof IFaq
     */
    displayOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFaq
     */
    isPublished: boolean;
    /**
     * 
     * @type {IFaqCategory}
     * @memberof IFaq
     */
    faqCategory?: IFaqCategory | null;
}
/**
 * 
 * @export
 * @interface IFaqCategory
 */
export interface IFaqCategory {
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IFaqCategory
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IFaqCategory
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IFaqCategory
     */
    displayOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFaqCategory
     */
    isPublished: boolean;
    /**
     * 
     * @type {Array<IFaq>}
     * @memberof IFaqCategory
     */
    faqs?: Array<IFaq>;
}
/**
 * 
 * @export
 * @interface IFile
 */
export interface IFile {
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IFile
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IFile
     */
    size?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {FileCategoryType}
     * @memberof IFile
     */
    category: FileCategoryType;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    documentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof IFile
     */
    document?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    bidId?: string | null;
    /**
     * 
     * @type {IBid}
     * @memberof IFile
     */
    bid?: IBid | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    projectId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof IFile
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {IBidSetup}
     * @memberof IFile
     */
    bidSetup?: IBidSetup | null;
    /**
     * 
     * @type {ISubscriber}
     * @memberof IFile
     */
    subscriber?: ISubscriber | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    flattenedFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IFile
     */
    flattenedFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    parentPdfFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IFile
     */
    parentPdfFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    thumbnailFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IFile
     */
    thumbnailFile?: IFile | null;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IFile
     */
    childBaxFiles?: Array<IFile>;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isSealed?: boolean | null;
    /**
     * 
     * @type {IUser}
     * @memberof IFile
     */
    creatorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    creatorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IFile
     */
    copiedFromFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IFile
     */
    copiedFromFile?: IFile | null;
    /**
     * 
     * @type {number}
     * @memberof IFile
     */
    procoreId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isPublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isConformedInSpecifications?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isConformedInDrawings?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isHiddenInPublishingCenter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IFile
     */
    isHiddenInSupportingItems?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IFile
     */
    pageStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IFile
     */
    pageEnd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IFile
     */
    numberOfPages?: number | null;
}
/**
 * 
 * @export
 * @interface IFilePermission
 */
export interface IFilePermission {
    /**
     * 
     * @type {string}
     * @memberof IFilePermission
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IFilePermission
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IFilePermission
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IFilePermission
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IFilePermission
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IFilePermission
     */
    designFileShareId: string;
    /**
     * 
     * @type {IDesignFileShare}
     * @memberof IFilePermission
     */
    designFileShare?: IDesignFileShare | null;
    /**
     * 
     * @type {FilePermissionType}
     * @memberof IFilePermission
     */
    permission: FilePermissionType;
}
/**
 * 
 * @export
 * @interface IGeneratePlaceholdersRequest
 */
export interface IGeneratePlaceholdersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IGeneratePlaceholdersRequest
     */
    documentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGeneratePlaceholdersRequest
     */
    ignoredSpecificationDivisions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGeneratePlaceholdersRequest
     */
    ignoredSpecificationSections?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGeneratePlaceholdersRequest
     */
    ignoredSheetNumbers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IGeneratePlaceholdersRequest
     */
    shouldDeleteIgnoredDocuments?: boolean;
}
/**
 * 
 * @export
 * @interface IInsertManyProjectlessUsersParams
 */
export interface IInsertManyProjectlessUsersParams {
    /**
     * 
     * @type {Array<ICustomInsertionUser>}
     * @memberof IInsertManyProjectlessUsersParams
     */
    users: Array<ICustomInsertionUser>;
}
/**
 * 
 * @export
 * @interface IInsertionAddress
 */
export interface IInsertionAddress {
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAddress
     */
    stateOrProvinceId: string;
}
/**
 * 
 * @export
 * @interface IInsertionAnnouncement
 */
export interface IInsertionAnnouncement {
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    postTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    lastTimestamp?: string | null;
    /**
     * 
     * @type {AnnouncementBroadcastOption}
     * @memberof IInsertionAnnouncement
     */
    broadcastTo: AnnouncementBroadcastOption;
    /**
     * 
     * @type {AnnouncementEmailOption}
     * @memberof IInsertionAnnouncement
     */
    emailTo: AnnouncementEmailOption;
    /**
     * 
     * @type {string}
     * @memberof IInsertionAnnouncement
     */
    creatorUserId?: string;
}
/**
 * 
 * @export
 * @interface IInsertionComment
 */
export interface IInsertionComment {
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionComment
     */
    consultantParentDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionComment
     */
    isCopiedFromConsultantReview?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionComment
     */
    isOnProcore?: boolean | null;
}
/**
 * 
 * @export
 * @interface IInsertionCompany
 */
export interface IInsertionCompany {
    /**
     * 
     * @type {string}
     * @memberof IInsertionCompany
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionCompany
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionCompany
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionCompany
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionCompany
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionCompany
     */
    website?: string | null;
}
/**
 * 
 * @export
 * @interface IInsertionDocumentUser
 */
export interface IInsertionDocumentUser {
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentUser
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentUser
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentUser
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentUser
     */
    isOnNotificationList: boolean;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IInsertionDocumentUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentUser
     */
    disableReminders?: boolean | null;
}
/**
 * 
 * @export
 * @interface IInsertionDocumentWithGraph
 */
export interface IInsertionDocumentWithGraph {
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    documentTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    projectId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    titleConfidence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    referenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    cost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    approvedCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    requestedAdditionalDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    coNumber?: number | null;
    /**
     * 
     * @type {ImpactType}
     * @memberof IInsertionDocumentWithGraph
     */
    impacts?: ImpactType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    proposedAction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    proposedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    approvedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    proposedTimeImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    approvedTimeImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    reportedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    ballInCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    architectUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    generalContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    subcontractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateSentOut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    changeOrdersValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    changeOrdersDaysAdded?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    receivedFrom?: string | null;
    /**
     * 
     * @type {TypeOfTestingType}
     * @memberof IInsertionDocumentWithGraph
     */
    typeOfTesting?: TypeOfTestingType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    typeOfTestingOther?: string | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IInsertionDocumentWithGraph
     */
    actionTaken?: ActionTakenType | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IInsertionDocumentWithGraph
     */
    recommendedAction?: ActionTakenType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalSection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalParagraphNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalSubparagraphNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    revisionNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    request?: string | null;
    /**
     * 
     * @type {WorkflowStatusType}
     * @memberof IInsertionDocumentWithGraph
     */
    workflowStatus?: WorkflowStatusType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isWorkflowInverted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    anticipatedInitialSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    actualSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateReturnedToContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateSubmitted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    additionalReviewRecommendedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    reviewCompleteOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    additionalReviewForDocumentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    paragraphNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    proposedSubstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    substitutionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    substitutionAffectsDrawing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    substitutionAffectsTrades?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    substitutionAffectedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    substitutionDifferences?: string | null;
    /**
     * 
     * @type {ManufacturersWarrantyType}
     * @memberof IInsertionDocumentWithGraph
     */
    manufacturersWarranties?: ManufacturersWarrantyType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    manufacturersWarrantiesExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    informationAvailable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalPackageDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    drawingsSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    completedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    subcontractorAssignedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    submittalUploadedOn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    numberOfSheets?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    shouldSyncProcore?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateApproved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    agency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateContractorCompleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    contactCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isFinalExecuted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateReturnedToReviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    addendumNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isDraft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    weather?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IInsertionDocumentWithGraph
     */
    partiesPresent?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    customArchitect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    customContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    currentWorkInProgress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    reviewComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateOfObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    parsedFromFileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    sheetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    sheetNumberConfidence?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isFileLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    additionalReviewForPackageDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isProcoreOriginated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    isResponseOnProcore?: boolean | null;
    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreDocumentType?: ProcoreDocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreCreatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreCreatedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreCreatedByEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreCreatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreBallInCourtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreBallInCourtName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreBallInCourtEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreResponsibleContractorId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    procoreResponsibleContractorName?: string | null;
    /**
     * 
     * @type {PunchListStatusType}
     * @memberof IInsertionDocumentWithGraph
     */
    punchListStatus?: PunchListStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    physicalLocationId?: string | null;
    /**
     * 
     * @type {ItemCoordinates}
     * @memberof IInsertionDocumentWithGraph
     */
    markedLocation?: ItemCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    parsedParentDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    linkedParentDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionDocumentWithGraph
     */
    willPublishResponse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    datePublished?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateFinalExecuted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    dateOfLastUpdate?: string | null;
    /**
     * 
     * @type {DocumentPriorityType}
     * @memberof IInsertionDocumentWithGraph
     */
    priority?: DocumentPriorityType | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    simplePackage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    expectedCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionDocumentWithGraph
     */
    bluebeamStudioSessionId?: string | null;
    /**
     * 
     * @type {Array<PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId>}
     * @memberof IInsertionDocumentWithGraph
     */
    comments?: Array<PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId>;
}
/**
 * 
 * @export
 * @interface IInsertionPhoneNumber
 */
export interface IInsertionPhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {PhoneNumberType}
     * @memberof IInsertionPhoneNumber
     */
    type: PhoneNumberType;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhoneNumber
     */
    countryId: string;
}
/**
 * 
 * @export
 * @interface IInsertionPhysicalFloor
 */
export interface IInsertionPhysicalFloor {
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionPhysicalFloor
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    buildingId: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    floorPlanImageFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPhysicalFloor
     */
    floorPlanDrawingFileId?: string | null;
}
/**
 * 
 * @export
 * @interface IInsertionPrimeBidder
 */
export interface IInsertionPrimeBidder {
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    phoneNumberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    contractorLicenseNo: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    alternateName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    alternateEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionPrimeBidder
     */
    alternatePhoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface IInsertionProductPackage
 */
export interface IInsertionProductPackage {
    /**
     * 
     * @type {string}
     * @memberof IInsertionProductPackage
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProductPackage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProductPackage
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionProductPackage
     */
    numberOfProjects?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionProductPackage
     */
    numberOfUsers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IInsertionProductPackage
     */
    costPerBillingPeriod: number;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof IInsertionProductPackage
     */
    billingPeriod: BillingPeriod;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProductPackage
     */
    expires?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionProductPackage
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionProductPackage
     */
    canViewDesign?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionProductPackage
     */
    canViewBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionProductPackage
     */
    canViewConstructionAndCloseout?: boolean | null;
}
/**
 * 
 * @export
 * @interface IInsertionProjectCalendarEvent
 */
export interface IInsertionProjectCalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    startTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    endTimestamp: string;
    /**
     * 
     * @type {number}
     * @memberof IInsertionProjectCalendarEvent
     */
    remindDaysBefore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    location?: string | null;
    /**
     * 
     * @type {CalendarEventType}
     * @memberof IInsertionProjectCalendarEvent
     */
    type: CalendarEventType;
    /**
     * 
     * @type {string}
     * @memberof IInsertionProjectCalendarEvent
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface IInsertionSubscriber
 */
export interface IInsertionSubscriber {
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionSubscriber
     */
    coreClient: boolean;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    logoFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    productPackageId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionSubscriber
     */
    isLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriber
     */
    lockedReason?: string | null;
}
/**
 * 
 * @export
 * @interface IInsertionSubscriberSecurityGroup
 */
export interface IInsertionSubscriberSecurityGroup {
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    designTabPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    bidManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    planholderListPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    bidTabulationPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    constructionManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    publishingCenterPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    bimPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    manageProjectUsersPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    canModifyUserGroups?: boolean | null;
    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    correspondenceInboxPermission?: InboxPermissionLevel | null;
    /**
     * 
     * @type {string}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    subscriberId?: string;
    /**
     * 
     * @type {Array<PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId>}
     * @memberof IInsertionSubscriberSecurityGroup
     */
    securityGroupDocumentTemplateList: Array<PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId>;
}
/**
 * 
 * @export
 * @interface IInvoice
 */
export interface IInvoice {
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IInvoice
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    subscriberId: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof IInvoice
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof IInvoice
     */
    amount: number;
    /**
     * 
     * @type {ISubscriber}
     * @memberof IInvoice
     */
    subscriber?: ISubscriber | null;
    /**
     * 
     * @type {IProject}
     * @memberof IInvoice
     */
    project?: IProject | null;
}
/**
 * 
 * @export
 * @interface IJobStatus
 */
export interface IJobStatus {
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IJobStatus
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {JobStatusType}
     * @memberof IJobStatus
     */
    status: JobStatusType;
    /**
     * 
     * @type {JobType}
     * @memberof IJobStatus
     */
    jobType: JobType;
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {any}
     * @memberof IJobStatus
     */
    progress?: any | null;
    /**
     * 
     * @type {number}
     * @memberof IJobStatus
     */
    percentComplete?: number | null;
    /**
     * 
     * @type {any}
     * @memberof IJobStatus
     */
    input: any | null;
    /**
     * 
     * @type {any}
     * @memberof IJobStatus
     */
    output?: any | null;
    /**
     * 
     * @type {string}
     * @memberof IJobStatus
     */
    error?: string | null;
}
/**
 * 
 * @export
 * @interface ILatestFileResponse
 */
export interface ILatestFileResponse {
    /**
     * 
     * @type {IFile}
     * @memberof ILatestFileResponse
     */
    file?: IFile;
}
/**
 * 
 * @export
 * @interface IModifyDocument
 */
export interface IModifyDocument {
    /**
     * 
     * @type {IRestrictedDocumentPatch}
     * @memberof IModifyDocument
     */
    patch?: IRestrictedDocumentPatch;
    /**
     * 
     * @type {ModifyFollowersProps}
     * @memberof IModifyDocument
     */
    followers?: ModifyFollowersProps;
    /**
     * 
     * @type {IModifyDocumentRequestAssociatedDocuments}
     * @memberof IModifyDocument
     */
    associatedDocuments?: IModifyDocumentRequestAssociatedDocuments;
    /**
     * 
     * @type {DocumentFileUploadRequest}
     * @memberof IModifyDocument
     */
    submittalSectionFileUpload?: DocumentFileUploadRequest;
    /**
     * 
     * @type {Array<DocumentFileUploadRequest>}
     * @memberof IModifyDocument
     */
    fileUploads?: Array<DocumentFileUploadRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocument
     */
    deleteFileIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface IModifyDocumentFullResponse
 */
export interface IModifyDocumentFullResponse {
    /**
     * 
     * @type {S3UploadResponse}
     * @memberof IModifyDocumentFullResponse
     */
    submittalSectionUploadResponse?: S3UploadResponse;
    /**
     * 
     * @type {Array<S3UploadResponse>}
     * @memberof IModifyDocumentFullResponse
     */
    fileUploadResponses: Array<S3UploadResponse>;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof IModifyDocumentFullResponse
     */
    updatedDocument: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface IModifyDocumentRequest
 */
export interface IModifyDocumentRequest {
    /**
     * 
     * @type {IRestrictedDocumentPatch}
     * @memberof IModifyDocumentRequest
     */
    patch?: IRestrictedDocumentPatch;
    /**
     * 
     * @type {ModifyFollowersProps}
     * @memberof IModifyDocumentRequest
     */
    followers?: ModifyFollowersProps;
    /**
     * 
     * @type {IModifyDocumentRequestAssociatedDocuments}
     * @memberof IModifyDocumentRequest
     */
    associatedDocuments?: IModifyDocumentRequestAssociatedDocuments;
    /**
     * 
     * @type {DocumentFileUploadRequest}
     * @memberof IModifyDocumentRequest
     */
    submittalSectionFileUpload?: DocumentFileUploadRequest;
    /**
     * 
     * @type {Array<DocumentFileUploadRequest>}
     * @memberof IModifyDocumentRequest
     */
    fileUploads?: Array<DocumentFileUploadRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocumentRequest
     */
    deleteFileIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IModifyDocumentRequest
     */
    allowReplaceExisting?: boolean;
}
/**
 * 
 * @export
 * @interface IModifyDocumentRequestAssociatedDocuments
 */
export interface IModifyDocumentRequestAssociatedDocuments {
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocumentRequestAssociatedDocuments
     */
    removeIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocumentRequestAssociatedDocuments
     */
    addIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface IModifyDocumentResponse
 */
export interface IModifyDocumentResponse {
    /**
     * 
     * @type {S3UploadResponse}
     * @memberof IModifyDocumentResponse
     */
    submittalSectionUploadResponse?: S3UploadResponse;
    /**
     * 
     * @type {Array<S3UploadResponse>}
     * @memberof IModifyDocumentResponse
     */
    fileUploadResponses: Array<S3UploadResponse>;
}
/**
 * 
 * @export
 * @interface IModifyDocumentsByBatch
 */
export interface IModifyDocumentsByBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocumentsByBatch
     */
    documentIds: Array<string>;
    /**
     * 
     * @type {IModifyDocument}
     * @memberof IModifyDocumentsByBatch
     */
    modificationRequest: IModifyDocument;
}
/**
 * 
 * @export
 * @interface IModifyDocumentsByBatchRequest
 */
export interface IModifyDocumentsByBatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof IModifyDocumentsByBatchRequest
     */
    allowReplaceExisting?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyDocumentsByBatchRequest
     */
    documentIds: Array<string>;
    /**
     * 
     * @type {IModifyDocument}
     * @memberof IModifyDocumentsByBatchRequest
     */
    modificationRequest: IModifyDocument;
}
/**
 * 
 * @export
 * @interface IModifyDocumentsByBatchesRequest
 */
export interface IModifyDocumentsByBatchesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof IModifyDocumentsByBatchesRequest
     */
    allowReplaceExisting?: boolean;
    /**
     * 
     * @type {Array<IModifyDocumentsByBatch>}
     * @memberof IModifyDocumentsByBatchesRequest
     */
    batches: Array<IModifyDocumentsByBatch>;
}
/**
 * 
 * @export
 * @interface IModifyManyDocumentsResponse
 */
export interface IModifyManyDocumentsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof IModifyManyDocumentsResponse
     */
    couldNotPatch: Array<string>;
    /**
     * 
     * @type {Array<IModifyManyDocumentsResponseModificationResponses>}
     * @memberof IModifyManyDocumentsResponse
     */
    modificationResponses: Array<IModifyManyDocumentsResponseModificationResponses>;
}
/**
 * 
 * @export
 * @interface IModifyManyDocumentsResponseModificationResponses
 */
export interface IModifyManyDocumentsResponseModificationResponses {
    /**
     * 
     * @type {IModifyDocumentResponse}
     * @memberof IModifyManyDocumentsResponseModificationResponses
     */
    modificationResponse: IModifyDocumentResponse;
    /**
     * 
     * @type {string}
     * @memberof IModifyManyDocumentsResponseModificationResponses
     */
    documentId: string;
}
/**
 * 
 * @export
 * @interface INotification
 */
export interface INotification {
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INotification
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof INotification
     */
    format: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    initiatorUserId: string;
    /**
     * 
     * @type {IUser}
     * @memberof INotification
     */
    initiatorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    targetUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INotification
     */
    targetUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    commentId?: string | null;
    /**
     * 
     * @type {IComment}
     * @memberof INotification
     */
    comment?: IComment | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    fileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof INotification
     */
    file?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    userGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof INotification
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    documentId?: string | null;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof INotification
     */
    document?: INumberedDocumentView | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    targetDocumentId?: string | null;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof INotification
     */
    targetDocument?: INumberedDocumentView | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    projectId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof INotification
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    announcementId?: string | null;
    /**
     * 
     * @type {IAnnouncement}
     * @memberof INotification
     */
    announcement?: IAnnouncement | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    genericDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    genericSubject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    genericText?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof INotification
     */
    deletedFileJsonb?: IFile | null;
    /**
     * 
     * @type {object}
     * @memberof INotification
     */
    fieldsUpdated?: object | null;
    /**
     * 
     * @type {DocumentNotificationFollowerUpdate}
     * @memberof INotification
     */
    followersUpdated?: DocumentNotificationFollowerUpdate | null;
    /**
     * 
     * @type {DocumentAssociationModifications}
     * @memberof INotification
     */
    documentAssociationModifications?: DocumentAssociationModifications | null;
    /**
     * 
     * @type {Array<IUserNotification>}
     * @memberof INotification
     */
    userNotificationList?: Array<IUserNotification>;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    bidId?: string | null;
    /**
     * 
     * @type {IBid}
     * @memberof INotification
     */
    bid?: IBid | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {IBidSetup}
     * @memberof INotification
     */
    bidSetup?: IBidSetup | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    designFileId?: string | null;
    /**
     * 
     * @type {IDesignFile}
     * @memberof INotification
     */
    designFile?: IDesignFile | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    designFileShareId?: string | null;
    /**
     * 
     * @type {IDesignFileShare}
     * @memberof INotification
     */
    designFileShare?: IDesignFileShare | null;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    documentTemplateId?: string | null;
    /**
     * 
     * @type {IDocumentTemplate}
     * @memberof INotification
     */
    documentTemplate?: IDocumentTemplate | null;
    /**
     * 
     * @type {Array<INotificationDesignFileAction>}
     * @memberof INotification
     */
    notificationDesignFileActionList?: Array<INotificationDesignFileAction>;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    copiedFromDocumentId?: string | null;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof INotification
     */
    copiedFromDocument?: INumberedDocumentView | null;
    /**
     * 
     * @type {Array<IUser>}
     * @memberof INotification
     */
    emailedUsers?: Array<IUser>;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    relatedToProjectId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof INotification
     */
    relatedToProject?: IProject | null;
}
/**
 * 
 * @export
 * @interface INotificationDesignFileAction
 */
export interface INotificationDesignFileAction {
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INotificationDesignFileAction
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    notificationId: string;
    /**
     * 
     * @type {INotification}
     * @memberof INotificationDesignFileAction
     */
    notification?: INotification | null;
    /**
     * 
     * @type {string}
     * @memberof INotificationDesignFileAction
     */
    designFileActionId: string;
    /**
     * 
     * @type {IDesignFileAction}
     * @memberof INotificationDesignFileAction
     */
    designFileAction?: IDesignFileAction | null;
}
/**
 * 
 * @export
 * @interface INumberedDocumentView
 */
export interface INumberedDocumentView {
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    creatorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    documentTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    projectId: string;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    titleConfidence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    referenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    cost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    approvedCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    requestedAdditionalDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    coNumber?: number | null;
    /**
     * 
     * @type {ImpactType}
     * @memberof INumberedDocumentView
     */
    impacts?: ImpactType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    proposedAction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    proposedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    approvedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    proposedTimeImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    approvedTimeImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    reportedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    ballInCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    architectUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    architectUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    generalContractorUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    generalContractorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    subcontractorUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    subcontractorUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateSentOut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    changeOrdersValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    changeOrdersDaysAdded?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    receivedFrom?: string | null;
    /**
     * 
     * @type {TypeOfTestingType}
     * @memberof INumberedDocumentView
     */
    typeOfTesting?: TypeOfTestingType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    typeOfTestingOther?: string | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof INumberedDocumentView
     */
    actionTaken?: ActionTakenType | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof INumberedDocumentView
     */
    recommendedAction?: ActionTakenType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalSection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalParagraphNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalSubparagraphNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    revisionNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    request?: string | null;
    /**
     * 
     * @type {WorkflowStatusType}
     * @memberof INumberedDocumentView
     */
    workflowStatus?: WorkflowStatusType | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isWorkflowInverted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    anticipatedInitialSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    actualSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateReturnedToContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateSubmitted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    additionalReviewRecommendedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    reviewCompleteOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    additionalReviewForDocumentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    paragraphNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    proposedSubstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    substitutionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    substitutionAffectsDrawing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    substitutionAffectsTrades?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    substitutionAffectedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    substitutionDifferences?: string | null;
    /**
     * 
     * @type {ManufacturersWarrantyType}
     * @memberof INumberedDocumentView
     */
    manufacturersWarranties?: ManufacturersWarrantyType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    manufacturersWarrantiesExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    informationAvailable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalPackageDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof INumberedDocumentView
     */
    submittalPackageDocument?: IDocument | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalSectionFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof INumberedDocumentView
     */
    submittalSectionFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    drawingsSectionFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof INumberedDocumentView
     */
    drawingsSectionFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    completedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    subcontractorAssignedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalUploadedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    creatorUser?: IUser | null;
    /**
     * 
     * @type {IDocumentTemplate}
     * @memberof INumberedDocumentView
     */
    documentTemplate?: IDocumentTemplate | null;
    /**
     * 
     * @type {IProject}
     * @memberof INumberedDocumentView
     */
    project?: IProject | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    additionalReviewDocuments?: Array<IDocument>;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    additionalReviewRecommendedBy?: IUser | null;
    /**
     * 
     * @type {IDocument}
     * @memberof INumberedDocumentView
     */
    additionalReviewForDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof INumberedDocumentView
     */
    comments?: Array<IComment>;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof INumberedDocumentView
     */
    consultantComments?: Array<IComment>;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof INumberedDocumentView
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {Array<IDocumentUser>}
     * @memberof INumberedDocumentView
     */
    documentUserList?: Array<IDocumentUser>;
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof INumberedDocumentView
     */
    associatedDocumentsList?: Array<IDocumentDocument>;
    /**
     * 
     * @type {Array<IDocumentDocument>}
     * @memberof INumberedDocumentView
     */
    reverseAssociatedDocumentsList?: Array<IDocumentDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    submittalChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    numberOfSheets?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    shouldSyncProcore?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateApproved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    agency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateContractorCompleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof INumberedDocumentView
     */
    notifications?: Array<INotification>;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    contactCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isFinalExecuted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateReturnedToReviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    addendumNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isDraft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    weather?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof INumberedDocumentView
     */
    partiesPresent?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    customArchitect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    customContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    currentWorkInProgress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    documentNumber?: number | null;
    /**
     * 
     * @type {ResponsiblePartyType}
     * @memberof INumberedDocumentView
     */
    responsibleParty?: ResponsiblePartyType | null;
    /**
     * 
     * @type {IUser}
     * @memberof INumberedDocumentView
     */
    assignedToUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    assignedToUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    reviewComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateOfObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    parsedFromFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof INumberedDocumentView
     */
    parsedFromFile?: IFile | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    procoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    sheetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    sheetNumberConfidence?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isFileLocked?: boolean | null;
    /**
     * 
     * @type {Array<IDocumentUserGroup>}
     * @memberof INumberedDocumentView
     */
    documentUserGroupList?: Array<IDocumentUserGroup>;
    /**
     * 
     * @type {Array<IDocumentUserGroupUserView>}
     * @memberof INumberedDocumentView
     */
    documentUserGroupUserViewList?: Array<IDocumentUserGroupUserView>;
    /**
     * 
     * @type {INotification}
     * @memberof INumberedDocumentView
     */
    lockedNotificationJson?: INotification | null;
    /**
     * 
     * @type {IDocumentConsultantReviewSummaryView}
     * @memberof INumberedDocumentView
     */
    consultantSummary?: IDocumentConsultantReviewSummaryView | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    additionalReviewForPackageDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof INumberedDocumentView
     */
    additionalReviewForPackageDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    reviewChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isProcoreOriginated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    isResponseOnProcore?: boolean | null;
    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof INumberedDocumentView
     */
    procoreDocumentType?: ProcoreDocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreCreatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreCreatedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreCreatedByEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    procoreCreatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    procoreBallInCourtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreBallInCourtName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreBallInCourtEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof INumberedDocumentView
     */
    procoreResponsibleContractorId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    procoreResponsibleContractorName?: string | null;
    /**
     * 
     * @type {PunchListStatusType}
     * @memberof INumberedDocumentView
     */
    punchListStatus?: PunchListStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    physicalLocationId?: string | null;
    /**
     * 
     * @type {IPhysicalLocation}
     * @memberof INumberedDocumentView
     */
    physicalLocation?: IPhysicalLocation | null;
    /**
     * 
     * @type {ItemCoordinates}
     * @memberof INumberedDocumentView
     */
    markedLocation?: ItemCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalExtendedIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalDivision?: string | null;
    /**
     * 
     * @type {PublishStatusType}
     * @memberof INumberedDocumentView
     */
    publishStatus?: PublishStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    parsedParentDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof INumberedDocumentView
     */
    parsedParentDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    parsedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    linkedParentDocumentId?: string | null;
    /**
     * 
     * @type {IDocument}
     * @memberof INumberedDocumentView
     */
    linkedParentDocument?: IDocument | null;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    linkedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof INumberedDocumentView
     */
    notGeneratedParsedChildDocuments?: Array<IDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof INumberedDocumentView
     */
    willPublishResponse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    datePublished?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateFinalExecuted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    dateOfLastUpdate?: string | null;
    /**
     * 
     * @type {DocumentPriorityType}
     * @memberof INumberedDocumentView
     */
    priority?: DocumentPriorityType | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    simplePackage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    expectedCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    bluebeamStudioSessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalDivisionDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof INumberedDocumentView
     */
    submittalSectionDescription?: string | null;
}
/**
 * 
 * @export
 * @interface IOauth
 */
export interface IOauth {
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IOauth
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {OauthProviderType}
     * @memberof IOauth
     */
    provider: OauthProviderType;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    secret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    access?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    refresh?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOauth
     */
    expiresOn?: string | null;
    /**
     * 
     * @type {any}
     * @memberof IOauth
     */
    tokenInfo?: any | null;
    /**
     * 
     * @type {number}
     * @memberof IOauth
     */
    resourceOwnerId?: number | null;
}
/**
 * 
 * @export
 * @interface IOptionalAnnouncement
 */
export interface IOptionalAnnouncement {
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    headline?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    postTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    lastTimestamp?: string | null;
    /**
     * 
     * @type {AnnouncementBroadcastOption}
     * @memberof IOptionalAnnouncement
     */
    broadcastTo?: AnnouncementBroadcastOption;
    /**
     * 
     * @type {AnnouncementEmailOption}
     * @memberof IOptionalAnnouncement
     */
    emailTo?: AnnouncementEmailOption;
    /**
     * 
     * @type {string}
     * @memberof IOptionalAnnouncement
     */
    creatorUserId?: string;
}
/**
 * 
 * @export
 * @interface IOptionalDocument
 */
export interface IOptionalDocument {
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    documentTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    projectId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    titleConfidence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    referenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    cost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    approvedCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    requestedAdditionalDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    coNumber?: number | null;
    /**
     * 
     * @type {ImpactType}
     * @memberof IOptionalDocument
     */
    impacts?: ImpactType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    proposedAction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    proposedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    approvedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    proposedTimeImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    approvedTimeImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    reportedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    ballInCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    architectUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    generalContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    subcontractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateSentOut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    changeOrdersValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    changeOrdersDaysAdded?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    receivedFrom?: string | null;
    /**
     * 
     * @type {TypeOfTestingType}
     * @memberof IOptionalDocument
     */
    typeOfTesting?: TypeOfTestingType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    typeOfTestingOther?: string | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IOptionalDocument
     */
    actionTaken?: ActionTakenType | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IOptionalDocument
     */
    recommendedAction?: ActionTakenType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalSection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalParagraphNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalSubparagraphNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    revisionNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    request?: string | null;
    /**
     * 
     * @type {WorkflowStatusType}
     * @memberof IOptionalDocument
     */
    workflowStatus?: WorkflowStatusType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isWorkflowInverted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    anticipatedInitialSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    actualSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateReturnedToContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateSubmitted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    additionalReviewRecommendedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    reviewCompleteOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    additionalReviewForDocumentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    paragraphNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    proposedSubstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    substitutionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    substitutionAffectsDrawing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    substitutionAffectsTrades?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    substitutionAffectedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    substitutionDifferences?: string | null;
    /**
     * 
     * @type {ManufacturersWarrantyType}
     * @memberof IOptionalDocument
     */
    manufacturersWarranties?: ManufacturersWarrantyType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    manufacturersWarrantiesExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    informationAvailable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalPackageDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    drawingsSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    completedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    subcontractorAssignedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    submittalUploadedOn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    numberOfSheets?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    shouldSyncProcore?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateApproved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    agency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateContractorCompleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    contactCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isFinalExecuted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateReturnedToReviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    addendumNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isDraft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    weather?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IOptionalDocument
     */
    partiesPresent?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    customArchitect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    customContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    currentWorkInProgress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    reviewComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateOfObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    parsedFromFileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    procoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    sheetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    sheetNumberConfidence?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isFileLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    additionalReviewForPackageDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isProcoreOriginated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    isResponseOnProcore?: boolean | null;
    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof IOptionalDocument
     */
    procoreDocumentType?: ProcoreDocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreCreatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreCreatedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreCreatedByEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    procoreCreatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    procoreBallInCourtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreBallInCourtName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreBallInCourtEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalDocument
     */
    procoreResponsibleContractorId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    procoreResponsibleContractorName?: string | null;
    /**
     * 
     * @type {PunchListStatusType}
     * @memberof IOptionalDocument
     */
    punchListStatus?: PunchListStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    physicalLocationId?: string | null;
    /**
     * 
     * @type {ItemCoordinates}
     * @memberof IOptionalDocument
     */
    markedLocation?: ItemCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    parsedParentDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    linkedParentDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalDocument
     */
    willPublishResponse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    datePublished?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateFinalExecuted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    dateOfLastUpdate?: string | null;
    /**
     * 
     * @type {DocumentPriorityType}
     * @memberof IOptionalDocument
     */
    priority?: DocumentPriorityType | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    simplePackage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    expectedCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalDocument
     */
    bluebeamStudioSessionId?: string | null;
}
/**
 * 
 * @export
 * @interface IOptionalFile
 */
export interface IOptionalFile {
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalFile
     */
    size?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {FileCategoryType}
     * @memberof IOptionalFile
     */
    category?: FileCategoryType;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    bidId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    flattenedFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    parentPdfFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    thumbnailFileId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isSealed?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalFile
     */
    copiedFromFileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalFile
     */
    procoreId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isConformedInSpecifications?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isConformedInDrawings?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isHiddenInPublishingCenter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalFile
     */
    isHiddenInSupportingItems?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalFile
     */
    pageStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalFile
     */
    pageEnd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalFile
     */
    numberOfPages?: number | null;
}
/**
 * 
 * @export
 * @interface IOptionalPhysicalFloor
 */
export interface IOptionalPhysicalFloor {
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalPhysicalFloor
     */
    index?: number;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    buildingId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    floorPlanImageFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalFloor
     */
    floorPlanDrawingFileId?: string | null;
}
/**
 * 
 * @export
 * @interface IOptionalPhysicalLocation
 */
export interface IOptionalPhysicalLocation {
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalLocation
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalLocation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalLocation
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalLocation
     */
    floorId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalPhysicalLocation
     */
    locationImageFileId?: string | null;
    /**
     * 
     * @type {RegionCoordinates}
     * @memberof IOptionalPhysicalLocation
     */
    markedLocation?: RegionCoordinates | null;
}
/**
 * 
 * @export
 * @interface IOptionalProductPackage
 */
export interface IOptionalProductPackage {
    /**
     * 
     * @type {string}
     * @memberof IOptionalProductPackage
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProductPackage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProductPackage
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProductPackage
     */
    numberOfProjects?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProductPackage
     */
    numberOfUsers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProductPackage
     */
    costPerBillingPeriod?: number;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof IOptionalProductPackage
     */
    billingPeriod?: BillingPeriod;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProductPackage
     */
    expires?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProductPackage
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProductPackage
     */
    canViewDesign?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProductPackage
     */
    canViewBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProductPackage
     */
    canViewConstructionAndCloseout?: boolean | null;
}
/**
 * 
 * @export
 * @interface IOptionalProject
 */
export interface IOptionalProject {
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    ownerProjectId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    subscriberId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    ownerProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    architectProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    defaultGeneralContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    defaultArchitectUserId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    liquidatedDamagesPerDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    documentReviewPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    rfiReviewPeriod?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    noticeToProceedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    scheduledEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    substantialCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    oneYearInspectionAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    warrantyPeriodStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    warrantyPeriodEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProject
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProject
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    lastArchivedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    bidTabulationsFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    bimOwnerUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    preferredBimProject?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    procoreProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProject
     */
    procoreCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProject
     */
    isProcoreIntegrationEnabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    procoreOauthId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    punchListNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    defaultNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    emailSlug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    procoreSubmittalsLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    procoreRfisLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProject
     */
    procoreSubmittalPackagesLastSync?: string | null;
}
/**
 * 
 * @export
 * @interface IOptionalProjectUser
 */
export interface IOptionalProjectUser {
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    securityGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    bidId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProjectUser
     */
    isUnlocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    planHolderOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    primeBidderId?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IOptionalProjectUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalProjectUser
     */
    isFavorite?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProjectUser
     */
    bidSetupDownloads?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IOptionalProjectUser
     */
    bidSetupViews?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalProjectUser
     */
    unsubscribedOn?: string | null;
}
/**
 * 
 * @export
 * @interface IOptionalSubscriber
 */
export interface IOptionalSubscriber {
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalSubscriber
     */
    coreClient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    logoFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    productPackageId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalSubscriber
     */
    isLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriber
     */
    lockedReason?: string | null;
}
/**
 * 
 * @export
 * @interface IOptionalSubscriberSecurityGroup
 */
export interface IOptionalSubscriberSecurityGroup {
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    subscriberId?: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    designTabPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    bidManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    planholderListPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    bidTabulationPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    constructionManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    publishingCenterPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    bimPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    manageProjectUsersPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    canModifyUserGroups?: boolean | null;
    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    correspondenceInboxPermission?: InboxPermissionLevel | null;
    /**
     * 
     * @type {Array<PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId>}
     * @memberof IOptionalSubscriberSecurityGroup
     */
    securityGroupDocumentTemplateList?: Array<PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId>;
}
/**
 * 
 * @export
 * @interface IPaginatedIAnnouncement
 */
export interface IPaginatedIAnnouncement {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIAnnouncement
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIAnnouncement
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIAnnouncement
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIAnnouncement
     */
    total: number;
    /**
     * 
     * @type {Array<IAnnouncement>}
     * @memberof IPaginatedIAnnouncement
     */
    results: Array<IAnnouncement>;
}
/**
 * 
 * @export
 * @interface IPaginatedIApiOutboundEmail
 */
export interface IPaginatedIApiOutboundEmail {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIApiOutboundEmail
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIApiOutboundEmail
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIApiOutboundEmail
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIApiOutboundEmail
     */
    total: number;
    /**
     * 
     * @type {Array<IApiOutboundEmail>}
     * @memberof IPaginatedIApiOutboundEmail
     */
    results: Array<IApiOutboundEmail>;
}
/**
 * 
 * @export
 * @interface IPaginatedIBid
 */
export interface IPaginatedIBid {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBid
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBid
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBid
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBid
     */
    total: number;
    /**
     * 
     * @type {Array<IBid>}
     * @memberof IPaginatedIBid
     */
    results: Array<IBid>;
}
/**
 * 
 * @export
 * @interface IPaginatedIBidSetup
 */
export interface IPaginatedIBidSetup {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBidSetup
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBidSetup
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBidSetup
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIBidSetup
     */
    total: number;
    /**
     * 
     * @type {Array<IBidSetup>}
     * @memberof IPaginatedIBidSetup
     */
    results: Array<IBidSetup>;
}
/**
 * 
 * @export
 * @interface IPaginatedIComment
 */
export interface IPaginatedIComment {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIComment
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIComment
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIComment
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIComment
     */
    total: number;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IPaginatedIComment
     */
    results: Array<IComment>;
}
/**
 * 
 * @export
 * @interface IPaginatedICompany
 */
export interface IPaginatedICompany {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICompany
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICompany
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICompany
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICompany
     */
    total: number;
    /**
     * 
     * @type {Array<ICompany>}
     * @memberof IPaginatedICompany
     */
    results: Array<ICompany>;
}
/**
 * 
 * @export
 * @interface IPaginatedICountry
 */
export interface IPaginatedICountry {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICountry
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICountry
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICountry
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedICountry
     */
    total: number;
    /**
     * 
     * @type {Array<ICountry>}
     * @memberof IPaginatedICountry
     */
    results: Array<ICountry>;
}
/**
 * 
 * @export
 * @interface IPaginatedIDesignFileAction
 */
export interface IPaginatedIDesignFileAction {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDesignFileAction
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDesignFileAction
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDesignFileAction
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDesignFileAction
     */
    total: number;
    /**
     * 
     * @type {Array<IDesignFileAction>}
     * @memberof IPaginatedIDesignFileAction
     */
    results: Array<IDesignFileAction>;
}
/**
 * 
 * @export
 * @interface IPaginatedIDocumentTemplate
 */
export interface IPaginatedIDocumentTemplate {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentTemplate
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentTemplate
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentTemplate
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentTemplate
     */
    total: number;
    /**
     * 
     * @type {Array<IDocumentTemplate>}
     * @memberof IPaginatedIDocumentTemplate
     */
    results: Array<IDocumentTemplate>;
}
/**
 * 
 * @export
 * @interface IPaginatedIDocumentUser
 */
export interface IPaginatedIDocumentUser {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentUser
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentUser
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentUser
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIDocumentUser
     */
    total: number;
    /**
     * 
     * @type {Array<IDocumentUser>}
     * @memberof IPaginatedIDocumentUser
     */
    results: Array<IDocumentUser>;
}
/**
 * 
 * @export
 * @interface IPaginatedIFaqCategory
 */
export interface IPaginatedIFaqCategory {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIFaqCategory
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIFaqCategory
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIFaqCategory
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIFaqCategory
     */
    total: number;
    /**
     * 
     * @type {Array<IFaqCategory>}
     * @memberof IPaginatedIFaqCategory
     */
    results: Array<IFaqCategory>;
}
/**
 * 
 * @export
 * @interface IPaginatedINotification
 */
export interface IPaginatedINotification {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINotification
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINotification
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINotification
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINotification
     */
    total: number;
    /**
     * 
     * @type {Array<INotification>}
     * @memberof IPaginatedINotification
     */
    results: Array<INotification>;
}
/**
 * 
 * @export
 * @interface IPaginatedINumberedDocumentView
 */
export interface IPaginatedINumberedDocumentView {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINumberedDocumentView
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINumberedDocumentView
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINumberedDocumentView
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedINumberedDocumentView
     */
    total: number;
    /**
     * 
     * @type {Array<INumberedDocumentView>}
     * @memberof IPaginatedINumberedDocumentView
     */
    results: Array<INumberedDocumentView>;
}
/**
 * 
 * @export
 * @interface IPaginatedIPhysicalBuilding
 */
export interface IPaginatedIPhysicalBuilding {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIPhysicalBuilding
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIPhysicalBuilding
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIPhysicalBuilding
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIPhysicalBuilding
     */
    total: number;
    /**
     * 
     * @type {Array<IPhysicalBuilding>}
     * @memberof IPaginatedIPhysicalBuilding
     */
    results: Array<IPhysicalBuilding>;
}
/**
 * 
 * @export
 * @interface IPaginatedIProductPackage
 */
export interface IPaginatedIProductPackage {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProductPackage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProductPackage
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProductPackage
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProductPackage
     */
    total: number;
    /**
     * 
     * @type {Array<IProductPackage>}
     * @memberof IPaginatedIProductPackage
     */
    results: Array<IProductPackage>;
}
/**
 * 
 * @export
 * @interface IPaginatedIProjectCalendarEvent
 */
export interface IPaginatedIProjectCalendarEvent {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectCalendarEvent
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectCalendarEvent
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectCalendarEvent
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectCalendarEvent
     */
    total: number;
    /**
     * 
     * @type {Array<IProjectCalendarEvent>}
     * @memberof IPaginatedIProjectCalendarEvent
     */
    results: Array<IProjectCalendarEvent>;
}
/**
 * 
 * @export
 * @interface IPaginatedIProjectUser
 */
export interface IPaginatedIProjectUser {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectUser
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectUser
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectUser
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectUser
     */
    total: number;
    /**
     * 
     * @type {Array<IProjectUser>}
     * @memberof IPaginatedIProjectUser
     */
    results: Array<IProjectUser>;
}
/**
 * 
 * @export
 * @interface IPaginatedIProjectView
 */
export interface IPaginatedIProjectView {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectView
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectView
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectView
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIProjectView
     */
    total: number;
    /**
     * 
     * @type {Array<IProjectView>}
     * @memberof IPaginatedIProjectView
     */
    results: Array<IProjectView>;
}
/**
 * 
 * @export
 * @interface IPaginatedIReceivedEmail
 */
export interface IPaginatedIReceivedEmail {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIReceivedEmail
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIReceivedEmail
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIReceivedEmail
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIReceivedEmail
     */
    total: number;
    /**
     * 
     * @type {Array<IReceivedEmail>}
     * @memberof IPaginatedIReceivedEmail
     */
    results: Array<IReceivedEmail>;
}
/**
 * 
 * @export
 * @interface IPaginatedISecurityGroup
 */
export interface IPaginatedISecurityGroup {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISecurityGroup
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISecurityGroup
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISecurityGroup
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISecurityGroup
     */
    total: number;
    /**
     * 
     * @type {Array<ISecurityGroup>}
     * @memberof IPaginatedISecurityGroup
     */
    results: Array<ISecurityGroup>;
}
/**
 * 
 * @export
 * @interface IPaginatedISimplifiedProjectView
 */
export interface IPaginatedISimplifiedProjectView {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISimplifiedProjectView
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISimplifiedProjectView
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISimplifiedProjectView
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISimplifiedProjectView
     */
    total: number;
    /**
     * 
     * @type {Array<ISimplifiedProjectView>}
     * @memberof IPaginatedISimplifiedProjectView
     */
    results: Array<ISimplifiedProjectView>;
}
/**
 * 
 * @export
 * @interface IPaginatedISubscriber
 */
export interface IPaginatedISubscriber {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISubscriber
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISubscriber
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISubscriber
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedISubscriber
     */
    total: number;
    /**
     * 
     * @type {Array<ISubscriber>}
     * @memberof IPaginatedISubscriber
     */
    results: Array<ISubscriber>;
}
/**
 * 
 * @export
 * @interface IPaginatedIUser
 */
export interface IPaginatedIUser {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUser
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUser
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUser
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUser
     */
    total: number;
    /**
     * 
     * @type {Array<IUser>}
     * @memberof IPaginatedIUser
     */
    results: Array<IUser>;
}
/**
 * 
 * @export
 * @interface IPaginatedIUserNotification
 */
export interface IPaginatedIUserNotification {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUserNotification
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUserNotification
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUserNotification
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIUserNotification
     */
    total: number;
    /**
     * 
     * @type {Array<IUserNotification>}
     * @memberof IPaginatedIUserNotification
     */
    results: Array<IUserNotification>;
}
/**
 * 
 * @export
 * @interface IPaginatedIWebsiteSetting
 */
export interface IPaginatedIWebsiteSetting {
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIWebsiteSetting
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIWebsiteSetting
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIWebsiteSetting
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof IPaginatedIWebsiteSetting
     */
    total: number;
    /**
     * 
     * @type {Array<IWebsiteSetting>}
     * @memberof IPaginatedIWebsiteSetting
     */
    results: Array<IWebsiteSetting>;
}
/**
 * 
 * @export
 * @interface IPersonalCalendarEvent
 */
export interface IPersonalCalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPersonalCalendarEvent
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    startTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    endTimestamp: string;
    /**
     * 
     * @type {number}
     * @memberof IPersonalCalendarEvent
     */
    remindDaysBefore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPersonalCalendarEvent
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IPersonalCalendarEvent
     */
    user?: IUser | null;
}
/**
 * 
 * @export
 * @interface IPhoneNumber
 */
export interface IPhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPhoneNumber
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {PhoneNumberType}
     * @memberof IPhoneNumber
     */
    type: PhoneNumberType;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhoneNumber
     */
    countryId: string;
    /**
     * 
     * @type {ICompany}
     * @memberof IPhoneNumber
     */
    company?: ICompany | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPhoneNumber
     */
    user?: IUser | null;
    /**
     * 
     * @type {ICountry}
     * @memberof IPhoneNumber
     */
    country?: ICountry | null;
}
/**
 * 
 * @export
 * @interface IPhysicalBuilding
 */
export interface IPhysicalBuilding {
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPhysicalBuilding
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalBuilding
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IPhysicalBuilding
     */
    project?: IProject | null;
    /**
     * 
     * @type {Array<IPhysicalFloor>}
     * @memberof IPhysicalBuilding
     */
    floors?: Array<IPhysicalFloor>;
    /**
     * 
     * @type {boolean}
     * @memberof IPhysicalBuilding
     */
    isDefault?: boolean | null;
}
/**
 * 
 * @export
 * @interface IPhysicalFloor
 */
export interface IPhysicalFloor {
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPhysicalFloor
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IPhysicalFloor
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    buildingId: string;
    /**
     * 
     * @type {IPhysicalBuilding}
     * @memberof IPhysicalFloor
     */
    building?: IPhysicalBuilding | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    floorPlanImageFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IPhysicalFloor
     */
    floorPlanImageFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalFloor
     */
    floorPlanDrawingFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IPhysicalFloor
     */
    floorPlanDrawingFile?: IFile | null;
    /**
     * 
     * @type {Array<IPhysicalLocation>}
     * @memberof IPhysicalFloor
     */
    locations?: Array<IPhysicalLocation>;
}
/**
 * 
 * @export
 * @interface IPhysicalLocation
 */
export interface IPhysicalLocation {
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPhysicalLocation
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    floorId: string;
    /**
     * 
     * @type {IPhysicalFloor}
     * @memberof IPhysicalLocation
     */
    floor?: IPhysicalFloor | null;
    /**
     * 
     * @type {string}
     * @memberof IPhysicalLocation
     */
    locationImageFileId?: string | null;
    /**
     * 
     * @type {IFile}
     * @memberof IPhysicalLocation
     */
    locationImageFile?: IFile | null;
    /**
     * 
     * @type {RegionCoordinates}
     * @memberof IPhysicalLocation
     */
    markedLocation?: RegionCoordinates | null;
}
/**
 * 
 * @export
 * @interface IPrimeBidder
 */
export interface IPrimeBidder {
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IPrimeBidder
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    phoneNumberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    email?: string | null;
    /**
     * 
     * @type {IAddress}
     * @memberof IPrimeBidder
     */
    address?: IAddress | null;
    /**
     * 
     * @type {IPhoneNumber}
     * @memberof IPrimeBidder
     */
    phoneNumber?: IPhoneNumber | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    contractorLicenseNo: string;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    alternateName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    alternateEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IPrimeBidder
     */
    alternatePhoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface IProductPackage
 */
export interface IProductPackage {
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProductPackage
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProductPackage
     */
    numberOfProjects?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProductPackage
     */
    numberOfUsers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProductPackage
     */
    costPerBillingPeriod: number;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof IProductPackage
     */
    billingPeriod: BillingPeriod;
    /**
     * 
     * @type {string}
     * @memberof IProductPackage
     */
    expires?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProductPackage
     */
    published: boolean;
    /**
     * 
     * @type {Array<ISubscriber>}
     * @memberof IProductPackage
     */
    subscribers?: Array<ISubscriber>;
    /**
     * 
     * @type {boolean}
     * @memberof IProductPackage
     */
    canViewDesign?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProductPackage
     */
    canViewBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProductPackage
     */
    canViewConstructionAndCloseout?: boolean | null;
}
/**
 * 
 * @export
 * @interface IProject
 */
export interface IProject {
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProject
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    ownerProjectId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    subscriberId: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    ownerProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    architectProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    defaultGeneralContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    defaultArchitectUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProject
     */
    defaultGeneralContractorUser?: IUser | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProject
     */
    defaultArchitectUser?: IUser | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    liquidatedDamagesPerDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    documentReviewPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    rfiReviewPeriod?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    noticeToProceedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    scheduledEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    substantialCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    oneYearInspectionAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    warrantyPeriodStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    warrantyPeriodEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    lastArchivedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    bidTabulationsFileId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProject
     */
    bimOwnerUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    bimOwnerUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    preferredBimProject?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    procoreProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    procoreCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    addressId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof IProject
     */
    ownerProject?: IProject | null;
    /**
     * 
     * @type {ISubscriber}
     * @memberof IProject
     */
    subscriber?: ISubscriber | null;
    /**
     * 
     * @type {IAddress}
     * @memberof IProject
     */
    address?: IAddress | null;
    /**
     * 
     * @type {IFile}
     * @memberof IProject
     */
    bidTabulationsFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {IBidSetup}
     * @memberof IProject
     */
    bidSetup?: IBidSetup | null;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IProject
     */
    comments?: Array<IComment>;
    /**
     * 
     * @type {Array<INumberedDocumentView>}
     * @memberof IProject
     */
    documents?: Array<INumberedDocumentView>;
    /**
     * 
     * @type {Array<IInvoice>}
     * @memberof IProject
     */
    invoices?: Array<IInvoice>;
    /**
     * 
     * @type {Array<IProject>}
     * @memberof IProject
     */
    childProjects?: Array<IProject>;
    /**
     * 
     * @type {Array<IProjectCalendarEvent>}
     * @memberof IProject
     */
    calendarEvents?: Array<IProjectCalendarEvent>;
    /**
     * 
     * @type {Array<IProjectCalendarEvent>}
     * @memberof IProject
     */
    recentCalendarEvents?: Array<IProjectCalendarEvent>;
    /**
     * 
     * @type {Array<IProjectUser>}
     * @memberof IProject
     */
    projectUserList?: Array<IProjectUser>;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IProject
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    isProcoreIntegrationEnabled?: boolean | null;
    /**
     * 
     * @type {Array<IProjectSubmittalCode>}
     * @memberof IProject
     */
    projectSubmittalCodeList?: Array<IProjectSubmittalCode>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    procoreOauthId?: string | null;
    /**
     * 
     * @type {IOauth}
     * @memberof IProject
     */
    procoreOauth?: IOauth | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    punchListNotificationGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IProject
     */
    punchListNotificationGroup?: IUserGroup | null;
    /**
     * 
     * @type {Array<IPhysicalBuilding>}
     * @memberof IProject
     */
    physicalBuildings?: Array<IPhysicalBuilding>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    defaultNotificationGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IProject
     */
    defaultNotificationGroup?: IUserGroup | null;
    /**
     * 
     * @type {Array<IDesignFile>}
     * @memberof IProject
     */
    designFiles?: Array<IDesignFile>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    emailSlug?: string | null;
    /**
     * 
     * @type {Array<IReceivedEmail>}
     * @memberof IProject
     */
    receivedEmails?: Array<IReceivedEmail>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    procoreSubmittalsLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    procoreRfisLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    procoreSubmittalPackagesLastSync?: string | null;
}
/**
 * 
 * @export
 * @interface IProjectArchiveJobStatus
 */
export interface IProjectArchiveJobStatus {
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectArchiveJobStatus
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {JobStatusType}
     * @memberof IProjectArchiveJobStatus
     */
    status: JobStatusType;
    /**
     * 
     * @type {JobType}
     * @memberof IProjectArchiveJobStatus
     */
    jobType: JobType;
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {ProjectArchiveProgress}
     * @memberof IProjectArchiveJobStatus
     */
    progress?: ProjectArchiveProgress;
    /**
     * 
     * @type {number}
     * @memberof IProjectArchiveJobStatus
     */
    percentComplete?: number | null;
    /**
     * 
     * @type {ProjectArchiveContext}
     * @memberof IProjectArchiveJobStatus
     */
    input: ProjectArchiveContext;
    /**
     * 
     * @type {any}
     * @memberof IProjectArchiveJobStatus
     */
    output?: any | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectArchiveJobStatus
     */
    error?: string | null;
}
/**
 * 
 * @export
 * @interface IProjectCalendarEvent
 */
export interface IProjectCalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectCalendarEvent
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    startTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    endTimestamp: string;
    /**
     * 
     * @type {number}
     * @memberof IProjectCalendarEvent
     */
    remindDaysBefore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    location?: string | null;
    /**
     * 
     * @type {CalendarEventType}
     * @memberof IProjectCalendarEvent
     */
    type: CalendarEventType;
    /**
     * 
     * @type {string}
     * @memberof IProjectCalendarEvent
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IProjectCalendarEvent
     */
    project?: IProject | null;
}
/**
 * 
 * @export
 * @interface IProjectName
 */
export interface IProjectName {
    /**
     * 
     * @type {string}
     * @memberof IProjectName
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectName
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IProjectSubmittalCode
 */
export interface IProjectSubmittalCode {
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectSubmittalCode
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IProjectSubmittalCode
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectSubmittalCode
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface IProjectUser
 */
export interface IProjectUser {
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectUser
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    securityGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    bidId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectUser
     */
    isUnlocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    planHolderOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    primeBidderId?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IProjectUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectUser
     */
    isFavorite?: boolean | null;
    /**
     * 
     * @type {IProject}
     * @memberof IProjectUser
     */
    project?: IProject | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectUser
     */
    user?: IUser | null;
    /**
     * 
     * @type {ISecurityGroup}
     * @memberof IProjectUser
     */
    securityGroup?: ISecurityGroup | null;
    /**
     * 
     * @type {Array<IBid>}
     * @memberof IProjectUser
     */
    bids?: Array<IBid>;
    /**
     * 
     * @type {IPrimeBidder}
     * @memberof IProjectUser
     */
    primeBidder?: IPrimeBidder | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectUser
     */
    bidSetupDownloads?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectUser
     */
    bidSetupViews?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectUser
     */
    unsubscribedOn?: string | null;
}
/**
 * 
 * @export
 * @interface IProjectView
 */
export interface IProjectView {
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectView
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    ownerProjectId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    subscriberId: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    ownerProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    architectProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    defaultGeneralContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    defaultArchitectUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectView
     */
    defaultGeneralContractorUser?: IUser | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectView
     */
    defaultArchitectUser?: IUser | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    liquidatedDamagesPerDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    documentReviewPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    rfiReviewPeriod?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    noticeToProceedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    scheduledEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    substantialCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    oneYearInspectionAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    warrantyPeriodStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    warrantyPeriodEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectView
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectView
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    lastArchivedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    bidTabulationsFileId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IProjectView
     */
    bimOwnerUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    bimOwnerUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    preferredBimProject?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    procoreProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    procoreCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    addressId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof IProjectView
     */
    ownerProject?: IProject | null;
    /**
     * 
     * @type {ISubscriber}
     * @memberof IProjectView
     */
    subscriber?: ISubscriber | null;
    /**
     * 
     * @type {IAddress}
     * @memberof IProjectView
     */
    address?: IAddress | null;
    /**
     * 
     * @type {IFile}
     * @memberof IProjectView
     */
    bidTabulationsFile?: IFile | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {IBidSetup}
     * @memberof IProjectView
     */
    bidSetup?: IBidSetup | null;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IProjectView
     */
    comments?: Array<IComment>;
    /**
     * 
     * @type {Array<INumberedDocumentView>}
     * @memberof IProjectView
     */
    documents?: Array<INumberedDocumentView>;
    /**
     * 
     * @type {Array<IInvoice>}
     * @memberof IProjectView
     */
    invoices?: Array<IInvoice>;
    /**
     * 
     * @type {Array<IProject>}
     * @memberof IProjectView
     */
    childProjects?: Array<IProject>;
    /**
     * 
     * @type {Array<IProjectCalendarEvent>}
     * @memberof IProjectView
     */
    calendarEvents?: Array<IProjectCalendarEvent>;
    /**
     * 
     * @type {Array<IProjectCalendarEvent>}
     * @memberof IProjectView
     */
    recentCalendarEvents?: Array<IProjectCalendarEvent>;
    /**
     * 
     * @type {Array<IProjectUser>}
     * @memberof IProjectView
     */
    projectUserList?: Array<IProjectUser>;
    /**
     * 
     * @type {Array<IFile>}
     * @memberof IProjectView
     */
    files?: Array<IFile>;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectView
     */
    isProcoreIntegrationEnabled?: boolean | null;
    /**
     * 
     * @type {Array<IProjectSubmittalCode>}
     * @memberof IProjectView
     */
    projectSubmittalCodeList?: Array<IProjectSubmittalCode>;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    procoreOauthId?: string | null;
    /**
     * 
     * @type {IOauth}
     * @memberof IProjectView
     */
    procoreOauth?: IOauth | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    punchListNotificationGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IProjectView
     */
    punchListNotificationGroup?: IUserGroup | null;
    /**
     * 
     * @type {Array<IPhysicalBuilding>}
     * @memberof IProjectView
     */
    physicalBuildings?: Array<IPhysicalBuilding>;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    defaultNotificationGroupId?: string | null;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IProjectView
     */
    defaultNotificationGroup?: IUserGroup | null;
    /**
     * 
     * @type {Array<IDesignFile>}
     * @memberof IProjectView
     */
    designFiles?: Array<IDesignFile>;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    emailSlug?: string | null;
    /**
     * 
     * @type {Array<IReceivedEmail>}
     * @memberof IProjectView
     */
    receivedEmails?: Array<IReceivedEmail>;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    procoreSubmittalsLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    procoreRfisLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectView
     */
    procoreSubmittalPackagesLastSync?: string | null;
    /**
     * 
     * @type {ProjectPhaseType}
     * @memberof IProjectView
     */
    phase?: ProjectPhaseType | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectView
     */
    daysToCompletion?: number | null;
}
/**
 * 
 * @export
 * @interface IReceivedEmail
 */
export interface IReceivedEmail {
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IReceivedEmail
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    projectId?: string | null;
    /**
     * 
     * @type {IProject}
     * @memberof IReceivedEmail
     */
    project?: IProject | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    recipientAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    recipientName: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    senderAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    senderName: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    s3Key: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    s3Bucket: string;
    /**
     * 
     * @type {boolean}
     * @memberof IReceivedEmail
     */
    hasAttachment: boolean;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    sendDate: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmail
     */
    receivedEmailRawId: string;
    /**
     * 
     * @type {Array<IReceivedEmailMailbox>}
     * @memberof IReceivedEmail
     */
    receivedEmailMailboxes?: Array<IReceivedEmailMailbox>;
}
/**
 * 
 * @export
 * @interface IReceivedEmailMailbox
 */
export interface IReceivedEmailMailbox {
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IReceivedEmailMailbox
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    receivedEmailId: string;
    /**
     * 
     * @type {IReceivedEmail}
     * @memberof IReceivedEmailMailbox
     */
    receivedEmail?: IReceivedEmail | null;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    mailboxName: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    mailboxAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IReceivedEmailMailbox
     */
    emailDomain: string;
    /**
     * 
     * @type {InvolvementType}
     * @memberof IReceivedEmailMailbox
     */
    involvementType: InvolvementType;
}
/**
 * 
 * @export
 * @interface IRestrictedDocumentPatch
 */
export interface IRestrictedDocumentPatch {
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    documentTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    projectId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    titleConfidence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    referenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    cost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    approvedCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    requestedAdditionalDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    coNumber?: number | null;
    /**
     * 
     * @type {ImpactType}
     * @memberof IRestrictedDocumentPatch
     */
    impacts?: ImpactType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    proposedAction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    proposedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    approvedCostImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    proposedTimeImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    approvedTimeImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    reportedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    ballInCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    architectUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    generalContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    subcontractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateSentOut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    changeOrdersValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    changeOrdersDaysAdded?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    receivedFrom?: string | null;
    /**
     * 
     * @type {TypeOfTestingType}
     * @memberof IRestrictedDocumentPatch
     */
    typeOfTesting?: TypeOfTestingType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    typeOfTestingOther?: string | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IRestrictedDocumentPatch
     */
    actionTaken?: ActionTakenType | null;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof IRestrictedDocumentPatch
     */
    recommendedAction?: ActionTakenType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalSection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalParagraphNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalSubparagraphNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    revisionNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    request?: string | null;
    /**
     * 
     * @type {WorkflowStatusType}
     * @memberof IRestrictedDocumentPatch
     */
    workflowStatus?: WorkflowStatusType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isWorkflowInverted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    anticipatedInitialSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    actualSubmissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateReturnedToContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateSubmitted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    additionalReviewRecommendedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    reviewCompleteOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    additionalReviewForDocumentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    paragraphNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    proposedSubstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    substitutionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    substitutionAffectsDrawing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    substitutionAffectsTrades?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    substitutionAffectedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    substitutionDifferences?: string | null;
    /**
     * 
     * @type {ManufacturersWarrantyType}
     * @memberof IRestrictedDocumentPatch
     */
    manufacturersWarranties?: ManufacturersWarrantyType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    manufacturersWarrantiesExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    informationAvailable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalPackageDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    drawingsSectionFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    completedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    subcontractorAssignedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalUploadedOn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    numberOfSheets?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    shouldSyncProcore?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateApproved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    agency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateContractorCompleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    contactCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isFinalExecuted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateReturnedToReviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    addendumNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isDraft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    weather?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IRestrictedDocumentPatch
     */
    partiesPresent?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    customArchitect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    customContractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    currentWorkInProgress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    reviewComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateOfObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    parsedFromFileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    procoreId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    sheetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    sheetNumberConfidence?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isFileLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    additionalReviewForPackageDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isProcoreOriginated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    isResponseOnProcore?: boolean | null;
    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof IRestrictedDocumentPatch
     */
    procoreDocumentType?: ProcoreDocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreCreatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreCreatedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreCreatedByEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    procoreCreatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    procoreBallInCourtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreBallInCourtName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreBallInCourtEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IRestrictedDocumentPatch
     */
    procoreResponsibleContractorId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    procoreResponsibleContractorName?: string | null;
    /**
     * 
     * @type {PunchListStatusType}
     * @memberof IRestrictedDocumentPatch
     */
    punchListStatus?: PunchListStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    physicalLocationId?: string | null;
    /**
     * 
     * @type {ItemCoordinates}
     * @memberof IRestrictedDocumentPatch
     */
    markedLocation?: ItemCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    parsedParentDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    linkedParentDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IRestrictedDocumentPatch
     */
    willPublishResponse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    datePublished?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateFinalExecuted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    dateOfLastUpdate?: string | null;
    /**
     * 
     * @type {DocumentPriorityType}
     * @memberof IRestrictedDocumentPatch
     */
    priority?: DocumentPriorityType | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    simplePackage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    expectedCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    bluebeamStudioSessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    assignedToUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalDivisionDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IRestrictedDocumentPatch
     */
    submittalSectionDescription?: string | null;
}
/**
 * 
 * @export
 * @interface ISecurityGroup
 */
export interface ISecurityGroup {
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof ISecurityGroup
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroup
     */
    subscriberId: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    designTabPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    bidManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    planholderListPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    bidTabulationPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    constructionManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    publishingCenterPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    bimPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroup
     */
    manageProjectUsersPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof ISecurityGroup
     */
    canModifyUserGroups?: boolean | null;
    /**
     * 
     * @type {ISubscriber}
     * @memberof ISecurityGroup
     */
    subscriber?: ISubscriber | null;
    /**
     * 
     * @type {Array<IProjectUser>}
     * @memberof ISecurityGroup
     */
    projectUser?: Array<IProjectUser>;
    /**
     * 
     * @type {Array<ISecurityGroupDocumentTemplate>}
     * @memberof ISecurityGroup
     */
    securityGroupDocumentTemplateList?: Array<ISecurityGroupDocumentTemplate>;
    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof ISecurityGroup
     */
    correspondenceInboxPermission?: InboxPermissionLevel | null;
}
/**
 * 
 * @export
 * @interface ISecurityGroupDocumentTemplate
 */
export interface ISecurityGroupDocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof ISecurityGroupDocumentTemplate
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    documentTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupDocumentTemplate
     */
    securityGroupId: string;
    /**
     * 
     * @type {IDocumentTemplate}
     * @memberof ISecurityGroupDocumentTemplate
     */
    documentTemplate?: IDocumentTemplate | null;
    /**
     * 
     * @type {ISecurityGroup}
     * @memberof ISecurityGroupDocumentTemplate
     */
    securityGroup?: ISecurityGroup | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof ISecurityGroupDocumentTemplate
     */
    securityPermissionLevel?: SecurityPermissionLevel | null;
}
/**
 * 
 * @export
 * @interface ISecurityGroupUserSummary
 */
export interface ISecurityGroupUserSummary {
    /**
     * 
     * @type {Array<ISecurityGroupUserSummaryRow>}
     * @memberof ISecurityGroupUserSummary
     */
    users: Array<ISecurityGroupUserSummaryRow>;
}
/**
 * 
 * @export
 * @interface ISecurityGroupUserSummaryRow
 */
export interface ISecurityGroupUserSummaryRow {
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupUserSummaryRow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupUserSummaryRow
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupUserSummaryRow
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof ISecurityGroupUserSummaryRow
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ISecurityGroupUserSummaryRow
     */
    projectIds: Array<string>;
}
/**
 * 
 * @export
 * @interface ISimplifiedBidSetupActivityLogEntry
 */
export interface ISimplifiedBidSetupActivityLogEntry {
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedBidSetupActivityLogEntry
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedBidSetupActivityLogEntry
     */
    event?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedBidSetupActivityLogEntry
     */
    dateTime?: string | null;
}
/**
 * 
 * @export
 * @interface ISimplifiedProject
 */
export interface ISimplifiedProject {
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProject
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ISimplifiedProject
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ISimplifiedProject
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProject
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProject
     */
    createdOn: string | null;
    /**
     * 
     * @type {number}
     * @memberof ISimplifiedProject
     */
    numProjectUsers: number;
}
/**
 * 
 * @export
 * @interface ISimplifiedProjectView
 */
export interface ISimplifiedProjectView {
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProjectView
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ISimplifiedProjectView
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ISimplifiedProjectView
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedProjectView
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ISimplifiedUser
 */
export interface ISimplifiedUser {
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedUser
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof ISimplifiedUser
     */
    isRegistered: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ISimplifiedUser
     */
    isDisabled: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ISimplifiedUser
     */
    companyName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ISimplifiedUser
     */
    projectIds: Array<string>;
}
/**
 * 
 * @export
 * @interface IStateOrProvince
 */
export interface IStateOrProvince {
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IStateOrProvince
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IStateOrProvince
     */
    countryId: string;
    /**
     * 
     * @type {ICountry}
     * @memberof IStateOrProvince
     */
    country?: ICountry | null;
    /**
     * 
     * @type {Array<IAddress>}
     * @memberof IStateOrProvince
     */
    addresses?: Array<IAddress>;
}
/**
 * 
 * @export
 * @interface ISubscriber
 */
export interface ISubscriber {
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof ISubscriber
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ISubscriber
     */
    coreClient: boolean;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    logoFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    productPackageId: string;
    /**
     * 
     * @type {Array<IUser>}
     * @memberof ISubscriber
     */
    adminUsers?: Array<IUser>;
    /**
     * 
     * @type {IFile}
     * @memberof ISubscriber
     */
    logoFile?: IFile | null;
    /**
     * 
     * @type {IProductPackage}
     * @memberof ISubscriber
     */
    productPackage?: IProductPackage | null;
    /**
     * 
     * @type {Array<IInvoice>}
     * @memberof ISubscriber
     */
    invoices?: Array<IInvoice>;
    /**
     * 
     * @type {Array<IProject>}
     * @memberof ISubscriber
     */
    projects?: Array<IProject>;
    /**
     * 
     * @type {Array<ISecurityGroup>}
     * @memberof ISubscriber
     */
    securityGroups?: Array<ISecurityGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof ISubscriber
     */
    isLocked?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ISubscriber
     */
    lockedReason?: string | null;
}
/**
 * 
 * @export
 * @interface ISubscriberAdminsSimplified
 */
export interface ISubscriberAdminsSimplified {
    /**
     * 
     * @type {string}
     * @memberof ISubscriberAdminsSimplified
     */
    name: string;
    /**
     * 
     * @type {Array<ISubscriberAdminsSimplifiedUsers>}
     * @memberof ISubscriberAdminsSimplified
     */
    users: Array<ISubscriberAdminsSimplifiedUsers>;
}
/**
 * 
 * @export
 * @interface ISubscriberAdminsSimplifiedUsers
 */
export interface ISubscriberAdminsSimplifiedUsers {
    /**
     * 
     * @type {string}
     * @memberof ISubscriberAdminsSimplifiedUsers
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ISubscriberAdminsSimplifiedUsers
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ISubscriberProjectUserSummary
 */
export interface ISubscriberProjectUserSummary {
    /**
     * 
     * @type {Array<ISimplifiedProject>}
     * @memberof ISubscriberProjectUserSummary
     */
    projects: Array<ISimplifiedProject>;
    /**
     * 
     * @type {Array<ISimplifiedUser>}
     * @memberof ISubscriberProjectUserSummary
     */
    users: Array<ISimplifiedUser>;
}
/**
 * 
 * @export
 * @interface IUser
 */
export interface IUser {
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUser
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    contractorLicenseNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isRegistered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isDisabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isSiteAdmin?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    website?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IUser
     */
    notificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    adminOfSubscriberId?: string | null;
    /**
     * 
     * @type {ISubscriber}
     * @memberof IUser
     */
    adminOfSubscriber?: ISubscriber | null;
    /**
     * 
     * @type {ICompany}
     * @memberof IUser
     */
    company?: ICompany | null;
    /**
     * 
     * @type {Array<IAnnouncement>}
     * @memberof IUser
     */
    announcements?: Array<IAnnouncement>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IUser
     */
    documentsForAdditionalReview?: Array<IDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IUser
     */
    documentsAssigned?: Array<IDocument>;
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IUser
     */
    documentsCreated?: Array<IDocument>;
    /**
     * 
     * @type {Array<IPersonalCalendarEvent>}
     * @memberof IUser
     */
    personalCalendarEvents?: Array<IPersonalCalendarEvent>;
    /**
     * 
     * @type {Array<IPhoneNumber>}
     * @memberof IUser
     */
    phoneNumbers?: Array<IPhoneNumber>;
    /**
     * 
     * @type {Array<IBidSetup>}
     * @memberof IUser
     */
    contactForBidSetups?: Array<IBidSetup>;
    /**
     * 
     * @type {Array<IDocumentUser>}
     * @memberof IUser
     */
    documentUserList?: Array<IDocumentUser>;
    /**
     * 
     * @type {Array<IProjectUser>}
     * @memberof IUser
     */
    projectUserList?: Array<IProjectUser>;
    /**
     * 
     * @type {Array<IUserProjectCalendarEvent>}
     * @memberof IUser
     */
    userProjectCalendarEventList?: Array<IUserProjectCalendarEvent>;
    /**
     * 
     * @type {Array<IUserNotification>}
     * @memberof IUser
     */
    userNotificationList?: Array<IUserNotification>;
    /**
     * 
     * @type {Array<IComment>}
     * @memberof IUser
     */
    comments?: Array<IComment>;
    /**
     * 
     * @type {Array<IUserDiscipline>}
     * @memberof IUser
     */
    userDisciplineList?: Array<IUserDiscipline>;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IUser
     */
    disciplineNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    impersonationUserId?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUser
     */
    impersonationUser?: IUser | null;
    /**
     * 
     * @type {Array<IUserGroupUserView>}
     * @memberof IUser
     */
    userGroupUserViewList?: Array<IUserGroupUserView>;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    publicCompanyName?: string | null;
    /**
     * 
     * @type {NotificationSettingType}
     * @memberof IUser
     */
    reminderNotificationSetting?: NotificationSettingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isRegistrationReminderDisabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    bimOauthId?: string | null;
    /**
     * 
     * @type {IOauth}
     * @memberof IUser
     */
    bimOauth?: IOauth | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    isLettermansImport?: boolean | null;
}
/**
 * 
 * @export
 * @interface IUserDiscipline
 */
export interface IUserDiscipline {
    /**
     * 
     * @type {string}
     * @memberof IUserDiscipline
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserDiscipline
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserDiscipline
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserDiscipline
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserDiscipline
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserDiscipline
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IUserDiscipline
     */
    user?: IUser | null;
    /**
     * 
     * @type {DisciplineType}
     * @memberof IUserDiscipline
     */
    discipline: DisciplineType;
}
/**
 * 
 * @export
 * @interface IUserGroup
 */
export interface IUserGroup {
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroup
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroup
     */
    projectId: string;
    /**
     * 
     * @type {IProject}
     * @memberof IUserGroup
     */
    project?: IProject | null;
    /**
     * 
     * @type {Array<IUserGroupUser>}
     * @memberof IUserGroup
     */
    userGroupUserList?: Array<IUserGroupUser>;
    /**
     * 
     * @type {Array<IUserGroupCompany>}
     * @memberof IUserGroup
     */
    userGroupCompanyList?: Array<IUserGroupCompany>;
    /**
     * 
     * @type {Array<IUserGroupDiscipline>}
     * @memberof IUserGroup
     */
    userGroupDisciplineList?: Array<IUserGroupDiscipline>;
    /**
     * 
     * @type {Array<IUserGroupUserView>}
     * @memberof IUserGroup
     */
    userGroupUserViewList?: Array<IUserGroupUserView>;
}
/**
 * 
 * @export
 * @interface IUserGroupCompany
 */
export interface IUserGroupCompany {
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroupCompany
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IUserGroupCompany
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupCompany
     */
    companyId: string;
    /**
     * 
     * @type {ICompany}
     * @memberof IUserGroupCompany
     */
    company?: ICompany | null;
}
/**
 * 
 * @export
 * @interface IUserGroupDiscipline
 */
export interface IUserGroupDiscipline {
    /**
     * 
     * @type {string}
     * @memberof IUserGroupDiscipline
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupDiscipline
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupDiscipline
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupDiscipline
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroupDiscipline
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupDiscipline
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IUserGroupDiscipline
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {DisciplineType}
     * @memberof IUserGroupDiscipline
     */
    discipline: DisciplineType;
    /**
     * 
     * @type {Array<IUserDiscipline>}
     * @memberof IUserGroupDiscipline
     */
    userDisciplineList?: Array<IUserDiscipline>;
}
/**
 * 
 * @export
 * @interface IUserGroupUser
 */
export interface IUserGroupUser {
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroupUser
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IUserGroupUser
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUser
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroupUser
     */
    user?: IUser | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUserGroupUser
     */
    isExcluded: boolean;
}
/**
 * 
 * @export
 * @interface IUserGroupUserView
 */
export interface IUserGroupUserView {
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUserView
     */
    userGroupId: string;
    /**
     * 
     * @type {IUserGroup}
     * @memberof IUserGroupUserView
     */
    userGroup?: IUserGroup | null;
    /**
     * 
     * @type {string}
     * @memberof IUserGroupUserView
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IUserGroupUserView
     */
    user?: IUser | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUserGroupUserView
     */
    isIncludedByCompany: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUserGroupUserView
     */
    isIncludedByUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUserGroupUserView
     */
    isIncludedByDiscipline: boolean;
}
/**
 * 
 * @export
 * @interface IUserInsertionUserDiscipline
 */
export interface IUserInsertionUserDiscipline {
    /**
     * 
     * @type {DisciplineType}
     * @memberof IUserInsertionUserDiscipline
     */
    discipline: DisciplineType;
}
/**
 * 
 * @export
 * @interface IUserNotification
 */
export interface IUserNotification {
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserNotification
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof IUserNotification
     */
    user?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    notificationId: string;
    /**
     * 
     * @type {INotification}
     * @memberof IUserNotification
     */
    notification?: INotification | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    designFileShareId?: string | null;
    /**
     * 
     * @type {IDesignFileShare}
     * @memberof IUserNotification
     */
    designFileShare?: IDesignFileShare | null;
    /**
     * 
     * @type {NotifiedUserType}
     * @memberof IUserNotification
     */
    userType: NotifiedUserType;
    /**
     * 
     * @type {boolean}
     * @memberof IUserNotification
     */
    isRead?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUserNotification
     */
    doNotSend?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    outboundEmailId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserNotification
     */
    emailGenerationInfoId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IUserNotification
     */
    isHidden?: boolean | null;
}
/**
 * 
 * @export
 * @interface IUserProjectCalendarEvent
 */
export interface IUserProjectCalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IUserProjectCalendarEvent
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectCalendarEvent
     */
    projectCalendarEventId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUserProjectCalendarEvent
     */
    isNotification: boolean;
    /**
     * 
     * @type {IUser}
     * @memberof IUserProjectCalendarEvent
     */
    user?: IUser | null;
    /**
     * 
     * @type {IProjectCalendarEvent}
     * @memberof IUserProjectCalendarEvent
     */
    projectCalendarEvent?: IProjectCalendarEvent | null;
}
/**
 * 
 * @export
 * @interface IUserSimplified
 */
export interface IUserSimplified {
    /**
     * 
     * @type {string}
     * @memberof IUserSimplified
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUserSimplified
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IUserSimplified
     */
    email: string;
    /**
     * 
     * @type {IUserSimplifiedCompany}
     * @memberof IUserSimplified
     */
    company?: IUserSimplifiedCompany | null;
    /**
     * 
     * @type {string}
     * @memberof IUserSimplified
     */
    securityGroupId?: string;
}
/**
 * 
 * @export
 * @interface IUserSimplifiedCompany
 */
export interface IUserSimplifiedCompany {
    /**
     * 
     * @type {string}
     * @memberof IUserSimplifiedCompany
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IUserSimplifiedCompany
     */
    id: string;
}
/**
 * 
 * @export
 * @interface IWebsiteSetting
 */
export interface IWebsiteSetting {
    /**
     * 
     * @type {string}
     * @memberof IWebsiteSetting
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IWebsiteSetting
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IWebsiteSetting
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IWebsiteSetting
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof IWebsiteSetting
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof IWebsiteSetting
     */
    subscriberDisclaimer?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ImpactType {
    Civil = 'civil',
    Plumbing = 'plumbing',
    Architectural = 'architectural',
    Electrical = 'electrical',
    Structural = 'structural',
    Landscape = 'landscape',
    Mechanical = 'mechanical'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InboxPermissionLevel {
    NUMBER_0 = 0,
    NUMBER_2 = 2,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {IUser}
     * @memberof InlineResponse200
     */
    originalUser?: IUser;
    /**
     * 
     * @type {IUser}
     * @memberof InlineResponse200
     */
    user: IUser;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {S3UploadResponse}
     * @memberof InlineResponse2001
     */
    s3Response: S3UploadResponse;
    /**
     * 
     * @type {IBidSetup}
     * @memberof InlineResponse2001
     */
    bidSetup: IBidSetup;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {S3UploadResponse}
     * @memberof InlineResponse2002
     */
    s3Response: S3UploadResponse;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    objectKey: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {S3UploadResponse}
     * @memberof InlineResponse2003
     */
    s3Response: S3UploadResponse;
    /**
     * 
     * @type {IProjectView}
     * @memberof InlineResponse2003
     */
    project: IProjectView;
}
/**
 * 
 * @export
 * @interface InsertBidProps
 */
export interface InsertBidProps {
    /**
     * 
     * @type {IInsertionPrimeBidder}
     * @memberof InsertBidProps
     */
    primeBidder?: IInsertionPrimeBidder;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertBidProps
     */
    bidStagingS3Keys: Array<string>;
    /**
     * 
     * @type {CustomInsertionBid}
     * @memberof InsertBidProps
     */
    bid: CustomInsertionBid;
}
/**
 * 
 * @export
 * @interface InsertBidSetupResponse
 */
export interface InsertBidSetupResponse {
    /**
     * 
     * @type {IBidSetup}
     * @memberof InsertBidSetupResponse
     */
    bidSetup: IBidSetup;
    /**
     * 
     * @type {IProjectView}
     * @memberof InsertBidSetupResponse
     */
    project: IProjectView;
}
/**
 * 
 * @export
 * @interface InsertDocumentProps
 */
export interface InsertDocumentProps {
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertDocumentProps
     */
    followerUserIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertDocumentProps
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertDocumentProps
     */
    associatedDocumentIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDocumentProps
     */
    disableUserNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDocumentProps
     */
    skipInitialStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDocumentProps
     */
    skipToReviewComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDocumentProps
     */
    skipToResolved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertDocumentProps
     */
    submittalDivisionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertDocumentProps
     */
    submittalSectionDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertDocumentProps
     */
    copyFileIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertDocumentProps
     */
    consultantUserIds?: Array<string>;
    /**
     * 
     * @type {IInsertionDocumentWithGraph}
     * @memberof InsertDocumentProps
     */
    document: IInsertionDocumentWithGraph;
}
/**
 * 
 * @export
 * @interface InsertDocumentResponse
 */
export interface InsertDocumentResponse {
    /**
     * 
     * @type {DocumentSupertype}
     * @memberof InsertDocumentResponse
     */
    documentSupertype: DocumentSupertype;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof InsertDocumentResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @interface InsertManyPhysicalBuildingsRequest
 */
export interface InsertManyPhysicalBuildingsRequest {
    /**
     * 
     * @type {Array<CustomInsertionPhysicalBuilding>}
     * @memberof InsertManyPhysicalBuildingsRequest
     */
    buildings: Array<CustomInsertionPhysicalBuilding>;
}
/**
 * 
 * @export
 * @interface InsertManyPhysicalLocationsRequest
 */
export interface InsertManyPhysicalLocationsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertManyPhysicalLocationsRequest
     */
    floorIds: Array<string>;
    /**
     * 
     * @type {Array<PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId>}
     * @memberof InsertManyPhysicalLocationsRequest
     */
    locations: Array<PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvolvementType {
    To = 'to',
    Cc = 'cc',
    From = 'from'
}

/**
 * 
 * @export
 * @interface ItemCoordinates
 */
export interface ItemCoordinates {
    /**
     * 
     * @type {number}
     * @memberof ItemCoordinates
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCoordinates
     */
    y: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum JobStatusType {
    Pending = 'pending',
    Initiated = 'initiated',
    Canceled = 'canceled',
    Complete = 'complete'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum JobType {
    BidSetupZip = 'bid_setup_zip',
    ProjectArchiveZip = 'project_archive_zip',
    SpecificationsParser = 'specifications_parser',
    DrawingsParser = 'drawings_parser'
}

/**
 * 
 * @export
 * @interface JsonFileCategory
 */
export interface JsonFileCategory {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ManufacturersWarrantyType {
    Same = 'same',
    Different = 'different'
}

/**
 * 
 * @export
 * @interface MarkParserFileAsConformedRequest
 */
export interface MarkParserFileAsConformedRequest {
    /**
     * 
     * @type {DocumentTemplateType}
     * @memberof MarkParserFileAsConformedRequest
     */
    documentType: DocumentTemplateType;
}
/**
 * 
 * @export
 * @interface ModifyFollowersProps
 */
export interface ModifyFollowersProps {
    /**
     * 
     * @type {Array<string>}
     * @memberof ModifyFollowersProps
     */
    addUserIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModifyFollowersProps
     */
    removeUserIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModifyFollowersProps
     */
    addUserGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModifyFollowersProps
     */
    removeUserGroupIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface ModifyUserFileSystemNodeRequest
 */
export interface ModifyUserFileSystemNodeRequest {
    /**
     * 
     * @type {FileNode}
     * @memberof ModifyUserFileSystemNodeRequest
     */
    fileNode: FileNode;
    /**
     * 
     * @type {string}
     * @memberof ModifyUserFileSystemNodeRequest
     */
    newName: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationSettingType {
    None = 'none',
    AllAsIssued = 'all_as_issued',
    AllDaily = 'all_daily',
    AllWeekly = 'all_weekly'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    BidFileViewed = 'bid_file_viewed',
    BidFileDownloaded = 'bid_file_downloaded',
    DocumentAssociationsModified = 'document_associations_modified',
    DocumentCreated = 'document_created',
    DocumentViewed = 'document_viewed',
    DocumentSubcontractorChanged = 'document_subcontractor_changed',
    DocumentSubcontractorUploaded = 'document_subcontractor_uploaded',
    DocumentSubcontractorUploadAccepted = 'document_subcontractor_upload_accepted',
    DocumentSubcontractorUploadRejected = 'document_subcontractor_upload_rejected',
    DocumentSubmittedForReview = 'document_submitted_for_review',
    DocumentConsultantAdded = 'document_consultant_added',
    DocumentConsultantBluebeamStarted = 'document_consultant_bluebeam_started',
    DocumentConsultantDownloaded = 'document_consultant_downloaded',
    DocumentConsultantFinished = 'document_consultant_finished',
    DocumentConsultantUploaded = 'document_consultant_uploaded',
    DocumentComplete = 'document_complete',
    DocumentDeleted = 'document_deleted',
    DocumentRestored = 'document_restored',
    DocumentUndoCompleteReview = 'document_undo_complete_review',
    DocumentFieldsUpdated = 'document_fields_updated',
    DocumentFollowersUpdated = 'document_followers_updated',
    DocumentFileCreated = 'document_file_created',
    DocumentFileDeleted = 'document_file_deleted',
    DocumentFileViewed = 'document_file_viewed',
    DocumentFileDownloaded = 'document_file_downloaded',
    DocumentFileOpenedBluebeam = 'document_file_opened_bluebeam',
    DocumentAddedInitialSubmittalDate = 'document_added_initial_submittal_date',
    DocumentCommentAdded = 'document_comment_added',
    DocumentDueDateChanged = 'document_due_date_changed',
    DocumentAnticipatedSubmissionDateChanged = 'document_anticipated_submission_date_changed',
    DocumentAddedToPackage = 'document_added_to_package',
    DocumentSeparatedFromPackage = 'document_separated_from_package',
    DocumentDocumentRemovedFromPackage = 'document_document_removed_from_package',
    DocumentPublished = 'document_published',
    DocumentFileLocked = 'document_file_locked',
    DocumentDueDateWarning = 'document_due_date_warning',
    DocumentSubmittedForReviewReminder = 'document_submitted_for_review_reminder',
    DocumentSubmittedForInformationOnly = 'document_submitted_for_information_only',
    DocumentProcoreSyncMismatch = 'document_procore_sync_mismatch',
    DocumentReviewUpdated = 'document_review_updated',
    DocumentUpdated = 'document_updated',
    DocumentWithdrawn = 'document_withdrawn',
    DocumentRespondedToReview = 'document_responded_to_review',
    AnnouncementLive = 'announcement_live',
    ProjectFileCreated = 'project_file_created',
    ProjectUserInvited = 'project_user_invited',
    ProjectUserInvitedExisting = 'project_user_invited_existing',
    ProjectUserUnsubscribed = 'project_user_unsubscribed',
    ProjectComment = 'project_comment',
    ProjectAddendaDueDateWarning = 'project_addenda_due_date_warning',
    ProjectBidDatePassed = 'project_bid_date_passed',
    ProjectBidSetupFileCreated = 'project_bid_setup_file_created',
    ProjectBidSetupViewed = 'project_bid_setup_viewed',
    ProjectBidSetupDownloaded = 'project_bid_setup_downloaded',
    ProjectBidRetracted = 'project_bid_retracted',
    ProjectBidSubmitted = 'project_bid_submitted',
    ProjectCompletedBidTabulationAdded = 'project_completed_bid_tabulation_added',
    ProjectSpecificationsParserCompleted = 'project_specifications_parser_completed',
    ProjectSpecificationsParserFailed = 'project_specifications_parser_failed',
    ProjectDrawingsParserCompleted = 'project_drawings_parser_completed',
    ProjectDrawingsParserFailed = 'project_drawings_parser_failed',
    ProjectMassDocumentPublish = 'project_mass_document_publish',
    UserInvited = 'user_invited',
    UserRegistrationReminder = 'user_registration_reminder',
    DesignFileAccess = 'design_file_access'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotifiedUserType {
    PlanHolder = 'plan_holder',
    Follower = 'follower',
    Creator = 'creator',
    Assigned = 'assigned'
}

/**
 * 
 * @export
 * @interface NotifyUserAccessDesignFileRequest
 */
export interface NotifyUserAccessDesignFileRequest {
    /**
     * 
     * @type {string}
     * @memberof NotifyUserAccessDesignFileRequest
     */
    subject: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotifyUserAccessDesignFileRequest
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotifyUserAccessDesignFileRequest
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<SimpleFileNode>}
     * @memberof NotifyUserAccessDesignFileRequest
     */
    fileNodes: Array<SimpleFileNode>;
    /**
     * 
     * @type {string}
     * @memberof NotifyUserAccessDesignFileRequest
     */
    description?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OauthProviderType {
    Bim = 'bim',
    Procore = 'procore'
}

/**
 * 
 * @export
 * @interface PaginatedReceivedEmailsResponse
 */
export interface PaginatedReceivedEmailsResponse {
    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof PaginatedReceivedEmailsResponse
     */
    permission: InboxPermissionLevel;
    /**
     * 
     * @type {IPaginatedIReceivedEmail}
     * @memberof PaginatedReceivedEmailsResponse
     */
    results: IPaginatedIReceivedEmail;
}
/**
 * 
 * @export
 * @interface ParserJobStatusRequest
 */
export interface ParserJobStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ParserJobStatusRequest
     */
    objectKey: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PhoneNumberType {
    Work = 'work',
    Home = 'home',
    Mobile = 'mobile',
    Other = 'other'
}

/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickFileNodeProjectIdOrOwnerIdOrFullKey
 */
export interface PickFileNodeProjectIdOrOwnerIdOrFullKey {
    /**
     * 
     * @type {string}
     * @memberof PickFileNodeProjectIdOrOwnerIdOrFullKey
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof PickFileNodeProjectIdOrOwnerIdOrFullKey
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof PickFileNodeProjectIdOrOwnerIdOrFullKey
     */
    fullKey: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIDocumentId
 */
export interface PickIDocumentId {
    /**
     * 
     * @type {string}
     * @memberof PickIDocumentId
     */
    id: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn
 */
export interface PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionBidExcludeKeyofIInsertionBidIsRetractedOrOpenedOn
     */
    bidNumber?: number | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
 */
export interface PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    allowInterestedPartiesToViewBidTabulation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    allowElectronicSubstitutionRequestSubmittals?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    allowElectronicBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    requireElectronicBidding?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    estimatedConstructionCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    allowElectronicRfiSubmittals: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    bidLocationCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    hasPreBidConference: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    liveAt: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    requestDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    preDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    addendaDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    contactUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    alternativeContactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    stateOrProvinceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionBidSetupExcludeKeyofIInsertionBidSetupShouldSetPrivate
     */
    bidNotificationGroupId?: string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
 */
export interface PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    projectId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    isCopiedFromConsultantReview?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    consultantParentDocumentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionCommentExcludeKeyofIInsertionCommentDocumentId
     */
    isOnProcore?: boolean | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault
 */
export interface PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalBuildingExcludeKeyofIInsertionPhysicalBuildingIsDefault
     */
    projectId: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
 */
export interface PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {RegionCoordinates}
     * @memberof PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
     */
    markedLocation?: RegionCoordinates | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId
     */
    locationImageFileId?: string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
 */
export interface PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId {
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    ownerProjectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    architectProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    ownerProjectNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    defaultGeneralContractorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    defaultArchitectUserId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    contractAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    liquidatedDamagesPerDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    documentReviewPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    rfiReviewPeriod?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    noticeToProceedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    scheduledEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    substantialCompletionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    oneYearInspectionAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    warrantyPeriodStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    warrantyPeriodEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    lastArchivedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    bimOwnerUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    preferredBimProject?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    bidSetupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    bidTabulationsFileId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    isProcoreIntegrationEnabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreOauthId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    punchListNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    defaultNotificationGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    emailSlug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreSubmittalsLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreRfisLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    procoreSubmittalPackagesLastSync?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionProjectExcludeKeyofIInsertionProjectSubscriberId
     */
    lastUpdatedByUserId?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId
 */
export interface PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId
     */
    documentTemplateId: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupDocumentTemplateExcludeKeyofIInsertionSecurityGroupDocumentTemplateSecurityGroupId
     */
    securityPermissionLevel?: SecurityPermissionLevel | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
 */
export interface PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    designTabPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    bidManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    planholderListPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    bidTabulationPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    constructionManagementPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    publishingCenterPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    bimPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    manageProjectUsersPermission?: SecurityPermissionLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    canModifyUserGroups?: boolean | null;
    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof PickIInsertionSecurityGroupExcludeKeyofIInsertionSecurityGroupSubscriberId
     */
    correspondenceInboxPermission?: InboxPermissionLevel | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId
 */
export interface PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId
     */
    companyId: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId
 */
export interface PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId {
    /**
     * 
     * @type {DisciplineType}
     * @memberof PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId
     */
    discipline: DisciplineType;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId
 */
export interface PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId
     */
    name: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId
 */
export interface PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId {
    /**
     * 
     * @type {string}
     * @memberof PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId
     */
    isExcluded: boolean;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
 */
export interface PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate {
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    allowInterestedPartiesToViewBidTabulation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    allowElectronicSubstitutionRequestSubmittals?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    allowElectronicBidding?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    requireElectronicBidding?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    estimatedConstructionCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    allowElectronicRfiSubmittals?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    bidLocationCompany?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    hasPreBidConference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    liveAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    requestDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    preDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    addendaDueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    contactUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    alternativeContactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    stateOrProvinceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalBidSetupExcludeKeyofIOptionalBidSetupShouldSetPrivate
     */
    bidNotificationGroupId?: string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId
 */
export interface PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId {
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId
     */
    lastUpdatedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId
     */
    documentTemplateId?: string;
    /**
     * 
     * @type {SecurityPermissionLevel}
     * @memberof PickIOptionalSecurityGroupDocumentTemplateExcludeKeyofIOptionalSecurityGroupDocumentTemplateSecurityGroupId
     */
    securityPermissionLevel?: SecurityPermissionLevel | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
 */
export interface PickIOutboundEmailExcludeKeyofIOutboundEmailSesData {
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    createdOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    beganSendingOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    sentOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    sendError?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    messageId?: string | null;
    /**
     * 
     * @type {IEmailLog}
     * @memberof PickIOutboundEmailExcludeKeyofIOutboundEmailSesData
     */
    emailLog?: IEmailLog | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIUserIdOrNameOrEmailOrPublicCompanyName
 */
export interface PickIUserIdOrNameOrEmailOrPublicCompanyName {
    /**
     * 
     * @type {string}
     * @memberof PickIUserIdOrNameOrEmailOrPublicCompanyName
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickIUserIdOrNameOrEmailOrPublicCompanyName
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PickIUserIdOrNameOrEmailOrPublicCompanyName
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PickIUserIdOrNameOrEmailOrPublicCompanyName
     */
    publicCompanyName?: string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
 */
export interface PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber {
    /**
     * 
     * @type {Array<string>}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    followerUserIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    associatedDocumentIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    disableUserNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    skipInitialStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    skipToReviewComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    skipToResolved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    submittalDivisionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    submittalSectionDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    copyFileIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickInsertDocumentOptionsExcludeKeyofInsertDocumentOptionsOverrideDocumentNumber
     */
    consultantUserIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface PlanholderSummaryType
 */
export interface PlanholderSummaryType {
    /**
     * 
     * @type {number}
     * @memberof PlanholderSummaryType
     */
    numDownloads: number;
    /**
     * 
     * @type {number}
     * @memberof PlanholderSummaryType
     */
    numViews: number;
    /**
     * 
     * @type {number}
     * @memberof PlanholderSummaryType
     */
    numUsers: number;
}
/**
 * 
 * @export
 * @interface PresignedUrlPartResponse
 */
export interface PresignedUrlPartResponse {
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlPartResponse
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof PresignedUrlPartResponse
     */
    partNumber: number;
}
/**
 * 
 * @export
 * @interface PresignedUrlPartsRequest
 */
export interface PresignedUrlPartsRequest {
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlPartsRequest
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlPartsRequest
     */
    uploadId: string;
    /**
     * 
     * @type {number}
     * @memberof PresignedUrlPartsRequest
     */
    parts: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProcoreDocumentType {
    Submittal = 'submittal',
    SubmittalPackage = 'submittal_package',
    Rfi = 'rfi'
}

/**
 * 
 * @export
 * @interface ProcoreProjectResponse
 */
export interface ProcoreProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    longitude: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    latitude: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    country_code: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    state_code: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProcoreProjectResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProcoreProjectResponse
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ProjectArchiveContext
 */
export interface ProjectArchiveContext {
    /**
     * 
     * @type {string}
     * @memberof ProjectArchiveContext
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectArchiveContext
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectArchiveContext
     */
    callingUserId: string;
    /**
     * 
     * @type {ZipFileCategory}
     * @memberof ProjectArchiveContext
     */
    fileCategoryType: ZipFileCategory;
}
/**
 * 
 * @export
 * @interface ProjectArchiveProgress
 */
export interface ProjectArchiveProgress {
    /**
     * 
     * @type {number}
     * @memberof ProjectArchiveProgress
     */
    filesToZipTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectArchiveProgress
     */
    filesToZipCompleted: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectArchiveProgress
     */
    uploadedBytes: number;
}
/**
 * 
 * @export
 * @interface ProjectBIMImportFileCategory
 */
export interface ProjectBIMImportFileCategory {
}
/**
 * 
 * @export
 * @interface ProjectBIMImportParams
 */
export interface ProjectBIMImportParams {
    /**
     * 
     * @type {string}
     * @memberof ProjectBIMImportParams
     */
    description?: string;
    /**
     * 
     * @type {BimTreeNode}
     * @memberof ProjectBIMImportParams
     */
    bimNode: BimTreeNode;
    /**
     * 
     * @type {ProjectBIMImportFileCategory}
     * @memberof ProjectBIMImportParams
     */
    fileCategory: ProjectBIMImportFileCategory;
}
/**
 * 
 * @export
 * @interface ProjectBiddingSummaryType
 */
export interface ProjectBiddingSummaryType {
    /**
     * 
     * @type {PlanholderSummaryType}
     * @memberof ProjectBiddingSummaryType
     */
    planholders: PlanholderSummaryType;
    /**
     * 
     * @type {DocumentSummaryType}
     * @memberof ProjectBiddingSummaryType
     */
    bidderRfis: DocumentSummaryType;
    /**
     * 
     * @type {DocumentSummaryType}
     * @memberof ProjectBiddingSummaryType
     */
    substRequests: DocumentSummaryType;
    /**
     * 
     * @type {number}
     * @memberof ProjectBiddingSummaryType
     */
    numBidSubmissions: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    addendaDueDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    requestDueDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    bidDueDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    preDueDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    liveAtDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectBiddingSummaryType
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface ProjectFileUploadRequestParams
 */
export interface ProjectFileUploadRequestParams {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectFileUploadRequestParams
     */
    useMultiPartUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileUploadRequestParams
     */
    fullFileName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileUploadRequestParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileUploadRequestParams
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileUploadRequestParams
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileUploadRequestParams
     */
    fullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectFileUploadRequestParams
     */
    isPublic?: boolean;
    /**
     * 
     * @type {FileCategoryTypeDrawings | FileCategoryTypeSpecifications | FileCategoryTypeCompletedBidTabulation | ArchiveFileCategory}
     * @memberof ProjectFileUploadRequestParams
     */
    fileType: FileCategoryTypeDrawings | FileCategoryTypeSpecifications | FileCategoryTypeCompletedBidTabulation | ArchiveFileCategory;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectFileUploadRequestParams
     */
    enableParseSubmittals?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectPhaseType {
    Design = 'design',
    Bidding = 'bidding',
    Construction = 'construction'
}

/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    longitude: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    latitude: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    country_code: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    state_code: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    id: number;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof ProjectResponse
     */
    company: CompanyResponse;
}
/**
 * 
 * @export
 * @interface ProjectResponseAllOf
 */
export interface ProjectResponseAllOf {
    /**
     * 
     * @type {CompanyResponse}
     * @memberof ProjectResponseAllOf
     */
    company: CompanyResponse;
}
/**
 * 
 * @export
 * @interface ProjectSummaryType
 */
export interface ProjectSummaryType {
    /**
     * 
     * @type {SimpleDocumentSummaryType}
     * @memberof ProjectSummaryType
     */
    constChangeDirectives: SimpleDocumentSummaryType;
    /**
     * 
     * @type {DocumentSummaryType}
     * @memberof ProjectSummaryType
     */
    potentialChangeOrders: DocumentSummaryType;
    /**
     * 
     * @type {DocumentSummaryType}
     * @memberof ProjectSummaryType
     */
    workChangePropReqs: DocumentSummaryType;
    /**
     * 
     * @type {DocumentSummaryType}
     * @memberof ProjectSummaryType
     */
    requestsForChange: DocumentSummaryType;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryType
     */
    numCo: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryType
     */
    daysAdded: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryType
     */
    costAdded: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryType
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface PublicUserInfoResponse
 */
export interface PublicUserInfoResponse {
    /**
     * 
     * @type {CognitoUserStatus | string}
     * @memberof PublicUserInfoResponse
     */
    cognitoUserStatus?: CognitoUserStatus | string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicUserInfoResponse
     */
    isPartOfMainPortal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicUserInfoResponse
     */
    isLettermansImport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicUserInfoResponse
     */
    isRegistered: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PublishStatusType {
    Deleted = 'deleted',
    BeforeUnpublished = 'before_unpublished',
    Unpublished = 'unpublished',
    Published = 'published'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PunchListStatusType {
    New = 'new',
    InProgress = 'in_progress',
    ReadyForVerification = 'ready_for_verification',
    Accepted = 'accepted',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface ReducedSubmittalCode
 */
export interface ReducedSubmittalCode {
    /**
     * 
     * @type {boolean}
     * @memberof ReducedSubmittalCode
     */
    is_project: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReducedSubmittalCode
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReducedSubmittalCode
     */
    code: string;
}
/**
 * 
 * @export
 * @interface RegionCoordinates
 */
export interface RegionCoordinates {
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    W: number;
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    H: number;
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    scale: number;
    /**
     * 
     * @type {number}
     * @memberof RegionCoordinates
     */
    width: number;
}
/**
 * 
 * @export
 * @interface RejectDocumentRequest
 */
export interface RejectDocumentRequest {
    /**
     * 
     * @type {IInsertionComment}
     * @memberof RejectDocumentRequest
     */
    comment?: IInsertionComment;
}
/**
 * 
 * @export
 * @interface RespondToReviewProps
 */
export interface RespondToReviewProps {
    /**
     * 
     * @type {string}
     * @memberof RespondToReviewProps
     */
    newDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RespondToReviewProps
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof RespondToReviewProps
     */
    response: RespondToReviewPropsResponseEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RespondToReviewPropsResponseEnum {
    Accepted = 'accepted',
    Resubmitted = 'resubmitted',
    Resolved = 'resolved',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface RespondToReviewResponse
 */
export interface RespondToReviewResponse {
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof RespondToReviewResponse
     */
    revisedDocument?: INumberedDocumentView;
    /**
     * 
     * @type {INumberedDocumentView}
     * @memberof RespondToReviewResponse
     */
    document: INumberedDocumentView;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ResponsiblePartyType {
    GeneralContractor = 'general_contractor',
    Subcontractor = 'subcontractor',
    Architect = 'architect',
    Consultant = 'consultant'
}

/**
 * 
 * @export
 * @interface RestoreDocumentsRequest
 */
export interface RestoreDocumentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestoreDocumentsRequest
     */
    documentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface S3DownloadResponse
 */
export interface S3DownloadResponse {
    /**
     * 
     * @type {string}
     * @memberof S3DownloadResponse
     */
    downloadLink: string;
    /**
     * 
     * @type {IFile}
     * @memberof S3DownloadResponse
     */
    file?: IFile;
    /**
     * 
     * @type {string}
     * @memberof S3DownloadResponse
     */
    sanitizedFullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof S3DownloadResponse
     */
    isThumbnailFile?: boolean;
}
/**
 * 
 * @export
 * @interface S3StagingUploadRequest
 */
export interface S3StagingUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof S3StagingUploadRequest
     */
    fileName: string;
    /**
     * 
     * @type {boolean}
     * @memberof S3StagingUploadRequest
     */
    useMultiPartUpload: boolean;
}
/**
 * 
 * @export
 * @interface S3UploadResponse
 */
export interface S3UploadResponse {
    /**
     * 
     * @type {string}
     * @memberof S3UploadResponse
     */
    uploadLink?: string;
    /**
     * 
     * @type {string}
     * @memberof S3UploadResponse
     */
    uploadId?: string;
    /**
     * 
     * @type {string}
     * @memberof S3UploadResponse
     */
    s3Key: string;
    /**
     * 
     * @type {IFile}
     * @memberof S3UploadResponse
     */
    file?: IFile;
    /**
     * 
     * @type {string}
     * @memberof S3UploadResponse
     */
    sanitizedFullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof S3UploadResponse
     */
    isThumbnailFile?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SecurityPermissionLevel {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface SendEmailRequest
 */
export interface SendEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequest
     */
    ConfigurationSetName?: string;
    /**
     * 
     * @type {Array<SendEmailRequestTags>}
     * @memberof SendEmailRequest
     */
    Tags?: Array<SendEmailRequestTags>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequest
     */
    ReturnPathArn?: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequest
     */
    SourceArn?: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequest
     */
    ReturnPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailRequest
     */
    ReplyToAddresses?: Array<string>;
    /**
     * 
     * @type {SendEmailRequestMessage}
     * @memberof SendEmailRequest
     */
    Message: SendEmailRequestMessage;
    /**
     * 
     * @type {SendEmailRequestDestination}
     * @memberof SendEmailRequest
     */
    Destination: SendEmailRequestDestination;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequest
     */
    Source: string;
}
/**
 * 
 * @export
 * @interface SendEmailRequestDestination
 */
export interface SendEmailRequestDestination {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailRequestDestination
     */
    BccAddresses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailRequestDestination
     */
    CcAddresses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailRequestDestination
     */
    ToAddresses?: Array<string>;
}
/**
 * 
 * @export
 * @interface SendEmailRequestMessage
 */
export interface SendEmailRequestMessage {
    /**
     * 
     * @type {SendEmailRequestMessageBody}
     * @memberof SendEmailRequestMessage
     */
    Body: SendEmailRequestMessageBody;
    /**
     * 
     * @type {SendEmailRequestMessageBodyHtml}
     * @memberof SendEmailRequestMessage
     */
    Subject: SendEmailRequestMessageBodyHtml;
}
/**
 * 
 * @export
 * @interface SendEmailRequestMessageBody
 */
export interface SendEmailRequestMessageBody {
    /**
     * 
     * @type {SendEmailRequestMessageBodyHtml}
     * @memberof SendEmailRequestMessageBody
     */
    Html?: SendEmailRequestMessageBodyHtml;
    /**
     * 
     * @type {SendEmailRequestMessageBodyHtml}
     * @memberof SendEmailRequestMessageBody
     */
    Text?: SendEmailRequestMessageBodyHtml;
}
/**
 * 
 * @export
 * @interface SendEmailRequestMessageBodyHtml
 */
export interface SendEmailRequestMessageBodyHtml {
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequestMessageBodyHtml
     */
    Charset?: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequestMessageBodyHtml
     */
    Data: string;
}
/**
 * 
 * @export
 * @interface SendEmailRequestTags
 */
export interface SendEmailRequestTags {
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequestTags
     */
    Value: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailRequestTags
     */
    Name: string;
}
/**
 * 
 * @export
 * @interface SendPrivateInviteEmailRequestParams
 */
export interface SendPrivateInviteEmailRequestParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendPrivateInviteEmailRequestParams
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface SendPublicDesignFileDownloadLinkRequest
 */
export interface SendPublicDesignFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPublicDesignFileDownloadLinkRequest
     */
    fullKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendPublicDesignFileDownloadLinkRequest
     */
    formatted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendPublicDesignFileDownloadLinkRequest
     */
    ownerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendPublicDesignFileDownloadLinkRequest
     */
    isForViewing: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendPublicDesignFileDownloadLinkRequest
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface SendPublicLinkToEmailsRequest
 */
export interface SendPublicLinkToEmailsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendPublicLinkToEmailsRequest
     */
    emails: Array<string>;
    /**
     * 
     * @type {SimpleFileNode}
     * @memberof SendPublicLinkToEmailsRequest
     */
    fileNode: SimpleFileNode;
}
/**
 * 
 * @export
 * @interface ShareFileSystemObjectRequest
 */
export interface ShareFileSystemObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareFileSystemObjectRequest
     */
    creatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareFileSystemObjectRequest
     */
    fileNodeKey: string;
    /**
     * 
     * @type {Array<FilePermissionType>}
     * @memberof ShareFileSystemObjectRequest
     */
    filePermissions: Array<FilePermissionType>;
    /**
     * 
     * @type {ShareFileSystemTarget}
     * @memberof ShareFileSystemObjectRequest
     */
    targetType: ShareFileSystemTarget;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareFileSystemObjectRequest
     */
    target?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ShareFileSystemTarget {
    UserGroup = 'UserGroup',
    ProjectUsers = 'ProjectUsers',
    Everyone = 'Everyone',
    ExternalEmail = 'ExternalEmail'
}

/**
 * 
 * @export
 * @interface SimpleDocumentSummaryType
 */
export interface SimpleDocumentSummaryType {
    /**
     * 
     * @type {number}
     * @memberof SimpleDocumentSummaryType
     */
    numIssued: number;
}
/**
 * 
 * @export
 * @interface SimpleFileNode
 */
export interface SimpleFileNode {
    /**
     * 
     * @type {string}
     * @memberof SimpleFileNode
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleFileNode
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleFileNode
     */
    fullKey: string;
}
/**
 * 
 * @export
 * @interface SimpleUserForDisplay
 */
export interface SimpleUserForDisplay {
    /**
     * 
     * @type {string}
     * @memberof SimpleUserForDisplay
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserForDisplay
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserForDisplay
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserForDisplay
     */
    publicCompanyName?: string | null;
    /**
     * 
     * @type {SimpleUserForDisplayAllOfCompany}
     * @memberof SimpleUserForDisplay
     */
    company?: SimpleUserForDisplayAllOfCompany | null;
}
/**
 * 
 * @export
 * @interface SimpleUserForDisplayAllOf
 */
export interface SimpleUserForDisplayAllOf {
    /**
     * 
     * @type {SimpleUserForDisplayAllOfCompany}
     * @memberof SimpleUserForDisplayAllOf
     */
    company?: SimpleUserForDisplayAllOfCompany | null;
}
/**
 * 
 * @export
 * @interface SimpleUserForDisplayAllOfCompany
 */
export interface SimpleUserForDisplayAllOfCompany {
    /**
     * 
     * @type {string}
     * @memberof SimpleUserForDisplayAllOfCompany
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SimpleUserNotificationSendingStatus
 */
export interface SimpleUserNotificationSendingStatus {
    /**
     * 
     * @type {SimpleUserForDisplay}
     * @memberof SimpleUserNotificationSendingStatus
     */
    user: SimpleUserForDisplay;
    /**
     * 
     * @type {EmailStatus}
     * @memberof SimpleUserNotificationSendingStatus
     */
    emailStatus: EmailStatus;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserNotificationSendingStatus
     */
    userNotificationId: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserNotificationSendingStatus
     */
    notificationId: string;
}
/**
 * 
 * @export
 * @interface SpecificationsParserRequest
 */
export interface SpecificationsParserRequest {
    /**
     * 
     * @type {string}
     * @memberof SpecificationsParserRequest
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof SpecificationsParserRequest
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface SubcontractorChangeRequest
 */
export interface SubcontractorChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof SubcontractorChangeRequest
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof SubcontractorChangeRequest
     */
    subcontractorEmail: string;
}
/**
 * 
 * @export
 * @interface SubmitToArchitectProps
 */
export interface SubmitToArchitectProps {
    /**
     * 
     * @type {string}
     * @memberof SubmitToArchitectProps
     */
    architectUserId?: string;
}
/**
 * 
 * @export
 * @interface SubmittalFlattenRequest
 */
export interface SubmittalFlattenRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmittalFlattenRequest
     */
    flattenedOutputName: string;
    /**
     * 
     * @type {IInsertionComment}
     * @memberof SubmittalFlattenRequest
     */
    comment?: IInsertionComment;
}
/**
 * 
 * @export
 * @interface SubmittalReview
 */
export interface SubmittalReview {
    /**
     * 
     * @type {boolean}
     * @memberof SubmittalReview
     */
    willPublishResponse?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmittalReview
     */
    copiedCommentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmittalReview
     */
    comment?: string;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof SubmittalReview
     */
    actionTaken?: ActionTakenType;
}
/**
 * 
 * @export
 * @interface SubmittalReviewProps
 */
export interface SubmittalReviewProps {
    /**
     * 
     * @type {ActionTakenType}
     * @memberof SubmittalReviewProps
     */
    actionTaken?: ActionTakenType;
    /**
     * 
     * @type {string}
     * @memberof SubmittalReviewProps
     */
    comment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmittalReviewProps
     */
    copiedCommentIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SubmittalReviewProps
     */
    willPublishResponse?: boolean;
    /**
     * 
     * @type {Array<SubmittalReviewRowProps>}
     * @memberof SubmittalReviewProps
     */
    packageReview?: Array<SubmittalReviewRowProps>;
}
/**
 * 
 * @export
 * @interface SubmittalReviewRowProps
 */
export interface SubmittalReviewRowProps {
    /**
     * 
     * @type {boolean}
     * @memberof SubmittalReviewRowProps
     */
    willPublishResponse?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmittalReviewRowProps
     */
    copiedCommentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmittalReviewRowProps
     */
    comment?: string;
    /**
     * 
     * @type {ActionTakenType}
     * @memberof SubmittalReviewRowProps
     */
    actionTaken?: ActionTakenType;
    /**
     * 
     * @type {string}
     * @memberof SubmittalReviewRowProps
     */
    documentId: string;
}
/**
 * 
 * @export
 * @interface SubmittalReviewRowPropsAllOf
 */
export interface SubmittalReviewRowPropsAllOf {
    /**
     * 
     * @type {string}
     * @memberof SubmittalReviewRowPropsAllOf
     */
    documentId: string;
}
/**
 * 
 * @export
 * @interface SubmittalUploadRequest
 */
export interface SubmittalUploadRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SubmittalUploadRequest
     */
    useMultiPartUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmittalUploadRequest
     */
    fullFileName: string;
    /**
     * 
     * @type {string}
     * @memberof SubmittalUploadRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmittalUploadRequest
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmittalUploadRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmittalUploadRequest
     */
    fullKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmittalUploadRequest
     */
    isPublic?: boolean;
    /**
     * 
     * @type {IInsertionComment}
     * @memberof SubmittalUploadRequest
     */
    comment?: IInsertionComment;
}
/**
 * 
 * @export
 * @interface ToggleRemindersProps
 */
export interface ToggleRemindersProps {
    /**
     * 
     * @type {string}
     * @memberof ToggleRemindersProps
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof ToggleRemindersProps
     */
    disableReminders?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToggleRemindersProps
     */
    disableNotifications?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfTestingType {
    Concrete = 'concrete',
    Soil = 'soil',
    Steel = 'steel',
    WaterInfiltration = 'water_infiltration',
    FireProtection = 'fire_protection',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface UnlockProjectsWithKeyParams
 */
export interface UnlockProjectsWithKeyParams {
    /**
     * 
     * @type {string}
     * @memberof UnlockProjectsWithKeyParams
     */
    key: string;
}
/**
 * 
 * @export
 * @interface UpdateReviewParams
 */
export interface UpdateReviewParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateReviewParams
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface UserFileSystemNodeRequest
 */
export interface UserFileSystemNodeRequest {
    /**
     * 
     * @type {FileNode}
     * @memberof UserFileSystemNodeRequest
     */
    fileNode: FileNode;
}
/**
 * 
 * @export
 * @interface UserGroupEdit
 */
export interface UserGroupEdit {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupEdit
     */
    deleteUserGroupDisciplineIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupEdit
     */
    deleteUserGroupCompanyIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupEdit
     */
    deleteUserGroupUserIds?: Array<string>;
    /**
     * 
     * @type {Array<PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupEdit
     */
    insertDisciplines?: Array<PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {Array<PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupEdit
     */
    insertCompanies?: Array<PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {Array<PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupEdit
     */
    insertUsers?: Array<PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {string}
     * @memberof UserGroupEdit
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupEdit
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UserGroupInsertionRequest
 */
export interface UserGroupInsertionRequest {
    /**
     * 
     * @type {Array<PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupInsertionRequest
     */
    insertDisciplines?: Array<PickIInsertionUserGroupDisciplineExcludeKeyofIInsertionUserGroupDisciplineUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {Array<PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupInsertionRequest
     */
    insertCompanies?: Array<PickIInsertionUserGroupCompanyExcludeKeyofIInsertionUserGroupCompanyUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {Array<PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId>}
     * @memberof UserGroupInsertionRequest
     */
    insertUsers?: Array<PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId>;
    /**
     * 
     * @type {PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId}
     * @memberof UserGroupInsertionRequest
     */
    userGroup: PickIInsertionUserGroupExcludeKeyofIInsertionUserGroupProjectIdOrLastUpdatedByUserId;
}
/**
 * 
 * @export
 * @interface UserNotificationWithEmail
 */
export interface UserNotificationWithEmail {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    lastUpdatedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    updatedOn?: string | null;
    /**
     * 
     * @type {IUser}
     * @memberof UserNotificationWithEmail
     */
    lastUpdatedByUser?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    userId: string;
    /**
     * 
     * @type {IUser}
     * @memberof UserNotificationWithEmail
     */
    user?: IUser | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    notificationId: string;
    /**
     * 
     * @type {INotification}
     * @memberof UserNotificationWithEmail
     */
    notification?: INotification | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    designFileShareId?: string | null;
    /**
     * 
     * @type {IDesignFileShare}
     * @memberof UserNotificationWithEmail
     */
    designFileShare?: IDesignFileShare | null;
    /**
     * 
     * @type {NotifiedUserType}
     * @memberof UserNotificationWithEmail
     */
    userType: NotifiedUserType;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationWithEmail
     */
    isRead?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationWithEmail
     */
    doNotSend?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    outboundEmailId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationWithEmail
     */
    emailGenerationInfoId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationWithEmail
     */
    isHidden?: boolean | null;
    /**
     * 
     * @type {SendEmailRequest}
     * @memberof UserNotificationWithEmail
     */
    email: SendEmailRequest;
}
/**
 * 
 * @export
 * @interface UserNotificationWithEmailAllOf
 */
export interface UserNotificationWithEmailAllOf {
    /**
     * 
     * @type {SendEmailRequest}
     * @memberof UserNotificationWithEmailAllOf
     */
    email: SendEmailRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkflowStatusType {
    Initial = 'initial',
    SubcontractorUploaded = 'subcontractor_uploaded',
    ReadyForSubmissionToArchitect = 'ready_for_submission_to_architect',
    GeneralContractorUploaded = 'general_contractor_uploaded',
    SubmittedForReview = 'submitted_for_review',
    UnderReview = 'under_review',
    ArchitectUploaded = 'architect_uploaded',
    Completed = 'completed',
    ReviewComplete = 'review_complete',
    Accepted = 'accepted',
    Resolved = 'resolved',
    Resubmitted = 'resubmitted',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface ZipFileCategory
 */
export interface ZipFileCategory {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSubmissionByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling acceptSubmissionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/accept`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AddDocumentToPackageProps} addDocumentToPackageProps 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentToPackageByDocumentId: async (documentId: string, addDocumentToPackageProps: AddDocumentToPackageProps, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling addDocumentToPackageByDocumentId.');
            }
            // verify required parameter 'addDocumentToPackageProps' is not null or undefined
            if (addDocumentToPackageProps === null || addDocumentToPackageProps === undefined) {
                throw new RequiredError('addDocumentToPackageProps','Required parameter addDocumentToPackageProps was null or undefined when calling addDocumentToPackageByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/add-to-package`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addDocumentToPackageProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addDocumentToPackageProps !== undefined ? addDocumentToPackageProps : {})
                : (addDocumentToPackageProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AddUserParams>} addUserParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrModifyUsersByProjectId: async (projectId: string, addUserParams: Array<AddUserParams>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling addOrModifyUsersByProjectId.');
            }
            // verify required parameter 'addUserParams' is not null or undefined
            if (addUserParams === null || addUserParams === undefined) {
                throw new RequiredError('addUserParams','Required parameter addUserParams was null or undefined when calling addOrModifyUsersByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addUserParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addUserParams !== undefined ? addUserParams : {})
                : (addUserParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssignByDomainRequest} assignByDomainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUsersToCompanyByEmailDomain: async (assignByDomainRequest: AssignByDomainRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignByDomainRequest' is not null or undefined
            if (assignByDomainRequest === null || assignByDomainRequest === undefined) {
                throw new RequiredError('assignByDomainRequest','Required parameter assignByDomainRequest was null or undefined when calling assignUsersToCompanyByEmailDomain.');
            }
            const localVarPath = `/users/assign-by-domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof assignByDomainRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(assignByDomainRequest !== undefined ? assignByDomainRequest : {})
                : (assignByDomainRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeBIM360: async (centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling authorizeBIM360.');
            }
            // verify required parameter 'iAuthorizeParams' is not null or undefined
            if (iAuthorizeParams === null || iAuthorizeParams === undefined) {
                throw new RequiredError('iAuthorizeParams','Required parameter iAuthorizeParams was null or undefined when calling authorizeBIM360.');
            }
            const localVarPath = `/bim360/{centerLineProjectId}/authorize`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iAuthorizeParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iAuthorizeParams !== undefined ? iAuthorizeParams : {})
                : (iAuthorizeParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeProcore: async (centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling authorizeProcore.');
            }
            // verify required parameter 'iAuthorizeParams' is not null or undefined
            if (iAuthorizeParams === null || iAuthorizeParams === undefined) {
                throw new RequiredError('iAuthorizeParams','Required parameter iAuthorizeParams was null or undefined when calling authorizeProcore.');
            }
            const localVarPath = `/procore/{centerLineProjectId}/authorize`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iAuthorizeParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iAuthorizeParams !== undefined ? iAuthorizeParams : {})
                : (iAuthorizeParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DrawingsParserRequest} drawingsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginDrawingsParser: async (drawingsParserRequest: DrawingsParserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'drawingsParserRequest' is not null or undefined
            if (drawingsParserRequest === null || drawingsParserRequest === undefined) {
                throw new RequiredError('drawingsParserRequest','Required parameter drawingsParserRequest was null or undefined when calling beginDrawingsParser.');
            }
            const localVarPath = `/parser/begin-drawings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof drawingsParserRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(drawingsParserRequest !== undefined ? drawingsParserRequest : {})
                : (drawingsParserRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DrawingsPreprocessorRequest} drawingsPreprocessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginDrawingsPreprocessor: async (drawingsPreprocessorRequest: DrawingsPreprocessorRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'drawingsPreprocessorRequest' is not null or undefined
            if (drawingsPreprocessorRequest === null || drawingsPreprocessorRequest === undefined) {
                throw new RequiredError('drawingsPreprocessorRequest','Required parameter drawingsPreprocessorRequest was null or undefined when calling beginDrawingsPreprocessor.');
            }
            const localVarPath = `/parser/preprocess-drawings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof drawingsPreprocessorRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(drawingsPreprocessorRequest !== undefined ? drawingsPreprocessorRequest : {})
                : (drawingsPreprocessorRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpecificationsParserRequest} specificationsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginSpecificationsParser: async (specificationsParserRequest: SpecificationsParserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'specificationsParserRequest' is not null or undefined
            if (specificationsParserRequest === null || specificationsParserRequest === undefined) {
                throw new RequiredError('specificationsParserRequest','Required parameter specificationsParserRequest was null or undefined when calling beginSpecificationsParser.');
            }
            const localVarPath = `/parser/begin-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof specificationsParserRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(specificationsParserRequest !== undefined ? specificationsParserRequest : {})
                : (specificationsParserRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteMultiPartUploadRequest} completeMultiPartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartUpload: async (completeMultiPartUploadRequest: CompleteMultiPartUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeMultiPartUploadRequest' is not null or undefined
            if (completeMultiPartUploadRequest === null || completeMultiPartUploadRequest === undefined) {
                throw new RequiredError('completeMultiPartUploadRequest','Required parameter completeMultiPartUploadRequest was null or undefined when calling completeMultipartUpload.');
            }
            const localVarPath = `/filesystem/complete-multi-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof completeMultiPartUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(completeMultiPartUploadRequest !== undefined ? completeMultiPartUploadRequest : {})
                : (completeMultiPartUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalReviewProps} submittalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeReviewByDocumentId: async (documentId: string, submittalReviewProps: SubmittalReviewProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling completeReviewByDocumentId.');
            }
            // verify required parameter 'submittalReviewProps' is not null or undefined
            if (submittalReviewProps === null || submittalReviewProps === undefined) {
                throw new RequiredError('submittalReviewProps','Required parameter submittalReviewProps was null or undefined when calling completeReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/complete-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submittalReviewProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submittalReviewProps !== undefined ? submittalReviewProps : {})
                : (submittalReviewProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ErrorEmail} errorEmail 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createErrorEmail: async (errorEmail: ErrorEmail, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'errorEmail' is not null or undefined
            if (errorEmail === null || errorEmail === undefined) {
                throw new RequiredError('errorEmail','Required parameter errorEmail was null or undefined when calling createErrorEmail.');
            }
            const localVarPath = `/outbound-email/send-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof errorEmail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(errorEmail !== undefined ? errorEmail : {})
                : (errorEmail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {CreateLinkedDocumentsForAssignedUsersRequest} createLinkedDocumentsForAssignedUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedDocumentsForAssignedUsers: async (documentId: string, createLinkedDocumentsForAssignedUsersRequest: CreateLinkedDocumentsForAssignedUsersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling createLinkedDocumentsForAssignedUsers.');
            }
            // verify required parameter 'createLinkedDocumentsForAssignedUsersRequest' is not null or undefined
            if (createLinkedDocumentsForAssignedUsersRequest === null || createLinkedDocumentsForAssignedUsersRequest === undefined) {
                throw new RequiredError('createLinkedDocumentsForAssignedUsersRequest','Required parameter createLinkedDocumentsForAssignedUsersRequest was null or undefined when calling createLinkedDocumentsForAssignedUsers.');
            }
            const localVarPath = `/documents/{documentId}/create-linked-documents-for-assigned-users`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createLinkedDocumentsForAssignedUsersRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createLinkedDocumentsForAssignedUsersRequest !== undefined ? createLinkedDocumentsForAssignedUsersRequest : {})
                : (createLinkedDocumentsForAssignedUsersRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedOtherFileSystemRequest} authenticatedOtherFileSystemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFileSystemFolder: async (projectId: string, authenticatedOtherFileSystemRequest: AuthenticatedOtherFileSystemRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createUserFileSystemFolder.');
            }
            // verify required parameter 'authenticatedOtherFileSystemRequest' is not null or undefined
            if (authenticatedOtherFileSystemRequest === null || authenticatedOtherFileSystemRequest === undefined) {
                throw new RequiredError('authenticatedOtherFileSystemRequest','Required parameter authenticatedOtherFileSystemRequest was null or undefined when calling createUserFileSystemFolder.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/create-folder`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedOtherFileSystemRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedOtherFileSystemRequest !== undefined ? authenticatedOtherFileSystemRequest : {})
                : (authenticatedOtherFileSystemRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllSecurityGroupsBySubscriberId: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling deleteAllSecurityGroupsBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/security-groups`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncementById: async (announcementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            if (announcementId === null || announcementId === undefined) {
                throw new RequiredError('announcementId','Required parameter announcementId was null or undefined when calling deleteAnnouncementById.');
            }
            const localVarPath = `/announcements/{announcementId}`
                .replace(`{${"announcementId"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBidSetupByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteBidSetupByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/bid-setup`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentById: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling deleteDocumentById.');
            }
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteDocumentsRequest} deleteDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentsByIds: async (deleteDocumentsRequest: DeleteDocumentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteDocumentsRequest' is not null or undefined
            if (deleteDocumentsRequest === null || deleteDocumentsRequest === undefined) {
                throw new RequiredError('deleteDocumentsRequest','Required parameter deleteDocumentsRequest was null or undefined when calling deleteDocumentsByIds.');
            }
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteDocumentsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteDocumentsRequest !== undefined ? deleteDocumentsRequest : {})
                : (deleteDocumentsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByBidSetupIdAndFileId: async (bidSetupId: string, fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling deleteFileByBidSetupIdAndFileId.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling deleteFileByBidSetupIdAndFileId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/files/{fileId}`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInviteEmailByProjectIdAndUserId: async (projectId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteInviteEmailByProjectIdAndUserId.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteInviteEmailByProjectIdAndUserId.');
            }
            const localVarPath = `/projects/{projectId}/users/{userId}/invite`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IDeleteUsersByEmailParams} iDeleteUsersByEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsersByEmail: async (iDeleteUsersByEmailParams: IDeleteUsersByEmailParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iDeleteUsersByEmailParams' is not null or undefined
            if (iDeleteUsersByEmailParams === null || iDeleteUsersByEmailParams === undefined) {
                throw new RequiredError('iDeleteUsersByEmailParams','Required parameter iDeleteUsersByEmailParams was null or undefined when calling deleteManyUsersByEmail.');
            }
            const localVarPath = `/users/many-by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iDeleteUsersByEmailParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iDeleteUsersByEmailParams !== undefined ? iDeleteUsersByEmailParams : {})
                : (iDeleteUsersByEmailParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParsedFileByProjectIdAndFileId: async (projectId: string, fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteParsedFileByProjectIdAndFileId.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling deleteParsedFileByProjectIdAndFileId.');
            }
            const localVarPath = `/projects/{projectId}/parsed-files/{fileId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteParsedFilesRequest} deleteParsedFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParsedFilesByProjectId: async (projectId: string, deleteParsedFilesRequest: DeleteParsedFilesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteParsedFilesByProjectId.');
            }
            // verify required parameter 'deleteParsedFilesRequest' is not null or undefined
            if (deleteParsedFilesRequest === null || deleteParsedFilesRequest === undefined) {
                throw new RequiredError('deleteParsedFilesRequest','Required parameter deleteParsedFilesRequest was null or undefined when calling deleteParsedFilesByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/parsed-files`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteParsedFilesRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteParsedFilesRequest !== undefined ? deleteParsedFilesRequest : {})
                : (deleteParsedFilesRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalBuildingById: async (buildingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            if (buildingId === null || buildingId === undefined) {
                throw new RequiredError('buildingId','Required parameter buildingId was null or undefined when calling deletePhysicalBuildingById.');
            }
            const localVarPath = `/physical-buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalFloorById: async (floorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            if (floorId === null || floorId === undefined) {
                throw new RequiredError('floorId','Required parameter floorId was null or undefined when calling deletePhysicalFloorById.');
            }
            const localVarPath = `/physical-floors/{floorId}`
                .replace(`{${"floorId"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalLocationById: async (locationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling deletePhysicalLocationById.');
            }
            const localVarPath = `/physical-locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrimeBidderByUserAndProject: async (userId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deletePrimeBidderByUserAndProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deletePrimeBidderByUserAndProject.');
            }
            const localVarPath = `/prime-bidders/users/{userId}/projects/{projectId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductPackageById: async (productPackageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPackageId' is not null or undefined
            if (productPackageId === null || productPackageId === undefined) {
                throw new RequiredError('productPackageId','Required parameter productPackageId was null or undefined when calling deleteProductPackageById.');
            }
            const localVarPath = `/product-packages/{productPackageId}`
                .replace(`{${"productPackageId"}}`, encodeURIComponent(String(productPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [newSecurityGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurityGroupById: async (id: string, newSecurityGroupId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSecurityGroupById.');
            }
            const localVarPath = `/security-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newSecurityGroupId !== undefined) {
                localVarQueryParameter['newSecurityGroupId'] = newSecurityGroupId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriberById: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling deleteSubscriberById.');
            }
            const localVarPath = `/subscribers/{subscriberId}`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTrashItemsRequest} deleteTrashItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrashItemsByProjectId: async (projectId: string, deleteTrashItemsRequest: DeleteTrashItemsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteTrashItemsByProjectId.');
            }
            // verify required parameter 'deleteTrashItemsRequest' is not null or undefined
            if (deleteTrashItemsRequest === null || deleteTrashItemsRequest === undefined) {
                throw new RequiredError('deleteTrashItemsRequest','Required parameter deleteTrashItemsRequest was null or undefined when calling deleteTrashItemsByProjectId.');
            }
            const localVarPath = `/filesystem/{projectId}/trash`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteTrashItemsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteTrashItemsRequest !== undefined ? deleteTrashItemsRequest : {})
                : (deleteTrashItemsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserById.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserFileSystemNodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFileSystemNode: async (projectId: string, body: UserFileSystemNodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteUserFileSystemNode.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUserFileSystemNode.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/delete`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupById: async (userGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupId' is not null or undefined
            if (userGroupId === null || userGroupId === undefined) {
                throw new RequiredError('userGroupId','Required parameter userGroupId was null or undefined when calling deleteUserGroupById.');
            }
            const localVarPath = `/user-groups/{userGroupId}`
                .replace(`{${"userGroupId"}}`, encodeURIComponent(String(userGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserNotificationsParams} deleteUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserNotificationsByIds: async (deleteUserNotificationsParams: DeleteUserNotificationsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUserNotificationsParams' is not null or undefined
            if (deleteUserNotificationsParams === null || deleteUserNotificationsParams === undefined) {
                throw new RequiredError('deleteUserNotificationsParams','Required parameter deleteUserNotificationsParams was null or undefined when calling deleteUserNotificationsByIds.');
            }
            const localVarPath = `/user-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteUserNotificationsParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteUserNotificationsParams !== undefined ? deleteUserNotificationsParams : {})
                : (deleteUserNotificationsParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableRegistrationReminderByUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling disableRegistrationReminderByUserId.');
            }
            const localVarPath = `/users/{userId}/disable-registration-reminder`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTrashItemRequest} getTrashItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTrashItemByProjectId: async (getTrashItemRequest: GetTrashItemRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTrashItemRequest' is not null or undefined
            if (getTrashItemRequest === null || getTrashItemRequest === undefined) {
                throw new RequiredError('getTrashItemRequest','Required parameter getTrashItemRequest was null or undefined when calling downloadTrashItemByProjectId.');
            }
            const localVarPath = `/filesystem/trash-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getTrashItemRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getTrashItemRequest !== undefined ? getTrashItemRequest : {})
                : (getTrashItemRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {IOptionalAnnouncement} iOptionalAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAnnouncementById: async (announcementId: string, iOptionalAnnouncement: IOptionalAnnouncement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            if (announcementId === null || announcementId === undefined) {
                throw new RequiredError('announcementId','Required parameter announcementId was null or undefined when calling editAnnouncementById.');
            }
            // verify required parameter 'iOptionalAnnouncement' is not null or undefined
            if (iOptionalAnnouncement === null || iOptionalAnnouncement === undefined) {
                throw new RequiredError('iOptionalAnnouncement','Required parameter iOptionalAnnouncement was null or undefined when calling editAnnouncementById.');
            }
            const localVarPath = `/announcements/{announcementId}`
                .replace(`{${"announcementId"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalAnnouncement !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalAnnouncement !== undefined ? iOptionalAnnouncement : {})
                : (iOptionalAnnouncement || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AnticipatedSubmissionDateChangeRequest} anticipatedSubmissionDateChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAnticipatedSubmissionDateOfDocumentById: async (documentId: string, anticipatedSubmissionDateChangeRequest: AnticipatedSubmissionDateChangeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling editAnticipatedSubmissionDateOfDocumentById.');
            }
            // verify required parameter 'anticipatedSubmissionDateChangeRequest' is not null or undefined
            if (anticipatedSubmissionDateChangeRequest === null || anticipatedSubmissionDateChangeRequest === undefined) {
                throw new RequiredError('anticipatedSubmissionDateChangeRequest','Required parameter anticipatedSubmissionDateChangeRequest was null or undefined when calling editAnticipatedSubmissionDateOfDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/anticipated-submission-date`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof anticipatedSubmissionDateChangeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(anticipatedSubmissionDateChangeRequest !== undefined ? anticipatedSubmissionDateChangeRequest : {})
                : (anticipatedSubmissionDateChangeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomOptionalBidSetup} iCustomOptionalBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBidSetupByProjectId: async (projectId: string, iCustomOptionalBidSetup: ICustomOptionalBidSetup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling editBidSetupByProjectId.');
            }
            // verify required parameter 'iCustomOptionalBidSetup' is not null or undefined
            if (iCustomOptionalBidSetup === null || iCustomOptionalBidSetup === undefined) {
                throw new RequiredError('iCustomOptionalBidSetup','Required parameter iCustomOptionalBidSetup was null or undefined when calling editBidSetupByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/bid-setup`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iCustomOptionalBidSetup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iCustomOptionalBidSetup !== undefined ? iCustomOptionalBidSetup : {})
                : (iCustomOptionalBidSetup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IOptionalDocument} iOptionalDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentById: async (documentId: string, iOptionalDocument: IOptionalDocument, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling editDocumentById.');
            }
            // verify required parameter 'iOptionalDocument' is not null or undefined
            if (iOptionalDocument === null || iOptionalDocument === undefined) {
                throw new RequiredError('iOptionalDocument','Required parameter iOptionalDocument was null or undefined when calling editDocumentById.');
            }
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalDocument !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalDocument !== undefined ? iOptionalDocument : {})
                : (iOptionalDocument || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {IEditDocumentSettings} iEditDocumentSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentSettingsByUserIdAndDocumentId: async (userId: string, documentId: string, iEditDocumentSettings: IEditDocumentSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling editDocumentSettingsByUserIdAndDocumentId.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling editDocumentSettingsByUserIdAndDocumentId.');
            }
            // verify required parameter 'iEditDocumentSettings' is not null or undefined
            if (iEditDocumentSettings === null || iEditDocumentSettings === undefined) {
                throw new RequiredError('iEditDocumentSettings','Required parameter iEditDocumentSettings was null or undefined when calling editDocumentSettingsByUserIdAndDocumentId.');
            }
            const localVarPath = `/users/{userId}/documents/{documentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iEditDocumentSettings !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iEditDocumentSettings !== undefined ? iEditDocumentSettings : {})
                : (iEditDocumentSettings || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {EditFavoriteProjectParams} editFavoriteProjectParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFavoriteProjectByUserIdAndProjectId: async (userId: string, projectId: string, editFavoriteProjectParams: EditFavoriteProjectParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling editFavoriteProjectByUserIdAndProjectId.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling editFavoriteProjectByUserIdAndProjectId.');
            }
            // verify required parameter 'editFavoriteProjectParams' is not null or undefined
            if (editFavoriteProjectParams === null || editFavoriteProjectParams === undefined) {
                throw new RequiredError('editFavoriteProjectParams','Required parameter editFavoriteProjectParams was null or undefined when calling editFavoriteProjectByUserIdAndProjectId.');
            }
            const localVarPath = `/users/{userId}/projects/{projectId}/favorite`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof editFavoriteProjectParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(editFavoriteProjectParams !== undefined ? editFavoriteProjectParams : {})
                : (editFavoriteProjectParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {EditFileParams} editFileParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFileByDocumentIdAndFileId: async (documentId: string, fileId: string, editFileParams: EditFileParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling editFileByDocumentIdAndFileId.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling editFileByDocumentIdAndFileId.');
            }
            // verify required parameter 'editFileParams' is not null or undefined
            if (editFileParams === null || editFileParams === undefined) {
                throw new RequiredError('editFileParams','Required parameter editFileParams was null or undefined when calling editFileByDocumentIdAndFileId.');
            }
            const localVarPath = `/documents/{documentId}/files/{fileId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof editFileParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(editFileParams !== undefined ? editFileParams : {})
                : (editFileParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {IOptionalFile} iOptionalFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFileById: async (fileId: string, iOptionalFile: IOptionalFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling editFileById.');
            }
            // verify required parameter 'iOptionalFile' is not null or undefined
            if (iOptionalFile === null || iOptionalFile === undefined) {
                throw new RequiredError('iOptionalFile','Required parameter iOptionalFile was null or undefined when calling editFileById.');
            }
            const localVarPath = `/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalFile !== undefined ? iOptionalFile : {})
                : (iOptionalFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditManyPhysicalFloorsRequest} editManyPhysicalFloorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editManyPhysicalFloorsByIds: async (editManyPhysicalFloorsRequest: EditManyPhysicalFloorsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editManyPhysicalFloorsRequest' is not null or undefined
            if (editManyPhysicalFloorsRequest === null || editManyPhysicalFloorsRequest === undefined) {
                throw new RequiredError('editManyPhysicalFloorsRequest','Required parameter editManyPhysicalFloorsRequest was null or undefined when calling editManyPhysicalFloorsByIds.');
            }
            const localVarPath = `/physical-floors/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof editManyPhysicalFloorsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(editManyPhysicalFloorsRequest !== undefined ? editManyPhysicalFloorsRequest : {})
                : (editManyPhysicalFloorsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {CustomOptionalPhysicalBuilding} customOptionalPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalBuildingById: async (buildingId: string, customOptionalPhysicalBuilding: CustomOptionalPhysicalBuilding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            if (buildingId === null || buildingId === undefined) {
                throw new RequiredError('buildingId','Required parameter buildingId was null or undefined when calling editPhysicalBuildingById.');
            }
            // verify required parameter 'customOptionalPhysicalBuilding' is not null or undefined
            if (customOptionalPhysicalBuilding === null || customOptionalPhysicalBuilding === undefined) {
                throw new RequiredError('customOptionalPhysicalBuilding','Required parameter customOptionalPhysicalBuilding was null or undefined when calling editPhysicalBuildingById.');
            }
            const localVarPath = `/physical-buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customOptionalPhysicalBuilding !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customOptionalPhysicalBuilding !== undefined ? customOptionalPhysicalBuilding : {})
                : (customOptionalPhysicalBuilding || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} floorId 
         * @param {IOptionalPhysicalFloor} iOptionalPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalFloorById: async (floorId: string, iOptionalPhysicalFloor: IOptionalPhysicalFloor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            if (floorId === null || floorId === undefined) {
                throw new RequiredError('floorId','Required parameter floorId was null or undefined when calling editPhysicalFloorById.');
            }
            // verify required parameter 'iOptionalPhysicalFloor' is not null or undefined
            if (iOptionalPhysicalFloor === null || iOptionalPhysicalFloor === undefined) {
                throw new RequiredError('iOptionalPhysicalFloor','Required parameter iOptionalPhysicalFloor was null or undefined when calling editPhysicalFloorById.');
            }
            const localVarPath = `/physical-floors/{floorId}`
                .replace(`{${"floorId"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalPhysicalFloor !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalPhysicalFloor !== undefined ? iOptionalPhysicalFloor : {})
                : (iOptionalPhysicalFloor || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {IOptionalPhysicalLocation} iOptionalPhysicalLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalLocationById: async (locationId: string, iOptionalPhysicalLocation: IOptionalPhysicalLocation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling editPhysicalLocationById.');
            }
            // verify required parameter 'iOptionalPhysicalLocation' is not null or undefined
            if (iOptionalPhysicalLocation === null || iOptionalPhysicalLocation === undefined) {
                throw new RequiredError('iOptionalPhysicalLocation','Required parameter iOptionalPhysicalLocation was null or undefined when calling editPhysicalLocationById.');
            }
            const localVarPath = `/physical-locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalPhysicalLocation !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalPhysicalLocation !== undefined ? iOptionalPhysicalLocation : {})
                : (iOptionalPhysicalLocation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {IOptionalProductPackage} iOptionalProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductPackageById: async (productPackageId: string, iOptionalProductPackage: IOptionalProductPackage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPackageId' is not null or undefined
            if (productPackageId === null || productPackageId === undefined) {
                throw new RequiredError('productPackageId','Required parameter productPackageId was null or undefined when calling editProductPackageById.');
            }
            // verify required parameter 'iOptionalProductPackage' is not null or undefined
            if (iOptionalProductPackage === null || iOptionalProductPackage === undefined) {
                throw new RequiredError('iOptionalProductPackage','Required parameter iOptionalProductPackage was null or undefined when calling editProductPackageById.');
            }
            const localVarPath = `/product-packages/{productPackageId}`
                .replace(`{${"productPackageId"}}`, encodeURIComponent(String(productPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalProductPackage !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalProductPackage !== undefined ? iOptionalProductPackage : {})
                : (iOptionalProductPackage || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IOptionalProject} iOptionalProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProjectById: async (projectId: string, iOptionalProject: IOptionalProject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling editProjectById.');
            }
            // verify required parameter 'iOptionalProject' is not null or undefined
            if (iOptionalProject === null || iOptionalProject === undefined) {
                throw new RequiredError('iOptionalProject','Required parameter iOptionalProject was null or undefined when calling editProjectById.');
            }
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalProject !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalProject !== undefined ? iOptionalProject : {})
                : (iOptionalProject || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IOptionalProjectUser} iOptionalProjectUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProjectUserById: async (id: string, iOptionalProjectUser: IOptionalProjectUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editProjectUserById.');
            }
            // verify required parameter 'iOptionalProjectUser' is not null or undefined
            if (iOptionalProjectUser === null || iOptionalProjectUser === undefined) {
                throw new RequiredError('iOptionalProjectUser','Required parameter iOptionalProjectUser was null or undefined when calling editProjectUserById.');
            }
            const localVarPath = `/project-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalProjectUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalProjectUser !== undefined ? iOptionalProjectUser : {})
                : (iOptionalProjectUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {string} securityGroupId 
         * @param {IOptionalSubscriberSecurityGroup} iOptionalSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSecurityGroupBySubscriberId: async (subscriberId: string, securityGroupId: string, iOptionalSubscriberSecurityGroup: IOptionalSubscriberSecurityGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling editSecurityGroupBySubscriberId.');
            }
            // verify required parameter 'securityGroupId' is not null or undefined
            if (securityGroupId === null || securityGroupId === undefined) {
                throw new RequiredError('securityGroupId','Required parameter securityGroupId was null or undefined when calling editSecurityGroupBySubscriberId.');
            }
            // verify required parameter 'iOptionalSubscriberSecurityGroup' is not null or undefined
            if (iOptionalSubscriberSecurityGroup === null || iOptionalSubscriberSecurityGroup === undefined) {
                throw new RequiredError('iOptionalSubscriberSecurityGroup','Required parameter iOptionalSubscriberSecurityGroup was null or undefined when calling editSecurityGroupBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/security-groups/{securityGroupId}`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)))
                .replace(`{${"securityGroupId"}}`, encodeURIComponent(String(securityGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalSubscriberSecurityGroup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalSubscriberSecurityGroup !== undefined ? iOptionalSubscriberSecurityGroup : {})
                : (iOptionalSubscriberSecurityGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubcontractorChangeRequest} subcontractorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubcontractorOfDocumentById: async (documentId: string, subcontractorChangeRequest: SubcontractorChangeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling editSubcontractorOfDocumentById.');
            }
            // verify required parameter 'subcontractorChangeRequest' is not null or undefined
            if (subcontractorChangeRequest === null || subcontractorChangeRequest === undefined) {
                throw new RequiredError('subcontractorChangeRequest','Required parameter subcontractorChangeRequest was null or undefined when calling editSubcontractorOfDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/subcontractor`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof subcontractorChangeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(subcontractorChangeRequest !== undefined ? subcontractorChangeRequest : {})
                : (subcontractorChangeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IOptionalSubscriber} iOptionalSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubscriberById: async (subscriberId: string, iOptionalSubscriber: IOptionalSubscriber, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling editSubscriberById.');
            }
            // verify required parameter 'iOptionalSubscriber' is not null or undefined
            if (iOptionalSubscriber === null || iOptionalSubscriber === undefined) {
                throw new RequiredError('iOptionalSubscriber','Required parameter iOptionalSubscriber was null or undefined when calling editSubscriberById.');
            }
            const localVarPath = `/subscribers/{subscriberId}`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iOptionalSubscriber !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iOptionalSubscriber !== undefined ? iOptionalSubscriber : {})
                : (iOptionalSubscriber || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ICustomOptionalUser} iCustomOptionalUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserById: async (userId: string, iCustomOptionalUser: ICustomOptionalUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling editUserById.');
            }
            // verify required parameter 'iCustomOptionalUser' is not null or undefined
            if (iCustomOptionalUser === null || iCustomOptionalUser === undefined) {
                throw new RequiredError('iCustomOptionalUser','Required parameter iCustomOptionalUser was null or undefined when calling editUserById.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iCustomOptionalUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iCustomOptionalUser !== undefined ? iCustomOptionalUser : {})
                : (iCustomOptionalUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {UserGroupEdit} userGroupEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserGroupById: async (userGroupId: string, userGroupEdit: UserGroupEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupId' is not null or undefined
            if (userGroupId === null || userGroupId === undefined) {
                throw new RequiredError('userGroupId','Required parameter userGroupId was null or undefined when calling editUserGroupById.');
            }
            // verify required parameter 'userGroupEdit' is not null or undefined
            if (userGroupEdit === null || userGroupEdit === undefined) {
                throw new RequiredError('userGroupEdit','Required parameter userGroupEdit was null or undefined when calling editUserGroupById.');
            }
            const localVarPath = `/user-groups/{userGroupId}`
                .replace(`{${"userGroupId"}}`, encodeURIComponent(String(userGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userGroupEdit !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userGroupEdit !== undefined ? userGroupEdit : {})
                : (userGroupEdit || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {EditUserNotificationsParams} editUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserNotificationsByIds: async (userId: string, editUserNotificationsParams: EditUserNotificationsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling editUserNotificationsByIds.');
            }
            // verify required parameter 'editUserNotificationsParams' is not null or undefined
            if (editUserNotificationsParams === null || editUserNotificationsParams === undefined) {
                throw new RequiredError('editUserNotificationsParams','Required parameter editUserNotificationsParams was null or undefined when calling editUserNotificationsByIds.');
            }
            const localVarPath = `/user-notification/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof editUserNotificationsParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(editUserNotificationsParams !== undefined ? editUserNotificationsParams : {})
                : (editUserNotificationsParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptyTrashByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling emptyTrashByProjectId.');
            }
            const localVarPath = `/filesystem/{projectId}/trash/empty`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flattenBlueBeamByDocumentId: async (documentId: string, submittalFlattenRequest: SubmittalFlattenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling flattenBlueBeamByDocumentId.');
            }
            // verify required parameter 'submittalFlattenRequest' is not null or undefined
            if (submittalFlattenRequest === null || submittalFlattenRequest === undefined) {
                throw new RequiredError('submittalFlattenRequest','Required parameter submittalFlattenRequest was null or undefined when calling flattenBlueBeamByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/submission-flatten`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submittalFlattenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submittalFlattenRequest !== undefined ? submittalFlattenRequest : {})
                : (submittalFlattenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flattenBlueBeamByKey: async (key: string, submittalFlattenRequest: SubmittalFlattenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling flattenBlueBeamByKey.');
            }
            // verify required parameter 'submittalFlattenRequest' is not null or undefined
            if (submittalFlattenRequest === null || submittalFlattenRequest === undefined) {
                throw new RequiredError('submittalFlattenRequest','Required parameter submittalFlattenRequest was null or undefined when calling flattenBlueBeamByKey.');
            }
            const localVarPath = `/documents/public/{key}/submission-flatten`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submittalFlattenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submittalFlattenRequest !== undefined ? submittalFlattenRequest : {})
                : (submittalFlattenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {GenerateAsBuiltDocumentParams} generateAsBuiltDocumentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsBuiltDocumentFromDrawingsDocumentId: async (documentId: string, generateAsBuiltDocumentParams: GenerateAsBuiltDocumentParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling generateAsBuiltDocumentFromDrawingsDocumentId.');
            }
            // verify required parameter 'generateAsBuiltDocumentParams' is not null or undefined
            if (generateAsBuiltDocumentParams === null || generateAsBuiltDocumentParams === undefined) {
                throw new RequiredError('generateAsBuiltDocumentParams','Required parameter generateAsBuiltDocumentParams was null or undefined when calling generateAsBuiltDocumentFromDrawingsDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/generate-as-built`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof generateAsBuiltDocumentParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(generateAsBuiltDocumentParams !== undefined ? generateAsBuiltDocumentParams : {})
                : (generateAsBuiltDocumentParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GenerateAsBuiltDocumentsRequest} generateAsBuiltDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsBuiltDocumentsByProjectId: async (projectId: string, generateAsBuiltDocumentsRequest: GenerateAsBuiltDocumentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateAsBuiltDocumentsByProjectId.');
            }
            // verify required parameter 'generateAsBuiltDocumentsRequest' is not null or undefined
            if (generateAsBuiltDocumentsRequest === null || generateAsBuiltDocumentsRequest === undefined) {
                throw new RequiredError('generateAsBuiltDocumentsRequest','Required parameter generateAsBuiltDocumentsRequest was null or undefined when calling generateAsBuiltDocumentsByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/generate-as-builts`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof generateAsBuiltDocumentsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(generateAsBuiltDocumentsRequest !== undefined ? generateAsBuiltDocumentsRequest : {})
                : (generateAsBuiltDocumentsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBimFileDownloadLink: async (centerLineProjectId: string, bimTreeNode: BimTreeNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling generateBimFileDownloadLink.');
            }
            // verify required parameter 'bimTreeNode' is not null or undefined
            if (bimTreeNode === null || bimTreeNode === undefined) {
                throw new RequiredError('bimTreeNode','Required parameter bimTreeNode was null or undefined when calling generateBimFileDownloadLink.');
            }
            const localVarPath = `/bim360/{centerLineProjectId}/treenode/download`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof bimTreeNode !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(bimTreeNode !== undefined ? bimTreeNode : {})
                : (bimTreeNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<GeneratePublicUserFileSystemLinkRequest>} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultiplePublicUserFileSystemFileDownloadLink: async (projectId: string, generatePublicUserFileSystemLinkRequest: Array<GeneratePublicUserFileSystemLinkRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateMultiplePublicUserFileSystemFileDownloadLink.');
            }
            // verify required parameter 'generatePublicUserFileSystemLinkRequest' is not null or undefined
            if (generatePublicUserFileSystemLinkRequest === null || generatePublicUserFileSystemLinkRequest === undefined) {
                throw new RequiredError('generatePublicUserFileSystemLinkRequest','Required parameter generatePublicUserFileSystemLinkRequest was null or undefined when calling generateMultiplePublicUserFileSystemFileDownloadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/public-multi-download`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof generatePublicUserFileSystemLinkRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(generatePublicUserFileSystemLinkRequest !== undefined ? generatePublicUserFileSystemLinkRequest : {})
                : (generatePublicUserFileSystemLinkRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedDownloadRequest>} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleUserFileSystemFileDownloadLink: async (projectId: string, authenticatedDownloadRequest: Array<AuthenticatedDownloadRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateMultipleUserFileSystemFileDownloadLink.');
            }
            // verify required parameter 'authenticatedDownloadRequest' is not null or undefined
            if (authenticatedDownloadRequest === null || authenticatedDownloadRequest === undefined) {
                throw new RequiredError('authenticatedDownloadRequest','Required parameter authenticatedDownloadRequest was null or undefined when calling generateMultipleUserFileSystemFileDownloadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/multi-download`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedDownloadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedDownloadRequest !== undefined ? authenticatedDownloadRequest : {})
                : (authenticatedDownloadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedUploadRequest>} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleUserFileSystemUploadLink: async (projectId: string, authenticatedUploadRequest: Array<AuthenticatedUploadRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateMultipleUserFileSystemUploadLink.');
            }
            // verify required parameter 'authenticatedUploadRequest' is not null or undefined
            if (authenticatedUploadRequest === null || authenticatedUploadRequest === undefined) {
                throw new RequiredError('authenticatedUploadRequest','Required parameter authenticatedUploadRequest was null or undefined when calling generateMultipleUserFileSystemUploadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/multi-upload`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedUploadRequest !== undefined ? authenticatedUploadRequest : {})
                : (authenticatedUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IGeneratePlaceholdersRequest} iGeneratePlaceholdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePlaceholdersForDocuments: async (iGeneratePlaceholdersRequest: IGeneratePlaceholdersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iGeneratePlaceholdersRequest' is not null or undefined
            if (iGeneratePlaceholdersRequest === null || iGeneratePlaceholdersRequest === undefined) {
                throw new RequiredError('iGeneratePlaceholdersRequest','Required parameter iGeneratePlaceholdersRequest was null or undefined when calling generatePlaceholdersForDocuments.');
            }
            const localVarPath = `/documents/generate-placeholders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iGeneratePlaceholdersRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iGeneratePlaceholdersRequest !== undefined ? iGeneratePlaceholdersRequest : {})
                : (iGeneratePlaceholdersRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PresignedUrlPartsRequest} presignedUrlPartsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedUrlsParts: async (presignedUrlPartsRequest: PresignedUrlPartsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'presignedUrlPartsRequest' is not null or undefined
            if (presignedUrlPartsRequest === null || presignedUrlPartsRequest === undefined) {
                throw new RequiredError('presignedUrlPartsRequest','Required parameter presignedUrlPartsRequest was null or undefined when calling generatePresignedUrlsParts.');
            }
            const localVarPath = `/filesystem/generate-multi-upload-parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof presignedUrlPartsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(presignedUrlPartsRequest !== undefined ? presignedUrlPartsRequest : {})
                : (presignedUrlPartsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GeneratePublicUserFileSystemLinkRequest} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePublicUserFileSystemFileDownloadLink: async (projectId: string, generatePublicUserFileSystemLinkRequest: GeneratePublicUserFileSystemLinkRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generatePublicUserFileSystemFileDownloadLink.');
            }
            // verify required parameter 'generatePublicUserFileSystemLinkRequest' is not null or undefined
            if (generatePublicUserFileSystemLinkRequest === null || generatePublicUserFileSystemLinkRequest === undefined) {
                throw new RequiredError('generatePublicUserFileSystemLinkRequest','Required parameter generatePublicUserFileSystemLinkRequest was null or undefined when calling generatePublicUserFileSystemFileDownloadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/public-download`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof generatePublicUserFileSystemLinkRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(generatePublicUserFileSystemLinkRequest !== undefined ? generatePublicUserFileSystemLinkRequest : {})
                : (generatePublicUserFileSystemLinkRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateUserFileSystemFileDownloadLink: async (projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateUserFileSystemFileDownloadLink.');
            }
            // verify required parameter 'authenticatedDownloadRequest' is not null or undefined
            if (authenticatedDownloadRequest === null || authenticatedDownloadRequest === undefined) {
                throw new RequiredError('authenticatedDownloadRequest','Required parameter authenticatedDownloadRequest was null or undefined when calling generateUserFileSystemFileDownloadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/download`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedDownloadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedDownloadRequest !== undefined ? authenticatedDownloadRequest : {})
                : (authenticatedDownloadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedUploadRequest} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateUserFileSystemUploadLink: async (projectId: string, authenticatedUploadRequest: AuthenticatedUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateUserFileSystemUploadLink.');
            }
            // verify required parameter 'authenticatedUploadRequest' is not null or undefined
            if (authenticatedUploadRequest === null || authenticatedUploadRequest === undefined) {
                throw new RequiredError('authenticatedUploadRequest','Required parameter authenticatedUploadRequest was null or undefined when calling generateUserFileSystemUploadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/upload`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedUploadRequest !== undefined ? authenticatedUploadRequest : {})
                : (authenticatedUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminUsers: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/administrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnouncements: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssignedDocumentsByUserId: async (userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getAllAssignedDocumentsByUserId.');
            }
            const localVarPath = `/users/{userId}/assigned-documents`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDefaultSecurityGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/security-groups/defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentTemplates: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentUsers: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocuments: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFaqCategories: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotifications: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, projectId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOutboundEmail: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductPackages: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectBidSetups: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-bid-setups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectBidSetupsSortedByDueDate: async (filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-bid-setups/sorted-by-due-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} beforeDate 
         * @param {string} afterDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectCalendarEventsBetweenDatesByProjectId: async (projectId: string, beforeDate: string, afterDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllProjectCalendarEventsBetweenDatesByProjectId.');
            }
            // verify required parameter 'beforeDate' is not null or undefined
            if (beforeDate === null || beforeDate === undefined) {
                throw new RequiredError('beforeDate','Required parameter beforeDate was null or undefined when calling getAllProjectCalendarEventsBetweenDatesByProjectId.');
            }
            // verify required parameter 'afterDate' is not null or undefined
            if (afterDate === null || afterDate === undefined) {
                throw new RequiredError('afterDate','Required parameter afterDate was null or undefined when calling getAllProjectCalendarEventsBetweenDatesByProjectId.');
            }
            const localVarPath = `/project-calendar-events/projects/{projectId}/between-dates`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (beforeDate !== undefined) {
                localVarQueryParameter['beforeDate'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString() :
                    beforeDate;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['afterDate'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString() :
                    afterDate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectCalendarEventsByProjectId: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllProjectCalendarEventsByProjectId.');
            }
            const localVarPath = `/project-calendar-events/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectNamesBySubscriberId: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getAllProjectNamesBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/project-names`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSummary: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectUsers: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsSimplified: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/simplified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InboxPermissionLevel} [selectedPermission] 
         * @param {string} [involvesEmail] 
         * @param {string} [fromEmail] 
         * @param {string} [toOrCcEmail] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReceivedEmails: async (projectId: string, selectedPermission?: InboxPermissionLevel, involvesEmail?: string, fromEmail?: string, toOrCcEmail?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllReceivedEmails.');
            }
            const localVarPath = `/received-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (selectedPermission !== undefined) {
                localVarQueryParameter['selectedPermission'] = selectedPermission;
            }

            if (involvesEmail !== undefined) {
                localVarQueryParameter['involvesEmail'] = involvesEmail;
            }

            if (fromEmail !== undefined) {
                localVarQueryParameter['fromEmail'] = fromEmail;
            }

            if (toOrCcEmail !== undefined) {
                localVarQueryParameter['toOrCcEmail'] = toOrCcEmail;
            }

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroups: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/security-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroupsBySubscriberId: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getAllSecurityGroupsBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/security-groups`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroupsForUsersSubscribersByUserId: async (userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getAllSecurityGroupsForUsersSubscribersByUserId.');
            }
            const localVarPath = `/security-groups/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubmittalCodesByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllSubmittalCodesByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/submittal-codes`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscribers: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscribers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserGroupsByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllUserGroupsByProjectId.');
            }
            const localVarPath = `/user-groups/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersSimplifiedBySubscriberId: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getAllUsersSimplifiedBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/users/simplified`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebsiteSettings: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/website-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementById: async (announcementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            if (announcementId === null || announcementId === undefined) {
                throw new RequiredError('announcementId','Required parameter announcementId was null or undefined when calling getAnnouncementById.');
            }
            const localVarPath = `/announcements/{announcementId}`
                .replace(`{${"announcementId"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedDocumentsByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getAssociatedDocumentsByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/associated-documents`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBIMTopFolders: async (centerLineProjectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling getBIMTopFolders.');
            }
            const localVarPath = `/bim360/{centerLineProjectId}/topfolders`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBIMTreeNode: async (centerLineProjectId: string, bimTreeNode: BimTreeNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling getBIMTreeNode.');
            }
            // verify required parameter 'bimTreeNode' is not null or undefined
            if (bimTreeNode === null || bimTreeNode === undefined) {
                throw new RequiredError('bimTreeNode','Required parameter bimTreeNode was null or undefined when calling getBIMTreeNode.');
            }
            const localVarPath = `/bim360/{centerLineProjectId}/treenode`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof bimTreeNode !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(bimTreeNode !== undefined ? bimTreeNode : {})
                : (bimTreeNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidById: async (bidId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidId' is not null or undefined
            if (bidId === null || bidId === undefined) {
                throw new RequiredError('bidId','Required parameter bidId was null or undefined when calling getBidById.');
            }
            const localVarPath = `/bids/{bidId}`
                .replace(`{${"bidId"}}`, encodeURIComponent(String(bidId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidConnectFileDeliveryUploadRequest} bidConnectFileDeliveryUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidConnectFileDeliveryUploadLink: async (bidConnectFileDeliveryUploadRequest: BidConnectFileDeliveryUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidConnectFileDeliveryUploadRequest' is not null or undefined
            if (bidConnectFileDeliveryUploadRequest === null || bidConnectFileDeliveryUploadRequest === undefined) {
                throw new RequiredError('bidConnectFileDeliveryUploadRequest','Required parameter bidConnectFileDeliveryUploadRequest was null or undefined when calling getBidConnectFileDeliveryUploadLink.');
            }
            const localVarPath = `/project-bid-setups/bidconnect-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof bidConnectFileDeliveryUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(bidConnectFileDeliveryUploadRequest !== undefined ? bidConnectFileDeliveryUploadRequest : {})
                : (bidConnectFileDeliveryUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidsOnProjectById: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getBidsOnProjectById.');
            }
            const localVarPath = `/projects/{projectId}/bids`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} [additionalReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBluebeamXml: async (fileId: string, additionalReviewDocumentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling getBluebeamXml.');
            }
            const localVarPath = `/files/{fileId}/bluebeam`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (additionalReviewDocumentId !== undefined) {
                localVarQueryParameter['additionalReviewDocumentId'] = additionalReviewDocumentId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling getCommentById.');
            }
            const localVarPath = `/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsOnDocumentById: async (documentId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getCommentsOnDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/comments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsOnProjectById: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getCommentsOnProjectById.');
            }
            const localVarPath = `/projects/{projectId}/comments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getCompaniesByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/companies`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new RequiredError('companyId','Required parameter companyId was null or undefined when calling getCompanyById.');
            }
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DocumentConformingCenterTemplateType} documentTemplateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConformingCenterDocumentsByProjectId: async (projectId: string, documentTemplateType: DocumentConformingCenterTemplateType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getConformingCenterDocumentsByProjectId.');
            }
            // verify required parameter 'documentTemplateType' is not null or undefined
            if (documentTemplateType === null || documentTemplateType === undefined) {
                throw new RequiredError('documentTemplateType','Required parameter documentTemplateType was null or undefined when calling getConformingCenterDocumentsByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/conforming-center-documents/{documentTemplateType}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"documentTemplateType"}}`, encodeURIComponent(String(documentTemplateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConformingCenterSummaryByProjectId: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getConformingCenterSummaryByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/conforming-center-summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryById: async (countryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            if (countryId === null || countryId === undefined) {
                throw new RequiredError('countryId','Required parameter countryId was null or undefined when calling getCountryById.');
            }
            const localVarPath = `/countries/{countryId}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fullKey 
         * @param {string} ownerUserId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesignFileActionsByProjectAndFullKey: async (projectId: string, fullKey: string, ownerUserId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getDesignFileActionsByProjectAndFullKey.');
            }
            // verify required parameter 'fullKey' is not null or undefined
            if (fullKey === null || fullKey === undefined) {
                throw new RequiredError('fullKey','Required parameter fullKey was null or undefined when calling getDesignFileActionsByProjectAndFullKey.');
            }
            // verify required parameter 'ownerUserId' is not null or undefined
            if (ownerUserId === null || ownerUserId === undefined) {
                throw new RequiredError('ownerUserId','Required parameter ownerUserId was null or undefined when calling getDesignFileActionsByProjectAndFullKey.');
            }
            const localVarPath = `/filesystem/{projectId}/design-file-actions`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fullKey !== undefined) {
                localVarQueryParameter['fullKey'] = fullKey;
            }

            if (ownerUserId !== undefined) {
                localVarQueryParameter['ownerUserId'] = ownerUserId;
            }

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDesignFileDownloadLink: async (projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getDirectDesignFileDownloadLink.');
            }
            // verify required parameter 'authenticatedDownloadRequest' is not null or undefined
            if (authenticatedDownloadRequest === null || authenticatedDownloadRequest === undefined) {
                throw new RequiredError('authenticatedDownloadRequest','Required parameter authenticatedDownloadRequest was null or undefined when calling getDirectDesignFileDownloadLink.');
            }
            const localVarPath = `/filesystem/{projectId}/get-public-email-link`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedDownloadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedDownloadRequest !== undefined ? authenticatedDownloadRequest : {})
                : (authenticatedDownloadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentById.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByKey: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getDocumentByKey.');
            }
            const localVarPath = `/documents/public/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublicLink: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getDocumentPublicLink.');
            }
            const localVarPath = `/documents/{documentId}/public-link`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} userId 
         * @param {boolean} [isInteractive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublicLinkAsUser: async (documentId: string, userId: string, isInteractive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getDocumentPublicLinkAsUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getDocumentPublicLinkAsUser.');
            }
            const localVarPath = `/documents/{documentId}/public-link-as-user/{userId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isInteractive !== undefined) {
                localVarQueryParameter['isInteractive'] = isInteractive;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentTemplateType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateByType: async (type: DocumentTemplateType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getDocumentTemplateByType.');
            }
            const localVarPath = `/document-templates/by-type/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUserPermissionByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getDocumentUserPermissionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/user-permissions`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUserPermissionsForDocumentByKey: async (key: string, consultantReviewDocumentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getDocumentUserPermissionsForDocumentByKey.');
            }
            const localVarPath = `/documents/permission-by-user-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultantReviewDocumentId !== undefined) {
                localVarQueryParameter['consultantReviewDocumentId'] = consultantReviewDocumentId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsAssignedByMe: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/summary-assigned-by-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsAssignedToMe: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/summary-assigned-to-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [selectedColumns] 
         * @param {string} [selectedDocumentTypes] 
         * @param {boolean} [includeFiles] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByProjectId: async (projectId: string, selectedColumns?: string, selectedDocumentTypes?: string, includeFiles?: boolean, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getDocumentsByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/documents`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (selectedColumns !== undefined) {
                localVarQueryParameter['selectedColumns'] = selectedColumns;
            }

            if (selectedDocumentTypes !== undefined) {
                localVarQueryParameter['selectedDocumentTypes'] = selectedDocumentTypes;
            }

            if (includeFiles !== undefined) {
                localVarQueryParameter['includeFiles'] = includeFiles;
            }

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByProjectIdAndDocumentTemplateId: async (projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getDocumentsByProjectIdAndDocumentTemplateId.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling getDocumentsByProjectIdAndDocumentTemplateId.');
            }
            const localVarPath = `/projects/{projectId}/document-templates/{documentTemplateId}/documents`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"documentTemplateId"}}`, encodeURIComponent(String(documentTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsSummaryByProjectIdAndDocumentTemplateId: async (projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getDocumentsSummaryByProjectIdAndDocumentTemplateId.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling getDocumentsSummaryByProjectIdAndDocumentTemplateId.');
            }
            const localVarPath = `/projects/{projectId}/document-templates/{documentTemplateId}/documents/summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"documentTemplateId"}}`, encodeURIComponent(String(documentTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} faqCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqCategoryById: async (faqCategoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqCategoryId' is not null or undefined
            if (faqCategoryId === null || faqCategoryId === undefined) {
                throw new RequiredError('faqCategoryId','Required parameter faqCategoryId was null or undefined when calling getFaqCategoryById.');
            }
            const localVarPath = `/faq-categories/{faqCategoryId}`
                .replace(`{${"faqCategoryId"}}`, encodeURIComponent(String(faqCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileById: async (fileId: string, flattened?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling getFileById.');
            }
            const localVarPath = `/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flattened !== undefined) {
                localVarQueryParameter['flattened'] = flattened;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {boolean} [fullSize] 
         * @param {string} [filename] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLinkById: async (fileId: string, flattened?: boolean, fullSize?: boolean, filename?: string, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling getFileLinkById.');
            }
            const localVarPath = `/files/{fileId}/view`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flattened !== undefined) {
                localVarQueryParameter['flattened'] = flattened;
            }

            if (fullSize !== undefined) {
                localVarQueryParameter['fullSize'] = fullSize;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} reviewDocumentId 
         * @param {boolean} [flattened] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLinkByIdForConsultant: async (fileId: string, reviewDocumentId: string, flattened?: boolean, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling getFileLinkByIdForConsultant.');
            }
            // verify required parameter 'reviewDocumentId' is not null or undefined
            if (reviewDocumentId === null || reviewDocumentId === undefined) {
                throw new RequiredError('reviewDocumentId','Required parameter reviewDocumentId was null or undefined when calling getFileLinkByIdForConsultant.');
            }
            const localVarPath = `/files/{fileId}/consultant-view`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reviewDocumentId !== undefined) {
                localVarQueryParameter['reviewDocumentId'] = reviewDocumentId;
            }

            if (flattened !== undefined) {
                localVarQueryParameter['flattened'] = flattened;
            }

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectFileUploadRequestParams} projectFileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUploadLinkByProjectId: async (projectId: string, projectFileUploadRequestParams: ProjectFileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getFileUploadLinkByProjectId.');
            }
            // verify required parameter 'projectFileUploadRequestParams' is not null or undefined
            if (projectFileUploadRequestParams === null || projectFileUploadRequestParams === undefined) {
                throw new RequiredError('projectFileUploadRequestParams','Required parameter projectFileUploadRequestParams was null or undefined when calling getFileUploadLinkByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/files`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof projectFileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(projectFileUploadRequestParams !== undefined ? projectFileUploadRequestParams : {})
                : (projectFileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getFilesByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/files`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestProjectArchiveZipFileLink: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getLatestProjectArchiveZipFileLink.');
            }
            const localVarPath = `/projects/{projectId}/latest-project-archive-zip`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSubmissionFileByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getLatestSubmissionFileByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/latest-submission-file`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSubmissionFileByKey: async (key: string, consultantReviewDocumentId?: string, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getLatestSubmissionFileByKey.');
            }
            const localVarPath = `/documents/latest-submission-file-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultantReviewDocumentId !== undefined) {
                localVarQueryParameter['consultantReviewDocumentId'] = consultantReviewDocumentId;
            }

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetManyDocumentLinksRequest} getManyDocumentLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyDocumentPublicLinks: async (getManyDocumentLinksRequest: GetManyDocumentLinksRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getManyDocumentLinksRequest' is not null or undefined
            if (getManyDocumentLinksRequest === null || getManyDocumentLinksRequest === undefined) {
                throw new RequiredError('getManyDocumentLinksRequest','Required parameter getManyDocumentLinksRequest was null or undefined when calling getManyDocumentPublicLinks.');
            }
            const localVarPath = `/documents/public-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getManyDocumentLinksRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getManyDocumentLinksRequest !== undefined ? getManyDocumentLinksRequest : {})
                : (getManyDocumentLinksRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConsultantNotificationRequest} consultantNotificationRequest 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostRecentConsultantNotificationsByDocumentIds: async (consultantNotificationRequest: ConsultantNotificationRequest, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultantNotificationRequest' is not null or undefined
            if (consultantNotificationRequest === null || consultantNotificationRequest === undefined) {
                throw new RequiredError('consultantNotificationRequest','Required parameter consultantNotificationRequest was null or undefined when calling getMostRecentConsultantNotificationsByDocumentIds.');
            }
            const localVarPath = `/documents/consultant-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof consultantNotificationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(consultantNotificationRequest !== undefined ? consultantNotificationRequest : {})
                : (consultantNotificationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDesignFileSharesByProject: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getMyDesignFileSharesByProject.');
            }
            const localVarPath = `/filesystem/{projectId}/file-share`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyFavoriteProjects: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotifications: async (orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-notification/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotificationsWithEmails: async (projectId?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-notification/me-with-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotificationsWithEmailsCount: async (projectId?: string, filterByObject?: string, curatedSampleData?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-notification/me-with-emails-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationById: async (notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling getNotificationById.');
            }
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationRecipientsByIds: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling getNotificationRecipientsByIds.');
            }
            const localVarPath = `/notifications/recipients-by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByBidId: async (id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNotificationsByBidId.');
            }
            const localVarPath = `/bids/{id}/notifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByBidSetupId: async (bidSetupId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling getNotificationsByBidSetupId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/notifications`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByDocumentId: async (id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNotificationsByDocumentId.');
            }
            const localVarPath = `/documents/{id}/notifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByKey: async (key: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getNotificationsByKey.');
            }
            const localVarPath = `/documents/notifications-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByProjectId: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getNotificationsByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/notifications`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} outboundEmailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundEmailById: async (outboundEmailId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outboundEmailId' is not null or undefined
            if (outboundEmailId === null || outboundEmailId === undefined) {
                throw new RequiredError('outboundEmailId','Required parameter outboundEmailId was null or undefined when calling getOutboundEmailById.');
            }
            const localVarPath = `/outbound-email/{outboundEmailId}`
                .replace(`{${"outboundEmailId"}}`, encodeURIComponent(String(outboundEmailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParserJobStatusRequest} parserJobStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserJobStatusByObjectKey: async (parserJobStatusRequest: ParserJobStatusRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parserJobStatusRequest' is not null or undefined
            if (parserJobStatusRequest === null || parserJobStatusRequest === undefined) {
                throw new RequiredError('parserJobStatusRequest','Required parameter parserJobStatusRequest was null or undefined when calling getParserJobStatusByObjectKey.');
            }
            const localVarPath = `/parser/job-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof parserJobStatusRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(parserJobStatusRequest !== undefined ? parserJobStatusRequest : {})
                : (parserJobStatusRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionByKey: async (key: string, consultantReviewDocumentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getPermissionByKey.');
            }
            const localVarPath = `/documents/user-permission-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultantReviewDocumentId !== undefined) {
                localVarQueryParameter['consultantReviewDocumentId'] = consultantReviewDocumentId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionByUserIdAndDocumentId: async (userId: string, documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getPermissionByUserIdAndDocumentId.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getPermissionByUserIdAndDocumentId.');
            }
            const localVarPath = `/users/{userId}/documents/{documentId}/permissions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalBuildingsByProjectId: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getPhysicalBuildingsByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/punch-list-locations`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimeBiddersOnProjectById: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getPrimeBiddersOnProjectById.');
            }
            const localVarPath = `/projects/{projectId}/prime-bidders`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcoreProjects: async (centerLineProjectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling getProcoreProjects.');
            }
            const localVarPath = `/procore/{centerLineProjectId}/projects`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerlineProjectId 
         * @param {number} procoreDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcoreSubmittal: async (centerlineProjectId: string, procoreDocumentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerlineProjectId' is not null or undefined
            if (centerlineProjectId === null || centerlineProjectId === undefined) {
                throw new RequiredError('centerlineProjectId','Required parameter centerlineProjectId was null or undefined when calling getProcoreSubmittal.');
            }
            // verify required parameter 'procoreDocumentId' is not null or undefined
            if (procoreDocumentId === null || procoreDocumentId === undefined) {
                throw new RequiredError('procoreDocumentId','Required parameter procoreDocumentId was null or undefined when calling getProcoreSubmittal.');
            }
            const localVarPath = `/procore/{centerlineProjectId}/get-procore-submittal/{procoreDocumentId}`
                .replace(`{${"centerlineProjectId"}}`, encodeURIComponent(String(centerlineProjectId)))
                .replace(`{${"procoreDocumentId"}}`, encodeURIComponent(String(procoreDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPackageById: async (productPackageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPackageId' is not null or undefined
            if (productPackageId === null || productPackageId === undefined) {
                throw new RequiredError('productPackageId','Required parameter productPackageId was null or undefined when calling getProductPackageById.');
            }
            const localVarPath = `/product-packages/{productPackageId}`
                .replace(`{${"productPackageId"}}`, encodeURIComponent(String(productPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBidSetupById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectBidSetupById.');
            }
            const localVarPath = `/project-bid-setups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBiddingSummaryById: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectBiddingSummaryById.');
            }
            const localVarPath = `/projects/{projectId}/bidding-summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectById.');
            }
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSummaryById: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectSummaryById.');
            }
            const localVarPath = `/projects/{projectId}/summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUserById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectUserById.');
            }
            const localVarPath = `/project-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} securityGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUserSummaryBySecurityGroupId: async (securityGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityGroupId' is not null or undefined
            if (securityGroupId === null || securityGroupId === undefined) {
                throw new RequiredError('securityGroupId','Required parameter securityGroupId was null or undefined when calling getProjectUserSummaryBySecurityGroupId.');
            }
            const localVarPath = `/project-users/summary/{securityGroupId}`
                .replace(`{${"securityGroupId"}}`, encodeURIComponent(String(securityGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUsersByProjectId: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectUsersByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/project-users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} numMostRecentProjects 
         * @param {number} numMostRecentComments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsWithMostRecentComments: async (numMostRecentProjects: number, numMostRecentComments: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'numMostRecentProjects' is not null or undefined
            if (numMostRecentProjects === null || numMostRecentProjects === undefined) {
                throw new RequiredError('numMostRecentProjects','Required parameter numMostRecentProjects was null or undefined when calling getProjectsWithMostRecentComments.');
            }
            // verify required parameter 'numMostRecentComments' is not null or undefined
            if (numMostRecentComments === null || numMostRecentComments === undefined) {
                throw new RequiredError('numMostRecentComments','Required parameter numMostRecentComments was null or undefined when calling getProjectsWithMostRecentComments.');
            }
            const localVarPath = `/projects/most-recent-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (numMostRecentProjects !== undefined) {
                localVarQueryParameter['numMostRecentProjects'] = numMostRecentProjects;
            }

            if (numMostRecentComments !== undefined) {
                localVarQueryParameter['numMostRecentComments'] = numMostRecentComments;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDocumentById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPublicDocumentById.');
            }
            const localVarPath = `/documents/{id}/public`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} designFileShareId 
         * @param {string} publicAccessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserFileSystemFileNode: async (projectId: string, designFileShareId: string, publicAccessKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getPublicUserFileSystemFileNode.');
            }
            // verify required parameter 'designFileShareId' is not null or undefined
            if (designFileShareId === null || designFileShareId === undefined) {
                throw new RequiredError('designFileShareId','Required parameter designFileShareId was null or undefined when calling getPublicUserFileSystemFileNode.');
            }
            // verify required parameter 'publicAccessKey' is not null or undefined
            if (publicAccessKey === null || publicAccessKey === undefined) {
                throw new RequiredError('publicAccessKey','Required parameter publicAccessKey was null or undefined when calling getPublicUserFileSystemFileNode.');
            }
            const localVarPath = `/filesystem/{projectId}/file-share/{designFileShareId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"designFileShareId"}}`, encodeURIComponent(String(designFileShareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicAccessKey !== undefined) {
                localVarQueryParameter['publicAccessKey'] = publicAccessKey;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserInfoByEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling getPublicUserInfoByEmail.');
            }
            const localVarPath = `/users/public-user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationUserByKey: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getRegistrationUserByKey.');
            }
            const localVarPath = `/users/by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForEmail: async (emailId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailId' is not null or undefined
            if (emailId === null || emailId === undefined) {
                throw new RequiredError('emailId','Required parameter emailId was null or undefined when calling getSignedUrlForEmail.');
            }
            const localVarPath = `/received-emails/{emailId}/signed-url`
                .replace(`{${"emailId"}}`, encodeURIComponent(String(emailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberAdminsSimplifiedBySubscriberId: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getSubscriberAdminsSimplifiedBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/admins/simplified`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberById: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getSubscriberById.');
            }
            const localVarPath = `/subscribers/{subscriberId}`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberProjectUserSummaryById: async (subscriberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling getSubscriberProjectUserSummaryById.');
            }
            const localVarPath = `/subscribers/{subscriberId}/project-user-summary`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSuggestedProjectEmailSlugRequest} getSuggestedProjectEmailSlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedProjectEmailSlug: async (getSuggestedProjectEmailSlugRequest: GetSuggestedProjectEmailSlugRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSuggestedProjectEmailSlugRequest' is not null or undefined
            if (getSuggestedProjectEmailSlugRequest === null || getSuggestedProjectEmailSlugRequest === undefined) {
                throw new RequiredError('getSuggestedProjectEmailSlugRequest','Required parameter getSuggestedProjectEmailSlugRequest was null or undefined when calling getSuggestedProjectEmailSlug.');
            }
            const localVarPath = `/projects/suggest-email-slug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSuggestedProjectEmailSlugRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSuggestedProjectEmailSlugRequest !== undefined ? getSuggestedProjectEmailSlugRequest : {})
                : (getSuggestedProjectEmailSlugRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [continuationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrashByProjectId: async (projectId: string, continuationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getTrashByProjectId.');
            }
            const localVarPath = `/filesystem/{projectId}/trash`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (continuationToken !== undefined) {
                localVarQueryParameter['continuationToken'] = continuationToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForAddendasByBidSetupId: async (bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling getUploadLinkForAddendasByBidSetupId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling getUploadLinkForAddendasByBidSetupId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/addendas`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForDrawingsByBidSetupId: async (bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling getUploadLinkForDrawingsByBidSetupId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling getUploadLinkForDrawingsByBidSetupId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/drawings`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForSpecificationsByBidSetupId: async (bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling getUploadLinkForSpecificationsByBidSetupId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling getUploadLinkForSpecificationsByBidSetupId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/specifications`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForSubmittalSectionByDocumentId: async (documentId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling getUploadLinkForSubmittalSectionByDocumentId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling getUploadLinkForSubmittalSectionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/specification-section`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFileSystemFileNode: async (projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getUserFileSystemFileNode.');
            }
            // verify required parameter 'authenticatedDownloadRequest' is not null or undefined
            if (authenticatedDownloadRequest === null || authenticatedDownloadRequest === undefined) {
                throw new RequiredError('authenticatedDownloadRequest','Required parameter authenticatedDownloadRequest was null or undefined when calling getUserFileSystemFileNode.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticatedDownloadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticatedDownloadRequest !== undefined ? authenticatedDownloadRequest : {})
                : (authenticatedDownloadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectId: async (projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getUsersByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderByColumn !== undefined) {
                localVarQueryParameter['orderByColumn'] = orderByColumn;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (filterByObject !== undefined) {
                localVarQueryParameter['filterByObject'] = filterByObject;
            }

            if (curatedSampleData !== undefined) {
                localVarQueryParameter['curatedSampleData'] = curatedSampleData;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteSettingById: async (websiteSettingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'websiteSettingId' is not null or undefined
            if (websiteSettingId === null || websiteSettingId === undefined) {
                throw new RequiredError('websiteSettingId','Required parameter websiteSettingId was null or undefined when calling getWebsiteSettingById.');
            }
            const localVarPath = `/website-settings/{websiteSettingId}`
                .replace(`{${"websiteSettingId"}}`, encodeURIComponent(String(websiteSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZippedBidDocuments: async (bidSetupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling getZippedBidDocuments.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/bid-documents-zipped`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling impersonateUser.');
            }
            const localVarPath = `/users/impersonate/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {BidBIMImportParams} bidBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBimFileByBidSetupId: async (bidSetupId: string, bidBIMImportParams: BidBIMImportParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidSetupId' is not null or undefined
            if (bidSetupId === null || bidSetupId === undefined) {
                throw new RequiredError('bidSetupId','Required parameter bidSetupId was null or undefined when calling importBimFileByBidSetupId.');
            }
            // verify required parameter 'bidBIMImportParams' is not null or undefined
            if (bidBIMImportParams === null || bidBIMImportParams === undefined) {
                throw new RequiredError('bidBIMImportParams','Required parameter bidBIMImportParams was null or undefined when calling importBimFileByBidSetupId.');
            }
            const localVarPath = `/project-bid-setups/{bidSetupId}/import-bim-file`
                .replace(`{${"bidSetupId"}}`, encodeURIComponent(String(bidSetupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof bidBIMImportParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(bidBIMImportParams !== undefined ? bidBIMImportParams : {})
                : (bidBIMImportParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectBIMImportParams} projectBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBimFileByProjectId: async (projectId: string, projectBIMImportParams: ProjectBIMImportParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling importBimFileByProjectId.');
            }
            // verify required parameter 'projectBIMImportParams' is not null or undefined
            if (projectBIMImportParams === null || projectBIMImportParams === undefined) {
                throw new RequiredError('projectBIMImportParams','Required parameter projectBIMImportParams was null or undefined when calling importBimFileByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/import-bim-file`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof projectBIMImportParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(projectBIMImportParams !== undefined ? projectBIMImportParams : {})
                : (projectBIMImportParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAdditionalReviewByDocumentId: async (documentId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling insertAdditionalReviewByDocumentId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling insertAdditionalReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/insert-additional-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAdditionalReviewByKey: async (key: string, fileUploadRequestParams: FileUploadRequestParams, consultantReviewDocumentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling insertAdditionalReviewByKey.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling insertAdditionalReviewByKey.');
            }
            const localVarPath = `/documents/insert-additional-review-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultantReviewDocumentId !== undefined) {
                localVarQueryParameter['consultantReviewDocumentId'] = consultantReviewDocumentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionAddress} iInsertionAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAddress: async (iInsertionAddress: IInsertionAddress, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionAddress' is not null or undefined
            if (iInsertionAddress === null || iInsertionAddress === undefined) {
                throw new RequiredError('iInsertionAddress','Required parameter iInsertionAddress was null or undefined when calling insertAddress.');
            }
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionAddress !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionAddress !== undefined ? iInsertionAddress : {})
                : (iInsertionAddress || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionAnnouncement} iInsertionAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAnnouncement: async (iInsertionAnnouncement: IInsertionAnnouncement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionAnnouncement' is not null or undefined
            if (iInsertionAnnouncement === null || iInsertionAnnouncement === undefined) {
                throw new RequiredError('iInsertionAnnouncement','Required parameter iInsertionAnnouncement was null or undefined when calling insertAnnouncement.');
            }
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionAnnouncement !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionAnnouncement !== undefined ? iInsertionAnnouncement : {})
                : (iInsertionAnnouncement || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {InsertBidProps} insertBidProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBidByUserAndProject: async (userId: string, projectId: string, insertBidProps: InsertBidProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling insertBidByUserAndProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertBidByUserAndProject.');
            }
            // verify required parameter 'insertBidProps' is not null or undefined
            if (insertBidProps === null || insertBidProps === undefined) {
                throw new RequiredError('insertBidProps','Required parameter insertBidProps was null or undefined when calling insertBidByUserAndProject.');
            }
            const localVarPath = `/bids/users/{userId}/projects/{projectId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertBidProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertBidProps !== undefined ? insertBidProps : {})
                : (insertBidProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomInsertionBidSetup} iCustomInsertionBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBidSetupByProjectId: async (projectId: string, iCustomInsertionBidSetup: ICustomInsertionBidSetup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertBidSetupByProjectId.');
            }
            // verify required parameter 'iCustomInsertionBidSetup' is not null or undefined
            if (iCustomInsertionBidSetup === null || iCustomInsertionBidSetup === undefined) {
                throw new RequiredError('iCustomInsertionBidSetup','Required parameter iCustomInsertionBidSetup was null or undefined when calling insertBidSetupByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/bid-setup`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iCustomInsertionBidSetup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iCustomInsertionBidSetup !== undefined ? iCustomInsertionBidSetup : {})
                : (iCustomInsertionBidSetup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCommentByDocumentId: async (documentId: string, iInsertionComment: IInsertionComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling insertCommentByDocumentId.');
            }
            // verify required parameter 'iInsertionComment' is not null or undefined
            if (iInsertionComment === null || iInsertionComment === undefined) {
                throw new RequiredError('iInsertionComment','Required parameter iInsertionComment was null or undefined when calling insertCommentByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/comments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionComment !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionComment !== undefined ? iInsertionComment : {})
                : (iInsertionComment || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCommentByProjectId: async (projectId: string, iInsertionComment: IInsertionComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertCommentByProjectId.');
            }
            // verify required parameter 'iInsertionComment' is not null or undefined
            if (iInsertionComment === null || iInsertionComment === undefined) {
                throw new RequiredError('iInsertionComment','Required parameter iInsertionComment was null or undefined when calling insertCommentByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/comments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionComment !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionComment !== undefined ? iInsertionComment : {})
                : (iInsertionComment || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionCompany} iInsertionCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCompany: async (iInsertionCompany: IInsertionCompany, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionCompany' is not null or undefined
            if (iInsertionCompany === null || iInsertionCompany === undefined) {
                throw new RequiredError('iInsertionCompany','Required parameter iInsertionCompany was null or undefined when calling insertCompany.');
            }
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionCompany !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionCompany !== undefined ? iInsertionCompany : {})
                : (iInsertionCompany || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a consultant to a document.
         * @param {string} documentId 
         * @param {AdditionalConsultantProps} additionalConsultantProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsultantByDocumentId: async (documentId: string, additionalConsultantProps: AdditionalConsultantProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling insertConsultantByDocumentId.');
            }
            // verify required parameter 'additionalConsultantProps' is not null or undefined
            if (additionalConsultantProps === null || additionalConsultantProps === undefined) {
                throw new RequiredError('additionalConsultantProps','Required parameter additionalConsultantProps was null or undefined when calling insertConsultantByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/consultant`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof additionalConsultantProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(additionalConsultantProps !== undefined ? additionalConsultantProps : {})
                : (additionalConsultantProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentDocumentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsultantConfidentialCommentByDocumentId: async (parentDocumentId: string, iInsertionComment: IInsertionComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentDocumentId' is not null or undefined
            if (parentDocumentId === null || parentDocumentId === undefined) {
                throw new RequiredError('parentDocumentId','Required parameter parentDocumentId was null or undefined when calling insertConsultantConfidentialCommentByDocumentId.');
            }
            // verify required parameter 'iInsertionComment' is not null or undefined
            if (iInsertionComment === null || iInsertionComment === undefined) {
                throw new RequiredError('iInsertionComment','Required parameter iInsertionComment was null or undefined when calling insertConsultantConfidentialCommentByDocumentId.');
            }
            const localVarPath = `/documents/{parentDocumentId}/consultant-review-shared-comments`
                .replace(`{${"parentDocumentId"}}`, encodeURIComponent(String(parentDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionComment !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionComment !== undefined ? iInsertionComment : {})
                : (iInsertionComment || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertDocumentProps} insertDocumentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDocument: async (insertDocumentProps: InsertDocumentProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertDocumentProps' is not null or undefined
            if (insertDocumentProps === null || insertDocumentProps === undefined) {
                throw new RequiredError('insertDocumentProps','Required parameter insertDocumentProps was null or undefined when calling insertDocument.');
            }
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertDocumentProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertDocumentProps !== undefined ? insertDocumentProps : {})
                : (insertDocumentProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionDocumentUser} iInsertionDocumentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDocumentUser: async (iInsertionDocumentUser: IInsertionDocumentUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionDocumentUser' is not null or undefined
            if (iInsertionDocumentUser === null || iInsertionDocumentUser === undefined) {
                throw new RequiredError('iInsertionDocumentUser','Required parameter iInsertionDocumentUser was null or undefined when calling insertDocumentUser.');
            }
            const localVarPath = `/document-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionDocumentUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionDocumentUser !== undefined ? iInsertionDocumentUser : {})
                : (iInsertionDocumentUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentFileUploadRequest} documentFileUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFileByDocumentId: async (documentId: string, documentFileUploadRequest: DocumentFileUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling insertFileByDocumentId.');
            }
            // verify required parameter 'documentFileUploadRequest' is not null or undefined
            if (documentFileUploadRequest === null || documentFileUploadRequest === undefined) {
                throw new RequiredError('documentFileUploadRequest','Required parameter documentFileUploadRequest was null or undefined when calling insertFileByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/files`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof documentFileUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(documentFileUploadRequest !== undefined ? documentFileUploadRequest : {})
                : (documentFileUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFloorPlanDrawingFileByProjectId: async (projectId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertFloorPlanDrawingFileByProjectId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling insertFloorPlanDrawingFileByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/floor-plan-drawing`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFloorPlanImageFileByProjectId: async (projectId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertFloorPlanImageFileByProjectId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling insertFloorPlanImageFileByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/floor-plan-image`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertLocationImageFileByProjectId: async (projectId: string, fileUploadRequestParams: FileUploadRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertLocationImageFileByProjectId.');
            }
            // verify required parameter 'fileUploadRequestParams' is not null or undefined
            if (fileUploadRequestParams === null || fileUploadRequestParams === undefined) {
                throw new RequiredError('fileUploadRequestParams','Required parameter fileUploadRequestParams was null or undefined when calling insertLocationImageFileByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/location-image`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof fileUploadRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(fileUploadRequestParams !== undefined ? fileUploadRequestParams : {})
                : (fileUploadRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertManyPhysicalBuildingsRequest} insertManyPhysicalBuildingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyPhysicalBuildings: async (insertManyPhysicalBuildingsRequest: InsertManyPhysicalBuildingsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertManyPhysicalBuildingsRequest' is not null or undefined
            if (insertManyPhysicalBuildingsRequest === null || insertManyPhysicalBuildingsRequest === undefined) {
                throw new RequiredError('insertManyPhysicalBuildingsRequest','Required parameter insertManyPhysicalBuildingsRequest was null or undefined when calling insertManyPhysicalBuildings.');
            }
            const localVarPath = `/physical-buildings/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertManyPhysicalBuildingsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertManyPhysicalBuildingsRequest !== undefined ? insertManyPhysicalBuildingsRequest : {})
                : (insertManyPhysicalBuildingsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertManyPhysicalLocationsRequest} insertManyPhysicalLocationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyPhysicalLocationsByFloorIds: async (insertManyPhysicalLocationsRequest: InsertManyPhysicalLocationsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertManyPhysicalLocationsRequest' is not null or undefined
            if (insertManyPhysicalLocationsRequest === null || insertManyPhysicalLocationsRequest === undefined) {
                throw new RequiredError('insertManyPhysicalLocationsRequest','Required parameter insertManyPhysicalLocationsRequest was null or undefined when calling insertManyPhysicalLocationsByFloorIds.');
            }
            const localVarPath = `/physical-floors/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertManyPhysicalLocationsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertManyPhysicalLocationsRequest !== undefined ? insertManyPhysicalLocationsRequest : {})
                : (insertManyPhysicalLocationsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertManyProjectlessUsersParams} iInsertManyProjectlessUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyProjectlessUser: async (iInsertManyProjectlessUsersParams: IInsertManyProjectlessUsersParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertManyProjectlessUsersParams' is not null or undefined
            if (iInsertManyProjectlessUsersParams === null || iInsertManyProjectlessUsersParams === undefined) {
                throw new RequiredError('iInsertManyProjectlessUsersParams','Required parameter iInsertManyProjectlessUsersParams was null or undefined when calling insertManyProjectlessUser.');
            }
            const localVarPath = `/users/many-projectless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertManyProjectlessUsersParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertManyProjectlessUsersParams !== undefined ? iInsertManyProjectlessUsersParams : {})
                : (iInsertManyProjectlessUsersParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhoneNumberByCompanyId: async (companyId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new RequiredError('companyId','Required parameter companyId was null or undefined when calling insertPhoneNumberByCompanyId.');
            }
            // verify required parameter 'iInsertionPhoneNumber' is not null or undefined
            if (iInsertionPhoneNumber === null || iInsertionPhoneNumber === undefined) {
                throw new RequiredError('iInsertionPhoneNumber','Required parameter iInsertionPhoneNumber was null or undefined when calling insertPhoneNumberByCompanyId.');
            }
            const localVarPath = `/companies/{companyId}/phone-numbers`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionPhoneNumber !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionPhoneNumber !== undefined ? iInsertionPhoneNumber : {})
                : (iInsertionPhoneNumber || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhoneNumberByUserId: async (userId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling insertPhoneNumberByUserId.');
            }
            // verify required parameter 'iInsertionPhoneNumber' is not null or undefined
            if (iInsertionPhoneNumber === null || iInsertionPhoneNumber === undefined) {
                throw new RequiredError('iInsertionPhoneNumber','Required parameter iInsertionPhoneNumber was null or undefined when calling insertPhoneNumberByUserId.');
            }
            const localVarPath = `/users/{userId}/phone-numbers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionPhoneNumber !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionPhoneNumber !== undefined ? iInsertionPhoneNumber : {})
                : (iInsertionPhoneNumber || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomInsertionPhysicalBuilding} customInsertionPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhysicalBuilding: async (customInsertionPhysicalBuilding: CustomInsertionPhysicalBuilding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customInsertionPhysicalBuilding' is not null or undefined
            if (customInsertionPhysicalBuilding === null || customInsertionPhysicalBuilding === undefined) {
                throw new RequiredError('customInsertionPhysicalBuilding','Required parameter customInsertionPhysicalBuilding was null or undefined when calling insertPhysicalBuilding.');
            }
            const localVarPath = `/physical-buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customInsertionPhysicalBuilding !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customInsertionPhysicalBuilding !== undefined ? customInsertionPhysicalBuilding : {})
                : (customInsertionPhysicalBuilding || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionPhysicalFloor} iInsertionPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhysicalFloor: async (iInsertionPhysicalFloor: IInsertionPhysicalFloor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionPhysicalFloor' is not null or undefined
            if (iInsertionPhysicalFloor === null || iInsertionPhysicalFloor === undefined) {
                throw new RequiredError('iInsertionPhysicalFloor','Required parameter iInsertionPhysicalFloor was null or undefined when calling insertPhysicalFloor.');
            }
            const localVarPath = `/physical-floors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionPhysicalFloor !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionPhysicalFloor !== undefined ? iInsertionPhysicalFloor : {})
                : (iInsertionPhysicalFloor || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {IInsertionPrimeBidder} iInsertionPrimeBidder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPrimeBidderByUserAndProject: async (userId: string, projectId: string, iInsertionPrimeBidder: IInsertionPrimeBidder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling insertPrimeBidderByUserAndProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertPrimeBidderByUserAndProject.');
            }
            // verify required parameter 'iInsertionPrimeBidder' is not null or undefined
            if (iInsertionPrimeBidder === null || iInsertionPrimeBidder === undefined) {
                throw new RequiredError('iInsertionPrimeBidder','Required parameter iInsertionPrimeBidder was null or undefined when calling insertPrimeBidderByUserAndProject.');
            }
            const localVarPath = `/prime-bidders/users/{userId}/projects/{projectId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionPrimeBidder !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionPrimeBidder !== undefined ? iInsertionPrimeBidder : {})
                : (iInsertionPrimeBidder || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionProductPackage} iInsertionProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProductPackage: async (iInsertionProductPackage: IInsertionProductPackage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionProductPackage' is not null or undefined
            if (iInsertionProductPackage === null || iInsertionProductPackage === undefined) {
                throw new RequiredError('iInsertionProductPackage','Required parameter iInsertionProductPackage was null or undefined when calling insertProductPackage.');
            }
            const localVarPath = `/product-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionProductPackage !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionProductPackage !== undefined ? iInsertionProductPackage : {})
                : (iInsertionProductPackage || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ICustomInsertionProject} iCustomInsertionProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProject: async (iCustomInsertionProject: ICustomInsertionProject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iCustomInsertionProject' is not null or undefined
            if (iCustomInsertionProject === null || iCustomInsertionProject === undefined) {
                throw new RequiredError('iCustomInsertionProject','Required parameter iCustomInsertionProject was null or undefined when calling insertProject.');
            }
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iCustomInsertionProject !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iCustomInsertionProject !== undefined ? iCustomInsertionProject : {})
                : (iCustomInsertionProject || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionProjectCalendarEvent} iInsertionProjectCalendarEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProjectCalendarEvent: async (iInsertionProjectCalendarEvent: IInsertionProjectCalendarEvent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionProjectCalendarEvent' is not null or undefined
            if (iInsertionProjectCalendarEvent === null || iInsertionProjectCalendarEvent === undefined) {
                throw new RequiredError('iInsertionProjectCalendarEvent','Required parameter iInsertionProjectCalendarEvent was null or undefined when calling insertProjectCalendarEvent.');
            }
            const localVarPath = `/project-calendar-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionProjectCalendarEvent !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionProjectCalendarEvent !== undefined ? iInsertionProjectCalendarEvent : {})
                : (iInsertionProjectCalendarEvent || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ICustomInsertionUser} iCustomInsertionUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProjectlessUser: async (iCustomInsertionUser: ICustomInsertionUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iCustomInsertionUser' is not null or undefined
            if (iCustomInsertionUser === null || iCustomInsertionUser === undefined) {
                throw new RequiredError('iCustomInsertionUser','Required parameter iCustomInsertionUser was null or undefined when calling insertProjectlessUser.');
            }
            const localVarPath = `/users/projectless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iCustomInsertionUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iCustomInsertionUser !== undefined ? iCustomInsertionUser : {})
                : (iCustomInsertionUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IInsertionSubscriberSecurityGroup} iInsertionSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSecurityGroupBySubscriberId: async (subscriberId: string, iInsertionSubscriberSecurityGroup: IInsertionSubscriberSecurityGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberId' is not null or undefined
            if (subscriberId === null || subscriberId === undefined) {
                throw new RequiredError('subscriberId','Required parameter subscriberId was null or undefined when calling insertSecurityGroupBySubscriberId.');
            }
            // verify required parameter 'iInsertionSubscriberSecurityGroup' is not null or undefined
            if (iInsertionSubscriberSecurityGroup === null || iInsertionSubscriberSecurityGroup === undefined) {
                throw new RequiredError('iInsertionSubscriberSecurityGroup','Required parameter iInsertionSubscriberSecurityGroup was null or undefined when calling insertSecurityGroupBySubscriberId.');
            }
            const localVarPath = `/subscribers/{subscriberId}/security-groups`
                .replace(`{${"subscriberId"}}`, encodeURIComponent(String(subscriberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionSubscriberSecurityGroup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionSubscriberSecurityGroup !== undefined ? iInsertionSubscriberSecurityGroup : {})
                : (iInsertionSubscriberSecurityGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubmissionByDocumentId: async (documentId: string, submittalUploadRequest: SubmittalUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling insertSubmissionByDocumentId.');
            }
            // verify required parameter 'submittalUploadRequest' is not null or undefined
            if (submittalUploadRequest === null || submittalUploadRequest === undefined) {
                throw new RequiredError('submittalUploadRequest','Required parameter submittalUploadRequest was null or undefined when calling insertSubmissionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/submission`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submittalUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submittalUploadRequest !== undefined ? submittalUploadRequest : {})
                : (submittalUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubmissionByKey: async (key: string, submittalUploadRequest: SubmittalUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling insertSubmissionByKey.');
            }
            // verify required parameter 'submittalUploadRequest' is not null or undefined
            if (submittalUploadRequest === null || submittalUploadRequest === undefined) {
                throw new RequiredError('submittalUploadRequest','Required parameter submittalUploadRequest was null or undefined when calling insertSubmissionByKey.');
            }
            const localVarPath = `/documents/public/{key}/submission`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submittalUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submittalUploadRequest !== undefined ? submittalUploadRequest : {})
                : (submittalUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IInsertionSubscriber} iInsertionSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubscriber: async (iInsertionSubscriber: IInsertionSubscriber, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInsertionSubscriber' is not null or undefined
            if (iInsertionSubscriber === null || iInsertionSubscriber === undefined) {
                throw new RequiredError('iInsertionSubscriber','Required parameter iInsertionSubscriber was null or undefined when calling insertSubscriber.');
            }
            const localVarPath = `/subscribers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iInsertionSubscriber !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iInsertionSubscriber !== undefined ? iInsertionSubscriber : {})
                : (iInsertionSubscriber || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IAddNewUser} iAddNewUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUser: async (iAddNewUser: IAddNewUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iAddNewUser' is not null or undefined
            if (iAddNewUser === null || iAddNewUser === undefined) {
                throw new RequiredError('iAddNewUser','Required parameter iAddNewUser was null or undefined when calling insertUser.');
            }
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iAddNewUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iAddNewUser !== undefined ? iAddNewUser : {})
                : (iAddNewUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserGroupInsertionRequest} userGroupInsertionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUserGroupByProjectId: async (projectId: string, userGroupInsertionRequest: UserGroupInsertionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling insertUserGroupByProjectId.');
            }
            // verify required parameter 'userGroupInsertionRequest' is not null or undefined
            if (userGroupInsertionRequest === null || userGroupInsertionRequest === undefined) {
                throw new RequiredError('userGroupInsertionRequest','Required parameter userGroupInsertionRequest was null or undefined when calling insertUserGroupByProjectId.');
            }
            const localVarPath = `/user-groups/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userGroupInsertionRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userGroupInsertionRequest !== undefined ? userGroupInsertionRequest : {})
                : (userGroupInsertionRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserFileSystem: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling listUserFileSystem.');
            }
            const localVarPath = `/filesystem/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserFileSystemCompressed: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling listUserFileSystemCompressed.');
            }
            const localVarPath = `/filesystem/{projectId}/compressed`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead: async (notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling markNotificationAsRead.');
            }
            const localVarPath = `/user-notification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsReadByUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling markNotificationsAsReadByUserId.');
            }
            const localVarPath = `/user-notification/users/{userId}/mark-as-read`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {MarkParserFileAsConformedRequest} markParserFileAsConformedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markParserFileAsConformed: async (fileId: string, markParserFileAsConformedRequest: MarkParserFileAsConformedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling markParserFileAsConformed.');
            }
            // verify required parameter 'markParserFileAsConformedRequest' is not null or undefined
            if (markParserFileAsConformedRequest === null || markParserFileAsConformedRequest === undefined) {
                throw new RequiredError('markParserFileAsConformedRequest','Required parameter markParserFileAsConformedRequest was null or undefined when calling markParserFileAsConformed.');
            }
            const localVarPath = `/parser/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof markParserFileAsConformedRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(markParserFileAsConformedRequest !== undefined ? markParserFileAsConformedRequest : {})
                : (markParserFileAsConformedRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentById: async (documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling modifyDocumentById.');
            }
            // verify required parameter 'iModifyDocumentRequest' is not null or undefined
            if (iModifyDocumentRequest === null || iModifyDocumentRequest === undefined) {
                throw new RequiredError('iModifyDocumentRequest','Required parameter iModifyDocumentRequest was null or undefined when calling modifyDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/modify`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iModifyDocumentRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iModifyDocumentRequest !== undefined ? iModifyDocumentRequest : {})
                : (iModifyDocumentRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentByIdWithResponse: async (documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling modifyDocumentByIdWithResponse.');
            }
            // verify required parameter 'iModifyDocumentRequest' is not null or undefined
            if (iModifyDocumentRequest === null || iModifyDocumentRequest === undefined) {
                throw new RequiredError('iModifyDocumentRequest','Required parameter iModifyDocumentRequest was null or undefined when calling modifyDocumentByIdWithResponse.');
            }
            const localVarPath = `/documents/{documentId}/modify-with-response`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iModifyDocumentRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iModifyDocumentRequest !== undefined ? iModifyDocumentRequest : {})
                : (iModifyDocumentRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchRequest} iModifyDocumentsByBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentsByBatch: async (iModifyDocumentsByBatchRequest: IModifyDocumentsByBatchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iModifyDocumentsByBatchRequest' is not null or undefined
            if (iModifyDocumentsByBatchRequest === null || iModifyDocumentsByBatchRequest === undefined) {
                throw new RequiredError('iModifyDocumentsByBatchRequest','Required parameter iModifyDocumentsByBatchRequest was null or undefined when calling modifyDocumentsByBatch.');
            }
            const localVarPath = `/documents/modify-by-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iModifyDocumentsByBatchRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iModifyDocumentsByBatchRequest !== undefined ? iModifyDocumentsByBatchRequest : {})
                : (iModifyDocumentsByBatchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchesRequest} iModifyDocumentsByBatchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentsByBatches: async (iModifyDocumentsByBatchesRequest: IModifyDocumentsByBatchesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iModifyDocumentsByBatchesRequest' is not null or undefined
            if (iModifyDocumentsByBatchesRequest === null || iModifyDocumentsByBatchesRequest === undefined) {
                throw new RequiredError('iModifyDocumentsByBatchesRequest','Required parameter iModifyDocumentsByBatchesRequest was null or undefined when calling modifyDocumentsByBatches.');
            }
            const localVarPath = `/documents/modify-by-batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof iModifyDocumentsByBatchesRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(iModifyDocumentsByBatchesRequest !== undefined ? iModifyDocumentsByBatchesRequest : {})
                : (iModifyDocumentsByBatchesRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ModifyUserFileSystemNodeRequest} modifyUserFileSystemNodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserFileSystemNode: async (projectId: string, modifyUserFileSystemNodeRequest: ModifyUserFileSystemNodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling modifyUserFileSystemNode.');
            }
            // verify required parameter 'modifyUserFileSystemNodeRequest' is not null or undefined
            if (modifyUserFileSystemNodeRequest === null || modifyUserFileSystemNodeRequest === undefined) {
                throw new RequiredError('modifyUserFileSystemNodeRequest','Required parameter modifyUserFileSystemNodeRequest was null or undefined when calling modifyUserFileSystemNode.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/modify`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof modifyUserFileSystemNodeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(modifyUserFileSystemNodeRequest !== undefined ? modifyUserFileSystemNodeRequest : {})
                : (modifyUserFileSystemNodeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notReadyForSubmissionByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling notReadyForSubmissionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/not-ready-for-submission`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {NotifyUserAccessDesignFileRequest} notifyUserAccessDesignFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyUsersOfAccessToDesignFile: async (projectId: string, notifyUserAccessDesignFileRequest: NotifyUserAccessDesignFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling notifyUsersOfAccessToDesignFile.');
            }
            // verify required parameter 'notifyUserAccessDesignFileRequest' is not null or undefined
            if (notifyUserAccessDesignFileRequest === null || notifyUserAccessDesignFileRequest === undefined) {
                throw new RequiredError('notifyUserAccessDesignFileRequest','Required parameter notifyUserAccessDesignFileRequest was null or undefined when calling notifyUsersOfAccessToDesignFile.');
            }
            const localVarPath = `/filesystem/{projectId}/notify-design-file-access`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notifyUserAccessDesignFileRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notifyUserAccessDesignFileRequest !== undefined ? notifyUserAccessDesignFileRequest : {})
                : (notifyUserAccessDesignFileRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permanentlyDeleteProjectById: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling permanentlyDeleteProjectById.');
            }
            const localVarPath = `/projects/{projectId}/permanently-delete`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RejectDocumentRequest} rejectDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseSubmissionByDocumentId: async (documentId: string, rejectDocumentRequest: RejectDocumentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling refuseSubmissionByDocumentId.');
            }
            // verify required parameter 'rejectDocumentRequest' is not null or undefined
            if (rejectDocumentRequest === null || rejectDocumentRequest === undefined) {
                throw new RequiredError('rejectDocumentRequest','Required parameter rejectDocumentRequest was null or undefined when calling refuseSubmissionByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/reject`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof rejectDocumentRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(rejectDocumentRequest !== undefined ? rejectDocumentRequest : {})
                : (rejectDocumentRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentFromPackageByDocumentId: async (documentId: string, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling removeDocumentFromPackageByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/remove-from-package`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePlanHolderFromProjectByUserIdAndProjectId: async (userId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling removePlanHolderFromProjectByUserIdAndProjectId.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling removePlanHolderFromProjectByUserIdAndProjectId.');
            }
            const localVarPath = `/users/{userId}/projects/{projectId}/planholder`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProjectByProjectId: async (projectId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling removeUserFromProjectByProjectId.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling removeUserFromProjectByProjectId.');
            }
            const localVarPath = `/projects/{projectId}/users/{userId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceDocumentById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceDocumentById.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {BaseFileUploadParams} baseFileUploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceFileByDocumentIdAndFileId: async (documentId: string, fileId: string, baseFileUploadParams: BaseFileUploadParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling replaceFileByDocumentIdAndFileId.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling replaceFileByDocumentIdAndFileId.');
            }
            // verify required parameter 'baseFileUploadParams' is not null or undefined
            if (baseFileUploadParams === null || baseFileUploadParams === undefined) {
                throw new RequiredError('baseFileUploadParams','Required parameter baseFileUploadParams was null or undefined when calling replaceFileByDocumentIdAndFileId.');
            }
            const localVarPath = `/documents/{documentId}/files/{fileId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof baseFileUploadParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(baseFileUploadParams !== undefined ? baseFileUploadParams : {})
                : (baseFileUploadParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWebsiteSettingById: async (websiteSettingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'websiteSettingId' is not null or undefined
            if (websiteSettingId === null || websiteSettingId === undefined) {
                throw new RequiredError('websiteSettingId','Required parameter websiteSettingId was null or undefined when calling replaceWebsiteSettingById.');
            }
            const localVarPath = `/website-settings/{websiteSettingId}`
                .replace(`{${"websiteSettingId"}}`, encodeURIComponent(String(websiteSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/original`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RespondToReviewProps} respondToReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToReviewByDocumentId: async (documentId: string, respondToReviewProps: RespondToReviewProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling respondToReviewByDocumentId.');
            }
            // verify required parameter 'respondToReviewProps' is not null or undefined
            if (respondToReviewProps === null || respondToReviewProps === undefined) {
                throw new RequiredError('respondToReviewProps','Required parameter respondToReviewProps was null or undefined when calling respondToReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/respond-to-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof respondToReviewProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(respondToReviewProps !== undefined ? respondToReviewProps : {})
                : (respondToReviewProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDocumentById: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling restoreDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/restore`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestoreDocumentsRequest} restoreDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDocumentsByIds: async (restoreDocumentsRequest: RestoreDocumentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'restoreDocumentsRequest' is not null or undefined
            if (restoreDocumentsRequest === null || restoreDocumentsRequest === undefined) {
                throw new RequiredError('restoreDocumentsRequest','Required parameter restoreDocumentsRequest was null or undefined when calling restoreDocumentsByIds.');
            }
            const localVarPath = `/documents/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof restoreDocumentsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(restoreDocumentsRequest !== undefined ? restoreDocumentsRequest : {})
                : (restoreDocumentsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retractBidById: async (bidId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidId' is not null or undefined
            if (bidId === null || bidId === undefined) {
                throw new RequiredError('bidId','Required parameter bidId was null or undefined when calling retractBidById.');
            }
            const localVarPath = `/bids/{bidId}/retract`
                .replace(`{${"bidId"}}`, encodeURIComponent(String(bidId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BidConnectFileDeliveryEmailRequest} bidConnectFileDeliveryEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBidConnectFileDeliveryEmail: async (bidConnectFileDeliveryEmailRequest: BidConnectFileDeliveryEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bidConnectFileDeliveryEmailRequest' is not null or undefined
            if (bidConnectFileDeliveryEmailRequest === null || bidConnectFileDeliveryEmailRequest === undefined) {
                throw new RequiredError('bidConnectFileDeliveryEmailRequest','Required parameter bidConnectFileDeliveryEmailRequest was null or undefined when calling sendBidConnectFileDeliveryEmail.');
            }
            const localVarPath = `/project-bid-setups/send-us-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof bidConnectFileDeliveryEmailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(bidConnectFileDeliveryEmailRequest !== undefined ? bidConnectFileDeliveryEmailRequest : {})
                : (bidConnectFileDeliveryEmailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicDesignFileDownloadLinkRequest} sendPublicDesignFileDownloadLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDirectDesignFileDownloadEmails: async (projectId: string, sendPublicDesignFileDownloadLinkRequest: SendPublicDesignFileDownloadLinkRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling sendDirectDesignFileDownloadEmails.');
            }
            // verify required parameter 'sendPublicDesignFileDownloadLinkRequest' is not null or undefined
            if (sendPublicDesignFileDownloadLinkRequest === null || sendPublicDesignFileDownloadLinkRequest === undefined) {
                throw new RequiredError('sendPublicDesignFileDownloadLinkRequest','Required parameter sendPublicDesignFileDownloadLinkRequest was null or undefined when calling sendDirectDesignFileDownloadEmails.');
            }
            const localVarPath = `/filesystem/{projectId}/send-public-email-link`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendPublicDesignFileDownloadLinkRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendPublicDesignFileDownloadLinkRequest !== undefined ? sendPublicDesignFileDownloadLinkRequest : {})
                : (sendPublicDesignFileDownloadLinkRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDocumentPublicLinkEmails: async (documentId: string, emailRequest: EmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling sendDocumentPublicLinkEmails.');
            }
            // verify required parameter 'emailRequest' is not null or undefined
            if (emailRequest === null || emailRequest === undefined) {
                throw new RequiredError('emailRequest','Required parameter emailRequest was null or undefined when calling sendDocumentPublicLinkEmails.');
            }
            const localVarPath = `/documents/{documentId}/send-public-link`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof emailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(emailRequest !== undefined ? emailRequest : {})
                : (emailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPrivateInviteEmailRequestParams} sendPrivateInviteEmailRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPrivateProjectInviteEmails: async (projectId: string, sendPrivateInviteEmailRequestParams: SendPrivateInviteEmailRequestParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling sendPrivateProjectInviteEmails.');
            }
            // verify required parameter 'sendPrivateInviteEmailRequestParams' is not null or undefined
            if (sendPrivateInviteEmailRequestParams === null || sendPrivateInviteEmailRequestParams === undefined) {
                throw new RequiredError('sendPrivateInviteEmailRequestParams','Required parameter sendPrivateInviteEmailRequestParams was null or undefined when calling sendPrivateProjectInviteEmails.');
            }
            const localVarPath = `/projects/{projectId}/private-invite`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendPrivateInviteEmailRequestParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendPrivateInviteEmailRequestParams !== undefined ? sendPrivateInviteEmailRequestParams : {})
                : (sendPrivateInviteEmailRequestParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicLinkToEmailsRequest} sendPublicLinkToEmailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPublicFolderAccessToEmails: async (projectId: string, sendPublicLinkToEmailsRequest: SendPublicLinkToEmailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling sendPublicFolderAccessToEmails.');
            }
            // verify required parameter 'sendPublicLinkToEmailsRequest' is not null or undefined
            if (sendPublicLinkToEmailsRequest === null || sendPublicLinkToEmailsRequest === undefined) {
                throw new RequiredError('sendPublicLinkToEmailsRequest','Required parameter sendPublicLinkToEmailsRequest was null or undefined when calling sendPublicFolderAccessToEmails.');
            }
            const localVarPath = `/filesystem/{projectId}/send-public-folder-access`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendPublicLinkToEmailsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendPublicLinkToEmailsRequest !== undefined ? sendPublicLinkToEmailsRequest : {})
                : (sendPublicLinkToEmailsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<ShareFileSystemObjectRequest>} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMultipleUserFileSystemObject: async (projectId: string, shareFileSystemObjectRequest: Array<ShareFileSystemObjectRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling shareMultipleUserFileSystemObject.');
            }
            // verify required parameter 'shareFileSystemObjectRequest' is not null or undefined
            if (shareFileSystemObjectRequest === null || shareFileSystemObjectRequest === undefined) {
                throw new RequiredError('shareFileSystemObjectRequest','Required parameter shareFileSystemObjectRequest was null or undefined when calling shareMultipleUserFileSystemObject.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/multi-share`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shareFileSystemObjectRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shareFileSystemObjectRequest !== undefined ? shareFileSystemObjectRequest : {})
                : (shareFileSystemObjectRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ShareFileSystemObjectRequest} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareUserFileSystemObject: async (projectId: string, shareFileSystemObjectRequest: ShareFileSystemObjectRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling shareUserFileSystemObject.');
            }
            // verify required parameter 'shareFileSystemObjectRequest' is not null or undefined
            if (shareFileSystemObjectRequest === null || shareFileSystemObjectRequest === undefined) {
                throw new RequiredError('shareFileSystemObjectRequest','Required parameter shareFileSystemObjectRequest was null or undefined when calling shareUserFileSystemObject.');
            }
            const localVarPath = `/filesystem/{projectId}/file-node/share`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shareFileSystemObjectRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shareFileSystemObjectRequest !== undefined ? shareFileSystemObjectRequest : {})
                : (shareFileSystemObjectRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipAssignSubcontractorByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling skipAssignSubcontractorByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/skip-subcontractor`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcontractorRefuseAssignmentByDocumentId: async (documentId: string, unauthenticatedUserEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling subcontractorRefuseAssignmentByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/subcontractor-refuse-assignment`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unauthenticatedUserEmail !== undefined) {
                localVarQueryParameter['unauthenticatedUserEmail'] = unauthenticatedUserEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAdditionalReviewByDocumentId: async (documentId: string, additionalReviewProps: AdditionalReviewProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling submitAdditionalReviewByDocumentId.');
            }
            // verify required parameter 'additionalReviewProps' is not null or undefined
            if (additionalReviewProps === null || additionalReviewProps === undefined) {
                throw new RequiredError('additionalReviewProps','Required parameter additionalReviewProps was null or undefined when calling submitAdditionalReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/submit-additional-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof additionalReviewProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(additionalReviewProps !== undefined ? additionalReviewProps : {})
                : (additionalReviewProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAdditionalReviewByKey: async (key: string, additionalReviewProps: AdditionalReviewProps, consultantReviewDocumentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling submitAdditionalReviewByKey.');
            }
            // verify required parameter 'additionalReviewProps' is not null or undefined
            if (additionalReviewProps === null || additionalReviewProps === undefined) {
                throw new RequiredError('additionalReviewProps','Required parameter additionalReviewProps was null or undefined when calling submitAdditionalReviewByKey.');
            }
            const localVarPath = `/documents/submit-additional-review-by-key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultantReviewDocumentId !== undefined) {
                localVarQueryParameter['consultantReviewDocumentId'] = consultantReviewDocumentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof additionalReviewProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(additionalReviewProps !== undefined ? additionalReviewProps : {})
                : (additionalReviewProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmitToArchitectProps} submitToArchitectProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitToArchitectByDocumentId: async (documentId: string, submitToArchitectProps: SubmitToArchitectProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling submitToArchitectByDocumentId.');
            }
            // verify required parameter 'submitToArchitectProps' is not null or undefined
            if (submitToArchitectProps === null || submitToArchitectProps === undefined) {
                throw new RequiredError('submitToArchitectProps','Required parameter submitToArchitectProps was null or undefined when calling submitToArchitectByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/submit-to-architect`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submitToArchitectProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submitToArchitectProps !== undefined ? submitToArchitectProps : {})
                : (submitToArchitectProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {ProcoreDocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProcoreDocuments: async (centerLineProjectId: string, documentType: ProcoreDocumentType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'centerLineProjectId' is not null or undefined
            if (centerLineProjectId === null || centerLineProjectId === undefined) {
                throw new RequiredError('centerLineProjectId','Required parameter centerLineProjectId was null or undefined when calling syncProcoreDocuments.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling syncProcoreDocuments.');
            }
            const localVarPath = `/procore/{centerLineProjectId}/sync-procore-documents`
                .replace(`{${"centerLineProjectId"}}`, encodeURIComponent(String(centerLineProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ToggleRemindersProps} toggleRemindersProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleRemindersByDocumentKey: async (toggleRemindersProps: ToggleRemindersProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'toggleRemindersProps' is not null or undefined
            if (toggleRemindersProps === null || toggleRemindersProps === undefined) {
                throw new RequiredError('toggleRemindersProps','Required parameter toggleRemindersProps was null or undefined when calling toggleRemindersByDocumentKey.');
            }
            const localVarPath = `/document-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof toggleRemindersProps !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(toggleRemindersProps !== undefined ? toggleRemindersProps : {})
                : (toggleRemindersProps || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoCompleteReviewByDocumentId: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling undoCompleteReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/undo-complete-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UnlockProjectsWithKeyParams} unlockProjectsWithKeyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockProjectsWithKeyByUserId: async (userId: string, unlockProjectsWithKeyParams: UnlockProjectsWithKeyParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling unlockProjectsWithKeyByUserId.');
            }
            // verify required parameter 'unlockProjectsWithKeyParams' is not null or undefined
            if (unlockProjectsWithKeyParams === null || unlockProjectsWithKeyParams === undefined) {
                throw new RequiredError('unlockProjectsWithKeyParams','Required parameter unlockProjectsWithKeyParams was null or undefined when calling unlockProjectsWithKeyByUserId.');
            }
            const localVarPath = `/users/{userId}/unlock-projects`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof unlockProjectsWithKeyParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(unlockProjectsWithKeyParams !== undefined ? unlockProjectsWithKeyParams : {})
                : (unlockProjectsWithKeyParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePlanholderByProjectIdAndUserId: async (projectId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling unsubscribePlanholderByProjectIdAndUserId.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling unsubscribePlanholderByProjectIdAndUserId.');
            }
            const localVarPath = `/projects/{projectId}/unsubscribe-planholder/{userId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows for bluebeam to POST back the BAX file. This should only be used by REVU.
         * @param {string} fileId 
         * @param {string} originalPdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaxFile: async (fileId: string, originalPdfId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling updateBaxFile.');
            }
            // verify required parameter 'originalPdfId' is not null or undefined
            if (originalPdfId === null || originalPdfId === undefined) {
                throw new RequiredError('originalPdfId','Required parameter originalPdfId was null or undefined when calling updateBaxFile.');
            }
            const localVarPath = `/files/{fileId}/bax`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (originalPdfId !== undefined) {
                localVarQueryParameter['originalPdfId'] = originalPdfId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {UpdateReviewParams} updateReviewParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewByDocumentId: async (documentId: string, updateReviewParams: UpdateReviewParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling updateReviewByDocumentId.');
            }
            // verify required parameter 'updateReviewParams' is not null or undefined
            if (updateReviewParams === null || updateReviewParams === undefined) {
                throw new RequiredError('updateReviewParams','Required parameter updateReviewParams was null or undefined when calling updateReviewByDocumentId.');
            }
            const localVarPath = `/documents/{documentId}/update-review`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateReviewParams !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateReviewParams !== undefined ? updateReviewParams : {})
                : (updateReviewParams || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {S3StagingUploadRequest} s3StagingUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToStaging: async (s3StagingUploadRequest: S3StagingUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 's3StagingUploadRequest' is not null or undefined
            if (s3StagingUploadRequest === null || s3StagingUploadRequest === undefined) {
                throw new RequiredError('s3StagingUploadRequest','Required parameter s3StagingUploadRequest was null or undefined when calling uploadToStaging.');
            }
            const localVarPath = `/filesystem/upload-to-staging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof s3StagingUploadRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(s3StagingUploadRequest !== undefined ? s3StagingUploadRequest : {})
                : (s3StagingUploadRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawDocumentById: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling withdrawDocumentById.');
            }
            const localVarPath = `/documents/{documentId}/withdraw`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptSubmissionByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).acceptSubmissionByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AddDocumentToPackageProps} addDocumentToPackageProps 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocumentToPackageByDocumentId(documentId: string, addDocumentToPackageProps: AddDocumentToPackageProps, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).addDocumentToPackageByDocumentId(documentId, addDocumentToPackageProps, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AddUserParams>} addUserParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrModifyUsersByProjectId(projectId: string, addUserParams: Array<AddUserParams>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).addOrModifyUsersByProjectId(projectId, addUserParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AssignByDomainRequest} assignByDomainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignUsersToCompanyByEmailDomain(assignByDomainRequest: AssignByDomainRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).assignUsersToCompanyByEmailDomain(assignByDomainRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeBIM360(centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).authorizeBIM360(centerLineProjectId, iAuthorizeParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeProcore(centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).authorizeProcore(centerLineProjectId, iAuthorizeParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DrawingsParserRequest} drawingsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beginDrawingsParser(drawingsParserRequest: DrawingsParserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDrawingsParserJobStatus>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).beginDrawingsParser(drawingsParserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DrawingsPreprocessorRequest} drawingsPreprocessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beginDrawingsPreprocessor(drawingsPreprocessorRequest: DrawingsPreprocessorRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDrawingsParserJobStatus>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).beginDrawingsPreprocessor(drawingsPreprocessorRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SpecificationsParserRequest} specificationsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beginSpecificationsParser(specificationsParserRequest: SpecificationsParserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJobStatus>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).beginSpecificationsParser(specificationsParserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CompleteMultiPartUploadRequest} completeMultiPartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeMultipartUpload(completeMultiPartUploadRequest: CompleteMultiPartUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).completeMultipartUpload(completeMultiPartUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalReviewProps} submittalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeReviewByDocumentId(documentId: string, submittalReviewProps: SubmittalReviewProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteReviewResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).completeReviewByDocumentId(documentId, submittalReviewProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ErrorEmail} errorEmail 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createErrorEmail(errorEmail: ErrorEmail, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createErrorEmail(errorEmail, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {CreateLinkedDocumentsForAssignedUsersRequest} createLinkedDocumentsForAssignedUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedDocumentsForAssignedUsers(documentId: string, createLinkedDocumentsForAssignedUsersRequest: CreateLinkedDocumentsForAssignedUsersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<INumberedDocumentView>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createLinkedDocumentsForAssignedUsers(documentId, createLinkedDocumentsForAssignedUsersRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedOtherFileSystemRequest} authenticatedOtherFileSystemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserFileSystemFolder(projectId: string, authenticatedOtherFileSystemRequest: AuthenticatedOtherFileSystemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFolderResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createUserFileSystemFolder(projectId, authenticatedOtherFileSystemRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllSecurityGroupsBySubscriberId(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteAllSecurityGroupsBySubscriberId(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnouncementById(announcementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteAnnouncementById(announcementId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBidSetupByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteBidSetupByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentById(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteDocumentById(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteDocumentsRequest} deleteDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentsByIds(deleteDocumentsRequest: DeleteDocumentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteDocumentsByIds(deleteDocumentsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileByBidSetupIdAndFileId(bidSetupId: string, fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteFileByBidSetupIdAndFileId(bidSetupId, fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInviteEmailByProjectIdAndUserId(projectId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteInviteEmailByProjectIdAndUserId(projectId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IDeleteUsersByEmailParams} iDeleteUsersByEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyUsersByEmail(iDeleteUsersByEmailParams: IDeleteUsersByEmailParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteManyUsersByEmail(iDeleteUsersByEmailParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParsedFileByProjectIdAndFileId(projectId: string, fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteParsedFileByProjectIdAndFileId(projectId, fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteParsedFilesRequest} deleteParsedFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParsedFilesByProjectId(projectId: string, deleteParsedFilesRequest: DeleteParsedFilesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteParsedFilesByProjectId(projectId, deleteParsedFilesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhysicalBuildingById(buildingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePhysicalBuildingById(buildingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhysicalFloorById(floorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePhysicalFloorById(floorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhysicalLocationById(locationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePhysicalLocationById(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrimeBidderByUserAndProject(userId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePrimeBidderByUserAndProject(userId, projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductPackageById(productPackageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteProductPackageById(productPackageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [newSecurityGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecurityGroupById(id: string, newSecurityGroupId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteSecurityGroupById(id, newSecurityGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscriberById(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteSubscriberById(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTrashItemsRequest} deleteTrashItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrashItemsByProjectId(projectId: string, deleteTrashItemsRequest: DeleteTrashItemsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteTrashItemsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteTrashItemsByProjectId(projectId, deleteTrashItemsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteUserById(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserFileSystemNodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserFileSystemNode(projectId: string, body: UserFileSystemNodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteUserFileSystemNode(projectId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroupById(userGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteUserGroupById(userGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteUserNotificationsParams} deleteUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserNotificationsByIds(deleteUserNotificationsParams: DeleteUserNotificationsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteUserNotificationsByIds(deleteUserNotificationsParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableRegistrationReminderByUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).disableRegistrationReminderByUserId(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetTrashItemRequest} getTrashItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTrashItemByProjectId(getTrashItemRequest: GetTrashItemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3DownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadTrashItemByProjectId(getTrashItemRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {IOptionalAnnouncement} iOptionalAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAnnouncementById(announcementId: string, iOptionalAnnouncement: IOptionalAnnouncement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editAnnouncementById(announcementId, iOptionalAnnouncement, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AnticipatedSubmissionDateChangeRequest} anticipatedSubmissionDateChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAnticipatedSubmissionDateOfDocumentById(documentId: string, anticipatedSubmissionDateChangeRequest: AnticipatedSubmissionDateChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editAnticipatedSubmissionDateOfDocumentById(documentId, anticipatedSubmissionDateChangeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomOptionalBidSetup} iCustomOptionalBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBidSetupByProjectId(projectId: string, iCustomOptionalBidSetup: ICustomOptionalBidSetup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditBidSetupResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editBidSetupByProjectId(projectId, iCustomOptionalBidSetup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IOptionalDocument} iOptionalDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDocumentById(documentId: string, iOptionalDocument: IOptionalDocument, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editDocumentById(documentId, iOptionalDocument, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {IEditDocumentSettings} iEditDocumentSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDocumentSettingsByUserIdAndDocumentId(userId: string, documentId: string, iEditDocumentSettings: IEditDocumentSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editDocumentSettingsByUserIdAndDocumentId(userId, documentId, iEditDocumentSettings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {EditFavoriteProjectParams} editFavoriteProjectParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editFavoriteProjectByUserIdAndProjectId(userId: string, projectId: string, editFavoriteProjectParams: EditFavoriteProjectParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editFavoriteProjectByUserIdAndProjectId(userId, projectId, editFavoriteProjectParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {EditFileParams} editFileParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editFileByDocumentIdAndFileId(documentId: string, fileId: string, editFileParams: EditFileParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFile>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editFileByDocumentIdAndFileId(documentId, fileId, editFileParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {IOptionalFile} iOptionalFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editFileById(fileId: string, iOptionalFile: IOptionalFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFile>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editFileById(fileId, iOptionalFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {EditManyPhysicalFloorsRequest} editManyPhysicalFloorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editManyPhysicalFloorsByIds(editManyPhysicalFloorsRequest: EditManyPhysicalFloorsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPhysicalFloor>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editManyPhysicalFloorsByIds(editManyPhysicalFloorsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {CustomOptionalPhysicalBuilding} customOptionalPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPhysicalBuildingById(buildingId: string, customOptionalPhysicalBuilding: CustomOptionalPhysicalBuilding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhysicalBuilding>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editPhysicalBuildingById(buildingId, customOptionalPhysicalBuilding, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} floorId 
         * @param {IOptionalPhysicalFloor} iOptionalPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPhysicalFloorById(floorId: string, iOptionalPhysicalFloor: IOptionalPhysicalFloor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhysicalFloor>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editPhysicalFloorById(floorId, iOptionalPhysicalFloor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {IOptionalPhysicalLocation} iOptionalPhysicalLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPhysicalLocationById(locationId: string, iOptionalPhysicalLocation: IOptionalPhysicalLocation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhysicalLocation>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editPhysicalLocationById(locationId, iOptionalPhysicalLocation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {IOptionalProductPackage} iOptionalProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProductPackageById(productPackageId: string, iOptionalProductPackage: IOptionalProductPackage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductPackage>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editProductPackageById(productPackageId, iOptionalProductPackage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IOptionalProject} iOptionalProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProjectById(projectId: string, iOptionalProject: IOptionalProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProject>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editProjectById(projectId, iOptionalProject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IOptionalProjectUser} iOptionalProjectUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProjectUserById(id: string, iOptionalProjectUser: IOptionalProjectUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editProjectUserById(id, iOptionalProjectUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {string} securityGroupId 
         * @param {IOptionalSubscriberSecurityGroup} iOptionalSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSecurityGroupBySubscriberId(subscriberId: string, securityGroupId: string, iOptionalSubscriberSecurityGroup: IOptionalSubscriberSecurityGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISecurityGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editSecurityGroupBySubscriberId(subscriberId, securityGroupId, iOptionalSubscriberSecurityGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubcontractorChangeRequest} subcontractorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSubcontractorOfDocumentById(documentId: string, subcontractorChangeRequest: SubcontractorChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editSubcontractorOfDocumentById(documentId, subcontractorChangeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IOptionalSubscriber} iOptionalSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSubscriberById(subscriberId: string, iOptionalSubscriber: IOptionalSubscriber, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscriber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editSubscriberById(subscriberId, iOptionalSubscriber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ICustomOptionalUser} iCustomOptionalUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserById(userId: string, iCustomOptionalUser: ICustomOptionalUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editUserById(userId, iCustomOptionalUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {UserGroupEdit} userGroupEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserGroupById(userGroupId: string, userGroupEdit: UserGroupEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editUserGroupById(userGroupId, userGroupEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {EditUserNotificationsParams} editUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserNotificationsByIds(userId: string, editUserNotificationsParams: EditUserNotificationsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).editUserNotificationsByIds(userId, editUserNotificationsParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emptyTrashByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).emptyTrashByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flattenBlueBeamByDocumentId(documentId: string, submittalFlattenRequest: SubmittalFlattenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFlattenResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).flattenBlueBeamByDocumentId(documentId, submittalFlattenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flattenBlueBeamByKey(key: string, submittalFlattenRequest: SubmittalFlattenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFlattenResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).flattenBlueBeamByKey(key, submittalFlattenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {GenerateAsBuiltDocumentParams} generateAsBuiltDocumentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAsBuiltDocumentFromDrawingsDocumentId(documentId: string, generateAsBuiltDocumentParams: GenerateAsBuiltDocumentParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertDocumentResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateAsBuiltDocumentFromDrawingsDocumentId(documentId, generateAsBuiltDocumentParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GenerateAsBuiltDocumentsRequest} generateAsBuiltDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAsBuiltDocumentsByProjectId(projectId: string, generateAsBuiltDocumentsRequest: GenerateAsBuiltDocumentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateAsBuiltDocumentsByProjectId(projectId, generateAsBuiltDocumentsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBimFileDownloadLink(centerLineProjectId: string, bimTreeNode: BimTreeNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3DownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateBimFileDownloadLink(centerLineProjectId, bimTreeNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<GeneratePublicUserFileSystemLinkRequest>} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMultiplePublicUserFileSystemFileDownloadLink(projectId: string, generatePublicUserFileSystemLinkRequest: Array<GeneratePublicUserFileSystemLinkRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DesignFileDownloadResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateMultiplePublicUserFileSystemFileDownloadLink(projectId, generatePublicUserFileSystemLinkRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedDownloadRequest>} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMultipleUserFileSystemFileDownloadLink(projectId: string, authenticatedDownloadRequest: Array<AuthenticatedDownloadRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DesignFileDownloadResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateMultipleUserFileSystemFileDownloadLink(projectId, authenticatedDownloadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedUploadRequest>} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMultipleUserFileSystemUploadLink(projectId: string, authenticatedUploadRequest: Array<AuthenticatedUploadRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DesignFileUploadResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateMultipleUserFileSystemUploadLink(projectId, authenticatedUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IGeneratePlaceholdersRequest} iGeneratePlaceholdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePlaceholdersForDocuments(iGeneratePlaceholdersRequest: IGeneratePlaceholdersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generatePlaceholdersForDocuments(iGeneratePlaceholdersRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PresignedUrlPartsRequest} presignedUrlPartsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePresignedUrlsParts(presignedUrlPartsRequest: PresignedUrlPartsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PresignedUrlPartResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generatePresignedUrlsParts(presignedUrlPartsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GeneratePublicUserFileSystemLinkRequest} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePublicUserFileSystemFileDownloadLink(projectId: string, generatePublicUserFileSystemLinkRequest: GeneratePublicUserFileSystemLinkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignFileDownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generatePublicUserFileSystemFileDownloadLink(projectId, generatePublicUserFileSystemLinkRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateUserFileSystemFileDownloadLink(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignFileDownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateUserFileSystemFileDownloadLink(projectId, authenticatedDownloadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedUploadRequest} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateUserFileSystemUploadLink(projectId: string, authenticatedUploadRequest: AuthenticatedUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignFileUploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateUserFileSystemUploadLink(projectId, authenticatedUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdminUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllAdminUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAnnouncements(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIAnnouncement>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllAnnouncements(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAssignedDocumentsByUserId(userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllAssignedDocumentsByUserId(userId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanies(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedICompany>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCompanies(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCountries(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedICountry>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCountries(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDefaultSecurityGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISecurityGroup>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllDefaultSecurityGroups(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDocumentTemplates(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIDocumentTemplate>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllDocumentTemplates(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDocumentUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIDocumentUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllDocumentUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDocuments(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllDocuments(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFaqCategories(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIFaqCategory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllFaqCategories(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNotifications(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, projectId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllNotifications(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOutboundEmail(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIApiOutboundEmail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllOutboundEmail(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProductPackages(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProductPackage>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProductPackages(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectBidSetups(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIBidSetup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectBidSetups(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectBidSetupsSortedByDueDate(filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIBidSetup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectBidSetupsSortedByDueDate(filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} beforeDate 
         * @param {string} afterDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectCalendarEventsBetweenDatesByProjectId(projectId: string, beforeDate: string, afterDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectCalendarEvent>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectCalendarEventsBetweenDatesByProjectId(projectId, beforeDate, afterDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectCalendarEventsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProjectCalendarEvent>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectCalendarEventsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectNamesBySubscriberId(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectName>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectNamesBySubscriberId(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectSummary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllProjectSummaryType>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectSummary(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjects(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProjectView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjects(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectsSimplified(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedISimplifiedProjectView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllProjectsSimplified(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InboxPermissionLevel} [selectedPermission] 
         * @param {string} [involvesEmail] 
         * @param {string} [fromEmail] 
         * @param {string} [toOrCcEmail] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReceivedEmails(projectId: string, selectedPermission?: InboxPermissionLevel, involvesEmail?: string, fromEmail?: string, toOrCcEmail?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedReceivedEmailsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllReceivedEmails(projectId, selectedPermission, involvesEmail, fromEmail, toOrCcEmail, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSecurityGroups(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedISecurityGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSecurityGroups(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSecurityGroupsBySubscriberId(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISecurityGroup>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSecurityGroupsBySubscriberId(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSecurityGroupsForUsersSubscribersByUserId(userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedISecurityGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSecurityGroupsForUsersSubscribersByUserId(userId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSubmittalCodesByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReducedSubmittalCode>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSubmittalCodesByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSubscribers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedISubscriber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSubscribers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserGroupsByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserGroup>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllUserGroupsByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersSimplifiedBySubscriberId(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserSimplified>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllUsersSimplifiedBySubscriberId(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWebsiteSettings(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIWebsiteSetting>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllWebsiteSettings(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncementById(announcementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAnnouncement>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAnnouncementById(announcementId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociatedDocumentsByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssociatedDocumentsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAssociatedDocumentsByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBIMTopFolders(centerLineProjectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BimTreeNode>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBIMTopFolders(centerLineProjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBIMTreeNode(centerLineProjectId: string, bimTreeNode: BimTreeNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object | Array<BimTreeNode>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBIMTreeNode(centerLineProjectId, bimTreeNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBidById(bidId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBid>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBidById(bidId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BidConnectFileDeliveryUploadRequest} bidConnectFileDeliveryUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBidConnectFileDeliveryUploadLink(bidConnectFileDeliveryUploadRequest: BidConnectFileDeliveryUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBidConnectFileDeliveryUploadLink(bidConnectFileDeliveryUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBidsOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIBid>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBidsOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} [additionalReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBluebeamXml(fileId: string, additionalReviewDocumentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBluebeamXml(fileId, additionalReviewDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentById(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCommentById(commentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsOnDocumentById(documentId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCommentsOnDocumentById(documentId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCommentsOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompany>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompaniesByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICompany>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompanyById(companyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DocumentConformingCenterTemplateType} documentTemplateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConformingCenterDocumentsByProjectId(projectId: string, documentTemplateType: DocumentConformingCenterTemplateType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IConformingCenterCategorizedDocument>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getConformingCenterDocumentsByProjectId(projectId, documentTemplateType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConformingCenterSummaryByProjectId(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IConformingCenterProjectSummary>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getConformingCenterSummaryByProjectId(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountryById(countryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICountry>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCountryById(countryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fullKey 
         * @param {string} ownerUserId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDesignFileActionsByProjectAndFullKey(projectId: string, fullKey: string, ownerUserId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignFileActionResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDesignFileActionsByProjectAndFullKey(projectId, fullKey, ownerUserId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectDesignFileDownloadLink(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3DownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDirectDesignFileDownloadLink(projectId, authenticatedDownloadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentByKey(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentByKeyResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentByKey(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPublicLink(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentPublicLinkResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentPublicLink(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} userId 
         * @param {boolean} [isInteractive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPublicLinkAsUser(documentId: string, userId: string, isInteractive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentPublicLinkResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentPublicLinkAsUser(documentId, userId, isInteractive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DocumentTemplateType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateByType(type: DocumentTemplateType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentTemplate>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentTemplateByType(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentUserPermissionByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDocumentUserPermissionView>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentUserPermissionByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentUserPermissionsForDocumentByKey(key: string, consultantReviewDocumentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDocumentUserPermissionView>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentUserPermissionsForDocumentByKey(key, consultantReviewDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsAssignedByMe(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentsAssignedByMe(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsAssignedToMe(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentsAssignedToMe(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [selectedColumns] 
         * @param {string} [selectedDocumentTypes] 
         * @param {boolean} [includeFiles] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsByProjectId(projectId: string, selectedColumns?: string, selectedDocumentTypes?: string, includeFiles?: boolean, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentsByProjectId(projectId, selectedColumns, selectedDocumentTypes, includeFiles, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsByProjectIdAndDocumentTemplateId(projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentsByProjectIdAndDocumentTemplateId(projectId, documentTemplateId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsSummaryByProjectIdAndDocumentTemplateId(projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDocumentsSummaryByProjectIdAndDocumentTemplateId(projectId, documentTemplateId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} faqCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaqCategoryById(faqCategoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFaqCategory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFaqCategoryById(faqCategoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileById(fileId: string, flattened?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFile>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFileById(fileId, flattened, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {boolean} [fullSize] 
         * @param {string} [filename] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileLinkById(fileId: string, flattened?: boolean, fullSize?: boolean, filename?: string, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3DownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFileLinkById(fileId, flattened, fullSize, filename, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} reviewDocumentId 
         * @param {boolean} [flattened] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileLinkByIdForConsultant(fileId: string, reviewDocumentId: string, flattened?: boolean, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3DownloadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFileLinkByIdForConsultant(fileId, reviewDocumentId, flattened, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectFileUploadRequestParams} projectFileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileUploadLinkByProjectId(projectId: string, projectFileUploadRequestParams: ProjectFileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFileUploadLinkByProjectId(projectId, projectFileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFile>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFilesByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestProjectArchiveZipFileLink(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectZipResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLatestProjectArchiveZipFileLink(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestSubmissionFileByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILatestFileResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLatestSubmissionFileByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestSubmissionFileByKey(key: string, consultantReviewDocumentId?: string, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILatestFileResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLatestSubmissionFileByKey(key, consultantReviewDocumentId, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetManyDocumentLinksRequest} getManyDocumentLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyDocumentPublicLinks(getManyDocumentLinksRequest: GetManyDocumentLinksRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetManyDocumentPublicLinksResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getManyDocumentPublicLinks(getManyDocumentLinksRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ConsultantNotificationRequest} consultantNotificationRequest 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMostRecentConsultantNotificationsByDocumentIds(consultantNotificationRequest: ConsultantNotificationRequest, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<INotification>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMostRecentConsultantNotificationsByDocumentIds(consultantNotificationRequest, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDesignFileSharesByProject(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDesignFileShare>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMyDesignFileSharesByProject(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyFavoriteProjects(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteProjectsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMyFavoriteProjects(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyNotifications(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIUserNotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMyNotifications(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyNotificationsWithEmails(projectId?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserNotificationWithEmail>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMyNotificationsWithEmails(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyNotificationsWithEmailsCount(projectId?: string, filterByObject?: string, curatedSampleData?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMyNotificationsWithEmailsCount(projectId, filterByObject, curatedSampleData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationById(notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationById(notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationRecipientsByIds(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleUserNotificationSendingStatus>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationRecipientsByIds(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByBidId(id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationsByBidId(id, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByBidSetupId(bidSetupId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationsByBidSetupId(bidSetupId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByDocumentId(id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationsByDocumentId(id, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByKey(key: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationsByKey(key, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedINotification>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotificationsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} outboundEmailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundEmailById(outboundEmailId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IApiOutboundEmail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getOutboundEmailById(outboundEmailId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ParserJobStatusRequest} parserJobStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParserJobStatusByObjectKey(parserJobStatusRequest: ParserJobStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJobStatus>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getParserJobStatusByObjectKey(parserJobStatusRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionByKey(key: string, consultantReviewDocumentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDocumentPermissionResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPermissionByKey(key, consultantReviewDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionByUserIdAndDocumentId(userId: string, documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDocumentPermissionResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPermissionByUserIdAndDocumentId(userId, documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicalBuildingsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIPhysicalBuilding>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPhysicalBuildingsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrimeBiddersOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPrimeBiddersOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcoreProjects(centerLineProjectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProcoreProjects(centerLineProjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerlineProjectId 
         * @param {number} procoreDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcoreSubmittal(centerlineProjectId: string, procoreDocumentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProcoreSubmittal(centerlineProjectId, procoreDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPackageById(productPackageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductPackage>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProductPackageById(productPackageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectBidSetupById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBidSetup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectBidSetupById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectBiddingSummaryById(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectBiddingSummaryType>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectBiddingSummaryById(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectById(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectSummaryById(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSummaryType>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectSummaryById(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectUserById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} securityGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectUserSummaryBySecurityGroupId(securityGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISecurityGroupUserSummary>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectUserSummaryBySecurityGroupId(securityGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectUsersByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectUsersByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} numMostRecentProjects 
         * @param {number} numMostRecentComments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsWithMostRecentComments(numMostRecentProjects: number, numMostRecentComments: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectView>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProjectsWithMostRecentComments(numMostRecentProjects, numMostRecentComments, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicDocumentById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPublicDocumentById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} designFileShareId 
         * @param {string} publicAccessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicUserFileSystemFileNode(projectId: string, designFileShareId: string, publicAccessKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileNode>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPublicUserFileSystemFileNode(projectId, designFileShareId, publicAccessKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicUserInfoByEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicUserInfoResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPublicUserInfoByEmail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationUserByKey(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRegistrationUserByKey(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlForEmail(emailId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSignedUrlForEmail(emailId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberAdminsSimplifiedBySubscriberId(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscriberAdminsSimplified>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubscriberAdminsSimplifiedBySubscriberId(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberById(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscriber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubscriberById(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberProjectUserSummaryById(subscriberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscriberProjectUserSummary>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubscriberProjectUserSummaryById(subscriberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetSuggestedProjectEmailSlugRequest} getSuggestedProjectEmailSlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestedProjectEmailSlug(getSuggestedProjectEmailSlugRequest: GetSuggestedProjectEmailSlugRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSuggestedProjectEmailSlug(getSuggestedProjectEmailSlugRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [continuationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrashByProjectId(projectId: string, continuationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTrashItemsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTrashByProjectId(projectId, continuationToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadLinkForAddendasByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUploadLinkForAddendasByBidSetupId(bidSetupId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadLinkForDrawingsByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUploadLinkForDrawingsByBidSetupId(bidSetupId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadLinkForSpecificationsByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUploadLinkForSpecificationsByBidSetupId(bidSetupId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadLinkForSubmittalSectionByDocumentId(documentId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUploadLinkForSubmittalSectionByDocumentId(documentId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFileSystemFileNode(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileNode>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUserFileSystemFileNode(projectId, authenticatedDownloadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPaginatedIUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUsersByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebsiteSettingById(websiteSettingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWebsiteSetting>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getWebsiteSettingById(websiteSettingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZippedBidDocuments(bidSetupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectZipResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getZippedBidDocuments(bidSetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).impersonateUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {BidBIMImportParams} bidBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBimFileByBidSetupId(bidSetupId: string, bidBIMImportParams: BidBIMImportParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).importBimFileByBidSetupId(bidSetupId, bidBIMImportParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectBIMImportParams} projectBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBimFileByProjectId(projectId: string, projectBIMImportParams: ProjectBIMImportParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).importBimFileByProjectId(projectId, projectBIMImportParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAdditionalReviewByDocumentId(documentId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentS3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertAdditionalReviewByDocumentId(documentId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAdditionalReviewByKey(key: string, fileUploadRequestParams: FileUploadRequestParams, consultantReviewDocumentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentS3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertAdditionalReviewByKey(key, fileUploadRequestParams, consultantReviewDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionAddress} iInsertionAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAddress(iInsertionAddress: IInsertionAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAddress>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertAddress(iInsertionAddress, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionAnnouncement} iInsertionAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAnnouncement(iInsertionAnnouncement: IInsertionAnnouncement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAnnouncement>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertAnnouncement(iInsertionAnnouncement, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {InsertBidProps} insertBidProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertBidByUserAndProject(userId: string, projectId: string, insertBidProps: InsertBidProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBid>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertBidByUserAndProject(userId, projectId, insertBidProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomInsertionBidSetup} iCustomInsertionBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertBidSetupByProjectId(projectId: string, iCustomInsertionBidSetup: ICustomInsertionBidSetup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBidSetupResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertBidSetupByProjectId(projectId, iCustomInsertionBidSetup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertCommentByDocumentId(documentId: string, iInsertionComment: IInsertionComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertCommentByDocumentId(documentId, iInsertionComment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertCommentByProjectId(projectId: string, iInsertionComment: IInsertionComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertCommentByProjectId(projectId, iInsertionComment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionCompany} iInsertionCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertCompany(iInsertionCompany: IInsertionCompany, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICompany>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertCompany(iInsertionCompany, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Adds a consultant to a document.
         * @param {string} documentId 
         * @param {AdditionalConsultantProps} additionalConsultantProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertConsultantByDocumentId(documentId: string, additionalConsultantProps: AdditionalConsultantProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalConsultantResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertConsultantByDocumentId(documentId, additionalConsultantProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} parentDocumentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertConsultantConfidentialCommentByDocumentId(parentDocumentId: string, iInsertionComment: IInsertionComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IComment>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertConsultantConfidentialCommentByDocumentId(parentDocumentId, iInsertionComment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InsertDocumentProps} insertDocumentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertDocument(insertDocumentProps: InsertDocumentProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertDocumentResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertDocument(insertDocumentProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionDocumentUser} iInsertionDocumentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertDocumentUser(iInsertionDocumentUser: IInsertionDocumentUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertDocumentUser(iInsertionDocumentUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentFileUploadRequest} documentFileUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertFileByDocumentId(documentId: string, documentFileUploadRequest: DocumentFileUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertFileByDocumentId(documentId, documentFileUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertFloorPlanDrawingFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertFloorPlanDrawingFileByProjectId(projectId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertFloorPlanImageFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertFloorPlanImageFileByProjectId(projectId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertLocationImageFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertLocationImageFileByProjectId(projectId, fileUploadRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InsertManyPhysicalBuildingsRequest} insertManyPhysicalBuildingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertManyPhysicalBuildings(insertManyPhysicalBuildingsRequest: InsertManyPhysicalBuildingsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPhysicalBuilding>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertManyPhysicalBuildings(insertManyPhysicalBuildingsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InsertManyPhysicalLocationsRequest} insertManyPhysicalLocationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertManyPhysicalLocationsByFloorIds(insertManyPhysicalLocationsRequest: InsertManyPhysicalLocationsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPhysicalLocation>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertManyPhysicalLocationsByFloorIds(insertManyPhysicalLocationsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertManyProjectlessUsersParams} iInsertManyProjectlessUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertManyProjectlessUser(iInsertManyProjectlessUsersParams: IInsertManyProjectlessUsersParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertManyProjectlessUser(iInsertManyProjectlessUsersParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPhoneNumberByCompanyId(companyId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhoneNumber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertPhoneNumberByCompanyId(companyId, iInsertionPhoneNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPhoneNumberByUserId(userId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhoneNumber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertPhoneNumberByUserId(userId, iInsertionPhoneNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CustomInsertionPhysicalBuilding} customInsertionPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPhysicalBuilding(customInsertionPhysicalBuilding: CustomInsertionPhysicalBuilding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhysicalBuilding>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertPhysicalBuilding(customInsertionPhysicalBuilding, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionPhysicalFloor} iInsertionPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPhysicalFloor(iInsertionPhysicalFloor: IInsertionPhysicalFloor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPhysicalFloor>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertPhysicalFloor(iInsertionPhysicalFloor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {IInsertionPrimeBidder} iInsertionPrimeBidder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPrimeBidderByUserAndProject(userId: string, projectId: string, iInsertionPrimeBidder: IInsertionPrimeBidder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPrimeBidder>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertPrimeBidderByUserAndProject(userId, projectId, iInsertionPrimeBidder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionProductPackage} iInsertionProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertProductPackage(iInsertionProductPackage: IInsertionProductPackage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductPackage>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertProductPackage(iInsertionProductPackage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ICustomInsertionProject} iCustomInsertionProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertProject(iCustomInsertionProject: ICustomInsertionProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProject>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertProject(iCustomInsertionProject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionProjectCalendarEvent} iInsertionProjectCalendarEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertProjectCalendarEvent(iInsertionProjectCalendarEvent: IInsertionProjectCalendarEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertProjectCalendarEvent(iInsertionProjectCalendarEvent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ICustomInsertionUser} iCustomInsertionUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertProjectlessUser(iCustomInsertionUser: ICustomInsertionUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertProjectlessUser(iCustomInsertionUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IInsertionSubscriberSecurityGroup} iInsertionSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSecurityGroupBySubscriberId(subscriberId: string, iInsertionSubscriberSecurityGroup: IInsertionSubscriberSecurityGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISecurityGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertSecurityGroupBySubscriberId(subscriberId, iInsertionSubscriberSecurityGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSubmissionByDocumentId(documentId: string, submittalUploadRequest: SubmittalUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentS3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertSubmissionByDocumentId(documentId, submittalUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSubmissionByKey(key: string, submittalUploadRequest: SubmittalUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentS3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertSubmissionByKey(key, submittalUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IInsertionSubscriber} iInsertionSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSubscriber(iInsertionSubscriber: IInsertionSubscriber, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscriber>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertSubscriber(iInsertionSubscriber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IAddNewUser} iAddNewUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertUser(iAddNewUser: IAddNewUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertUser(iAddNewUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserGroupInsertionRequest} userGroupInsertionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertUserGroupByProjectId(projectId: string, userGroupInsertionRequest: UserGroupInsertionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserGroup>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).insertUserGroupByProjectId(projectId, userGroupInsertionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserFileSystem(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileNode>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).listUserFileSystem(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserFileSystemCompressed(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).listUserFileSystemCompressed(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsRead(notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).markNotificationAsRead(notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationsAsReadByUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).markNotificationsAsReadByUserId(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {MarkParserFileAsConformedRequest} markParserFileAsConformedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markParserFileAsConformed(fileId: string, markParserFileAsConformedRequest: MarkParserFileAsConformedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).markParserFileAsConformed(fileId, markParserFileAsConformedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDocumentById(documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IModifyDocumentResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).modifyDocumentById(documentId, iModifyDocumentRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDocumentByIdWithResponse(documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IModifyDocumentFullResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).modifyDocumentByIdWithResponse(documentId, iModifyDocumentRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchRequest} iModifyDocumentsByBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDocumentsByBatch(iModifyDocumentsByBatchRequest: IModifyDocumentsByBatchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IModifyManyDocumentsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).modifyDocumentsByBatch(iModifyDocumentsByBatchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchesRequest} iModifyDocumentsByBatchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDocumentsByBatches(iModifyDocumentsByBatchesRequest: IModifyDocumentsByBatchesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IModifyManyDocumentsResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).modifyDocumentsByBatches(iModifyDocumentsByBatchesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ModifyUserFileSystemNodeRequest} modifyUserFileSystemNodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyUserFileSystemNode(projectId: string, modifyUserFileSystemNodeRequest: ModifyUserFileSystemNodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileNode>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).modifyUserFileSystemNode(projectId, modifyUserFileSystemNodeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notReadyForSubmissionByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).notReadyForSubmissionByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {NotifyUserAccessDesignFileRequest} notifyUserAccessDesignFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyUsersOfAccessToDesignFile(projectId: string, notifyUserAccessDesignFileRequest: NotifyUserAccessDesignFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).notifyUsersOfAccessToDesignFile(projectId, notifyUserAccessDesignFileRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permanentlyDeleteProjectById(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).permanentlyDeleteProjectById(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RejectDocumentRequest} rejectDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuseSubmissionByDocumentId(documentId: string, rejectDocumentRequest: RejectDocumentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).refuseSubmissionByDocumentId(documentId, rejectDocumentRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDocumentFromPackageByDocumentId(documentId: string, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).removeDocumentFromPackageByDocumentId(documentId, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePlanHolderFromProjectByUserIdAndProjectId(userId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).removePlanHolderFromProjectByUserIdAndProjectId(userId, projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromProjectByProjectId(projectId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).removeUserFromProjectByProjectId(projectId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceDocumentById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).replaceDocumentById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {BaseFileUploadParams} baseFileUploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceFileByDocumentIdAndFileId(documentId: string, fileId: string, baseFileUploadParams: BaseFileUploadParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).replaceFileByDocumentIdAndFileId(documentId, fileId, baseFileUploadParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceWebsiteSettingById(websiteSettingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).replaceWebsiteSettingById(websiteSettingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).resetUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RespondToReviewProps} respondToReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToReviewByDocumentId(documentId: string, respondToReviewProps: RespondToReviewProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RespondToReviewResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).respondToReviewByDocumentId(documentId, respondToReviewProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreDocumentById(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).restoreDocumentById(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RestoreDocumentsRequest} restoreDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreDocumentsByIds(restoreDocumentsRequest: RestoreDocumentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).restoreDocumentsByIds(restoreDocumentsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retractBidById(bidId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBid>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).retractBidById(bidId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BidConnectFileDeliveryEmailRequest} bidConnectFileDeliveryEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBidConnectFileDeliveryEmail(bidConnectFileDeliveryEmailRequest: BidConnectFileDeliveryEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendBidConnectFileDeliveryEmail(bidConnectFileDeliveryEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicDesignFileDownloadLinkRequest} sendPublicDesignFileDownloadLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDirectDesignFileDownloadEmails(projectId: string, sendPublicDesignFileDownloadLinkRequest: SendPublicDesignFileDownloadLinkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendDirectDesignFileDownloadEmails(projectId, sendPublicDesignFileDownloadLinkRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDocumentPublicLinkEmails(documentId: string, emailRequest: EmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendDocumentPublicLinkEmails(documentId, emailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPrivateInviteEmailRequestParams} sendPrivateInviteEmailRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPrivateProjectInviteEmails(projectId: string, sendPrivateInviteEmailRequestParams: SendPrivateInviteEmailRequestParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendPrivateProjectInviteEmails(projectId, sendPrivateInviteEmailRequestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicLinkToEmailsRequest} sendPublicLinkToEmailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPublicFolderAccessToEmails(projectId: string, sendPublicLinkToEmailsRequest: SendPublicLinkToEmailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendPublicFolderAccessToEmails(projectId, sendPublicLinkToEmailsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<ShareFileSystemObjectRequest>} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareMultipleUserFileSystemObject(projectId: string, shareFileSystemObjectRequest: Array<ShareFileSystemObjectRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileNode>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).shareMultipleUserFileSystemObject(projectId, shareFileSystemObjectRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ShareFileSystemObjectRequest} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareUserFileSystemObject(projectId: string, shareFileSystemObjectRequest: ShareFileSystemObjectRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileNode>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).shareUserFileSystemObject(projectId, shareFileSystemObjectRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipAssignSubcontractorByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).skipAssignSubcontractorByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcontractorRefuseAssignmentByDocumentId(documentId: string, unauthenticatedUserEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).subcontractorRefuseAssignmentByDocumentId(documentId, unauthenticatedUserEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAdditionalReviewByDocumentId(documentId: string, additionalReviewProps: AdditionalReviewProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).submitAdditionalReviewByDocumentId(documentId, additionalReviewProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAdditionalReviewByKey(key: string, additionalReviewProps: AdditionalReviewProps, consultantReviewDocumentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).submitAdditionalReviewByKey(key, additionalReviewProps, consultantReviewDocumentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmitToArchitectProps} submitToArchitectProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitToArchitectByDocumentId(documentId: string, submitToArchitectProps: SubmitToArchitectProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).submitToArchitectByDocumentId(documentId, submitToArchitectProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {ProcoreDocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProcoreDocuments(centerLineProjectId: string, documentType: ProcoreDocumentType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).syncProcoreDocuments(centerLineProjectId, documentType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ToggleRemindersProps} toggleRemindersProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleRemindersByDocumentKey(toggleRemindersProps: ToggleRemindersProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number | IDocumentUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).toggleRemindersByDocumentKey(toggleRemindersProps, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoCompleteReviewByDocumentId(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).undoCompleteReviewByDocumentId(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UnlockProjectsWithKeyParams} unlockProjectsWithKeyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockProjectsWithKeyByUserId(userId: string, unlockProjectsWithKeyParams: UnlockProjectsWithKeyParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).unlockProjectsWithKeyByUserId(userId, unlockProjectsWithKeyParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribePlanholderByProjectIdAndUserId(projectId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectUser>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).unsubscribePlanholderByProjectIdAndUserId(projectId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allows for bluebeam to POST back the BAX file. This should only be used by REVU.
         * @param {string} fileId 
         * @param {string} originalPdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBaxFile(fileId: string, originalPdfId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateBaxFile(fileId, originalPdfId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {UpdateReviewParams} updateReviewParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReviewByDocumentId(documentId: string, updateReviewParams: UpdateReviewParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INumberedDocumentView>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateReviewByDocumentId(documentId, updateReviewParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {S3StagingUploadRequest} s3StagingUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadToStaging(s3StagingUploadRequest: S3StagingUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).uploadToStaging(s3StagingUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawDocumentById(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).withdrawDocumentById(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSubmissionByDocumentId(documentId: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).acceptSubmissionByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AddDocumentToPackageProps} addDocumentToPackageProps 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentToPackageByDocumentId(documentId: string, addDocumentToPackageProps: AddDocumentToPackageProps, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).addDocumentToPackageByDocumentId(documentId, addDocumentToPackageProps, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AddUserParams>} addUserParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrModifyUsersByProjectId(projectId: string, addUserParams: Array<AddUserParams>, options?: any): AxiosPromise<IProjectView> {
            return DefaultApiFp(configuration).addOrModifyUsersByProjectId(projectId, addUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssignByDomainRequest} assignByDomainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUsersToCompanyByEmailDomain(assignByDomainRequest: AssignByDomainRequest, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).assignUsersToCompanyByEmailDomain(assignByDomainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeBIM360(centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).authorizeBIM360(centerLineProjectId, iAuthorizeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {IAuthorizeParams} iAuthorizeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeProcore(centerLineProjectId: string, iAuthorizeParams: IAuthorizeParams, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).authorizeProcore(centerLineProjectId, iAuthorizeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DrawingsParserRequest} drawingsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginDrawingsParser(drawingsParserRequest: DrawingsParserRequest, options?: any): AxiosPromise<IDrawingsParserJobStatus> {
            return DefaultApiFp(configuration).beginDrawingsParser(drawingsParserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DrawingsPreprocessorRequest} drawingsPreprocessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginDrawingsPreprocessor(drawingsPreprocessorRequest: DrawingsPreprocessorRequest, options?: any): AxiosPromise<IDrawingsParserJobStatus> {
            return DefaultApiFp(configuration).beginDrawingsPreprocessor(drawingsPreprocessorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpecificationsParserRequest} specificationsParserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginSpecificationsParser(specificationsParserRequest: SpecificationsParserRequest, options?: any): AxiosPromise<IJobStatus> {
            return DefaultApiFp(configuration).beginSpecificationsParser(specificationsParserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteMultiPartUploadRequest} completeMultiPartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartUpload(completeMultiPartUploadRequest: CompleteMultiPartUploadRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).completeMultipartUpload(completeMultiPartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalReviewProps} submittalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeReviewByDocumentId(documentId: string, submittalReviewProps: SubmittalReviewProps, options?: any): AxiosPromise<CompleteReviewResponse> {
            return DefaultApiFp(configuration).completeReviewByDocumentId(documentId, submittalReviewProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ErrorEmail} errorEmail 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createErrorEmail(errorEmail: ErrorEmail, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createErrorEmail(errorEmail, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {CreateLinkedDocumentsForAssignedUsersRequest} createLinkedDocumentsForAssignedUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedDocumentsForAssignedUsers(documentId: string, createLinkedDocumentsForAssignedUsersRequest: CreateLinkedDocumentsForAssignedUsersRequest, options?: any): AxiosPromise<Array<INumberedDocumentView>> {
            return DefaultApiFp(configuration).createLinkedDocumentsForAssignedUsers(documentId, createLinkedDocumentsForAssignedUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedOtherFileSystemRequest} authenticatedOtherFileSystemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFileSystemFolder(projectId: string, authenticatedOtherFileSystemRequest: AuthenticatedOtherFileSystemRequest, options?: any): AxiosPromise<CreateFolderResponse> {
            return DefaultApiFp(configuration).createUserFileSystemFolder(projectId, authenticatedOtherFileSystemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllSecurityGroupsBySubscriberId(subscriberId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteAllSecurityGroupsBySubscriberId(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncementById(announcementId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteAnnouncementById(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBidSetupByProjectId(projectId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteBidSetupByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentById(documentId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteDocumentById(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteDocumentsRequest} deleteDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentsByIds(deleteDocumentsRequest: DeleteDocumentsRequest, options?: any): AxiosPromise<Array<number>> {
            return DefaultApiFp(configuration).deleteDocumentsByIds(deleteDocumentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByBidSetupIdAndFileId(bidSetupId: string, fileId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteFileByBidSetupIdAndFileId(bidSetupId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInviteEmailByProjectIdAndUserId(projectId: string, userId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteInviteEmailByProjectIdAndUserId(projectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IDeleteUsersByEmailParams} iDeleteUsersByEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsersByEmail(iDeleteUsersByEmailParams: IDeleteUsersByEmailParams, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteManyUsersByEmail(iDeleteUsersByEmailParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParsedFileByProjectIdAndFileId(projectId: string, fileId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteParsedFileByProjectIdAndFileId(projectId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteParsedFilesRequest} deleteParsedFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParsedFilesByProjectId(projectId: string, deleteParsedFilesRequest: DeleteParsedFilesRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteParsedFilesByProjectId(projectId, deleteParsedFilesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalBuildingById(buildingId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deletePhysicalBuildingById(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalFloorById(floorId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deletePhysicalFloorById(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhysicalLocationById(locationId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deletePhysicalLocationById(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrimeBidderByUserAndProject(userId: string, projectId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deletePrimeBidderByUserAndProject(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductPackageById(productPackageId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteProductPackageById(productPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [newSecurityGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurityGroupById(id: string, newSecurityGroupId?: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteSecurityGroupById(id, newSecurityGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriberById(subscriberId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteSubscriberById(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTrashItemsRequest} deleteTrashItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrashItemsByProjectId(projectId: string, deleteTrashItemsRequest: DeleteTrashItemsRequest, options?: any): AxiosPromise<DeleteTrashItemsResponse> {
            return DefaultApiFp(configuration).deleteTrashItemsByProjectId(projectId, deleteTrashItemsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(userId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserFileSystemNodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFileSystemNode(projectId: string, body: UserFileSystemNodeRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteUserFileSystemNode(projectId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupById(userGroupId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).deleteUserGroupById(userGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserNotificationsParams} deleteUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserNotificationsByIds(deleteUserNotificationsParams: DeleteUserNotificationsParams, options?: any): AxiosPromise<Array<number>> {
            return DefaultApiFp(configuration).deleteUserNotificationsByIds(deleteUserNotificationsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableRegistrationReminderByUserId(userId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).disableRegistrationReminderByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTrashItemRequest} getTrashItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTrashItemByProjectId(getTrashItemRequest: GetTrashItemRequest, options?: any): AxiosPromise<S3DownloadResponse> {
            return DefaultApiFp(configuration).downloadTrashItemByProjectId(getTrashItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {IOptionalAnnouncement} iOptionalAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAnnouncementById(announcementId: string, iOptionalAnnouncement: IOptionalAnnouncement, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).editAnnouncementById(announcementId, iOptionalAnnouncement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AnticipatedSubmissionDateChangeRequest} anticipatedSubmissionDateChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAnticipatedSubmissionDateOfDocumentById(documentId: string, anticipatedSubmissionDateChangeRequest: AnticipatedSubmissionDateChangeRequest, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).editAnticipatedSubmissionDateOfDocumentById(documentId, anticipatedSubmissionDateChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomOptionalBidSetup} iCustomOptionalBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBidSetupByProjectId(projectId: string, iCustomOptionalBidSetup: ICustomOptionalBidSetup, options?: any): AxiosPromise<EditBidSetupResponse> {
            return DefaultApiFp(configuration).editBidSetupByProjectId(projectId, iCustomOptionalBidSetup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IOptionalDocument} iOptionalDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentById(documentId: string, iOptionalDocument: IOptionalDocument, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).editDocumentById(documentId, iOptionalDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {IEditDocumentSettings} iEditDocumentSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentSettingsByUserIdAndDocumentId(userId: string, documentId: string, iEditDocumentSettings: IEditDocumentSettings, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).editDocumentSettingsByUserIdAndDocumentId(userId, documentId, iEditDocumentSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {EditFavoriteProjectParams} editFavoriteProjectParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFavoriteProjectByUserIdAndProjectId(userId: string, projectId: string, editFavoriteProjectParams: EditFavoriteProjectParams, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).editFavoriteProjectByUserIdAndProjectId(userId, projectId, editFavoriteProjectParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {EditFileParams} editFileParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFileByDocumentIdAndFileId(documentId: string, fileId: string, editFileParams: EditFileParams, options?: any): AxiosPromise<IFile> {
            return DefaultApiFp(configuration).editFileByDocumentIdAndFileId(documentId, fileId, editFileParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {IOptionalFile} iOptionalFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFileById(fileId: string, iOptionalFile: IOptionalFile, options?: any): AxiosPromise<IFile> {
            return DefaultApiFp(configuration).editFileById(fileId, iOptionalFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditManyPhysicalFloorsRequest} editManyPhysicalFloorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editManyPhysicalFloorsByIds(editManyPhysicalFloorsRequest: EditManyPhysicalFloorsRequest, options?: any): AxiosPromise<Array<IPhysicalFloor>> {
            return DefaultApiFp(configuration).editManyPhysicalFloorsByIds(editManyPhysicalFloorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} buildingId 
         * @param {CustomOptionalPhysicalBuilding} customOptionalPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalBuildingById(buildingId: string, customOptionalPhysicalBuilding: CustomOptionalPhysicalBuilding, options?: any): AxiosPromise<IPhysicalBuilding> {
            return DefaultApiFp(configuration).editPhysicalBuildingById(buildingId, customOptionalPhysicalBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} floorId 
         * @param {IOptionalPhysicalFloor} iOptionalPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalFloorById(floorId: string, iOptionalPhysicalFloor: IOptionalPhysicalFloor, options?: any): AxiosPromise<IPhysicalFloor> {
            return DefaultApiFp(configuration).editPhysicalFloorById(floorId, iOptionalPhysicalFloor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {IOptionalPhysicalLocation} iOptionalPhysicalLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhysicalLocationById(locationId: string, iOptionalPhysicalLocation: IOptionalPhysicalLocation, options?: any): AxiosPromise<IPhysicalLocation> {
            return DefaultApiFp(configuration).editPhysicalLocationById(locationId, iOptionalPhysicalLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {IOptionalProductPackage} iOptionalProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductPackageById(productPackageId: string, iOptionalProductPackage: IOptionalProductPackage, options?: any): AxiosPromise<IProductPackage> {
            return DefaultApiFp(configuration).editProductPackageById(productPackageId, iOptionalProductPackage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IOptionalProject} iOptionalProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProjectById(projectId: string, iOptionalProject: IOptionalProject, options?: any): AxiosPromise<IProject> {
            return DefaultApiFp(configuration).editProjectById(projectId, iOptionalProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {IOptionalProjectUser} iOptionalProjectUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProjectUserById(id: string, iOptionalProjectUser: IOptionalProjectUser, options?: any): AxiosPromise<IProjectUser> {
            return DefaultApiFp(configuration).editProjectUserById(id, iOptionalProjectUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {string} securityGroupId 
         * @param {IOptionalSubscriberSecurityGroup} iOptionalSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSecurityGroupBySubscriberId(subscriberId: string, securityGroupId: string, iOptionalSubscriberSecurityGroup: IOptionalSubscriberSecurityGroup, options?: any): AxiosPromise<ISecurityGroup> {
            return DefaultApiFp(configuration).editSecurityGroupBySubscriberId(subscriberId, securityGroupId, iOptionalSubscriberSecurityGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubcontractorChangeRequest} subcontractorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubcontractorOfDocumentById(documentId: string, subcontractorChangeRequest: SubcontractorChangeRequest, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).editSubcontractorOfDocumentById(documentId, subcontractorChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IOptionalSubscriber} iOptionalSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubscriberById(subscriberId: string, iOptionalSubscriber: IOptionalSubscriber, options?: any): AxiosPromise<ISubscriber> {
            return DefaultApiFp(configuration).editSubscriberById(subscriberId, iOptionalSubscriber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ICustomOptionalUser} iCustomOptionalUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserById(userId: string, iCustomOptionalUser: ICustomOptionalUser, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).editUserById(userId, iCustomOptionalUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userGroupId 
         * @param {UserGroupEdit} userGroupEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserGroupById(userGroupId: string, userGroupEdit: UserGroupEdit, options?: any): AxiosPromise<IUserGroup> {
            return DefaultApiFp(configuration).editUserGroupById(userGroupId, userGroupEdit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {EditUserNotificationsParams} editUserNotificationsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserNotificationsByIds(userId: string, editUserNotificationsParams: EditUserNotificationsParams, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).editUserNotificationsByIds(userId, editUserNotificationsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptyTrashByProjectId(projectId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).emptyTrashByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flattenBlueBeamByDocumentId(documentId: string, submittalFlattenRequest: SubmittalFlattenRequest, options?: any): AxiosPromise<DocumentFlattenResponse> {
            return DefaultApiFp(configuration).flattenBlueBeamByDocumentId(documentId, submittalFlattenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalFlattenRequest} submittalFlattenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flattenBlueBeamByKey(key: string, submittalFlattenRequest: SubmittalFlattenRequest, options?: any): AxiosPromise<DocumentFlattenResponse> {
            return DefaultApiFp(configuration).flattenBlueBeamByKey(key, submittalFlattenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {GenerateAsBuiltDocumentParams} generateAsBuiltDocumentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsBuiltDocumentFromDrawingsDocumentId(documentId: string, generateAsBuiltDocumentParams: GenerateAsBuiltDocumentParams, options?: any): AxiosPromise<InsertDocumentResponse> {
            return DefaultApiFp(configuration).generateAsBuiltDocumentFromDrawingsDocumentId(documentId, generateAsBuiltDocumentParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GenerateAsBuiltDocumentsRequest} generateAsBuiltDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsBuiltDocumentsByProjectId(projectId: string, generateAsBuiltDocumentsRequest: GenerateAsBuiltDocumentsRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).generateAsBuiltDocumentsByProjectId(projectId, generateAsBuiltDocumentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBimFileDownloadLink(centerLineProjectId: string, bimTreeNode: BimTreeNode, options?: any): AxiosPromise<S3DownloadResponse> {
            return DefaultApiFp(configuration).generateBimFileDownloadLink(centerLineProjectId, bimTreeNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<GeneratePublicUserFileSystemLinkRequest>} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultiplePublicUserFileSystemFileDownloadLink(projectId: string, generatePublicUserFileSystemLinkRequest: Array<GeneratePublicUserFileSystemLinkRequest>, options?: any): AxiosPromise<Array<DesignFileDownloadResponse>> {
            return DefaultApiFp(configuration).generateMultiplePublicUserFileSystemFileDownloadLink(projectId, generatePublicUserFileSystemLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedDownloadRequest>} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleUserFileSystemFileDownloadLink(projectId: string, authenticatedDownloadRequest: Array<AuthenticatedDownloadRequest>, options?: any): AxiosPromise<Array<DesignFileDownloadResponse>> {
            return DefaultApiFp(configuration).generateMultipleUserFileSystemFileDownloadLink(projectId, authenticatedDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<AuthenticatedUploadRequest>} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleUserFileSystemUploadLink(projectId: string, authenticatedUploadRequest: Array<AuthenticatedUploadRequest>, options?: any): AxiosPromise<Array<DesignFileUploadResponse>> {
            return DefaultApiFp(configuration).generateMultipleUserFileSystemUploadLink(projectId, authenticatedUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IGeneratePlaceholdersRequest} iGeneratePlaceholdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePlaceholdersForDocuments(iGeneratePlaceholdersRequest: IGeneratePlaceholdersRequest, options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).generatePlaceholdersForDocuments(iGeneratePlaceholdersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PresignedUrlPartsRequest} presignedUrlPartsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedUrlsParts(presignedUrlPartsRequest: PresignedUrlPartsRequest, options?: any): AxiosPromise<Array<PresignedUrlPartResponse>> {
            return DefaultApiFp(configuration).generatePresignedUrlsParts(presignedUrlPartsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {GeneratePublicUserFileSystemLinkRequest} generatePublicUserFileSystemLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePublicUserFileSystemFileDownloadLink(projectId: string, generatePublicUserFileSystemLinkRequest: GeneratePublicUserFileSystemLinkRequest, options?: any): AxiosPromise<DesignFileDownloadResponse> {
            return DefaultApiFp(configuration).generatePublicUserFileSystemFileDownloadLink(projectId, generatePublicUserFileSystemLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateUserFileSystemFileDownloadLink(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): AxiosPromise<DesignFileDownloadResponse> {
            return DefaultApiFp(configuration).generateUserFileSystemFileDownloadLink(projectId, authenticatedDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedUploadRequest} authenticatedUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateUserFileSystemUploadLink(projectId: string, authenticatedUploadRequest: AuthenticatedUploadRequest, options?: any): AxiosPromise<DesignFileUploadResponse> {
            return DefaultApiFp(configuration).generateUserFileSystemUploadLink(projectId, authenticatedUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIUser> {
            return DefaultApiFp(configuration).getAllAdminUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnouncements(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIAnnouncement> {
            return DefaultApiFp(configuration).getAllAnnouncements(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssignedDocumentsByUserId(userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getAllAssignedDocumentsByUserId(userId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedICompany> {
            return DefaultApiFp(configuration).getAllCompanies(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedICountry> {
            return DefaultApiFp(configuration).getAllCountries(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDefaultSecurityGroups(options?: any): AxiosPromise<Array<ISecurityGroup>> {
            return DefaultApiFp(configuration).getAllDefaultSecurityGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentTemplates(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIDocumentTemplate> {
            return DefaultApiFp(configuration).getAllDocumentTemplates(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIDocumentUser> {
            return DefaultApiFp(configuration).getAllDocumentUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocuments(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getAllDocuments(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFaqCategories(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIFaqCategory> {
            return DefaultApiFp(configuration).getAllFaqCategories(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotifications(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, projectId?: string, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getAllNotifications(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOutboundEmail(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIApiOutboundEmail> {
            return DefaultApiFp(configuration).getAllOutboundEmail(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductPackages(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProductPackage> {
            return DefaultApiFp(configuration).getAllProductPackages(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectBidSetups(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIBidSetup> {
            return DefaultApiFp(configuration).getAllProjectBidSetups(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectBidSetupsSortedByDueDate(filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIBidSetup> {
            return DefaultApiFp(configuration).getAllProjectBidSetupsSortedByDueDate(filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} beforeDate 
         * @param {string} afterDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectCalendarEventsBetweenDatesByProjectId(projectId: string, beforeDate: string, afterDate: string, options?: any): AxiosPromise<Array<IProjectCalendarEvent>> {
            return DefaultApiFp(configuration).getAllProjectCalendarEventsBetweenDatesByProjectId(projectId, beforeDate, afterDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectCalendarEventsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProjectCalendarEvent> {
            return DefaultApiFp(configuration).getAllProjectCalendarEventsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectNamesBySubscriberId(subscriberId: string, options?: any): AxiosPromise<Array<IProjectName>> {
            return DefaultApiFp(configuration).getAllProjectNamesBySubscriberId(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSummary(options?: any): AxiosPromise<AllProjectSummaryType> {
            return DefaultApiFp(configuration).getAllProjectSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProjectUser> {
            return DefaultApiFp(configuration).getAllProjectUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProjectView> {
            return DefaultApiFp(configuration).getAllProjects(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsSimplified(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedISimplifiedProjectView> {
            return DefaultApiFp(configuration).getAllProjectsSimplified(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InboxPermissionLevel} [selectedPermission] 
         * @param {string} [involvesEmail] 
         * @param {string} [fromEmail] 
         * @param {string} [toOrCcEmail] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReceivedEmails(projectId: string, selectedPermission?: InboxPermissionLevel, involvesEmail?: string, fromEmail?: string, toOrCcEmail?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedReceivedEmailsResponse> {
            return DefaultApiFp(configuration).getAllReceivedEmails(projectId, selectedPermission, involvesEmail, fromEmail, toOrCcEmail, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroups(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedISecurityGroup> {
            return DefaultApiFp(configuration).getAllSecurityGroups(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroupsBySubscriberId(subscriberId: string, options?: any): AxiosPromise<Array<ISecurityGroup>> {
            return DefaultApiFp(configuration).getAllSecurityGroupsBySubscriberId(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSecurityGroupsForUsersSubscribersByUserId(userId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedISecurityGroup> {
            return DefaultApiFp(configuration).getAllSecurityGroupsForUsersSubscribersByUserId(userId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubmittalCodesByProjectId(projectId: string, options?: any): AxiosPromise<Array<ReducedSubmittalCode>> {
            return DefaultApiFp(configuration).getAllSubmittalCodesByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscribers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedISubscriber> {
            return DefaultApiFp(configuration).getAllSubscribers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserGroupsByProjectId(projectId: string, options?: any): AxiosPromise<Array<IUserGroup>> {
            return DefaultApiFp(configuration).getAllUserGroupsByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIUser> {
            return DefaultApiFp(configuration).getAllUsers(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersSimplifiedBySubscriberId(subscriberId: string, options?: any): AxiosPromise<Array<IUserSimplified>> {
            return DefaultApiFp(configuration).getAllUsersSimplifiedBySubscriberId(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebsiteSettings(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIWebsiteSetting> {
            return DefaultApiFp(configuration).getAllWebsiteSettings(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementById(announcementId: string, options?: any): AxiosPromise<IAnnouncement> {
            return DefaultApiFp(configuration).getAnnouncementById(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedDocumentsByDocumentId(documentId: string, options?: any): AxiosPromise<GetAssociatedDocumentsResponse> {
            return DefaultApiFp(configuration).getAssociatedDocumentsByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBIMTopFolders(centerLineProjectId: string, options?: any): AxiosPromise<Array<BimTreeNode>> {
            return DefaultApiFp(configuration).getBIMTopFolders(centerLineProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {BimTreeNode} bimTreeNode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBIMTreeNode(centerLineProjectId: string, bimTreeNode: BimTreeNode, options?: any): AxiosPromise<object | Array<BimTreeNode>> {
            return DefaultApiFp(configuration).getBIMTreeNode(centerLineProjectId, bimTreeNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidById(bidId: string, options?: any): AxiosPromise<IBid> {
            return DefaultApiFp(configuration).getBidById(bidId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BidConnectFileDeliveryUploadRequest} bidConnectFileDeliveryUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidConnectFileDeliveryUploadLink(bidConnectFileDeliveryUploadRequest: BidConnectFileDeliveryUploadRequest, options?: any): AxiosPromise<InlineResponse2002> {
            return DefaultApiFp(configuration).getBidConnectFileDeliveryUploadLink(bidConnectFileDeliveryUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBidsOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIBid> {
            return DefaultApiFp(configuration).getBidsOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} [additionalReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBluebeamXml(fileId: string, additionalReviewDocumentId?: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).getBluebeamXml(fileId, additionalReviewDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById(commentId: string, options?: any): AxiosPromise<IComment> {
            return DefaultApiFp(configuration).getCommentById(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsOnDocumentById(documentId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIComment> {
            return DefaultApiFp(configuration).getCommentsOnDocumentById(documentId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIComment> {
            return DefaultApiFp(configuration).getCommentsOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesByProjectId(projectId: string, options?: any): AxiosPromise<Array<ICompany>> {
            return DefaultApiFp(configuration).getCompaniesByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId: string, options?: any): AxiosPromise<ICompany> {
            return DefaultApiFp(configuration).getCompanyById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DocumentConformingCenterTemplateType} documentTemplateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConformingCenterDocumentsByProjectId(projectId: string, documentTemplateType: DocumentConformingCenterTemplateType, options?: any): AxiosPromise<Array<IConformingCenterCategorizedDocument>> {
            return DefaultApiFp(configuration).getConformingCenterDocumentsByProjectId(projectId, documentTemplateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConformingCenterSummaryByProjectId(projectId: string, options?: any): AxiosPromise<IConformingCenterProjectSummary> {
            return DefaultApiFp(configuration).getConformingCenterSummaryByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryById(countryId: string, options?: any): AxiosPromise<ICountry> {
            return DefaultApiFp(configuration).getCountryById(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<InlineResponse200> {
            return DefaultApiFp(configuration).getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} fullKey 
         * @param {string} ownerUserId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesignFileActionsByProjectAndFullKey(projectId: string, fullKey: string, ownerUserId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<DesignFileActionResponse> {
            return DefaultApiFp(configuration).getDesignFileActionsByProjectAndFullKey(projectId, fullKey, ownerUserId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDesignFileDownloadLink(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): AxiosPromise<S3DownloadResponse> {
            return DefaultApiFp(configuration).getDirectDesignFileDownloadLink(projectId, authenticatedDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById(id: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByKey(key: string, options?: any): AxiosPromise<GetDocumentByKeyResponse> {
            return DefaultApiFp(configuration).getDocumentByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublicLink(documentId: string, options?: any): AxiosPromise<GetDocumentPublicLinkResponse> {
            return DefaultApiFp(configuration).getDocumentPublicLink(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} userId 
         * @param {boolean} [isInteractive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublicLinkAsUser(documentId: string, userId: string, isInteractive?: boolean, options?: any): AxiosPromise<GetDocumentPublicLinkResponse> {
            return DefaultApiFp(configuration).getDocumentPublicLinkAsUser(documentId, userId, isInteractive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentTemplateType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateByType(type: DocumentTemplateType, options?: any): AxiosPromise<IDocumentTemplate> {
            return DefaultApiFp(configuration).getDocumentTemplateByType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUserPermissionByDocumentId(documentId: string, options?: any): AxiosPromise<Array<IDocumentUserPermissionView>> {
            return DefaultApiFp(configuration).getDocumentUserPermissionByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUserPermissionsForDocumentByKey(key: string, consultantReviewDocumentId?: string, options?: any): AxiosPromise<Array<IDocumentUserPermissionView>> {
            return DefaultApiFp(configuration).getDocumentUserPermissionsForDocumentByKey(key, consultantReviewDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsAssignedByMe(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentsAssignedByMe(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsAssignedToMe(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentsAssignedToMe(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [selectedColumns] 
         * @param {string} [selectedDocumentTypes] 
         * @param {boolean} [includeFiles] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByProjectId(projectId: string, selectedColumns?: string, selectedDocumentTypes?: string, includeFiles?: boolean, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentsByProjectId(projectId, selectedColumns, selectedDocumentTypes, includeFiles, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByProjectIdAndDocumentTemplateId(projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentsByProjectIdAndDocumentTemplateId(projectId, documentTemplateId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} documentTemplateId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsSummaryByProjectIdAndDocumentTemplateId(projectId: string, documentTemplateId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINumberedDocumentView> {
            return DefaultApiFp(configuration).getDocumentsSummaryByProjectIdAndDocumentTemplateId(projectId, documentTemplateId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} faqCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqCategoryById(faqCategoryId: string, options?: any): AxiosPromise<IFaqCategory> {
            return DefaultApiFp(configuration).getFaqCategoryById(faqCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileById(fileId: string, flattened?: boolean, options?: any): AxiosPromise<IFile> {
            return DefaultApiFp(configuration).getFileById(fileId, flattened, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {boolean} [flattened] 
         * @param {boolean} [fullSize] 
         * @param {string} [filename] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLinkById(fileId: string, flattened?: boolean, fullSize?: boolean, filename?: string, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<S3DownloadResponse> {
            return DefaultApiFp(configuration).getFileLinkById(fileId, flattened, fullSize, filename, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {string} reviewDocumentId 
         * @param {boolean} [flattened] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLinkByIdForConsultant(fileId: string, reviewDocumentId: string, flattened?: boolean, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<S3DownloadResponse> {
            return DefaultApiFp(configuration).getFileLinkByIdForConsultant(fileId, reviewDocumentId, flattened, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectFileUploadRequestParams} projectFileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUploadLinkByProjectId(projectId: string, projectFileUploadRequestParams: ProjectFileUploadRequestParams, options?: any): AxiosPromise<InlineResponse2003> {
            return DefaultApiFp(configuration).getFileUploadLinkByProjectId(projectId, projectFileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByDocumentId(documentId: string, options?: any): AxiosPromise<Array<IFile>> {
            return DefaultApiFp(configuration).getFilesByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestProjectArchiveZipFileLink(projectId: string, options?: any): AxiosPromise<GetProjectZipResponse> {
            return DefaultApiFp(configuration).getLatestProjectArchiveZipFileLink(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSubmissionFileByDocumentId(documentId: string, options?: any): AxiosPromise<ILatestFileResponse> {
            return DefaultApiFp(configuration).getLatestSubmissionFileByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSubmissionFileByKey(key: string, consultantReviewDocumentId?: string, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<ILatestFileResponse> {
            return DefaultApiFp(configuration).getLatestSubmissionFileByKey(key, consultantReviewDocumentId, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetManyDocumentLinksRequest} getManyDocumentLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyDocumentPublicLinks(getManyDocumentLinksRequest: GetManyDocumentLinksRequest, options?: any): AxiosPromise<Array<GetManyDocumentPublicLinksResponse>> {
            return DefaultApiFp(configuration).getManyDocumentPublicLinks(getManyDocumentLinksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConsultantNotificationRequest} consultantNotificationRequest 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostRecentConsultantNotificationsByDocumentIds(consultantNotificationRequest: ConsultantNotificationRequest, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<Array<INotification>> {
            return DefaultApiFp(configuration).getMostRecentConsultantNotificationsByDocumentIds(consultantNotificationRequest, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDesignFileSharesByProject(projectId: string, options?: any): AxiosPromise<Array<IDesignFileShare>> {
            return DefaultApiFp(configuration).getMyDesignFileSharesByProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyFavoriteProjects(options?: any): AxiosPromise<FavoriteProjectsResponse> {
            return DefaultApiFp(configuration).getMyFavoriteProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotifications(orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIUserNotification> {
            return DefaultApiFp(configuration).getMyNotifications(orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotificationsWithEmails(projectId?: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<UserNotificationWithEmail>> {
            return DefaultApiFp(configuration).getMyNotificationsWithEmails(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotificationsWithEmailsCount(projectId?: string, filterByObject?: string, curatedSampleData?: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).getMyNotificationsWithEmailsCount(projectId, filterByObject, curatedSampleData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationById(notificationId: string, options?: any): AxiosPromise<INotification> {
            return DefaultApiFp(configuration).getNotificationById(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationRecipientsByIds(requestBody: Array<string>, options?: any): AxiosPromise<Array<SimpleUserNotificationSendingStatus>> {
            return DefaultApiFp(configuration).getNotificationRecipientsByIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByBidId(id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getNotificationsByBidId(id, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByBidSetupId(bidSetupId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getNotificationsByBidSetupId(bidSetupId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByDocumentId(id: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getNotificationsByDocumentId(id, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByKey(key: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getNotificationsByKey(key, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedINotification> {
            return DefaultApiFp(configuration).getNotificationsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} outboundEmailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundEmailById(outboundEmailId: string, options?: any): AxiosPromise<IApiOutboundEmail> {
            return DefaultApiFp(configuration).getOutboundEmailById(outboundEmailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParserJobStatusRequest} parserJobStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserJobStatusByObjectKey(parserJobStatusRequest: ParserJobStatusRequest, options?: any): AxiosPromise<IJobStatus> {
            return DefaultApiFp(configuration).getParserJobStatusByObjectKey(parserJobStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionByKey(key: string, consultantReviewDocumentId?: string, options?: any): AxiosPromise<GetUserDocumentPermissionResponse> {
            return DefaultApiFp(configuration).getPermissionByKey(key, consultantReviewDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionByUserIdAndDocumentId(userId: string, documentId: string, options?: any): AxiosPromise<GetUserDocumentPermissionResponse> {
            return DefaultApiFp(configuration).getPermissionByUserIdAndDocumentId(userId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalBuildingsByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIPhysicalBuilding> {
            return DefaultApiFp(configuration).getPhysicalBuildingsByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimeBiddersOnProjectById(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProjectUser> {
            return DefaultApiFp(configuration).getPrimeBiddersOnProjectById(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcoreProjects(centerLineProjectId: string, options?: any): AxiosPromise<Array<ProjectResponse>> {
            return DefaultApiFp(configuration).getProcoreProjects(centerLineProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerlineProjectId 
         * @param {number} procoreDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcoreSubmittal(centerlineProjectId: string, procoreDocumentId: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getProcoreSubmittal(centerlineProjectId, procoreDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPackageById(productPackageId: string, options?: any): AxiosPromise<IProductPackage> {
            return DefaultApiFp(configuration).getProductPackageById(productPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBidSetupById(id: string, options?: any): AxiosPromise<IBidSetup> {
            return DefaultApiFp(configuration).getProjectBidSetupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBiddingSummaryById(projectId: string, options?: any): AxiosPromise<ProjectBiddingSummaryType> {
            return DefaultApiFp(configuration).getProjectBiddingSummaryById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(projectId: string, options?: any): AxiosPromise<IProjectView> {
            return DefaultApiFp(configuration).getProjectById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSummaryById(projectId: string, options?: any): AxiosPromise<ProjectSummaryType> {
            return DefaultApiFp(configuration).getProjectSummaryById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUserById(id: string, options?: any): AxiosPromise<IProjectUser> {
            return DefaultApiFp(configuration).getProjectUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} securityGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUserSummaryBySecurityGroupId(securityGroupId: string, options?: any): AxiosPromise<ISecurityGroupUserSummary> {
            return DefaultApiFp(configuration).getProjectUserSummaryBySecurityGroupId(securityGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUsersByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIProjectUser> {
            return DefaultApiFp(configuration).getProjectUsersByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} numMostRecentProjects 
         * @param {number} numMostRecentComments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsWithMostRecentComments(numMostRecentProjects: number, numMostRecentComments: number, options?: any): AxiosPromise<Array<IProjectView>> {
            return DefaultApiFp(configuration).getProjectsWithMostRecentComments(numMostRecentProjects, numMostRecentComments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDocumentById(id: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).getPublicDocumentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} designFileShareId 
         * @param {string} publicAccessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserFileSystemFileNode(projectId: string, designFileShareId: string, publicAccessKey: string, options?: any): AxiosPromise<FileNode> {
            return DefaultApiFp(configuration).getPublicUserFileSystemFileNode(projectId, designFileShareId, publicAccessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserInfoByEmail(email: string, options?: any): AxiosPromise<PublicUserInfoResponse> {
            return DefaultApiFp(configuration).getPublicUserInfoByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationUserByKey(key: string, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).getRegistrationUserByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForEmail(emailId: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).getSignedUrlForEmail(emailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberAdminsSimplifiedBySubscriberId(subscriberId: string, options?: any): AxiosPromise<ISubscriberAdminsSimplified> {
            return DefaultApiFp(configuration).getSubscriberAdminsSimplifiedBySubscriberId(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberById(subscriberId: string, options?: any): AxiosPromise<ISubscriber> {
            return DefaultApiFp(configuration).getSubscriberById(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberProjectUserSummaryById(subscriberId: string, options?: any): AxiosPromise<ISubscriberProjectUserSummary> {
            return DefaultApiFp(configuration).getSubscriberProjectUserSummaryById(subscriberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetSuggestedProjectEmailSlugRequest} getSuggestedProjectEmailSlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedProjectEmailSlug(getSuggestedProjectEmailSlugRequest: GetSuggestedProjectEmailSlugRequest, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).getSuggestedProjectEmailSlug(getSuggestedProjectEmailSlugRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [continuationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrashByProjectId(projectId: string, continuationToken?: string, options?: any): AxiosPromise<GetTrashItemsResponse> {
            return DefaultApiFp(configuration).getTrashByProjectId(projectId, continuationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForAddendasByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).getUploadLinkForAddendasByBidSetupId(bidSetupId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForDrawingsByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<InlineResponse2001> {
            return DefaultApiFp(configuration).getUploadLinkForDrawingsByBidSetupId(bidSetupId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForSpecificationsByBidSetupId(bidSetupId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<InlineResponse2001> {
            return DefaultApiFp(configuration).getUploadLinkForSpecificationsByBidSetupId(bidSetupId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadLinkForSubmittalSectionByDocumentId(documentId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).getUploadLinkForSubmittalSectionByDocumentId(documentId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AuthenticatedDownloadRequest} authenticatedDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFileSystemFileNode(projectId: string, authenticatedDownloadRequest: AuthenticatedDownloadRequest, options?: any): AxiosPromise<FileNode> {
            return DefaultApiFp(configuration).getUserFileSystemFileNode(projectId, authenticatedDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [orderByColumn] 
         * @param {string} [orderByDirection] 
         * @param {string} [filterByObject] 
         * @param {string} [curatedSampleData] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectId(projectId: string, orderByColumn?: string, orderByDirection?: string, filterByObject?: string, curatedSampleData?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<IPaginatedIUser> {
            return DefaultApiFp(configuration).getUsersByProjectId(projectId, orderByColumn, orderByDirection, filterByObject, curatedSampleData, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteSettingById(websiteSettingId: string, options?: any): AxiosPromise<IWebsiteSetting> {
            return DefaultApiFp(configuration).getWebsiteSettingById(websiteSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZippedBidDocuments(bidSetupId: string, options?: any): AxiosPromise<GetProjectZipResponse> {
            return DefaultApiFp(configuration).getZippedBidDocuments(bidSetupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser(userId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).impersonateUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidSetupId 
         * @param {BidBIMImportParams} bidBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBimFileByBidSetupId(bidSetupId: string, bidBIMImportParams: BidBIMImportParams, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).importBimFileByBidSetupId(bidSetupId, bidBIMImportParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ProjectBIMImportParams} projectBIMImportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBimFileByProjectId(projectId: string, projectBIMImportParams: ProjectBIMImportParams, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).importBimFileByProjectId(projectId, projectBIMImportParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAdditionalReviewByDocumentId(documentId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<DocumentS3UploadResponse> {
            return DefaultApiFp(configuration).insertAdditionalReviewByDocumentId(documentId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAdditionalReviewByKey(key: string, fileUploadRequestParams: FileUploadRequestParams, consultantReviewDocumentId?: string, options?: any): AxiosPromise<DocumentS3UploadResponse> {
            return DefaultApiFp(configuration).insertAdditionalReviewByKey(key, fileUploadRequestParams, consultantReviewDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionAddress} iInsertionAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAddress(iInsertionAddress: IInsertionAddress, options?: any): AxiosPromise<IAddress> {
            return DefaultApiFp(configuration).insertAddress(iInsertionAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionAnnouncement} iInsertionAnnouncement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAnnouncement(iInsertionAnnouncement: IInsertionAnnouncement, options?: any): AxiosPromise<IAnnouncement> {
            return DefaultApiFp(configuration).insertAnnouncement(iInsertionAnnouncement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {InsertBidProps} insertBidProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBidByUserAndProject(userId: string, projectId: string, insertBidProps: InsertBidProps, options?: any): AxiosPromise<IBid> {
            return DefaultApiFp(configuration).insertBidByUserAndProject(userId, projectId, insertBidProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ICustomInsertionBidSetup} iCustomInsertionBidSetup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBidSetupByProjectId(projectId: string, iCustomInsertionBidSetup: ICustomInsertionBidSetup, options?: any): AxiosPromise<InsertBidSetupResponse> {
            return DefaultApiFp(configuration).insertBidSetupByProjectId(projectId, iCustomInsertionBidSetup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCommentByDocumentId(documentId: string, iInsertionComment: IInsertionComment, options?: any): AxiosPromise<IComment> {
            return DefaultApiFp(configuration).insertCommentByDocumentId(documentId, iInsertionComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCommentByProjectId(projectId: string, iInsertionComment: IInsertionComment, options?: any): AxiosPromise<IComment> {
            return DefaultApiFp(configuration).insertCommentByProjectId(projectId, iInsertionComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionCompany} iInsertionCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCompany(iInsertionCompany: IInsertionCompany, options?: any): AxiosPromise<ICompany> {
            return DefaultApiFp(configuration).insertCompany(iInsertionCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a consultant to a document.
         * @param {string} documentId 
         * @param {AdditionalConsultantProps} additionalConsultantProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsultantByDocumentId(documentId: string, additionalConsultantProps: AdditionalConsultantProps, options?: any): AxiosPromise<AdditionalConsultantResponse> {
            return DefaultApiFp(configuration).insertConsultantByDocumentId(documentId, additionalConsultantProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentDocumentId 
         * @param {IInsertionComment} iInsertionComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsultantConfidentialCommentByDocumentId(parentDocumentId: string, iInsertionComment: IInsertionComment, options?: any): AxiosPromise<IComment> {
            return DefaultApiFp(configuration).insertConsultantConfidentialCommentByDocumentId(parentDocumentId, iInsertionComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertDocumentProps} insertDocumentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDocument(insertDocumentProps: InsertDocumentProps, options?: any): AxiosPromise<InsertDocumentResponse> {
            return DefaultApiFp(configuration).insertDocument(insertDocumentProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionDocumentUser} iInsertionDocumentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDocumentUser(iInsertionDocumentUser: IInsertionDocumentUser, options?: any): AxiosPromise<IDocumentUser> {
            return DefaultApiFp(configuration).insertDocumentUser(iInsertionDocumentUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentFileUploadRequest} documentFileUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFileByDocumentId(documentId: string, documentFileUploadRequest: DocumentFileUploadRequest, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).insertFileByDocumentId(documentId, documentFileUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFloorPlanDrawingFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).insertFloorPlanDrawingFileByProjectId(projectId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertFloorPlanImageFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).insertFloorPlanImageFileByProjectId(projectId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FileUploadRequestParams} fileUploadRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertLocationImageFileByProjectId(projectId: string, fileUploadRequestParams: FileUploadRequestParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).insertLocationImageFileByProjectId(projectId, fileUploadRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertManyPhysicalBuildingsRequest} insertManyPhysicalBuildingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyPhysicalBuildings(insertManyPhysicalBuildingsRequest: InsertManyPhysicalBuildingsRequest, options?: any): AxiosPromise<Array<IPhysicalBuilding>> {
            return DefaultApiFp(configuration).insertManyPhysicalBuildings(insertManyPhysicalBuildingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertManyPhysicalLocationsRequest} insertManyPhysicalLocationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyPhysicalLocationsByFloorIds(insertManyPhysicalLocationsRequest: InsertManyPhysicalLocationsRequest, options?: any): AxiosPromise<Array<IPhysicalLocation>> {
            return DefaultApiFp(configuration).insertManyPhysicalLocationsByFloorIds(insertManyPhysicalLocationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertManyProjectlessUsersParams} iInsertManyProjectlessUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManyProjectlessUser(iInsertManyProjectlessUsersParams: IInsertManyProjectlessUsersParams, options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).insertManyProjectlessUser(iInsertManyProjectlessUsersParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhoneNumberByCompanyId(companyId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options?: any): AxiosPromise<IPhoneNumber> {
            return DefaultApiFp(configuration).insertPhoneNumberByCompanyId(companyId, iInsertionPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {IInsertionPhoneNumber} iInsertionPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhoneNumberByUserId(userId: string, iInsertionPhoneNumber: IInsertionPhoneNumber, options?: any): AxiosPromise<IPhoneNumber> {
            return DefaultApiFp(configuration).insertPhoneNumberByUserId(userId, iInsertionPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomInsertionPhysicalBuilding} customInsertionPhysicalBuilding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhysicalBuilding(customInsertionPhysicalBuilding: CustomInsertionPhysicalBuilding, options?: any): AxiosPromise<IPhysicalBuilding> {
            return DefaultApiFp(configuration).insertPhysicalBuilding(customInsertionPhysicalBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionPhysicalFloor} iInsertionPhysicalFloor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPhysicalFloor(iInsertionPhysicalFloor: IInsertionPhysicalFloor, options?: any): AxiosPromise<IPhysicalFloor> {
            return DefaultApiFp(configuration).insertPhysicalFloor(iInsertionPhysicalFloor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {IInsertionPrimeBidder} iInsertionPrimeBidder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPrimeBidderByUserAndProject(userId: string, projectId: string, iInsertionPrimeBidder: IInsertionPrimeBidder, options?: any): AxiosPromise<IPrimeBidder> {
            return DefaultApiFp(configuration).insertPrimeBidderByUserAndProject(userId, projectId, iInsertionPrimeBidder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionProductPackage} iInsertionProductPackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProductPackage(iInsertionProductPackage: IInsertionProductPackage, options?: any): AxiosPromise<IProductPackage> {
            return DefaultApiFp(configuration).insertProductPackage(iInsertionProductPackage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ICustomInsertionProject} iCustomInsertionProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProject(iCustomInsertionProject: ICustomInsertionProject, options?: any): AxiosPromise<IProject> {
            return DefaultApiFp(configuration).insertProject(iCustomInsertionProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionProjectCalendarEvent} iInsertionProjectCalendarEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProjectCalendarEvent(iInsertionProjectCalendarEvent: IInsertionProjectCalendarEvent, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).insertProjectCalendarEvent(iInsertionProjectCalendarEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ICustomInsertionUser} iCustomInsertionUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProjectlessUser(iCustomInsertionUser: ICustomInsertionUser, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).insertProjectlessUser(iCustomInsertionUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriberId 
         * @param {IInsertionSubscriberSecurityGroup} iInsertionSubscriberSecurityGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSecurityGroupBySubscriberId(subscriberId: string, iInsertionSubscriberSecurityGroup: IInsertionSubscriberSecurityGroup, options?: any): AxiosPromise<ISecurityGroup> {
            return DefaultApiFp(configuration).insertSecurityGroupBySubscriberId(subscriberId, iInsertionSubscriberSecurityGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubmissionByDocumentId(documentId: string, submittalUploadRequest: SubmittalUploadRequest, options?: any): AxiosPromise<DocumentS3UploadResponse> {
            return DefaultApiFp(configuration).insertSubmissionByDocumentId(documentId, submittalUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {SubmittalUploadRequest} submittalUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubmissionByKey(key: string, submittalUploadRequest: SubmittalUploadRequest, options?: any): AxiosPromise<DocumentS3UploadResponse> {
            return DefaultApiFp(configuration).insertSubmissionByKey(key, submittalUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IInsertionSubscriber} iInsertionSubscriber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSubscriber(iInsertionSubscriber: IInsertionSubscriber, options?: any): AxiosPromise<ISubscriber> {
            return DefaultApiFp(configuration).insertSubscriber(iInsertionSubscriber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IAddNewUser} iAddNewUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUser(iAddNewUser: IAddNewUser, options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).insertUser(iAddNewUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UserGroupInsertionRequest} userGroupInsertionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUserGroupByProjectId(projectId: string, userGroupInsertionRequest: UserGroupInsertionRequest, options?: any): AxiosPromise<IUserGroup> {
            return DefaultApiFp(configuration).insertUserGroupByProjectId(projectId, userGroupInsertionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserFileSystem(projectId: string, options?: any): AxiosPromise<FileNode> {
            return DefaultApiFp(configuration).listUserFileSystem(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserFileSystemCompressed(projectId: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).listUserFileSystemCompressed(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead(notificationId: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).markNotificationAsRead(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsReadByUserId(userId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).markNotificationsAsReadByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {MarkParserFileAsConformedRequest} markParserFileAsConformedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markParserFileAsConformed(fileId: string, markParserFileAsConformedRequest: MarkParserFileAsConformedRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).markParserFileAsConformed(fileId, markParserFileAsConformedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentById(documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options?: any): AxiosPromise<IModifyDocumentResponse> {
            return DefaultApiFp(configuration).modifyDocumentById(documentId, iModifyDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {IModifyDocumentRequest} iModifyDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentByIdWithResponse(documentId: string, iModifyDocumentRequest: IModifyDocumentRequest, options?: any): AxiosPromise<IModifyDocumentFullResponse> {
            return DefaultApiFp(configuration).modifyDocumentByIdWithResponse(documentId, iModifyDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchRequest} iModifyDocumentsByBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentsByBatch(iModifyDocumentsByBatchRequest: IModifyDocumentsByBatchRequest, options?: any): AxiosPromise<IModifyManyDocumentsResponse> {
            return DefaultApiFp(configuration).modifyDocumentsByBatch(iModifyDocumentsByBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IModifyDocumentsByBatchesRequest} iModifyDocumentsByBatchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDocumentsByBatches(iModifyDocumentsByBatchesRequest: IModifyDocumentsByBatchesRequest, options?: any): AxiosPromise<IModifyManyDocumentsResponse> {
            return DefaultApiFp(configuration).modifyDocumentsByBatches(iModifyDocumentsByBatchesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ModifyUserFileSystemNodeRequest} modifyUserFileSystemNodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserFileSystemNode(projectId: string, modifyUserFileSystemNodeRequest: ModifyUserFileSystemNodeRequest, options?: any): AxiosPromise<FileNode> {
            return DefaultApiFp(configuration).modifyUserFileSystemNode(projectId, modifyUserFileSystemNodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notReadyForSubmissionByDocumentId(documentId: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).notReadyForSubmissionByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {NotifyUserAccessDesignFileRequest} notifyUserAccessDesignFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyUsersOfAccessToDesignFile(projectId: string, notifyUserAccessDesignFileRequest: NotifyUserAccessDesignFileRequest, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).notifyUsersOfAccessToDesignFile(projectId, notifyUserAccessDesignFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permanentlyDeleteProjectById(projectId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).permanentlyDeleteProjectById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RejectDocumentRequest} rejectDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseSubmissionByDocumentId(documentId: string, rejectDocumentRequest: RejectDocumentRequest, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).refuseSubmissionByDocumentId(documentId, rejectDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentFromPackageByDocumentId(documentId: string, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).removeDocumentFromPackageByDocumentId(documentId, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePlanHolderFromProjectByUserIdAndProjectId(userId: string, projectId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).removePlanHolderFromProjectByUserIdAndProjectId(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProjectByProjectId(projectId: string, userId: string, options?: any): AxiosPromise<IProjectView> {
            return DefaultApiFp(configuration).removeUserFromProjectByProjectId(projectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceDocumentById(id: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).replaceDocumentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileId 
         * @param {BaseFileUploadParams} baseFileUploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceFileByDocumentIdAndFileId(documentId: string, fileId: string, baseFileUploadParams: BaseFileUploadParams, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).replaceFileByDocumentIdAndFileId(documentId, fileId, baseFileUploadParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} websiteSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWebsiteSettingById(websiteSettingId: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).replaceWebsiteSettingById(websiteSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUser(options?: any): AxiosPromise<IUser> {
            return DefaultApiFp(configuration).resetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {RespondToReviewProps} respondToReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToReviewByDocumentId(documentId: string, respondToReviewProps: RespondToReviewProps, options?: any): AxiosPromise<RespondToReviewResponse> {
            return DefaultApiFp(configuration).respondToReviewByDocumentId(documentId, respondToReviewProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDocumentById(documentId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).restoreDocumentById(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestoreDocumentsRequest} restoreDocumentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDocumentsByIds(restoreDocumentsRequest: RestoreDocumentsRequest, options?: any): AxiosPromise<Array<any>> {
            return DefaultApiFp(configuration).restoreDocumentsByIds(restoreDocumentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bidId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retractBidById(bidId: string, options?: any): AxiosPromise<IBid> {
            return DefaultApiFp(configuration).retractBidById(bidId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BidConnectFileDeliveryEmailRequest} bidConnectFileDeliveryEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBidConnectFileDeliveryEmail(bidConnectFileDeliveryEmailRequest: BidConnectFileDeliveryEmailRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).sendBidConnectFileDeliveryEmail(bidConnectFileDeliveryEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicDesignFileDownloadLinkRequest} sendPublicDesignFileDownloadLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDirectDesignFileDownloadEmails(projectId: string, sendPublicDesignFileDownloadLinkRequest: SendPublicDesignFileDownloadLinkRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).sendDirectDesignFileDownloadEmails(projectId, sendPublicDesignFileDownloadLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDocumentPublicLinkEmails(documentId: string, emailRequest: EmailRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).sendDocumentPublicLinkEmails(documentId, emailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPrivateInviteEmailRequestParams} sendPrivateInviteEmailRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPrivateProjectInviteEmails(projectId: string, sendPrivateInviteEmailRequestParams: SendPrivateInviteEmailRequestParams, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).sendPrivateProjectInviteEmails(projectId, sendPrivateInviteEmailRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SendPublicLinkToEmailsRequest} sendPublicLinkToEmailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPublicFolderAccessToEmails(projectId: string, sendPublicLinkToEmailsRequest: SendPublicLinkToEmailsRequest, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).sendPublicFolderAccessToEmails(projectId, sendPublicLinkToEmailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<ShareFileSystemObjectRequest>} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMultipleUserFileSystemObject(projectId: string, shareFileSystemObjectRequest: Array<ShareFileSystemObjectRequest>, options?: any): AxiosPromise<Array<FileNode>> {
            return DefaultApiFp(configuration).shareMultipleUserFileSystemObject(projectId, shareFileSystemObjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ShareFileSystemObjectRequest} shareFileSystemObjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareUserFileSystemObject(projectId: string, shareFileSystemObjectRequest: ShareFileSystemObjectRequest, options?: any): AxiosPromise<FileNode> {
            return DefaultApiFp(configuration).shareUserFileSystemObject(projectId, shareFileSystemObjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipAssignSubcontractorByDocumentId(documentId: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).skipAssignSubcontractorByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [unauthenticatedUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcontractorRefuseAssignmentByDocumentId(documentId: string, unauthenticatedUserEmail?: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).subcontractorRefuseAssignmentByDocumentId(documentId, unauthenticatedUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAdditionalReviewByDocumentId(documentId: string, additionalReviewProps: AdditionalReviewProps, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).submitAdditionalReviewByDocumentId(documentId, additionalReviewProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {AdditionalReviewProps} additionalReviewProps 
         * @param {string} [consultantReviewDocumentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAdditionalReviewByKey(key: string, additionalReviewProps: AdditionalReviewProps, consultantReviewDocumentId?: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).submitAdditionalReviewByKey(key, additionalReviewProps, consultantReviewDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {SubmitToArchitectProps} submitToArchitectProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitToArchitectByDocumentId(documentId: string, submitToArchitectProps: SubmitToArchitectProps, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).submitToArchitectByDocumentId(documentId, submitToArchitectProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} centerLineProjectId 
         * @param {ProcoreDocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProcoreDocuments(centerLineProjectId: string, documentType: ProcoreDocumentType, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).syncProcoreDocuments(centerLineProjectId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ToggleRemindersProps} toggleRemindersProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleRemindersByDocumentKey(toggleRemindersProps: ToggleRemindersProps, options?: any): AxiosPromise<number | IDocumentUser> {
            return DefaultApiFp(configuration).toggleRemindersByDocumentKey(toggleRemindersProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoCompleteReviewByDocumentId(documentId: string, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).undoCompleteReviewByDocumentId(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UnlockProjectsWithKeyParams} unlockProjectsWithKeyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockProjectsWithKeyByUserId(userId: string, unlockProjectsWithKeyParams: UnlockProjectsWithKeyParams, options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).unlockProjectsWithKeyByUserId(userId, unlockProjectsWithKeyParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePlanholderByProjectIdAndUserId(projectId: string, userId: string, options?: any): AxiosPromise<IProjectUser> {
            return DefaultApiFp(configuration).unsubscribePlanholderByProjectIdAndUserId(projectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows for bluebeam to POST back the BAX file. This should only be used by REVU.
         * @param {string} fileId 
         * @param {string} originalPdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaxFile(fileId: string, originalPdfId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateBaxFile(fileId, originalPdfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {UpdateReviewParams} updateReviewParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewByDocumentId(documentId: string, updateReviewParams: UpdateReviewParams, options?: any): AxiosPromise<INumberedDocumentView> {
            return DefaultApiFp(configuration).updateReviewByDocumentId(documentId, updateReviewParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {S3StagingUploadRequest} s3StagingUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToStaging(s3StagingUploadRequest: S3StagingUploadRequest, options?: any): AxiosPromise<S3UploadResponse> {
            return DefaultApiFp(configuration).uploadToStaging(s3StagingUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawDocumentById(documentId: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).withdrawDocumentById(documentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acceptSubmissionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiAcceptSubmissionByDocumentIdRequest
 */
export interface DefaultApiAcceptSubmissionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAcceptSubmissionByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for addDocumentToPackageByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiAddDocumentToPackageByDocumentIdRequest
 */
export interface DefaultApiAddDocumentToPackageByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAddDocumentToPackageByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {AddDocumentToPackageProps}
     * @memberof DefaultApiAddDocumentToPackageByDocumentId
     */
    readonly addDocumentToPackageProps: AddDocumentToPackageProps

    /**
     * 
     * @type {string}
     * @memberof DefaultApiAddDocumentToPackageByDocumentId
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for addOrModifyUsersByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiAddOrModifyUsersByProjectIdRequest
 */
export interface DefaultApiAddOrModifyUsersByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAddOrModifyUsersByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<AddUserParams>}
     * @memberof DefaultApiAddOrModifyUsersByProjectId
     */
    readonly addUserParams: Array<AddUserParams>
}

/**
 * Request parameters for assignUsersToCompanyByEmailDomain operation in DefaultApi.
 * @export
 * @interface DefaultApiAssignUsersToCompanyByEmailDomainRequest
 */
export interface DefaultApiAssignUsersToCompanyByEmailDomainRequest {
    /**
     * 
     * @type {AssignByDomainRequest}
     * @memberof DefaultApiAssignUsersToCompanyByEmailDomain
     */
    readonly assignByDomainRequest: AssignByDomainRequest
}

/**
 * Request parameters for authorizeBIM360 operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthorizeBIM360Request
 */
export interface DefaultApiAuthorizeBIM360Request {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAuthorizeBIM360
     */
    readonly centerLineProjectId: string

    /**
     * 
     * @type {IAuthorizeParams}
     * @memberof DefaultApiAuthorizeBIM360
     */
    readonly iAuthorizeParams: IAuthorizeParams
}

/**
 * Request parameters for authorizeProcore operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthorizeProcoreRequest
 */
export interface DefaultApiAuthorizeProcoreRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAuthorizeProcore
     */
    readonly centerLineProjectId: string

    /**
     * 
     * @type {IAuthorizeParams}
     * @memberof DefaultApiAuthorizeProcore
     */
    readonly iAuthorizeParams: IAuthorizeParams
}

/**
 * Request parameters for beginDrawingsParser operation in DefaultApi.
 * @export
 * @interface DefaultApiBeginDrawingsParserRequest
 */
export interface DefaultApiBeginDrawingsParserRequest {
    /**
     * 
     * @type {DrawingsParserRequest}
     * @memberof DefaultApiBeginDrawingsParser
     */
    readonly drawingsParserRequest: DrawingsParserRequest
}

/**
 * Request parameters for beginDrawingsPreprocessor operation in DefaultApi.
 * @export
 * @interface DefaultApiBeginDrawingsPreprocessorRequest
 */
export interface DefaultApiBeginDrawingsPreprocessorRequest {
    /**
     * 
     * @type {DrawingsPreprocessorRequest}
     * @memberof DefaultApiBeginDrawingsPreprocessor
     */
    readonly drawingsPreprocessorRequest: DrawingsPreprocessorRequest
}

/**
 * Request parameters for beginSpecificationsParser operation in DefaultApi.
 * @export
 * @interface DefaultApiBeginSpecificationsParserRequest
 */
export interface DefaultApiBeginSpecificationsParserRequest {
    /**
     * 
     * @type {SpecificationsParserRequest}
     * @memberof DefaultApiBeginSpecificationsParser
     */
    readonly specificationsParserRequest: SpecificationsParserRequest
}

/**
 * Request parameters for completeMultipartUpload operation in DefaultApi.
 * @export
 * @interface DefaultApiCompleteMultipartUploadRequest
 */
export interface DefaultApiCompleteMultipartUploadRequest {
    /**
     * 
     * @type {CompleteMultiPartUploadRequest}
     * @memberof DefaultApiCompleteMultipartUpload
     */
    readonly completeMultiPartUploadRequest: CompleteMultiPartUploadRequest
}

/**
 * Request parameters for completeReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiCompleteReviewByDocumentIdRequest
 */
export interface DefaultApiCompleteReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCompleteReviewByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {SubmittalReviewProps}
     * @memberof DefaultApiCompleteReviewByDocumentId
     */
    readonly submittalReviewProps: SubmittalReviewProps
}

/**
 * Request parameters for createErrorEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateErrorEmailRequest
 */
export interface DefaultApiCreateErrorEmailRequest {
    /**
     * 
     * @type {ErrorEmail}
     * @memberof DefaultApiCreateErrorEmail
     */
    readonly errorEmail: ErrorEmail

    /**
     * 
     * @type {string}
     * @memberof DefaultApiCreateErrorEmail
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for createLinkedDocumentsForAssignedUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateLinkedDocumentsForAssignedUsersRequest
 */
export interface DefaultApiCreateLinkedDocumentsForAssignedUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCreateLinkedDocumentsForAssignedUsers
     */
    readonly documentId: string

    /**
     * 
     * @type {CreateLinkedDocumentsForAssignedUsersRequest}
     * @memberof DefaultApiCreateLinkedDocumentsForAssignedUsers
     */
    readonly createLinkedDocumentsForAssignedUsersRequest: CreateLinkedDocumentsForAssignedUsersRequest
}

/**
 * Request parameters for createUserFileSystemFolder operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateUserFileSystemFolderRequest
 */
export interface DefaultApiCreateUserFileSystemFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCreateUserFileSystemFolder
     */
    readonly projectId: string

    /**
     * 
     * @type {AuthenticatedOtherFileSystemRequest}
     * @memberof DefaultApiCreateUserFileSystemFolder
     */
    readonly authenticatedOtherFileSystemRequest: AuthenticatedOtherFileSystemRequest
}

/**
 * Request parameters for deleteAllSecurityGroupsBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteAllSecurityGroupsBySubscriberIdRequest
 */
export interface DefaultApiDeleteAllSecurityGroupsBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteAllSecurityGroupsBySubscriberId
     */
    readonly subscriberId: string
}

/**
 * Request parameters for deleteAnnouncementById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteAnnouncementByIdRequest
 */
export interface DefaultApiDeleteAnnouncementByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteAnnouncementById
     */
    readonly announcementId: string
}

/**
 * Request parameters for deleteBidSetupByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteBidSetupByProjectIdRequest
 */
export interface DefaultApiDeleteBidSetupByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteBidSetupByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for deleteDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteDocumentByIdRequest
 */
export interface DefaultApiDeleteDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteDocumentById
     */
    readonly documentId: string
}

/**
 * Request parameters for deleteDocumentsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteDocumentsByIdsRequest
 */
export interface DefaultApiDeleteDocumentsByIdsRequest {
    /**
     * 
     * @type {DeleteDocumentsRequest}
     * @memberof DefaultApiDeleteDocumentsByIds
     */
    readonly deleteDocumentsRequest: DeleteDocumentsRequest
}

/**
 * Request parameters for deleteFileByBidSetupIdAndFileId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteFileByBidSetupIdAndFileIdRequest
 */
export interface DefaultApiDeleteFileByBidSetupIdAndFileIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteFileByBidSetupIdAndFileId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteFileByBidSetupIdAndFileId
     */
    readonly fileId: string
}

/**
 * Request parameters for deleteInviteEmailByProjectIdAndUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteInviteEmailByProjectIdAndUserIdRequest
 */
export interface DefaultApiDeleteInviteEmailByProjectIdAndUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteInviteEmailByProjectIdAndUserId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteInviteEmailByProjectIdAndUserId
     */
    readonly userId: string
}

/**
 * Request parameters for deleteManyUsersByEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteManyUsersByEmailRequest
 */
export interface DefaultApiDeleteManyUsersByEmailRequest {
    /**
     * 
     * @type {IDeleteUsersByEmailParams}
     * @memberof DefaultApiDeleteManyUsersByEmail
     */
    readonly iDeleteUsersByEmailParams: IDeleteUsersByEmailParams
}

/**
 * Request parameters for deleteParsedFileByProjectIdAndFileId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteParsedFileByProjectIdAndFileIdRequest
 */
export interface DefaultApiDeleteParsedFileByProjectIdAndFileIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteParsedFileByProjectIdAndFileId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteParsedFileByProjectIdAndFileId
     */
    readonly fileId: string
}

/**
 * Request parameters for deleteParsedFilesByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteParsedFilesByProjectIdRequest
 */
export interface DefaultApiDeleteParsedFilesByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteParsedFilesByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {DeleteParsedFilesRequest}
     * @memberof DefaultApiDeleteParsedFilesByProjectId
     */
    readonly deleteParsedFilesRequest: DeleteParsedFilesRequest
}

/**
 * Request parameters for deletePhysicalBuildingById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePhysicalBuildingByIdRequest
 */
export interface DefaultApiDeletePhysicalBuildingByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeletePhysicalBuildingById
     */
    readonly buildingId: string
}

/**
 * Request parameters for deletePhysicalFloorById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePhysicalFloorByIdRequest
 */
export interface DefaultApiDeletePhysicalFloorByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeletePhysicalFloorById
     */
    readonly floorId: string
}

/**
 * Request parameters for deletePhysicalLocationById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePhysicalLocationByIdRequest
 */
export interface DefaultApiDeletePhysicalLocationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeletePhysicalLocationById
     */
    readonly locationId: string
}

/**
 * Request parameters for deletePrimeBidderByUserAndProject operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePrimeBidderByUserAndProjectRequest
 */
export interface DefaultApiDeletePrimeBidderByUserAndProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeletePrimeBidderByUserAndProject
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeletePrimeBidderByUserAndProject
     */
    readonly projectId: string
}

/**
 * Request parameters for deleteProductPackageById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteProductPackageByIdRequest
 */
export interface DefaultApiDeleteProductPackageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteProductPackageById
     */
    readonly productPackageId: string
}

/**
 * Request parameters for deleteSecurityGroupById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteSecurityGroupByIdRequest
 */
export interface DefaultApiDeleteSecurityGroupByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteSecurityGroupById
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteSecurityGroupById
     */
    readonly newSecurityGroupId?: string
}

/**
 * Request parameters for deleteSubscriberById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteSubscriberByIdRequest
 */
export interface DefaultApiDeleteSubscriberByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteSubscriberById
     */
    readonly subscriberId: string
}

/**
 * Request parameters for deleteTrashItemsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteTrashItemsByProjectIdRequest
 */
export interface DefaultApiDeleteTrashItemsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteTrashItemsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {DeleteTrashItemsRequest}
     * @memberof DefaultApiDeleteTrashItemsByProjectId
     */
    readonly deleteTrashItemsRequest: DeleteTrashItemsRequest
}

/**
 * Request parameters for deleteUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserByIdRequest
 */
export interface DefaultApiDeleteUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteUserById
     */
    readonly userId: string
}

/**
 * Request parameters for deleteUserFileSystemNode operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserFileSystemNodeRequest
 */
export interface DefaultApiDeleteUserFileSystemNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteUserFileSystemNode
     */
    readonly projectId: string

    /**
     * 
     * @type {UserFileSystemNodeRequest}
     * @memberof DefaultApiDeleteUserFileSystemNode
     */
    readonly body: UserFileSystemNodeRequest
}

/**
 * Request parameters for deleteUserGroupById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserGroupByIdRequest
 */
export interface DefaultApiDeleteUserGroupByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteUserGroupById
     */
    readonly userGroupId: string
}

/**
 * Request parameters for deleteUserNotificationsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserNotificationsByIdsRequest
 */
export interface DefaultApiDeleteUserNotificationsByIdsRequest {
    /**
     * 
     * @type {DeleteUserNotificationsParams}
     * @memberof DefaultApiDeleteUserNotificationsByIds
     */
    readonly deleteUserNotificationsParams: DeleteUserNotificationsParams
}

/**
 * Request parameters for disableRegistrationReminderByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiDisableRegistrationReminderByUserIdRequest
 */
export interface DefaultApiDisableRegistrationReminderByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDisableRegistrationReminderByUserId
     */
    readonly userId: string
}

/**
 * Request parameters for downloadTrashItemByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiDownloadTrashItemByProjectIdRequest
 */
export interface DefaultApiDownloadTrashItemByProjectIdRequest {
    /**
     * 
     * @type {GetTrashItemRequest}
     * @memberof DefaultApiDownloadTrashItemByProjectId
     */
    readonly getTrashItemRequest: GetTrashItemRequest
}

/**
 * Request parameters for editAnnouncementById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditAnnouncementByIdRequest
 */
export interface DefaultApiEditAnnouncementByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditAnnouncementById
     */
    readonly announcementId: string

    /**
     * 
     * @type {IOptionalAnnouncement}
     * @memberof DefaultApiEditAnnouncementById
     */
    readonly iOptionalAnnouncement: IOptionalAnnouncement
}

/**
 * Request parameters for editAnticipatedSubmissionDateOfDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditAnticipatedSubmissionDateOfDocumentByIdRequest
 */
export interface DefaultApiEditAnticipatedSubmissionDateOfDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditAnticipatedSubmissionDateOfDocumentById
     */
    readonly documentId: string

    /**
     * 
     * @type {AnticipatedSubmissionDateChangeRequest}
     * @memberof DefaultApiEditAnticipatedSubmissionDateOfDocumentById
     */
    readonly anticipatedSubmissionDateChangeRequest: AnticipatedSubmissionDateChangeRequest
}

/**
 * Request parameters for editBidSetupByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiEditBidSetupByProjectIdRequest
 */
export interface DefaultApiEditBidSetupByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditBidSetupByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {ICustomOptionalBidSetup}
     * @memberof DefaultApiEditBidSetupByProjectId
     */
    readonly iCustomOptionalBidSetup: ICustomOptionalBidSetup
}

/**
 * Request parameters for editDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditDocumentByIdRequest
 */
export interface DefaultApiEditDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditDocumentById
     */
    readonly documentId: string

    /**
     * 
     * @type {IOptionalDocument}
     * @memberof DefaultApiEditDocumentById
     */
    readonly iOptionalDocument: IOptionalDocument
}

/**
 * Request parameters for editDocumentSettingsByUserIdAndDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiEditDocumentSettingsByUserIdAndDocumentIdRequest
 */
export interface DefaultApiEditDocumentSettingsByUserIdAndDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditDocumentSettingsByUserIdAndDocumentId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditDocumentSettingsByUserIdAndDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {IEditDocumentSettings}
     * @memberof DefaultApiEditDocumentSettingsByUserIdAndDocumentId
     */
    readonly iEditDocumentSettings: IEditDocumentSettings
}

/**
 * Request parameters for editFavoriteProjectByUserIdAndProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiEditFavoriteProjectByUserIdAndProjectIdRequest
 */
export interface DefaultApiEditFavoriteProjectByUserIdAndProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditFavoriteProjectByUserIdAndProjectId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditFavoriteProjectByUserIdAndProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {EditFavoriteProjectParams}
     * @memberof DefaultApiEditFavoriteProjectByUserIdAndProjectId
     */
    readonly editFavoriteProjectParams: EditFavoriteProjectParams
}

/**
 * Request parameters for editFileByDocumentIdAndFileId operation in DefaultApi.
 * @export
 * @interface DefaultApiEditFileByDocumentIdAndFileIdRequest
 */
export interface DefaultApiEditFileByDocumentIdAndFileIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditFileByDocumentIdAndFileId
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditFileByDocumentIdAndFileId
     */
    readonly fileId: string

    /**
     * 
     * @type {EditFileParams}
     * @memberof DefaultApiEditFileByDocumentIdAndFileId
     */
    readonly editFileParams: EditFileParams
}

/**
 * Request parameters for editFileById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditFileByIdRequest
 */
export interface DefaultApiEditFileByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditFileById
     */
    readonly fileId: string

    /**
     * 
     * @type {IOptionalFile}
     * @memberof DefaultApiEditFileById
     */
    readonly iOptionalFile: IOptionalFile
}

/**
 * Request parameters for editManyPhysicalFloorsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiEditManyPhysicalFloorsByIdsRequest
 */
export interface DefaultApiEditManyPhysicalFloorsByIdsRequest {
    /**
     * 
     * @type {EditManyPhysicalFloorsRequest}
     * @memberof DefaultApiEditManyPhysicalFloorsByIds
     */
    readonly editManyPhysicalFloorsRequest: EditManyPhysicalFloorsRequest
}

/**
 * Request parameters for editPhysicalBuildingById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditPhysicalBuildingByIdRequest
 */
export interface DefaultApiEditPhysicalBuildingByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditPhysicalBuildingById
     */
    readonly buildingId: string

    /**
     * 
     * @type {CustomOptionalPhysicalBuilding}
     * @memberof DefaultApiEditPhysicalBuildingById
     */
    readonly customOptionalPhysicalBuilding: CustomOptionalPhysicalBuilding
}

/**
 * Request parameters for editPhysicalFloorById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditPhysicalFloorByIdRequest
 */
export interface DefaultApiEditPhysicalFloorByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditPhysicalFloorById
     */
    readonly floorId: string

    /**
     * 
     * @type {IOptionalPhysicalFloor}
     * @memberof DefaultApiEditPhysicalFloorById
     */
    readonly iOptionalPhysicalFloor: IOptionalPhysicalFloor
}

/**
 * Request parameters for editPhysicalLocationById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditPhysicalLocationByIdRequest
 */
export interface DefaultApiEditPhysicalLocationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditPhysicalLocationById
     */
    readonly locationId: string

    /**
     * 
     * @type {IOptionalPhysicalLocation}
     * @memberof DefaultApiEditPhysicalLocationById
     */
    readonly iOptionalPhysicalLocation: IOptionalPhysicalLocation
}

/**
 * Request parameters for editProductPackageById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditProductPackageByIdRequest
 */
export interface DefaultApiEditProductPackageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditProductPackageById
     */
    readonly productPackageId: string

    /**
     * 
     * @type {IOptionalProductPackage}
     * @memberof DefaultApiEditProductPackageById
     */
    readonly iOptionalProductPackage: IOptionalProductPackage
}

/**
 * Request parameters for editProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditProjectByIdRequest
 */
export interface DefaultApiEditProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {IOptionalProject}
     * @memberof DefaultApiEditProjectById
     */
    readonly iOptionalProject: IOptionalProject
}

/**
 * Request parameters for editProjectUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditProjectUserByIdRequest
 */
export interface DefaultApiEditProjectUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditProjectUserById
     */
    readonly id: string

    /**
     * 
     * @type {IOptionalProjectUser}
     * @memberof DefaultApiEditProjectUserById
     */
    readonly iOptionalProjectUser: IOptionalProjectUser
}

/**
 * Request parameters for editSecurityGroupBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiEditSecurityGroupBySubscriberIdRequest
 */
export interface DefaultApiEditSecurityGroupBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditSecurityGroupBySubscriberId
     */
    readonly subscriberId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditSecurityGroupBySubscriberId
     */
    readonly securityGroupId: string

    /**
     * 
     * @type {IOptionalSubscriberSecurityGroup}
     * @memberof DefaultApiEditSecurityGroupBySubscriberId
     */
    readonly iOptionalSubscriberSecurityGroup: IOptionalSubscriberSecurityGroup
}

/**
 * Request parameters for editSubcontractorOfDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditSubcontractorOfDocumentByIdRequest
 */
export interface DefaultApiEditSubcontractorOfDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditSubcontractorOfDocumentById
     */
    readonly documentId: string

    /**
     * 
     * @type {SubcontractorChangeRequest}
     * @memberof DefaultApiEditSubcontractorOfDocumentById
     */
    readonly subcontractorChangeRequest: SubcontractorChangeRequest
}

/**
 * Request parameters for editSubscriberById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditSubscriberByIdRequest
 */
export interface DefaultApiEditSubscriberByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditSubscriberById
     */
    readonly subscriberId: string

    /**
     * 
     * @type {IOptionalSubscriber}
     * @memberof DefaultApiEditSubscriberById
     */
    readonly iOptionalSubscriber: IOptionalSubscriber
}

/**
 * Request parameters for editUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditUserByIdRequest
 */
export interface DefaultApiEditUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditUserById
     */
    readonly userId: string

    /**
     * 
     * @type {ICustomOptionalUser}
     * @memberof DefaultApiEditUserById
     */
    readonly iCustomOptionalUser: ICustomOptionalUser
}

/**
 * Request parameters for editUserGroupById operation in DefaultApi.
 * @export
 * @interface DefaultApiEditUserGroupByIdRequest
 */
export interface DefaultApiEditUserGroupByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditUserGroupById
     */
    readonly userGroupId: string

    /**
     * 
     * @type {UserGroupEdit}
     * @memberof DefaultApiEditUserGroupById
     */
    readonly userGroupEdit: UserGroupEdit
}

/**
 * Request parameters for editUserNotificationsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiEditUserNotificationsByIdsRequest
 */
export interface DefaultApiEditUserNotificationsByIdsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEditUserNotificationsByIds
     */
    readonly userId: string

    /**
     * 
     * @type {EditUserNotificationsParams}
     * @memberof DefaultApiEditUserNotificationsByIds
     */
    readonly editUserNotificationsParams: EditUserNotificationsParams
}

/**
 * Request parameters for emptyTrashByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiEmptyTrashByProjectIdRequest
 */
export interface DefaultApiEmptyTrashByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEmptyTrashByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for flattenBlueBeamByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiFlattenBlueBeamByDocumentIdRequest
 */
export interface DefaultApiFlattenBlueBeamByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFlattenBlueBeamByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {SubmittalFlattenRequest}
     * @memberof DefaultApiFlattenBlueBeamByDocumentId
     */
    readonly submittalFlattenRequest: SubmittalFlattenRequest
}

/**
 * Request parameters for flattenBlueBeamByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiFlattenBlueBeamByKeyRequest
 */
export interface DefaultApiFlattenBlueBeamByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFlattenBlueBeamByKey
     */
    readonly key: string

    /**
     * 
     * @type {SubmittalFlattenRequest}
     * @memberof DefaultApiFlattenBlueBeamByKey
     */
    readonly submittalFlattenRequest: SubmittalFlattenRequest
}

/**
 * Request parameters for generateAsBuiltDocumentFromDrawingsDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentIdRequest
 */
export interface DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {GenerateAsBuiltDocumentParams}
     * @memberof DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentId
     */
    readonly generateAsBuiltDocumentParams: GenerateAsBuiltDocumentParams
}

/**
 * Request parameters for generateAsBuiltDocumentsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateAsBuiltDocumentsByProjectIdRequest
 */
export interface DefaultApiGenerateAsBuiltDocumentsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateAsBuiltDocumentsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {GenerateAsBuiltDocumentsRequest}
     * @memberof DefaultApiGenerateAsBuiltDocumentsByProjectId
     */
    readonly generateAsBuiltDocumentsRequest: GenerateAsBuiltDocumentsRequest
}

/**
 * Request parameters for generateBimFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateBimFileDownloadLinkRequest
 */
export interface DefaultApiGenerateBimFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateBimFileDownloadLink
     */
    readonly centerLineProjectId: string

    /**
     * 
     * @type {BimTreeNode}
     * @memberof DefaultApiGenerateBimFileDownloadLink
     */
    readonly bimTreeNode: BimTreeNode
}

/**
 * Request parameters for generateMultiplePublicUserFileSystemFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLinkRequest
 */
export interface DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<GeneratePublicUserFileSystemLinkRequest>}
     * @memberof DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLink
     */
    readonly generatePublicUserFileSystemLinkRequest: Array<GeneratePublicUserFileSystemLinkRequest>
}

/**
 * Request parameters for generateMultipleUserFileSystemFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateMultipleUserFileSystemFileDownloadLinkRequest
 */
export interface DefaultApiGenerateMultipleUserFileSystemFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateMultipleUserFileSystemFileDownloadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<AuthenticatedDownloadRequest>}
     * @memberof DefaultApiGenerateMultipleUserFileSystemFileDownloadLink
     */
    readonly authenticatedDownloadRequest: Array<AuthenticatedDownloadRequest>
}

/**
 * Request parameters for generateMultipleUserFileSystemUploadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateMultipleUserFileSystemUploadLinkRequest
 */
export interface DefaultApiGenerateMultipleUserFileSystemUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateMultipleUserFileSystemUploadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<AuthenticatedUploadRequest>}
     * @memberof DefaultApiGenerateMultipleUserFileSystemUploadLink
     */
    readonly authenticatedUploadRequest: Array<AuthenticatedUploadRequest>
}

/**
 * Request parameters for generatePlaceholdersForDocuments operation in DefaultApi.
 * @export
 * @interface DefaultApiGeneratePlaceholdersForDocumentsRequest
 */
export interface DefaultApiGeneratePlaceholdersForDocumentsRequest {
    /**
     * 
     * @type {IGeneratePlaceholdersRequest}
     * @memberof DefaultApiGeneratePlaceholdersForDocuments
     */
    readonly iGeneratePlaceholdersRequest: IGeneratePlaceholdersRequest
}

/**
 * Request parameters for generatePresignedUrlsParts operation in DefaultApi.
 * @export
 * @interface DefaultApiGeneratePresignedUrlsPartsRequest
 */
export interface DefaultApiGeneratePresignedUrlsPartsRequest {
    /**
     * 
     * @type {PresignedUrlPartsRequest}
     * @memberof DefaultApiGeneratePresignedUrlsParts
     */
    readonly presignedUrlPartsRequest: PresignedUrlPartsRequest
}

/**
 * Request parameters for generatePublicUserFileSystemFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGeneratePublicUserFileSystemFileDownloadLinkRequest
 */
export interface DefaultApiGeneratePublicUserFileSystemFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGeneratePublicUserFileSystemFileDownloadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {GeneratePublicUserFileSystemLinkRequest}
     * @memberof DefaultApiGeneratePublicUserFileSystemFileDownloadLink
     */
    readonly generatePublicUserFileSystemLinkRequest: GeneratePublicUserFileSystemLinkRequest
}

/**
 * Request parameters for generateUserFileSystemFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateUserFileSystemFileDownloadLinkRequest
 */
export interface DefaultApiGenerateUserFileSystemFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateUserFileSystemFileDownloadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {AuthenticatedDownloadRequest}
     * @memberof DefaultApiGenerateUserFileSystemFileDownloadLink
     */
    readonly authenticatedDownloadRequest: AuthenticatedDownloadRequest
}

/**
 * Request parameters for generateUserFileSystemUploadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGenerateUserFileSystemUploadLinkRequest
 */
export interface DefaultApiGenerateUserFileSystemUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGenerateUserFileSystemUploadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {AuthenticatedUploadRequest}
     * @memberof DefaultApiGenerateUserFileSystemUploadLink
     */
    readonly authenticatedUploadRequest: AuthenticatedUploadRequest
}

/**
 * Request parameters for getAllAdminUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllAdminUsersRequest
 */
export interface DefaultApiGetAllAdminUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAdminUsers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllAnnouncements operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllAnnouncementsRequest
 */
export interface DefaultApiGetAllAnnouncementsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAnnouncements
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllAssignedDocumentsByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllAssignedDocumentsByUserIdRequest
 */
export interface DefaultApiGetAllAssignedDocumentsByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllAssignedDocumentsByUserId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllCompanies operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllCompaniesRequest
 */
export interface DefaultApiGetAllCompaniesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllCompanies
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllCountries operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllCountriesRequest
 */
export interface DefaultApiGetAllCountriesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCountries
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCountries
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCountries
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllCountries
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllCountries
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllCountries
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllDocumentTemplates operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllDocumentTemplatesRequest
 */
export interface DefaultApiGetAllDocumentTemplatesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocumentTemplates
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllDocumentUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllDocumentUsersRequest
 */
export interface DefaultApiGetAllDocumentUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocumentUsers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllDocuments operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllDocumentsRequest
 */
export interface DefaultApiGetAllDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllDocuments
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllFaqCategories operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllFaqCategoriesRequest
 */
export interface DefaultApiGetAllFaqCategoriesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllFaqCategories
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllNotifications operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllNotificationsRequest
 */
export interface DefaultApiGetAllNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllNotifications
     */
    readonly projectId?: string
}

/**
 * Request parameters for getAllOutboundEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllOutboundEmailRequest
 */
export interface DefaultApiGetAllOutboundEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllOutboundEmail
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProductPackages operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProductPackagesRequest
 */
export interface DefaultApiGetAllProductPackagesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProductPackages
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjectBidSetups operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectBidSetupsRequest
 */
export interface DefaultApiGetAllProjectBidSetupsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectBidSetups
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjectBidSetupsSortedByDueDate operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectBidSetupsSortedByDueDateRequest
 */
export interface DefaultApiGetAllProjectBidSetupsSortedByDueDateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetupsSortedByDueDate
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectBidSetupsSortedByDueDate
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectBidSetupsSortedByDueDate
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectBidSetupsSortedByDueDate
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjectCalendarEventsBetweenDatesByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectIdRequest
 */
export interface DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectId
     */
    readonly beforeDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectId
     */
    readonly afterDate: string
}

/**
 * Request parameters for getAllProjectCalendarEventsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectCalendarEventsByProjectIdRequest
 */
export interface DefaultApiGetAllProjectCalendarEventsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectCalendarEventsByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjectNamesBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectNamesBySubscriberIdRequest
 */
export interface DefaultApiGetAllProjectNamesBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectNamesBySubscriberId
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getAllProjectUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectUsersRequest
 */
export interface DefaultApiGetAllProjectUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectUsers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjects operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectsRequest
 */
export interface DefaultApiGetAllProjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjects
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjects
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjects
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjects
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjects
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjects
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllProjectsSimplified operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllProjectsSimplifiedRequest
 */
export interface DefaultApiGetAllProjectsSimplifiedRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllProjectsSimplified
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllReceivedEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllReceivedEmailsRequest
 */
export interface DefaultApiGetAllReceivedEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly projectId: string

    /**
     * 
     * @type {InboxPermissionLevel}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly selectedPermission?: InboxPermissionLevel

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly involvesEmail?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly fromEmail?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly toOrCcEmail?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllReceivedEmails
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllSecurityGroups operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllSecurityGroupsRequest
 */
export interface DefaultApiGetAllSecurityGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSecurityGroups
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllSecurityGroupsBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllSecurityGroupsBySubscriberIdRequest
 */
export interface DefaultApiGetAllSecurityGroupsBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsBySubscriberId
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getAllSecurityGroupsForUsersSubscribersByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserIdRequest
 */
export interface DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllSubmittalCodesByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllSubmittalCodesByProjectIdRequest
 */
export interface DefaultApiGetAllSubmittalCodesByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSubmittalCodesByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for getAllSubscribers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllSubscribersRequest
 */
export interface DefaultApiGetAllSubscribersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllSubscribers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllUserGroupsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllUserGroupsByProjectIdRequest
 */
export interface DefaultApiGetAllUserGroupsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUserGroupsByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for getAllUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllUsersRequest
 */
export interface DefaultApiGetAllUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUsers
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUsers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUsers
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUsers
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllUsers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllUsersSimplifiedBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllUsersSimplifiedBySubscriberIdRequest
 */
export interface DefaultApiGetAllUsersSimplifiedBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllUsersSimplifiedBySubscriberId
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getAllWebsiteSettings operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllWebsiteSettingsRequest
 */
export interface DefaultApiGetAllWebsiteSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetAllWebsiteSettings
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAnnouncementById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAnnouncementByIdRequest
 */
export interface DefaultApiGetAnnouncementByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAnnouncementById
     */
    readonly announcementId: string
}

/**
 * Request parameters for getAssociatedDocumentsByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAssociatedDocumentsByDocumentIdRequest
 */
export interface DefaultApiGetAssociatedDocumentsByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetAssociatedDocumentsByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for getBIMTopFolders operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBIMTopFoldersRequest
 */
export interface DefaultApiGetBIMTopFoldersRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBIMTopFolders
     */
    readonly centerLineProjectId: string
}

/**
 * Request parameters for getBIMTreeNode operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBIMTreeNodeRequest
 */
export interface DefaultApiGetBIMTreeNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBIMTreeNode
     */
    readonly centerLineProjectId: string

    /**
     * 
     * @type {BimTreeNode}
     * @memberof DefaultApiGetBIMTreeNode
     */
    readonly bimTreeNode: BimTreeNode
}

/**
 * Request parameters for getBidById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBidByIdRequest
 */
export interface DefaultApiGetBidByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidById
     */
    readonly bidId: string
}

/**
 * Request parameters for getBidConnectFileDeliveryUploadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBidConnectFileDeliveryUploadLinkRequest
 */
export interface DefaultApiGetBidConnectFileDeliveryUploadLinkRequest {
    /**
     * 
     * @type {BidConnectFileDeliveryUploadRequest}
     * @memberof DefaultApiGetBidConnectFileDeliveryUploadLink
     */
    readonly bidConnectFileDeliveryUploadRequest: BidConnectFileDeliveryUploadRequest
}

/**
 * Request parameters for getBidsOnProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBidsOnProjectByIdRequest
 */
export interface DefaultApiGetBidsOnProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetBidsOnProjectById
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getBluebeamXml operation in DefaultApi.
 * @export
 * @interface DefaultApiGetBluebeamXmlRequest
 */
export interface DefaultApiGetBluebeamXmlRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBluebeamXml
     */
    readonly fileId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetBluebeamXml
     */
    readonly additionalReviewDocumentId?: string
}

/**
 * Request parameters for getCommentById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCommentByIdRequest
 */
export interface DefaultApiGetCommentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentById
     */
    readonly commentId: string
}

/**
 * Request parameters for getCommentsOnDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCommentsOnDocumentByIdRequest
 */
export interface DefaultApiGetCommentsOnDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetCommentsOnDocumentById
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getCommentsOnProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCommentsOnProjectByIdRequest
 */
export interface DefaultApiGetCommentsOnProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetCommentsOnProjectById
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getCompaniesByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCompaniesByProjectIdRequest
 */
export interface DefaultApiGetCompaniesByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCompaniesByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for getCompanyById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCompanyByIdRequest
 */
export interface DefaultApiGetCompanyByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCompanyById
     */
    readonly companyId: string
}

/**
 * Request parameters for getConformingCenterDocumentsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetConformingCenterDocumentsByProjectIdRequest
 */
export interface DefaultApiGetConformingCenterDocumentsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetConformingCenterDocumentsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {DocumentConformingCenterTemplateType}
     * @memberof DefaultApiGetConformingCenterDocumentsByProjectId
     */
    readonly documentTemplateType: DocumentConformingCenterTemplateType
}

/**
 * Request parameters for getConformingCenterSummaryByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetConformingCenterSummaryByProjectIdRequest
 */
export interface DefaultApiGetConformingCenterSummaryByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetConformingCenterSummaryByProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for getCountryById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCountryByIdRequest
 */
export interface DefaultApiGetCountryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetCountryById
     */
    readonly countryId: string
}

/**
 * Request parameters for getDesignFileActionsByProjectAndFullKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDesignFileActionsByProjectAndFullKeyRequest
 */
export interface DefaultApiGetDesignFileActionsByProjectAndFullKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly fullKey: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly ownerUserId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDesignFileActionsByProjectAndFullKey
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getDirectDesignFileDownloadLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDirectDesignFileDownloadLinkRequest
 */
export interface DefaultApiGetDirectDesignFileDownloadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDirectDesignFileDownloadLink
     */
    readonly projectId: string

    /**
     * 
     * @type {AuthenticatedDownloadRequest}
     * @memberof DefaultApiGetDirectDesignFileDownloadLink
     */
    readonly authenticatedDownloadRequest: AuthenticatedDownloadRequest
}

/**
 * Request parameters for getDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentByIdRequest
 */
export interface DefaultApiGetDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentById
     */
    readonly id: string
}

/**
 * Request parameters for getDocumentByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentByKeyRequest
 */
export interface DefaultApiGetDocumentByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentByKey
     */
    readonly key: string
}

/**
 * Request parameters for getDocumentPublicLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentPublicLinkRequest
 */
export interface DefaultApiGetDocumentPublicLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentPublicLink
     */
    readonly documentId: string
}

/**
 * Request parameters for getDocumentPublicLinkAsUser operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentPublicLinkAsUserRequest
 */
export interface DefaultApiGetDocumentPublicLinkAsUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentPublicLinkAsUser
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentPublicLinkAsUser
     */
    readonly userId: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetDocumentPublicLinkAsUser
     */
    readonly isInteractive?: boolean
}

/**
 * Request parameters for getDocumentTemplateByType operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentTemplateByTypeRequest
 */
export interface DefaultApiGetDocumentTemplateByTypeRequest {
    /**
     * 
     * @type {DocumentTemplateType}
     * @memberof DefaultApiGetDocumentTemplateByType
     */
    readonly type: DocumentTemplateType
}

/**
 * Request parameters for getDocumentUserPermissionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentUserPermissionByDocumentIdRequest
 */
export interface DefaultApiGetDocumentUserPermissionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentUserPermissionByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for getDocumentUserPermissionsForDocumentByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentUserPermissionsForDocumentByKeyRequest
 */
export interface DefaultApiGetDocumentUserPermissionsForDocumentByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentUserPermissionsForDocumentByKey
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentUserPermissionsForDocumentByKey
     */
    readonly consultantReviewDocumentId?: string
}

/**
 * Request parameters for getDocumentsAssignedByMe operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentsAssignedByMeRequest
 */
export interface DefaultApiGetDocumentsAssignedByMeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsAssignedByMe
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getDocumentsAssignedToMe operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentsAssignedToMeRequest
 */
export interface DefaultApiGetDocumentsAssignedToMeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsAssignedToMe
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getDocumentsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentsByProjectIdRequest
 */
export interface DefaultApiGetDocumentsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly selectedColumns?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly selectedDocumentTypes?: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly includeFiles?: boolean

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getDocumentsByProjectIdAndDocumentTemplateId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentsByProjectIdAndDocumentTemplateIdRequest
 */
export interface DefaultApiGetDocumentsByProjectIdAndDocumentTemplateIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly documentTemplateId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsByProjectIdAndDocumentTemplateId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getDocumentsSummaryByProjectIdAndDocumentTemplateId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateIdRequest
 */
export interface DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly documentTemplateId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getFaqCategoryById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFaqCategoryByIdRequest
 */
export interface DefaultApiGetFaqCategoryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFaqCategoryById
     */
    readonly faqCategoryId: string
}

/**
 * Request parameters for getFileById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFileByIdRequest
 */
export interface DefaultApiGetFileByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileById
     */
    readonly fileId: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetFileById
     */
    readonly flattened?: boolean
}

/**
 * Request parameters for getFileLinkById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFileLinkByIdRequest
 */
export interface DefaultApiGetFileLinkByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkById
     */
    readonly fileId: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetFileLinkById
     */
    readonly flattened?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetFileLinkById
     */
    readonly fullSize?: boolean

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkById
     */
    readonly filename?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkById
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for getFileLinkByIdForConsultant operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFileLinkByIdForConsultantRequest
 */
export interface DefaultApiGetFileLinkByIdForConsultantRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkByIdForConsultant
     */
    readonly fileId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkByIdForConsultant
     */
    readonly reviewDocumentId: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiGetFileLinkByIdForConsultant
     */
    readonly flattened?: boolean

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileLinkByIdForConsultant
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for getFileUploadLinkByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFileUploadLinkByProjectIdRequest
 */
export interface DefaultApiGetFileUploadLinkByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFileUploadLinkByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {ProjectFileUploadRequestParams}
     * @memberof DefaultApiGetFileUploadLinkByProjectId
     */
    readonly projectFileUploadRequestParams: ProjectFileUploadRequestParams
}

/**
 * Request parameters for getFilesByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetFilesByDocumentIdRequest
 */
export interface DefaultApiGetFilesByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetFilesByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for getLatestProjectArchiveZipFileLink operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLatestProjectArchiveZipFileLinkRequest
 */
export interface DefaultApiGetLatestProjectArchiveZipFileLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetLatestProjectArchiveZipFileLink
     */
    readonly projectId: string
}

/**
 * Request parameters for getLatestSubmissionFileByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLatestSubmissionFileByDocumentIdRequest
 */
export interface DefaultApiGetLatestSubmissionFileByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetLatestSubmissionFileByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for getLatestSubmissionFileByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLatestSubmissionFileByKeyRequest
 */
export interface DefaultApiGetLatestSubmissionFileByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetLatestSubmissionFileByKey
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetLatestSubmissionFileByKey
     */
    readonly consultantReviewDocumentId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetLatestSubmissionFileByKey
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for getManyDocumentPublicLinks operation in DefaultApi.
 * @export
 * @interface DefaultApiGetManyDocumentPublicLinksRequest
 */
export interface DefaultApiGetManyDocumentPublicLinksRequest {
    /**
     * 
     * @type {GetManyDocumentLinksRequest}
     * @memberof DefaultApiGetManyDocumentPublicLinks
     */
    readonly getManyDocumentLinksRequest: GetManyDocumentLinksRequest
}

/**
 * Request parameters for getMostRecentConsultantNotificationsByDocumentIds operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMostRecentConsultantNotificationsByDocumentIdsRequest
 */
export interface DefaultApiGetMostRecentConsultantNotificationsByDocumentIdsRequest {
    /**
     * 
     * @type {ConsultantNotificationRequest}
     * @memberof DefaultApiGetMostRecentConsultantNotificationsByDocumentIds
     */
    readonly consultantNotificationRequest: ConsultantNotificationRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMostRecentConsultantNotificationsByDocumentIds
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for getMyDesignFileSharesByProject operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMyDesignFileSharesByProjectRequest
 */
export interface DefaultApiGetMyDesignFileSharesByProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyDesignFileSharesByProject
     */
    readonly projectId: string
}

/**
 * Request parameters for getMyNotifications operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMyNotificationsRequest
 */
export interface DefaultApiGetMyNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetMyNotifications
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getMyNotificationsWithEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMyNotificationsWithEmailsRequest
 */
export interface DefaultApiGetMyNotificationsWithEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly projectId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetMyNotificationsWithEmails
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getMyNotificationsWithEmailsCount operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMyNotificationsWithEmailsCountRequest
 */
export interface DefaultApiGetMyNotificationsWithEmailsCountRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmailsCount
     */
    readonly projectId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmailsCount
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetMyNotificationsWithEmailsCount
     */
    readonly curatedSampleData?: string
}

/**
 * Request parameters for getNotificationById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationByIdRequest
 */
export interface DefaultApiGetNotificationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationById
     */
    readonly notificationId: string
}

/**
 * Request parameters for getNotificationRecipientsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationRecipientsByIdsRequest
 */
export interface DefaultApiGetNotificationRecipientsByIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiGetNotificationRecipientsByIds
     */
    readonly requestBody: Array<string>
}

/**
 * Request parameters for getNotificationsByBidId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationsByBidIdRequest
 */
export interface DefaultApiGetNotificationsByBidIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByBidId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getNotificationsByBidSetupId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationsByBidSetupIdRequest
 */
export interface DefaultApiGetNotificationsByBidSetupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByBidSetupId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getNotificationsByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationsByDocumentIdRequest
 */
export interface DefaultApiGetNotificationsByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByDocumentId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getNotificationsByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationsByKeyRequest
 */
export interface DefaultApiGetNotificationsByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByKey
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getNotificationsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNotificationsByProjectIdRequest
 */
export interface DefaultApiGetNotificationsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetNotificationsByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getOutboundEmailById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOutboundEmailByIdRequest
 */
export interface DefaultApiGetOutboundEmailByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetOutboundEmailById
     */
    readonly outboundEmailId: string
}

/**
 * Request parameters for getParserJobStatusByObjectKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetParserJobStatusByObjectKeyRequest
 */
export interface DefaultApiGetParserJobStatusByObjectKeyRequest {
    /**
     * 
     * @type {ParserJobStatusRequest}
     * @memberof DefaultApiGetParserJobStatusByObjectKey
     */
    readonly parserJobStatusRequest: ParserJobStatusRequest
}

/**
 * Request parameters for getPermissionByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPermissionByKeyRequest
 */
export interface DefaultApiGetPermissionByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPermissionByKey
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPermissionByKey
     */
    readonly consultantReviewDocumentId?: string
}

/**
 * Request parameters for getPermissionByUserIdAndDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPermissionByUserIdAndDocumentIdRequest
 */
export interface DefaultApiGetPermissionByUserIdAndDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPermissionByUserIdAndDocumentId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPermissionByUserIdAndDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for getPhysicalBuildingsByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPhysicalBuildingsByProjectIdRequest
 */
export interface DefaultApiGetPhysicalBuildingsByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetPhysicalBuildingsByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getPrimeBiddersOnProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPrimeBiddersOnProjectByIdRequest
 */
export interface DefaultApiGetPrimeBiddersOnProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetPrimeBiddersOnProjectById
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getProcoreProjects operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProcoreProjectsRequest
 */
export interface DefaultApiGetProcoreProjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProcoreProjects
     */
    readonly centerLineProjectId: string
}

/**
 * Request parameters for getProcoreSubmittal operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProcoreSubmittalRequest
 */
export interface DefaultApiGetProcoreSubmittalRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProcoreSubmittal
     */
    readonly centerlineProjectId: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetProcoreSubmittal
     */
    readonly procoreDocumentId: number
}

/**
 * Request parameters for getProductPackageById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProductPackageByIdRequest
 */
export interface DefaultApiGetProductPackageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProductPackageById
     */
    readonly productPackageId: string
}

/**
 * Request parameters for getProjectBidSetupById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectBidSetupByIdRequest
 */
export interface DefaultApiGetProjectBidSetupByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectBidSetupById
     */
    readonly id: string
}

/**
 * Request parameters for getProjectBiddingSummaryById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectBiddingSummaryByIdRequest
 */
export interface DefaultApiGetProjectBiddingSummaryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectBiddingSummaryById
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectByIdRequest
 */
export interface DefaultApiGetProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectById
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectSummaryById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectSummaryByIdRequest
 */
export interface DefaultApiGetProjectSummaryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectSummaryById
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectUserByIdRequest
 */
export interface DefaultApiGetProjectUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUserById
     */
    readonly id: string
}

/**
 * Request parameters for getProjectUserSummaryBySecurityGroupId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectUserSummaryBySecurityGroupIdRequest
 */
export interface DefaultApiGetProjectUserSummaryBySecurityGroupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUserSummaryBySecurityGroupId
     */
    readonly securityGroupId: string
}

/**
 * Request parameters for getProjectUsersByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectUsersByProjectIdRequest
 */
export interface DefaultApiGetProjectUsersByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetProjectUsersByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getProjectsWithMostRecentComments operation in DefaultApi.
 * @export
 * @interface DefaultApiGetProjectsWithMostRecentCommentsRequest
 */
export interface DefaultApiGetProjectsWithMostRecentCommentsRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetProjectsWithMostRecentComments
     */
    readonly numMostRecentProjects: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetProjectsWithMostRecentComments
     */
    readonly numMostRecentComments: number
}

/**
 * Request parameters for getPublicDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPublicDocumentByIdRequest
 */
export interface DefaultApiGetPublicDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPublicDocumentById
     */
    readonly id: string
}

/**
 * Request parameters for getPublicUserFileSystemFileNode operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPublicUserFileSystemFileNodeRequest
 */
export interface DefaultApiGetPublicUserFileSystemFileNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPublicUserFileSystemFileNode
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPublicUserFileSystemFileNode
     */
    readonly designFileShareId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPublicUserFileSystemFileNode
     */
    readonly publicAccessKey: string
}

/**
 * Request parameters for getPublicUserInfoByEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPublicUserInfoByEmailRequest
 */
export interface DefaultApiGetPublicUserInfoByEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPublicUserInfoByEmail
     */
    readonly email: string
}

/**
 * Request parameters for getRegistrationUserByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRegistrationUserByKeyRequest
 */
export interface DefaultApiGetRegistrationUserByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRegistrationUserByKey
     */
    readonly key: string
}

/**
 * Request parameters for getSignedUrlForEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSignedUrlForEmailRequest
 */
export interface DefaultApiGetSignedUrlForEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetSignedUrlForEmail
     */
    readonly emailId: string
}

/**
 * Request parameters for getSubscriberAdminsSimplifiedBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSubscriberAdminsSimplifiedBySubscriberIdRequest
 */
export interface DefaultApiGetSubscriberAdminsSimplifiedBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetSubscriberAdminsSimplifiedBySubscriberId
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getSubscriberById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSubscriberByIdRequest
 */
export interface DefaultApiGetSubscriberByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetSubscriberById
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getSubscriberProjectUserSummaryById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSubscriberProjectUserSummaryByIdRequest
 */
export interface DefaultApiGetSubscriberProjectUserSummaryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetSubscriberProjectUserSummaryById
     */
    readonly subscriberId: string
}

/**
 * Request parameters for getSuggestedProjectEmailSlug operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSuggestedProjectEmailSlugRequest
 */
export interface DefaultApiGetSuggestedProjectEmailSlugRequest {
    /**
     * 
     * @type {GetSuggestedProjectEmailSlugRequest}
     * @memberof DefaultApiGetSuggestedProjectEmailSlug
     */
    readonly getSuggestedProjectEmailSlugRequest: GetSuggestedProjectEmailSlugRequest
}

/**
 * Request parameters for getTrashByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetTrashByProjectIdRequest
 */
export interface DefaultApiGetTrashByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetTrashByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetTrashByProjectId
     */
    readonly continuationToken?: string
}

/**
 * Request parameters for getUploadLinkForAddendasByBidSetupId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUploadLinkForAddendasByBidSetupIdRequest
 */
export interface DefaultApiGetUploadLinkForAddendasByBidSetupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUploadLinkForAddendasByBidSetupId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiGetUploadLinkForAddendasByBidSetupId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for getUploadLinkForDrawingsByBidSetupId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUploadLinkForDrawingsByBidSetupIdRequest
 */
export interface DefaultApiGetUploadLinkForDrawingsByBidSetupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUploadLinkForDrawingsByBidSetupId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiGetUploadLinkForDrawingsByBidSetupId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for getUploadLinkForSpecificationsByBidSetupId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUploadLinkForSpecificationsByBidSetupIdRequest
 */
export interface DefaultApiGetUploadLinkForSpecificationsByBidSetupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUploadLinkForSpecificationsByBidSetupId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiGetUploadLinkForSpecificationsByBidSetupId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for getUploadLinkForSubmittalSectionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUploadLinkForSubmittalSectionByDocumentIdRequest
 */
export interface DefaultApiGetUploadLinkForSubmittalSectionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUploadLinkForSubmittalSectionByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiGetUploadLinkForSubmittalSectionByDocumentId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for getUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUserByIdRequest
 */
export interface DefaultApiGetUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUserById
     */
    readonly id: string
}

/**
 * Request parameters for getUserFileSystemFileNode operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUserFileSystemFileNodeRequest
 */
export interface DefaultApiGetUserFileSystemFileNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUserFileSystemFileNode
     */
    readonly projectId: string

    /**
     * 
     * @type {AuthenticatedDownloadRequest}
     * @memberof DefaultApiGetUserFileSystemFileNode
     */
    readonly authenticatedDownloadRequest: AuthenticatedDownloadRequest
}

/**
 * Request parameters for getUsersByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUsersByProjectIdRequest
 */
export interface DefaultApiGetUsersByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly orderByColumn?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly filterByObject?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly curatedSampleData?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetUsersByProjectId
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getWebsiteSettingById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetWebsiteSettingByIdRequest
 */
export interface DefaultApiGetWebsiteSettingByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetWebsiteSettingById
     */
    readonly websiteSettingId: string
}

/**
 * Request parameters for getZippedBidDocuments operation in DefaultApi.
 * @export
 * @interface DefaultApiGetZippedBidDocumentsRequest
 */
export interface DefaultApiGetZippedBidDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetZippedBidDocuments
     */
    readonly bidSetupId: string
}

/**
 * Request parameters for impersonateUser operation in DefaultApi.
 * @export
 * @interface DefaultApiImpersonateUserRequest
 */
export interface DefaultApiImpersonateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiImpersonateUser
     */
    readonly userId: string
}

/**
 * Request parameters for importBimFileByBidSetupId operation in DefaultApi.
 * @export
 * @interface DefaultApiImportBimFileByBidSetupIdRequest
 */
export interface DefaultApiImportBimFileByBidSetupIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiImportBimFileByBidSetupId
     */
    readonly bidSetupId: string

    /**
     * 
     * @type {BidBIMImportParams}
     * @memberof DefaultApiImportBimFileByBidSetupId
     */
    readonly bidBIMImportParams: BidBIMImportParams
}

/**
 * Request parameters for importBimFileByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiImportBimFileByProjectIdRequest
 */
export interface DefaultApiImportBimFileByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiImportBimFileByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {ProjectBIMImportParams}
     * @memberof DefaultApiImportBimFileByProjectId
     */
    readonly projectBIMImportParams: ProjectBIMImportParams
}

/**
 * Request parameters for insertAdditionalReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertAdditionalReviewByDocumentIdRequest
 */
export interface DefaultApiInsertAdditionalReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertAdditionalReviewByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiInsertAdditionalReviewByDocumentId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for insertAdditionalReviewByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertAdditionalReviewByKeyRequest
 */
export interface DefaultApiInsertAdditionalReviewByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertAdditionalReviewByKey
     */
    readonly key: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiInsertAdditionalReviewByKey
     */
    readonly fileUploadRequestParams: FileUploadRequestParams

    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertAdditionalReviewByKey
     */
    readonly consultantReviewDocumentId?: string
}

/**
 * Request parameters for insertAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertAddressRequest
 */
export interface DefaultApiInsertAddressRequest {
    /**
     * 
     * @type {IInsertionAddress}
     * @memberof DefaultApiInsertAddress
     */
    readonly iInsertionAddress: IInsertionAddress
}

/**
 * Request parameters for insertAnnouncement operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertAnnouncementRequest
 */
export interface DefaultApiInsertAnnouncementRequest {
    /**
     * 
     * @type {IInsertionAnnouncement}
     * @memberof DefaultApiInsertAnnouncement
     */
    readonly iInsertionAnnouncement: IInsertionAnnouncement
}

/**
 * Request parameters for insertBidByUserAndProject operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertBidByUserAndProjectRequest
 */
export interface DefaultApiInsertBidByUserAndProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertBidByUserAndProject
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertBidByUserAndProject
     */
    readonly projectId: string

    /**
     * 
     * @type {InsertBidProps}
     * @memberof DefaultApiInsertBidByUserAndProject
     */
    readonly insertBidProps: InsertBidProps
}

/**
 * Request parameters for insertBidSetupByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertBidSetupByProjectIdRequest
 */
export interface DefaultApiInsertBidSetupByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertBidSetupByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {ICustomInsertionBidSetup}
     * @memberof DefaultApiInsertBidSetupByProjectId
     */
    readonly iCustomInsertionBidSetup: ICustomInsertionBidSetup
}

/**
 * Request parameters for insertCommentByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertCommentByDocumentIdRequest
 */
export interface DefaultApiInsertCommentByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertCommentByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {IInsertionComment}
     * @memberof DefaultApiInsertCommentByDocumentId
     */
    readonly iInsertionComment: IInsertionComment
}

/**
 * Request parameters for insertCommentByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertCommentByProjectIdRequest
 */
export interface DefaultApiInsertCommentByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertCommentByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {IInsertionComment}
     * @memberof DefaultApiInsertCommentByProjectId
     */
    readonly iInsertionComment: IInsertionComment
}

/**
 * Request parameters for insertCompany operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertCompanyRequest
 */
export interface DefaultApiInsertCompanyRequest {
    /**
     * 
     * @type {IInsertionCompany}
     * @memberof DefaultApiInsertCompany
     */
    readonly iInsertionCompany: IInsertionCompany
}

/**
 * Request parameters for insertConsultantByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertConsultantByDocumentIdRequest
 */
export interface DefaultApiInsertConsultantByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertConsultantByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {AdditionalConsultantProps}
     * @memberof DefaultApiInsertConsultantByDocumentId
     */
    readonly additionalConsultantProps: AdditionalConsultantProps
}

/**
 * Request parameters for insertConsultantConfidentialCommentByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertConsultantConfidentialCommentByDocumentIdRequest
 */
export interface DefaultApiInsertConsultantConfidentialCommentByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertConsultantConfidentialCommentByDocumentId
     */
    readonly parentDocumentId: string

    /**
     * 
     * @type {IInsertionComment}
     * @memberof DefaultApiInsertConsultantConfidentialCommentByDocumentId
     */
    readonly iInsertionComment: IInsertionComment
}

/**
 * Request parameters for insertDocument operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertDocumentRequest
 */
export interface DefaultApiInsertDocumentRequest {
    /**
     * 
     * @type {InsertDocumentProps}
     * @memberof DefaultApiInsertDocument
     */
    readonly insertDocumentProps: InsertDocumentProps
}

/**
 * Request parameters for insertDocumentUser operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertDocumentUserRequest
 */
export interface DefaultApiInsertDocumentUserRequest {
    /**
     * 
     * @type {IInsertionDocumentUser}
     * @memberof DefaultApiInsertDocumentUser
     */
    readonly iInsertionDocumentUser: IInsertionDocumentUser
}

/**
 * Request parameters for insertFileByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertFileByDocumentIdRequest
 */
export interface DefaultApiInsertFileByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertFileByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {DocumentFileUploadRequest}
     * @memberof DefaultApiInsertFileByDocumentId
     */
    readonly documentFileUploadRequest: DocumentFileUploadRequest
}

/**
 * Request parameters for insertFloorPlanDrawingFileByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertFloorPlanDrawingFileByProjectIdRequest
 */
export interface DefaultApiInsertFloorPlanDrawingFileByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertFloorPlanDrawingFileByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiInsertFloorPlanDrawingFileByProjectId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for insertFloorPlanImageFileByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertFloorPlanImageFileByProjectIdRequest
 */
export interface DefaultApiInsertFloorPlanImageFileByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertFloorPlanImageFileByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiInsertFloorPlanImageFileByProjectId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for insertLocationImageFileByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertLocationImageFileByProjectIdRequest
 */
export interface DefaultApiInsertLocationImageFileByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertLocationImageFileByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {FileUploadRequestParams}
     * @memberof DefaultApiInsertLocationImageFileByProjectId
     */
    readonly fileUploadRequestParams: FileUploadRequestParams
}

/**
 * Request parameters for insertManyPhysicalBuildings operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertManyPhysicalBuildingsRequest
 */
export interface DefaultApiInsertManyPhysicalBuildingsRequest {
    /**
     * 
     * @type {InsertManyPhysicalBuildingsRequest}
     * @memberof DefaultApiInsertManyPhysicalBuildings
     */
    readonly insertManyPhysicalBuildingsRequest: InsertManyPhysicalBuildingsRequest
}

/**
 * Request parameters for insertManyPhysicalLocationsByFloorIds operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertManyPhysicalLocationsByFloorIdsRequest
 */
export interface DefaultApiInsertManyPhysicalLocationsByFloorIdsRequest {
    /**
     * 
     * @type {InsertManyPhysicalLocationsRequest}
     * @memberof DefaultApiInsertManyPhysicalLocationsByFloorIds
     */
    readonly insertManyPhysicalLocationsRequest: InsertManyPhysicalLocationsRequest
}

/**
 * Request parameters for insertManyProjectlessUser operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertManyProjectlessUserRequest
 */
export interface DefaultApiInsertManyProjectlessUserRequest {
    /**
     * 
     * @type {IInsertManyProjectlessUsersParams}
     * @memberof DefaultApiInsertManyProjectlessUser
     */
    readonly iInsertManyProjectlessUsersParams: IInsertManyProjectlessUsersParams
}

/**
 * Request parameters for insertPhoneNumberByCompanyId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertPhoneNumberByCompanyIdRequest
 */
export interface DefaultApiInsertPhoneNumberByCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertPhoneNumberByCompanyId
     */
    readonly companyId: string

    /**
     * 
     * @type {IInsertionPhoneNumber}
     * @memberof DefaultApiInsertPhoneNumberByCompanyId
     */
    readonly iInsertionPhoneNumber: IInsertionPhoneNumber
}

/**
 * Request parameters for insertPhoneNumberByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertPhoneNumberByUserIdRequest
 */
export interface DefaultApiInsertPhoneNumberByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertPhoneNumberByUserId
     */
    readonly userId: string

    /**
     * 
     * @type {IInsertionPhoneNumber}
     * @memberof DefaultApiInsertPhoneNumberByUserId
     */
    readonly iInsertionPhoneNumber: IInsertionPhoneNumber
}

/**
 * Request parameters for insertPhysicalBuilding operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertPhysicalBuildingRequest
 */
export interface DefaultApiInsertPhysicalBuildingRequest {
    /**
     * 
     * @type {CustomInsertionPhysicalBuilding}
     * @memberof DefaultApiInsertPhysicalBuilding
     */
    readonly customInsertionPhysicalBuilding: CustomInsertionPhysicalBuilding
}

/**
 * Request parameters for insertPhysicalFloor operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertPhysicalFloorRequest
 */
export interface DefaultApiInsertPhysicalFloorRequest {
    /**
     * 
     * @type {IInsertionPhysicalFloor}
     * @memberof DefaultApiInsertPhysicalFloor
     */
    readonly iInsertionPhysicalFloor: IInsertionPhysicalFloor
}

/**
 * Request parameters for insertPrimeBidderByUserAndProject operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertPrimeBidderByUserAndProjectRequest
 */
export interface DefaultApiInsertPrimeBidderByUserAndProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertPrimeBidderByUserAndProject
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertPrimeBidderByUserAndProject
     */
    readonly projectId: string

    /**
     * 
     * @type {IInsertionPrimeBidder}
     * @memberof DefaultApiInsertPrimeBidderByUserAndProject
     */
    readonly iInsertionPrimeBidder: IInsertionPrimeBidder
}

/**
 * Request parameters for insertProductPackage operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertProductPackageRequest
 */
export interface DefaultApiInsertProductPackageRequest {
    /**
     * 
     * @type {IInsertionProductPackage}
     * @memberof DefaultApiInsertProductPackage
     */
    readonly iInsertionProductPackage: IInsertionProductPackage
}

/**
 * Request parameters for insertProject operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertProjectRequest
 */
export interface DefaultApiInsertProjectRequest {
    /**
     * 
     * @type {ICustomInsertionProject}
     * @memberof DefaultApiInsertProject
     */
    readonly iCustomInsertionProject: ICustomInsertionProject
}

/**
 * Request parameters for insertProjectCalendarEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertProjectCalendarEventRequest
 */
export interface DefaultApiInsertProjectCalendarEventRequest {
    /**
     * 
     * @type {IInsertionProjectCalendarEvent}
     * @memberof DefaultApiInsertProjectCalendarEvent
     */
    readonly iInsertionProjectCalendarEvent: IInsertionProjectCalendarEvent
}

/**
 * Request parameters for insertProjectlessUser operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertProjectlessUserRequest
 */
export interface DefaultApiInsertProjectlessUserRequest {
    /**
     * 
     * @type {ICustomInsertionUser}
     * @memberof DefaultApiInsertProjectlessUser
     */
    readonly iCustomInsertionUser: ICustomInsertionUser
}

/**
 * Request parameters for insertSecurityGroupBySubscriberId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertSecurityGroupBySubscriberIdRequest
 */
export interface DefaultApiInsertSecurityGroupBySubscriberIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertSecurityGroupBySubscriberId
     */
    readonly subscriberId: string

    /**
     * 
     * @type {IInsertionSubscriberSecurityGroup}
     * @memberof DefaultApiInsertSecurityGroupBySubscriberId
     */
    readonly iInsertionSubscriberSecurityGroup: IInsertionSubscriberSecurityGroup
}

/**
 * Request parameters for insertSubmissionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertSubmissionByDocumentIdRequest
 */
export interface DefaultApiInsertSubmissionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertSubmissionByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {SubmittalUploadRequest}
     * @memberof DefaultApiInsertSubmissionByDocumentId
     */
    readonly submittalUploadRequest: SubmittalUploadRequest
}

/**
 * Request parameters for insertSubmissionByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertSubmissionByKeyRequest
 */
export interface DefaultApiInsertSubmissionByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertSubmissionByKey
     */
    readonly key: string

    /**
     * 
     * @type {SubmittalUploadRequest}
     * @memberof DefaultApiInsertSubmissionByKey
     */
    readonly submittalUploadRequest: SubmittalUploadRequest
}

/**
 * Request parameters for insertSubscriber operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertSubscriberRequest
 */
export interface DefaultApiInsertSubscriberRequest {
    /**
     * 
     * @type {IInsertionSubscriber}
     * @memberof DefaultApiInsertSubscriber
     */
    readonly iInsertionSubscriber: IInsertionSubscriber
}

/**
 * Request parameters for insertUser operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertUserRequest
 */
export interface DefaultApiInsertUserRequest {
    /**
     * 
     * @type {IAddNewUser}
     * @memberof DefaultApiInsertUser
     */
    readonly iAddNewUser: IAddNewUser
}

/**
 * Request parameters for insertUserGroupByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertUserGroupByProjectIdRequest
 */
export interface DefaultApiInsertUserGroupByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiInsertUserGroupByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {UserGroupInsertionRequest}
     * @memberof DefaultApiInsertUserGroupByProjectId
     */
    readonly userGroupInsertionRequest: UserGroupInsertionRequest
}

/**
 * Request parameters for listUserFileSystem operation in DefaultApi.
 * @export
 * @interface DefaultApiListUserFileSystemRequest
 */
export interface DefaultApiListUserFileSystemRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiListUserFileSystem
     */
    readonly projectId: string
}

/**
 * Request parameters for listUserFileSystemCompressed operation in DefaultApi.
 * @export
 * @interface DefaultApiListUserFileSystemCompressedRequest
 */
export interface DefaultApiListUserFileSystemCompressedRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiListUserFileSystemCompressed
     */
    readonly projectId: string
}

/**
 * Request parameters for markNotificationAsRead operation in DefaultApi.
 * @export
 * @interface DefaultApiMarkNotificationAsReadRequest
 */
export interface DefaultApiMarkNotificationAsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMarkNotificationAsRead
     */
    readonly notificationId: string
}

/**
 * Request parameters for markNotificationsAsReadByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiMarkNotificationsAsReadByUserIdRequest
 */
export interface DefaultApiMarkNotificationsAsReadByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMarkNotificationsAsReadByUserId
     */
    readonly userId: string
}

/**
 * Request parameters for markParserFileAsConformed operation in DefaultApi.
 * @export
 * @interface DefaultApiMarkParserFileAsConformedRequest
 */
export interface DefaultApiMarkParserFileAsConformedRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMarkParserFileAsConformed
     */
    readonly fileId: string

    /**
     * 
     * @type {MarkParserFileAsConformedRequest}
     * @memberof DefaultApiMarkParserFileAsConformed
     */
    readonly markParserFileAsConformedRequest: MarkParserFileAsConformedRequest
}

/**
 * Request parameters for modifyDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyDocumentByIdRequest
 */
export interface DefaultApiModifyDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiModifyDocumentById
     */
    readonly documentId: string

    /**
     * 
     * @type {IModifyDocumentRequest}
     * @memberof DefaultApiModifyDocumentById
     */
    readonly iModifyDocumentRequest: IModifyDocumentRequest
}

/**
 * Request parameters for modifyDocumentByIdWithResponse operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyDocumentByIdWithResponseRequest
 */
export interface DefaultApiModifyDocumentByIdWithResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiModifyDocumentByIdWithResponse
     */
    readonly documentId: string

    /**
     * 
     * @type {IModifyDocumentRequest}
     * @memberof DefaultApiModifyDocumentByIdWithResponse
     */
    readonly iModifyDocumentRequest: IModifyDocumentRequest
}

/**
 * Request parameters for modifyDocumentsByBatch operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyDocumentsByBatchRequest
 */
export interface DefaultApiModifyDocumentsByBatchRequest {
    /**
     * 
     * @type {IModifyDocumentsByBatchRequest}
     * @memberof DefaultApiModifyDocumentsByBatch
     */
    readonly iModifyDocumentsByBatchRequest: IModifyDocumentsByBatchRequest
}

/**
 * Request parameters for modifyDocumentsByBatches operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyDocumentsByBatchesRequest
 */
export interface DefaultApiModifyDocumentsByBatchesRequest {
    /**
     * 
     * @type {IModifyDocumentsByBatchesRequest}
     * @memberof DefaultApiModifyDocumentsByBatches
     */
    readonly iModifyDocumentsByBatchesRequest: IModifyDocumentsByBatchesRequest
}

/**
 * Request parameters for modifyUserFileSystemNode operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyUserFileSystemNodeRequest
 */
export interface DefaultApiModifyUserFileSystemNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiModifyUserFileSystemNode
     */
    readonly projectId: string

    /**
     * 
     * @type {ModifyUserFileSystemNodeRequest}
     * @memberof DefaultApiModifyUserFileSystemNode
     */
    readonly modifyUserFileSystemNodeRequest: ModifyUserFileSystemNodeRequest
}

/**
 * Request parameters for notReadyForSubmissionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiNotReadyForSubmissionByDocumentIdRequest
 */
export interface DefaultApiNotReadyForSubmissionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiNotReadyForSubmissionByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for notifyUsersOfAccessToDesignFile operation in DefaultApi.
 * @export
 * @interface DefaultApiNotifyUsersOfAccessToDesignFileRequest
 */
export interface DefaultApiNotifyUsersOfAccessToDesignFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiNotifyUsersOfAccessToDesignFile
     */
    readonly projectId: string

    /**
     * 
     * @type {NotifyUserAccessDesignFileRequest}
     * @memberof DefaultApiNotifyUsersOfAccessToDesignFile
     */
    readonly notifyUserAccessDesignFileRequest: NotifyUserAccessDesignFileRequest
}

/**
 * Request parameters for permanentlyDeleteProjectById operation in DefaultApi.
 * @export
 * @interface DefaultApiPermanentlyDeleteProjectByIdRequest
 */
export interface DefaultApiPermanentlyDeleteProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPermanentlyDeleteProjectById
     */
    readonly projectId: string
}

/**
 * Request parameters for refuseSubmissionByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiRefuseSubmissionByDocumentIdRequest
 */
export interface DefaultApiRefuseSubmissionByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRefuseSubmissionByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {RejectDocumentRequest}
     * @memberof DefaultApiRefuseSubmissionByDocumentId
     */
    readonly rejectDocumentRequest: RejectDocumentRequest
}

/**
 * Request parameters for removeDocumentFromPackageByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiRemoveDocumentFromPackageByDocumentIdRequest
 */
export interface DefaultApiRemoveDocumentFromPackageByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemoveDocumentFromPackageByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemoveDocumentFromPackageByDocumentId
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for removePlanHolderFromProjectByUserIdAndProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectIdRequest
 */
export interface DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectId
     */
    readonly projectId: string
}

/**
 * Request parameters for removeUserFromProjectByProjectId operation in DefaultApi.
 * @export
 * @interface DefaultApiRemoveUserFromProjectByProjectIdRequest
 */
export interface DefaultApiRemoveUserFromProjectByProjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemoveUserFromProjectByProjectId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiRemoveUserFromProjectByProjectId
     */
    readonly userId: string
}

/**
 * Request parameters for replaceDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceDocumentByIdRequest
 */
export interface DefaultApiReplaceDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiReplaceDocumentById
     */
    readonly id: string
}

/**
 * Request parameters for replaceFileByDocumentIdAndFileId operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceFileByDocumentIdAndFileIdRequest
 */
export interface DefaultApiReplaceFileByDocumentIdAndFileIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiReplaceFileByDocumentIdAndFileId
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiReplaceFileByDocumentIdAndFileId
     */
    readonly fileId: string

    /**
     * 
     * @type {BaseFileUploadParams}
     * @memberof DefaultApiReplaceFileByDocumentIdAndFileId
     */
    readonly baseFileUploadParams: BaseFileUploadParams
}

/**
 * Request parameters for replaceWebsiteSettingById operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceWebsiteSettingByIdRequest
 */
export interface DefaultApiReplaceWebsiteSettingByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiReplaceWebsiteSettingById
     */
    readonly websiteSettingId: string
}

/**
 * Request parameters for respondToReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiRespondToReviewByDocumentIdRequest
 */
export interface DefaultApiRespondToReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRespondToReviewByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {RespondToReviewProps}
     * @memberof DefaultApiRespondToReviewByDocumentId
     */
    readonly respondToReviewProps: RespondToReviewProps
}

/**
 * Request parameters for restoreDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiRestoreDocumentByIdRequest
 */
export interface DefaultApiRestoreDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRestoreDocumentById
     */
    readonly documentId: string
}

/**
 * Request parameters for restoreDocumentsByIds operation in DefaultApi.
 * @export
 * @interface DefaultApiRestoreDocumentsByIdsRequest
 */
export interface DefaultApiRestoreDocumentsByIdsRequest {
    /**
     * 
     * @type {RestoreDocumentsRequest}
     * @memberof DefaultApiRestoreDocumentsByIds
     */
    readonly restoreDocumentsRequest: RestoreDocumentsRequest
}

/**
 * Request parameters for retractBidById operation in DefaultApi.
 * @export
 * @interface DefaultApiRetractBidByIdRequest
 */
export interface DefaultApiRetractBidByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRetractBidById
     */
    readonly bidId: string
}

/**
 * Request parameters for sendBidConnectFileDeliveryEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiSendBidConnectFileDeliveryEmailRequest
 */
export interface DefaultApiSendBidConnectFileDeliveryEmailRequest {
    /**
     * 
     * @type {BidConnectFileDeliveryEmailRequest}
     * @memberof DefaultApiSendBidConnectFileDeliveryEmail
     */
    readonly bidConnectFileDeliveryEmailRequest: BidConnectFileDeliveryEmailRequest
}

/**
 * Request parameters for sendDirectDesignFileDownloadEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiSendDirectDesignFileDownloadEmailsRequest
 */
export interface DefaultApiSendDirectDesignFileDownloadEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSendDirectDesignFileDownloadEmails
     */
    readonly projectId: string

    /**
     * 
     * @type {SendPublicDesignFileDownloadLinkRequest}
     * @memberof DefaultApiSendDirectDesignFileDownloadEmails
     */
    readonly sendPublicDesignFileDownloadLinkRequest: SendPublicDesignFileDownloadLinkRequest
}

/**
 * Request parameters for sendDocumentPublicLinkEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiSendDocumentPublicLinkEmailsRequest
 */
export interface DefaultApiSendDocumentPublicLinkEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSendDocumentPublicLinkEmails
     */
    readonly documentId: string

    /**
     * 
     * @type {EmailRequest}
     * @memberof DefaultApiSendDocumentPublicLinkEmails
     */
    readonly emailRequest: EmailRequest
}

/**
 * Request parameters for sendPrivateProjectInviteEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiSendPrivateProjectInviteEmailsRequest
 */
export interface DefaultApiSendPrivateProjectInviteEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSendPrivateProjectInviteEmails
     */
    readonly projectId: string

    /**
     * 
     * @type {SendPrivateInviteEmailRequestParams}
     * @memberof DefaultApiSendPrivateProjectInviteEmails
     */
    readonly sendPrivateInviteEmailRequestParams: SendPrivateInviteEmailRequestParams
}

/**
 * Request parameters for sendPublicFolderAccessToEmails operation in DefaultApi.
 * @export
 * @interface DefaultApiSendPublicFolderAccessToEmailsRequest
 */
export interface DefaultApiSendPublicFolderAccessToEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSendPublicFolderAccessToEmails
     */
    readonly projectId: string

    /**
     * 
     * @type {SendPublicLinkToEmailsRequest}
     * @memberof DefaultApiSendPublicFolderAccessToEmails
     */
    readonly sendPublicLinkToEmailsRequest: SendPublicLinkToEmailsRequest
}

/**
 * Request parameters for shareMultipleUserFileSystemObject operation in DefaultApi.
 * @export
 * @interface DefaultApiShareMultipleUserFileSystemObjectRequest
 */
export interface DefaultApiShareMultipleUserFileSystemObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiShareMultipleUserFileSystemObject
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<ShareFileSystemObjectRequest>}
     * @memberof DefaultApiShareMultipleUserFileSystemObject
     */
    readonly shareFileSystemObjectRequest: Array<ShareFileSystemObjectRequest>
}

/**
 * Request parameters for shareUserFileSystemObject operation in DefaultApi.
 * @export
 * @interface DefaultApiShareUserFileSystemObjectRequest
 */
export interface DefaultApiShareUserFileSystemObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiShareUserFileSystemObject
     */
    readonly projectId: string

    /**
     * 
     * @type {ShareFileSystemObjectRequest}
     * @memberof DefaultApiShareUserFileSystemObject
     */
    readonly shareFileSystemObjectRequest: ShareFileSystemObjectRequest
}

/**
 * Request parameters for skipAssignSubcontractorByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiSkipAssignSubcontractorByDocumentIdRequest
 */
export interface DefaultApiSkipAssignSubcontractorByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSkipAssignSubcontractorByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for subcontractorRefuseAssignmentByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiSubcontractorRefuseAssignmentByDocumentIdRequest
 */
export interface DefaultApiSubcontractorRefuseAssignmentByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubcontractorRefuseAssignmentByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubcontractorRefuseAssignmentByDocumentId
     */
    readonly unauthenticatedUserEmail?: string
}

/**
 * Request parameters for submitAdditionalReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiSubmitAdditionalReviewByDocumentIdRequest
 */
export interface DefaultApiSubmitAdditionalReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitAdditionalReviewByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {AdditionalReviewProps}
     * @memberof DefaultApiSubmitAdditionalReviewByDocumentId
     */
    readonly additionalReviewProps: AdditionalReviewProps
}

/**
 * Request parameters for submitAdditionalReviewByKey operation in DefaultApi.
 * @export
 * @interface DefaultApiSubmitAdditionalReviewByKeyRequest
 */
export interface DefaultApiSubmitAdditionalReviewByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitAdditionalReviewByKey
     */
    readonly key: string

    /**
     * 
     * @type {AdditionalReviewProps}
     * @memberof DefaultApiSubmitAdditionalReviewByKey
     */
    readonly additionalReviewProps: AdditionalReviewProps

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitAdditionalReviewByKey
     */
    readonly consultantReviewDocumentId?: string
}

/**
 * Request parameters for submitToArchitectByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiSubmitToArchitectByDocumentIdRequest
 */
export interface DefaultApiSubmitToArchitectByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitToArchitectByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {SubmitToArchitectProps}
     * @memberof DefaultApiSubmitToArchitectByDocumentId
     */
    readonly submitToArchitectProps: SubmitToArchitectProps
}

/**
 * Request parameters for syncProcoreDocuments operation in DefaultApi.
 * @export
 * @interface DefaultApiSyncProcoreDocumentsRequest
 */
export interface DefaultApiSyncProcoreDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSyncProcoreDocuments
     */
    readonly centerLineProjectId: string

    /**
     * 
     * @type {ProcoreDocumentType}
     * @memberof DefaultApiSyncProcoreDocuments
     */
    readonly documentType: ProcoreDocumentType
}

/**
 * Request parameters for toggleRemindersByDocumentKey operation in DefaultApi.
 * @export
 * @interface DefaultApiToggleRemindersByDocumentKeyRequest
 */
export interface DefaultApiToggleRemindersByDocumentKeyRequest {
    /**
     * 
     * @type {ToggleRemindersProps}
     * @memberof DefaultApiToggleRemindersByDocumentKey
     */
    readonly toggleRemindersProps: ToggleRemindersProps
}

/**
 * Request parameters for undoCompleteReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiUndoCompleteReviewByDocumentIdRequest
 */
export interface DefaultApiUndoCompleteReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUndoCompleteReviewByDocumentId
     */
    readonly documentId: string
}

/**
 * Request parameters for unlockProjectsWithKeyByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiUnlockProjectsWithKeyByUserIdRequest
 */
export interface DefaultApiUnlockProjectsWithKeyByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUnlockProjectsWithKeyByUserId
     */
    readonly userId: string

    /**
     * 
     * @type {UnlockProjectsWithKeyParams}
     * @memberof DefaultApiUnlockProjectsWithKeyByUserId
     */
    readonly unlockProjectsWithKeyParams: UnlockProjectsWithKeyParams
}

/**
 * Request parameters for unsubscribePlanholderByProjectIdAndUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiUnsubscribePlanholderByProjectIdAndUserIdRequest
 */
export interface DefaultApiUnsubscribePlanholderByProjectIdAndUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUnsubscribePlanholderByProjectIdAndUserId
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUnsubscribePlanholderByProjectIdAndUserId
     */
    readonly userId: string
}

/**
 * Request parameters for updateBaxFile operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateBaxFileRequest
 */
export interface DefaultApiUpdateBaxFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUpdateBaxFile
     */
    readonly fileId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUpdateBaxFile
     */
    readonly originalPdfId: string
}

/**
 * Request parameters for updateReviewByDocumentId operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateReviewByDocumentIdRequest
 */
export interface DefaultApiUpdateReviewByDocumentIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUpdateReviewByDocumentId
     */
    readonly documentId: string

    /**
     * 
     * @type {UpdateReviewParams}
     * @memberof DefaultApiUpdateReviewByDocumentId
     */
    readonly updateReviewParams: UpdateReviewParams
}

/**
 * Request parameters for uploadToStaging operation in DefaultApi.
 * @export
 * @interface DefaultApiUploadToStagingRequest
 */
export interface DefaultApiUploadToStagingRequest {
    /**
     * 
     * @type {S3StagingUploadRequest}
     * @memberof DefaultApiUploadToStaging
     */
    readonly s3StagingUploadRequest: S3StagingUploadRequest
}

/**
 * Request parameters for withdrawDocumentById operation in DefaultApi.
 * @export
 * @interface DefaultApiWithdrawDocumentByIdRequest
 */
export interface DefaultApiWithdrawDocumentByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiWithdrawDocumentById
     */
    readonly documentId: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DefaultApiAcceptSubmissionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public acceptSubmissionByDocumentId(requestParameters: DefaultApiAcceptSubmissionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).acceptSubmissionByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAddDocumentToPackageByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addDocumentToPackageByDocumentId(requestParameters: DefaultApiAddDocumentToPackageByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).addDocumentToPackageByDocumentId(requestParameters.documentId, requestParameters.addDocumentToPackageProps, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAddOrModifyUsersByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addOrModifyUsersByProjectId(requestParameters: DefaultApiAddOrModifyUsersByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).addOrModifyUsersByProjectId(requestParameters.projectId, requestParameters.addUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAssignUsersToCompanyByEmailDomainRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assignUsersToCompanyByEmailDomain(requestParameters: DefaultApiAssignUsersToCompanyByEmailDomainRequest, options?: any) {
        return DefaultApiFp(this.configuration).assignUsersToCompanyByEmailDomain(requestParameters.assignByDomainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthorizeBIM360Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authorizeBIM360(requestParameters: DefaultApiAuthorizeBIM360Request, options?: any) {
        return DefaultApiFp(this.configuration).authorizeBIM360(requestParameters.centerLineProjectId, requestParameters.iAuthorizeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthorizeProcoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authorizeProcore(requestParameters: DefaultApiAuthorizeProcoreRequest, options?: any) {
        return DefaultApiFp(this.configuration).authorizeProcore(requestParameters.centerLineProjectId, requestParameters.iAuthorizeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiBeginDrawingsParserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public beginDrawingsParser(requestParameters: DefaultApiBeginDrawingsParserRequest, options?: any) {
        return DefaultApiFp(this.configuration).beginDrawingsParser(requestParameters.drawingsParserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiBeginDrawingsPreprocessorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public beginDrawingsPreprocessor(requestParameters: DefaultApiBeginDrawingsPreprocessorRequest, options?: any) {
        return DefaultApiFp(this.configuration).beginDrawingsPreprocessor(requestParameters.drawingsPreprocessorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiBeginSpecificationsParserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public beginSpecificationsParser(requestParameters: DefaultApiBeginSpecificationsParserRequest, options?: any) {
        return DefaultApiFp(this.configuration).beginSpecificationsParser(requestParameters.specificationsParserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompleteMultipartUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public completeMultipartUpload(requestParameters: DefaultApiCompleteMultipartUploadRequest, options?: any) {
        return DefaultApiFp(this.configuration).completeMultipartUpload(requestParameters.completeMultiPartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompleteReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public completeReviewByDocumentId(requestParameters: DefaultApiCompleteReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).completeReviewByDocumentId(requestParameters.documentId, requestParameters.submittalReviewProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCreateErrorEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createErrorEmail(requestParameters: DefaultApiCreateErrorEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).createErrorEmail(requestParameters.errorEmail, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCreateLinkedDocumentsForAssignedUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLinkedDocumentsForAssignedUsers(requestParameters: DefaultApiCreateLinkedDocumentsForAssignedUsersRequest, options?: any) {
        return DefaultApiFp(this.configuration).createLinkedDocumentsForAssignedUsers(requestParameters.documentId, requestParameters.createLinkedDocumentsForAssignedUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCreateUserFileSystemFolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUserFileSystemFolder(requestParameters: DefaultApiCreateUserFileSystemFolderRequest, options?: any) {
        return DefaultApiFp(this.configuration).createUserFileSystemFolder(requestParameters.projectId, requestParameters.authenticatedOtherFileSystemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteAllSecurityGroupsBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAllSecurityGroupsBySubscriberId(requestParameters: DefaultApiDeleteAllSecurityGroupsBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteAllSecurityGroupsBySubscriberId(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteAnnouncementByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAnnouncementById(requestParameters: DefaultApiDeleteAnnouncementByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteAnnouncementById(requestParameters.announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteBidSetupByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteBidSetupByProjectId(requestParameters: DefaultApiDeleteBidSetupByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteBidSetupByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDocumentById(requestParameters: DefaultApiDeleteDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteDocumentById(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteDocumentsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDocumentsByIds(requestParameters: DefaultApiDeleteDocumentsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteDocumentsByIds(requestParameters.deleteDocumentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteFileByBidSetupIdAndFileIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFileByBidSetupIdAndFileId(requestParameters: DefaultApiDeleteFileByBidSetupIdAndFileIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteFileByBidSetupIdAndFileId(requestParameters.bidSetupId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteInviteEmailByProjectIdAndUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteInviteEmailByProjectIdAndUserId(requestParameters: DefaultApiDeleteInviteEmailByProjectIdAndUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteInviteEmailByProjectIdAndUserId(requestParameters.projectId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteManyUsersByEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteManyUsersByEmail(requestParameters: DefaultApiDeleteManyUsersByEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteManyUsersByEmail(requestParameters.iDeleteUsersByEmailParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteParsedFileByProjectIdAndFileIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteParsedFileByProjectIdAndFileId(requestParameters: DefaultApiDeleteParsedFileByProjectIdAndFileIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteParsedFileByProjectIdAndFileId(requestParameters.projectId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteParsedFilesByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteParsedFilesByProjectId(requestParameters: DefaultApiDeleteParsedFilesByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteParsedFilesByProjectId(requestParameters.projectId, requestParameters.deleteParsedFilesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeletePhysicalBuildingByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePhysicalBuildingById(requestParameters: DefaultApiDeletePhysicalBuildingByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deletePhysicalBuildingById(requestParameters.buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeletePhysicalFloorByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePhysicalFloorById(requestParameters: DefaultApiDeletePhysicalFloorByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deletePhysicalFloorById(requestParameters.floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeletePhysicalLocationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePhysicalLocationById(requestParameters: DefaultApiDeletePhysicalLocationByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deletePhysicalLocationById(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeletePrimeBidderByUserAndProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePrimeBidderByUserAndProject(requestParameters: DefaultApiDeletePrimeBidderByUserAndProjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).deletePrimeBidderByUserAndProject(requestParameters.userId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteProductPackageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductPackageById(requestParameters: DefaultApiDeleteProductPackageByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductPackageById(requestParameters.productPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteSecurityGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSecurityGroupById(requestParameters: DefaultApiDeleteSecurityGroupByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteSecurityGroupById(requestParameters.id, requestParameters.newSecurityGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteSubscriberByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSubscriberById(requestParameters: DefaultApiDeleteSubscriberByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteSubscriberById(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteTrashItemsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTrashItemsByProjectId(requestParameters: DefaultApiDeleteTrashItemsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteTrashItemsByProjectId(requestParameters.projectId, requestParameters.deleteTrashItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserById(requestParameters: DefaultApiDeleteUserByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteUserFileSystemNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserFileSystemNode(requestParameters: DefaultApiDeleteUserFileSystemNodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserFileSystemNode(requestParameters.projectId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteUserGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserGroupById(requestParameters: DefaultApiDeleteUserGroupByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserGroupById(requestParameters.userGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDeleteUserNotificationsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserNotificationsByIds(requestParameters: DefaultApiDeleteUserNotificationsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserNotificationsByIds(requestParameters.deleteUserNotificationsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDisableRegistrationReminderByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public disableRegistrationReminderByUserId(requestParameters: DefaultApiDisableRegistrationReminderByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).disableRegistrationReminderByUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDownloadTrashItemByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTrashItemByProjectId(requestParameters: DefaultApiDownloadTrashItemByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).downloadTrashItemByProjectId(requestParameters.getTrashItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditAnnouncementByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editAnnouncementById(requestParameters: DefaultApiEditAnnouncementByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editAnnouncementById(requestParameters.announcementId, requestParameters.iOptionalAnnouncement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditAnticipatedSubmissionDateOfDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editAnticipatedSubmissionDateOfDocumentById(requestParameters: DefaultApiEditAnticipatedSubmissionDateOfDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editAnticipatedSubmissionDateOfDocumentById(requestParameters.documentId, requestParameters.anticipatedSubmissionDateChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditBidSetupByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editBidSetupByProjectId(requestParameters: DefaultApiEditBidSetupByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editBidSetupByProjectId(requestParameters.projectId, requestParameters.iCustomOptionalBidSetup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editDocumentById(requestParameters: DefaultApiEditDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editDocumentById(requestParameters.documentId, requestParameters.iOptionalDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditDocumentSettingsByUserIdAndDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editDocumentSettingsByUserIdAndDocumentId(requestParameters: DefaultApiEditDocumentSettingsByUserIdAndDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editDocumentSettingsByUserIdAndDocumentId(requestParameters.userId, requestParameters.documentId, requestParameters.iEditDocumentSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditFavoriteProjectByUserIdAndProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editFavoriteProjectByUserIdAndProjectId(requestParameters: DefaultApiEditFavoriteProjectByUserIdAndProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editFavoriteProjectByUserIdAndProjectId(requestParameters.userId, requestParameters.projectId, requestParameters.editFavoriteProjectParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditFileByDocumentIdAndFileIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editFileByDocumentIdAndFileId(requestParameters: DefaultApiEditFileByDocumentIdAndFileIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editFileByDocumentIdAndFileId(requestParameters.documentId, requestParameters.fileId, requestParameters.editFileParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditFileByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editFileById(requestParameters: DefaultApiEditFileByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editFileById(requestParameters.fileId, requestParameters.iOptionalFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditManyPhysicalFloorsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editManyPhysicalFloorsByIds(requestParameters: DefaultApiEditManyPhysicalFloorsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).editManyPhysicalFloorsByIds(requestParameters.editManyPhysicalFloorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditPhysicalBuildingByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editPhysicalBuildingById(requestParameters: DefaultApiEditPhysicalBuildingByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editPhysicalBuildingById(requestParameters.buildingId, requestParameters.customOptionalPhysicalBuilding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditPhysicalFloorByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editPhysicalFloorById(requestParameters: DefaultApiEditPhysicalFloorByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editPhysicalFloorById(requestParameters.floorId, requestParameters.iOptionalPhysicalFloor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditPhysicalLocationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editPhysicalLocationById(requestParameters: DefaultApiEditPhysicalLocationByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editPhysicalLocationById(requestParameters.locationId, requestParameters.iOptionalPhysicalLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditProductPackageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editProductPackageById(requestParameters: DefaultApiEditProductPackageByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editProductPackageById(requestParameters.productPackageId, requestParameters.iOptionalProductPackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editProjectById(requestParameters: DefaultApiEditProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editProjectById(requestParameters.projectId, requestParameters.iOptionalProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditProjectUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editProjectUserById(requestParameters: DefaultApiEditProjectUserByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editProjectUserById(requestParameters.id, requestParameters.iOptionalProjectUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditSecurityGroupBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editSecurityGroupBySubscriberId(requestParameters: DefaultApiEditSecurityGroupBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editSecurityGroupBySubscriberId(requestParameters.subscriberId, requestParameters.securityGroupId, requestParameters.iOptionalSubscriberSecurityGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditSubcontractorOfDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editSubcontractorOfDocumentById(requestParameters: DefaultApiEditSubcontractorOfDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editSubcontractorOfDocumentById(requestParameters.documentId, requestParameters.subcontractorChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditSubscriberByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editSubscriberById(requestParameters: DefaultApiEditSubscriberByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editSubscriberById(requestParameters.subscriberId, requestParameters.iOptionalSubscriber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editUserById(requestParameters: DefaultApiEditUserByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editUserById(requestParameters.userId, requestParameters.iCustomOptionalUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditUserGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editUserGroupById(requestParameters: DefaultApiEditUserGroupByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).editUserGroupById(requestParameters.userGroupId, requestParameters.userGroupEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEditUserNotificationsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editUserNotificationsByIds(requestParameters: DefaultApiEditUserNotificationsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).editUserNotificationsByIds(requestParameters.userId, requestParameters.editUserNotificationsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEmptyTrashByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emptyTrashByProjectId(requestParameters: DefaultApiEmptyTrashByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).emptyTrashByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFlattenBlueBeamByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public flattenBlueBeamByDocumentId(requestParameters: DefaultApiFlattenBlueBeamByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).flattenBlueBeamByDocumentId(requestParameters.documentId, requestParameters.submittalFlattenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFlattenBlueBeamByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public flattenBlueBeamByKey(requestParameters: DefaultApiFlattenBlueBeamByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).flattenBlueBeamByKey(requestParameters.key, requestParameters.submittalFlattenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateAsBuiltDocumentFromDrawingsDocumentId(requestParameters: DefaultApiGenerateAsBuiltDocumentFromDrawingsDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateAsBuiltDocumentFromDrawingsDocumentId(requestParameters.documentId, requestParameters.generateAsBuiltDocumentParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateAsBuiltDocumentsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateAsBuiltDocumentsByProjectId(requestParameters: DefaultApiGenerateAsBuiltDocumentsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateAsBuiltDocumentsByProjectId(requestParameters.projectId, requestParameters.generateAsBuiltDocumentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateBimFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBimFileDownloadLink(requestParameters: DefaultApiGenerateBimFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateBimFileDownloadLink(requestParameters.centerLineProjectId, requestParameters.bimTreeNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateMultiplePublicUserFileSystemFileDownloadLink(requestParameters: DefaultApiGenerateMultiplePublicUserFileSystemFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateMultiplePublicUserFileSystemFileDownloadLink(requestParameters.projectId, requestParameters.generatePublicUserFileSystemLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateMultipleUserFileSystemFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateMultipleUserFileSystemFileDownloadLink(requestParameters: DefaultApiGenerateMultipleUserFileSystemFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateMultipleUserFileSystemFileDownloadLink(requestParameters.projectId, requestParameters.authenticatedDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateMultipleUserFileSystemUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateMultipleUserFileSystemUploadLink(requestParameters: DefaultApiGenerateMultipleUserFileSystemUploadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateMultipleUserFileSystemUploadLink(requestParameters.projectId, requestParameters.authenticatedUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGeneratePlaceholdersForDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generatePlaceholdersForDocuments(requestParameters: DefaultApiGeneratePlaceholdersForDocumentsRequest, options?: any) {
        return DefaultApiFp(this.configuration).generatePlaceholdersForDocuments(requestParameters.iGeneratePlaceholdersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGeneratePresignedUrlsPartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generatePresignedUrlsParts(requestParameters: DefaultApiGeneratePresignedUrlsPartsRequest, options?: any) {
        return DefaultApiFp(this.configuration).generatePresignedUrlsParts(requestParameters.presignedUrlPartsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGeneratePublicUserFileSystemFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generatePublicUserFileSystemFileDownloadLink(requestParameters: DefaultApiGeneratePublicUserFileSystemFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generatePublicUserFileSystemFileDownloadLink(requestParameters.projectId, requestParameters.generatePublicUserFileSystemLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateUserFileSystemFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateUserFileSystemFileDownloadLink(requestParameters: DefaultApiGenerateUserFileSystemFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateUserFileSystemFileDownloadLink(requestParameters.projectId, requestParameters.authenticatedDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGenerateUserFileSystemUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateUserFileSystemUploadLink(requestParameters: DefaultApiGenerateUserFileSystemUploadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateUserFileSystemUploadLink(requestParameters.projectId, requestParameters.authenticatedUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllAdminUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAdminUsers(requestParameters: DefaultApiGetAllAdminUsersRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllAdminUsers(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllAnnouncementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAnnouncements(requestParameters: DefaultApiGetAllAnnouncementsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllAnnouncements(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllAssignedDocumentsByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAssignedDocumentsByUserId(requestParameters: DefaultApiGetAllAssignedDocumentsByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllAssignedDocumentsByUserId(requestParameters.userId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllCompanies(requestParameters: DefaultApiGetAllCompaniesRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllCompanies(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllCountries(requestParameters: DefaultApiGetAllCountriesRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllCountries(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDefaultSecurityGroups(options?: any) {
        return DefaultApiFp(this.configuration).getAllDefaultSecurityGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllDocumentTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDocumentTemplates(requestParameters: DefaultApiGetAllDocumentTemplatesRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllDocumentTemplates(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllDocumentUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDocumentUsers(requestParameters: DefaultApiGetAllDocumentUsersRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllDocumentUsers(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDocuments(requestParameters: DefaultApiGetAllDocumentsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllDocuments(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllFaqCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllFaqCategories(requestParameters: DefaultApiGetAllFaqCategoriesRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllFaqCategories(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllNotifications(requestParameters: DefaultApiGetAllNotificationsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllNotifications(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllOutboundEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllOutboundEmail(requestParameters: DefaultApiGetAllOutboundEmailRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllOutboundEmail(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProductPackagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProductPackages(requestParameters: DefaultApiGetAllProductPackagesRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProductPackages(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectBidSetupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectBidSetups(requestParameters: DefaultApiGetAllProjectBidSetupsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectBidSetups(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectBidSetupsSortedByDueDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectBidSetupsSortedByDueDate(requestParameters: DefaultApiGetAllProjectBidSetupsSortedByDueDateRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectBidSetupsSortedByDueDate(requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectCalendarEventsBetweenDatesByProjectId(requestParameters: DefaultApiGetAllProjectCalendarEventsBetweenDatesByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectCalendarEventsBetweenDatesByProjectId(requestParameters.projectId, requestParameters.beforeDate, requestParameters.afterDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectCalendarEventsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectCalendarEventsByProjectId(requestParameters: DefaultApiGetAllProjectCalendarEventsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectCalendarEventsByProjectId(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectNamesBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectNamesBySubscriberId(requestParameters: DefaultApiGetAllProjectNamesBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectNamesBySubscriberId(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectSummary(options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectUsers(requestParameters: DefaultApiGetAllProjectUsersRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectUsers(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjects(requestParameters: DefaultApiGetAllProjectsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjects(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllProjectsSimplifiedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectsSimplified(requestParameters: DefaultApiGetAllProjectsSimplifiedRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectsSimplified(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllReceivedEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllReceivedEmails(requestParameters: DefaultApiGetAllReceivedEmailsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllReceivedEmails(requestParameters.projectId, requestParameters.selectedPermission, requestParameters.involvesEmail, requestParameters.fromEmail, requestParameters.toOrCcEmail, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllSecurityGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSecurityGroups(requestParameters: DefaultApiGetAllSecurityGroupsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllSecurityGroups(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllSecurityGroupsBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSecurityGroupsBySubscriberId(requestParameters: DefaultApiGetAllSecurityGroupsBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllSecurityGroupsBySubscriberId(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSecurityGroupsForUsersSubscribersByUserId(requestParameters: DefaultApiGetAllSecurityGroupsForUsersSubscribersByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllSecurityGroupsForUsersSubscribersByUserId(requestParameters.userId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllSubmittalCodesByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSubmittalCodesByProjectId(requestParameters: DefaultApiGetAllSubmittalCodesByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllSubmittalCodesByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllSubscribersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSubscribers(requestParameters: DefaultApiGetAllSubscribersRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllSubscribers(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllUserGroupsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUserGroupsByProjectId(requestParameters: DefaultApiGetAllUserGroupsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllUserGroupsByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsers(requestParameters: DefaultApiGetAllUsersRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllUsers(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllUsersSimplifiedBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsersSimplifiedBySubscriberId(requestParameters: DefaultApiGetAllUsersSimplifiedBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAllUsersSimplifiedBySubscriberId(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAllWebsiteSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllWebsiteSettings(requestParameters: DefaultApiGetAllWebsiteSettingsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getAllWebsiteSettings(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAnnouncementByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnouncementById(requestParameters: DefaultApiGetAnnouncementByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAnnouncementById(requestParameters.announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetAssociatedDocumentsByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAssociatedDocumentsByDocumentId(requestParameters: DefaultApiGetAssociatedDocumentsByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAssociatedDocumentsByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBIMTopFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBIMTopFolders(requestParameters: DefaultApiGetBIMTopFoldersRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBIMTopFolders(requestParameters.centerLineProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBIMTreeNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBIMTreeNode(requestParameters: DefaultApiGetBIMTreeNodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBIMTreeNode(requestParameters.centerLineProjectId, requestParameters.bimTreeNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBidByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBidById(requestParameters: DefaultApiGetBidByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBidById(requestParameters.bidId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBidConnectFileDeliveryUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBidConnectFileDeliveryUploadLink(requestParameters: DefaultApiGetBidConnectFileDeliveryUploadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBidConnectFileDeliveryUploadLink(requestParameters.bidConnectFileDeliveryUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBidsOnProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBidsOnProjectById(requestParameters: DefaultApiGetBidsOnProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBidsOnProjectById(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetBluebeamXmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBluebeamXml(requestParameters: DefaultApiGetBluebeamXmlRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBluebeamXml(requestParameters.fileId, requestParameters.additionalReviewDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCommentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommentById(requestParameters: DefaultApiGetCommentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCommentById(requestParameters.commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCommentsOnDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommentsOnDocumentById(requestParameters: DefaultApiGetCommentsOnDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCommentsOnDocumentById(requestParameters.documentId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCommentsOnProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommentsOnProjectById(requestParameters: DefaultApiGetCommentsOnProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCommentsOnProjectById(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCompaniesByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompaniesByProjectId(requestParameters: DefaultApiGetCompaniesByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCompaniesByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCompanyByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyById(requestParameters: DefaultApiGetCompanyByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyById(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetConformingCenterDocumentsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConformingCenterDocumentsByProjectId(requestParameters: DefaultApiGetConformingCenterDocumentsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getConformingCenterDocumentsByProjectId(requestParameters.projectId, requestParameters.documentTemplateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetConformingCenterSummaryByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConformingCenterSummaryByProjectId(requestParameters: DefaultApiGetConformingCenterSummaryByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getConformingCenterSummaryByProjectId(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetCountryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCountryById(requestParameters: DefaultApiGetCountryByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getCountryById(requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentUser(options?: any) {
        return DefaultApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDesignFileActionsByProjectAndFullKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDesignFileActionsByProjectAndFullKey(requestParameters: DefaultApiGetDesignFileActionsByProjectAndFullKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDesignFileActionsByProjectAndFullKey(requestParameters.projectId, requestParameters.fullKey, requestParameters.ownerUserId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDirectDesignFileDownloadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDirectDesignFileDownloadLink(requestParameters: DefaultApiGetDirectDesignFileDownloadLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDirectDesignFileDownloadLink(requestParameters.projectId, requestParameters.authenticatedDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentById(requestParameters: DefaultApiGetDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentByKey(requestParameters: DefaultApiGetDocumentByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentByKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentPublicLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentPublicLink(requestParameters: DefaultApiGetDocumentPublicLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentPublicLink(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentPublicLinkAsUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentPublicLinkAsUser(requestParameters: DefaultApiGetDocumentPublicLinkAsUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentPublicLinkAsUser(requestParameters.documentId, requestParameters.userId, requestParameters.isInteractive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentTemplateByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentTemplateByType(requestParameters: DefaultApiGetDocumentTemplateByTypeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentTemplateByType(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentUserPermissionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentUserPermissionByDocumentId(requestParameters: DefaultApiGetDocumentUserPermissionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentUserPermissionByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentUserPermissionsForDocumentByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentUserPermissionsForDocumentByKey(requestParameters: DefaultApiGetDocumentUserPermissionsForDocumentByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentUserPermissionsForDocumentByKey(requestParameters.key, requestParameters.consultantReviewDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentsAssignedByMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentsAssignedByMe(requestParameters: DefaultApiGetDocumentsAssignedByMeRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentsAssignedByMe(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentsAssignedToMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentsAssignedToMe(requestParameters: DefaultApiGetDocumentsAssignedToMeRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentsAssignedToMe(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentsByProjectId(requestParameters: DefaultApiGetDocumentsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentsByProjectId(requestParameters.projectId, requestParameters.selectedColumns, requestParameters.selectedDocumentTypes, requestParameters.includeFiles, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentsByProjectIdAndDocumentTemplateIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentsByProjectIdAndDocumentTemplateId(requestParameters: DefaultApiGetDocumentsByProjectIdAndDocumentTemplateIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentsByProjectIdAndDocumentTemplateId(requestParameters.projectId, requestParameters.documentTemplateId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentsSummaryByProjectIdAndDocumentTemplateId(requestParameters: DefaultApiGetDocumentsSummaryByProjectIdAndDocumentTemplateIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentsSummaryByProjectIdAndDocumentTemplateId(requestParameters.projectId, requestParameters.documentTemplateId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFaqCategoryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFaqCategoryById(requestParameters: DefaultApiGetFaqCategoryByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFaqCategoryById(requestParameters.faqCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFileByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileById(requestParameters: DefaultApiGetFileByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFileById(requestParameters.fileId, requestParameters.flattened, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFileLinkByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileLinkById(requestParameters: DefaultApiGetFileLinkByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFileLinkById(requestParameters.fileId, requestParameters.flattened, requestParameters.fullSize, requestParameters.filename, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFileLinkByIdForConsultantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileLinkByIdForConsultant(requestParameters: DefaultApiGetFileLinkByIdForConsultantRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFileLinkByIdForConsultant(requestParameters.fileId, requestParameters.reviewDocumentId, requestParameters.flattened, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFileUploadLinkByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileUploadLinkByProjectId(requestParameters: DefaultApiGetFileUploadLinkByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFileUploadLinkByProjectId(requestParameters.projectId, requestParameters.projectFileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetFilesByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFilesByDocumentId(requestParameters: DefaultApiGetFilesByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFilesByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetLatestProjectArchiveZipFileLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLatestProjectArchiveZipFileLink(requestParameters: DefaultApiGetLatestProjectArchiveZipFileLinkRequest, options?: any) {
        return DefaultApiFp(this.configuration).getLatestProjectArchiveZipFileLink(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetLatestSubmissionFileByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLatestSubmissionFileByDocumentId(requestParameters: DefaultApiGetLatestSubmissionFileByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getLatestSubmissionFileByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetLatestSubmissionFileByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLatestSubmissionFileByKey(requestParameters: DefaultApiGetLatestSubmissionFileByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getLatestSubmissionFileByKey(requestParameters.key, requestParameters.consultantReviewDocumentId, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetManyDocumentPublicLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyDocumentPublicLinks(requestParameters: DefaultApiGetManyDocumentPublicLinksRequest, options?: any) {
        return DefaultApiFp(this.configuration).getManyDocumentPublicLinks(requestParameters.getManyDocumentLinksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetMostRecentConsultantNotificationsByDocumentIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMostRecentConsultantNotificationsByDocumentIds(requestParameters: DefaultApiGetMostRecentConsultantNotificationsByDocumentIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getMostRecentConsultantNotificationsByDocumentIds(requestParameters.consultantNotificationRequest, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetMyDesignFileSharesByProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyDesignFileSharesByProject(requestParameters: DefaultApiGetMyDesignFileSharesByProjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).getMyDesignFileSharesByProject(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyFavoriteProjects(options?: any) {
        return DefaultApiFp(this.configuration).getMyFavoriteProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetMyNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyNotifications(requestParameters: DefaultApiGetMyNotificationsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getMyNotifications(requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetMyNotificationsWithEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyNotificationsWithEmails(requestParameters: DefaultApiGetMyNotificationsWithEmailsRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getMyNotificationsWithEmails(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetMyNotificationsWithEmailsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyNotificationsWithEmailsCount(requestParameters: DefaultApiGetMyNotificationsWithEmailsCountRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).getMyNotificationsWithEmailsCount(requestParameters.projectId, requestParameters.filterByObject, requestParameters.curatedSampleData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationById(requestParameters: DefaultApiGetNotificationByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationById(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationRecipientsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationRecipientsByIds(requestParameters: DefaultApiGetNotificationRecipientsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationRecipientsByIds(requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationsByBidIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationsByBidId(requestParameters: DefaultApiGetNotificationsByBidIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationsByBidId(requestParameters.id, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationsByBidSetupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationsByBidSetupId(requestParameters: DefaultApiGetNotificationsByBidSetupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationsByBidSetupId(requestParameters.bidSetupId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationsByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationsByDocumentId(requestParameters: DefaultApiGetNotificationsByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationsByDocumentId(requestParameters.id, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationsByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationsByKey(requestParameters: DefaultApiGetNotificationsByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationsByKey(requestParameters.key, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetNotificationsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationsByProjectId(requestParameters: DefaultApiGetNotificationsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNotificationsByProjectId(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetOutboundEmailByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOutboundEmailById(requestParameters: DefaultApiGetOutboundEmailByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getOutboundEmailById(requestParameters.outboundEmailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetParserJobStatusByObjectKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getParserJobStatusByObjectKey(requestParameters: DefaultApiGetParserJobStatusByObjectKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getParserJobStatusByObjectKey(requestParameters.parserJobStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPermissionByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPermissionByKey(requestParameters: DefaultApiGetPermissionByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPermissionByKey(requestParameters.key, requestParameters.consultantReviewDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPermissionByUserIdAndDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPermissionByUserIdAndDocumentId(requestParameters: DefaultApiGetPermissionByUserIdAndDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPermissionByUserIdAndDocumentId(requestParameters.userId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPhysicalBuildingsByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPhysicalBuildingsByProjectId(requestParameters: DefaultApiGetPhysicalBuildingsByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPhysicalBuildingsByProjectId(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPrimeBiddersOnProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPrimeBiddersOnProjectById(requestParameters: DefaultApiGetPrimeBiddersOnProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPrimeBiddersOnProjectById(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProcoreProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcoreProjects(requestParameters: DefaultApiGetProcoreProjectsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProcoreProjects(requestParameters.centerLineProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProcoreSubmittalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcoreSubmittal(requestParameters: DefaultApiGetProcoreSubmittalRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProcoreSubmittal(requestParameters.centerlineProjectId, requestParameters.procoreDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProductPackageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductPackageById(requestParameters: DefaultApiGetProductPackageByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProductPackageById(requestParameters.productPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectBidSetupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectBidSetupById(requestParameters: DefaultApiGetProjectBidSetupByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectBidSetupById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectBiddingSummaryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectBiddingSummaryById(requestParameters: DefaultApiGetProjectBiddingSummaryByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectBiddingSummaryById(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectById(requestParameters: DefaultApiGetProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectById(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectSummaryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectSummaryById(requestParameters: DefaultApiGetProjectSummaryByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectSummaryById(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectUserById(requestParameters: DefaultApiGetProjectUserByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectUserById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectUserSummaryBySecurityGroupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectUserSummaryBySecurityGroupId(requestParameters: DefaultApiGetProjectUserSummaryBySecurityGroupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectUserSummaryBySecurityGroupId(requestParameters.securityGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectUsersByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectUsersByProjectId(requestParameters: DefaultApiGetProjectUsersByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectUsersByProjectId(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetProjectsWithMostRecentCommentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectsWithMostRecentComments(requestParameters: DefaultApiGetProjectsWithMostRecentCommentsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getProjectsWithMostRecentComments(requestParameters.numMostRecentProjects, requestParameters.numMostRecentComments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPublicDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublicDocumentById(requestParameters: DefaultApiGetPublicDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPublicDocumentById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPublicUserFileSystemFileNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublicUserFileSystemFileNode(requestParameters: DefaultApiGetPublicUserFileSystemFileNodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPublicUserFileSystemFileNode(requestParameters.projectId, requestParameters.designFileShareId, requestParameters.publicAccessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetPublicUserInfoByEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublicUserInfoByEmail(requestParameters: DefaultApiGetPublicUserInfoByEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPublicUserInfoByEmail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetRegistrationUserByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRegistrationUserByKey(requestParameters: DefaultApiGetRegistrationUserByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).getRegistrationUserByKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetSignedUrlForEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSignedUrlForEmail(requestParameters: DefaultApiGetSignedUrlForEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSignedUrlForEmail(requestParameters.emailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetSubscriberAdminsSimplifiedBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubscriberAdminsSimplifiedBySubscriberId(requestParameters: DefaultApiGetSubscriberAdminsSimplifiedBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSubscriberAdminsSimplifiedBySubscriberId(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetSubscriberByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubscriberById(requestParameters: DefaultApiGetSubscriberByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSubscriberById(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetSubscriberProjectUserSummaryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubscriberProjectUserSummaryById(requestParameters: DefaultApiGetSubscriberProjectUserSummaryByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSubscriberProjectUserSummaryById(requestParameters.subscriberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetSuggestedProjectEmailSlugRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSuggestedProjectEmailSlug(requestParameters: DefaultApiGetSuggestedProjectEmailSlugRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSuggestedProjectEmailSlug(requestParameters.getSuggestedProjectEmailSlugRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetTrashByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTrashByProjectId(requestParameters: DefaultApiGetTrashByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getTrashByProjectId(requestParameters.projectId, requestParameters.continuationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUploadLinkForAddendasByBidSetupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUploadLinkForAddendasByBidSetupId(requestParameters: DefaultApiGetUploadLinkForAddendasByBidSetupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUploadLinkForAddendasByBidSetupId(requestParameters.bidSetupId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUploadLinkForDrawingsByBidSetupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUploadLinkForDrawingsByBidSetupId(requestParameters: DefaultApiGetUploadLinkForDrawingsByBidSetupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUploadLinkForDrawingsByBidSetupId(requestParameters.bidSetupId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUploadLinkForSpecificationsByBidSetupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUploadLinkForSpecificationsByBidSetupId(requestParameters: DefaultApiGetUploadLinkForSpecificationsByBidSetupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUploadLinkForSpecificationsByBidSetupId(requestParameters.bidSetupId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUploadLinkForSubmittalSectionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUploadLinkForSubmittalSectionByDocumentId(requestParameters: DefaultApiGetUploadLinkForSubmittalSectionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUploadLinkForSubmittalSectionByDocumentId(requestParameters.documentId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserById(requestParameters: DefaultApiGetUserByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUserById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUserFileSystemFileNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserFileSystemFileNode(requestParameters: DefaultApiGetUserFileSystemFileNodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUserFileSystemFileNode(requestParameters.projectId, requestParameters.authenticatedDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUsersByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsersByProjectId(requestParameters: DefaultApiGetUsersByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUsersByProjectId(requestParameters.projectId, requestParameters.orderByColumn, requestParameters.orderByDirection, requestParameters.filterByObject, requestParameters.curatedSampleData, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetWebsiteSettingByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWebsiteSettingById(requestParameters: DefaultApiGetWebsiteSettingByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getWebsiteSettingById(requestParameters.websiteSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetZippedBidDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZippedBidDocuments(requestParameters: DefaultApiGetZippedBidDocumentsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getZippedBidDocuments(requestParameters.bidSetupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiImpersonateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public impersonateUser(requestParameters: DefaultApiImpersonateUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).impersonateUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiImportBimFileByBidSetupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importBimFileByBidSetupId(requestParameters: DefaultApiImportBimFileByBidSetupIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).importBimFileByBidSetupId(requestParameters.bidSetupId, requestParameters.bidBIMImportParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiImportBimFileByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importBimFileByProjectId(requestParameters: DefaultApiImportBimFileByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).importBimFileByProjectId(requestParameters.projectId, requestParameters.projectBIMImportParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertAdditionalReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertAdditionalReviewByDocumentId(requestParameters: DefaultApiInsertAdditionalReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertAdditionalReviewByDocumentId(requestParameters.documentId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertAdditionalReviewByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertAdditionalReviewByKey(requestParameters: DefaultApiInsertAdditionalReviewByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertAdditionalReviewByKey(requestParameters.key, requestParameters.fileUploadRequestParams, requestParameters.consultantReviewDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertAddress(requestParameters: DefaultApiInsertAddressRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertAddress(requestParameters.iInsertionAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertAnnouncementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertAnnouncement(requestParameters: DefaultApiInsertAnnouncementRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertAnnouncement(requestParameters.iInsertionAnnouncement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertBidByUserAndProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertBidByUserAndProject(requestParameters: DefaultApiInsertBidByUserAndProjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertBidByUserAndProject(requestParameters.userId, requestParameters.projectId, requestParameters.insertBidProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertBidSetupByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertBidSetupByProjectId(requestParameters: DefaultApiInsertBidSetupByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertBidSetupByProjectId(requestParameters.projectId, requestParameters.iCustomInsertionBidSetup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertCommentByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertCommentByDocumentId(requestParameters: DefaultApiInsertCommentByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertCommentByDocumentId(requestParameters.documentId, requestParameters.iInsertionComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertCommentByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertCommentByProjectId(requestParameters: DefaultApiInsertCommentByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertCommentByProjectId(requestParameters.projectId, requestParameters.iInsertionComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertCompany(requestParameters: DefaultApiInsertCompanyRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertCompany(requestParameters.iInsertionCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a consultant to a document.
     * @param {DefaultApiInsertConsultantByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertConsultantByDocumentId(requestParameters: DefaultApiInsertConsultantByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertConsultantByDocumentId(requestParameters.documentId, requestParameters.additionalConsultantProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertConsultantConfidentialCommentByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertConsultantConfidentialCommentByDocumentId(requestParameters: DefaultApiInsertConsultantConfidentialCommentByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertConsultantConfidentialCommentByDocumentId(requestParameters.parentDocumentId, requestParameters.iInsertionComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertDocument(requestParameters: DefaultApiInsertDocumentRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertDocument(requestParameters.insertDocumentProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertDocumentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertDocumentUser(requestParameters: DefaultApiInsertDocumentUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertDocumentUser(requestParameters.iInsertionDocumentUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertFileByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertFileByDocumentId(requestParameters: DefaultApiInsertFileByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertFileByDocumentId(requestParameters.documentId, requestParameters.documentFileUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertFloorPlanDrawingFileByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertFloorPlanDrawingFileByProjectId(requestParameters: DefaultApiInsertFloorPlanDrawingFileByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertFloorPlanDrawingFileByProjectId(requestParameters.projectId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertFloorPlanImageFileByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertFloorPlanImageFileByProjectId(requestParameters: DefaultApiInsertFloorPlanImageFileByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertFloorPlanImageFileByProjectId(requestParameters.projectId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertLocationImageFileByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertLocationImageFileByProjectId(requestParameters: DefaultApiInsertLocationImageFileByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertLocationImageFileByProjectId(requestParameters.projectId, requestParameters.fileUploadRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertManyPhysicalBuildingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertManyPhysicalBuildings(requestParameters: DefaultApiInsertManyPhysicalBuildingsRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertManyPhysicalBuildings(requestParameters.insertManyPhysicalBuildingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertManyPhysicalLocationsByFloorIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertManyPhysicalLocationsByFloorIds(requestParameters: DefaultApiInsertManyPhysicalLocationsByFloorIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertManyPhysicalLocationsByFloorIds(requestParameters.insertManyPhysicalLocationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertManyProjectlessUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertManyProjectlessUser(requestParameters: DefaultApiInsertManyProjectlessUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertManyProjectlessUser(requestParameters.iInsertManyProjectlessUsersParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertPhoneNumberByCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertPhoneNumberByCompanyId(requestParameters: DefaultApiInsertPhoneNumberByCompanyIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertPhoneNumberByCompanyId(requestParameters.companyId, requestParameters.iInsertionPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertPhoneNumberByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertPhoneNumberByUserId(requestParameters: DefaultApiInsertPhoneNumberByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertPhoneNumberByUserId(requestParameters.userId, requestParameters.iInsertionPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertPhysicalBuildingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertPhysicalBuilding(requestParameters: DefaultApiInsertPhysicalBuildingRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertPhysicalBuilding(requestParameters.customInsertionPhysicalBuilding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertPhysicalFloorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertPhysicalFloor(requestParameters: DefaultApiInsertPhysicalFloorRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertPhysicalFloor(requestParameters.iInsertionPhysicalFloor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertPrimeBidderByUserAndProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertPrimeBidderByUserAndProject(requestParameters: DefaultApiInsertPrimeBidderByUserAndProjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertPrimeBidderByUserAndProject(requestParameters.userId, requestParameters.projectId, requestParameters.iInsertionPrimeBidder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertProductPackageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertProductPackage(requestParameters: DefaultApiInsertProductPackageRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertProductPackage(requestParameters.iInsertionProductPackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertProject(requestParameters: DefaultApiInsertProjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertProject(requestParameters.iCustomInsertionProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertProjectCalendarEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertProjectCalendarEvent(requestParameters: DefaultApiInsertProjectCalendarEventRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertProjectCalendarEvent(requestParameters.iInsertionProjectCalendarEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertProjectlessUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertProjectlessUser(requestParameters: DefaultApiInsertProjectlessUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertProjectlessUser(requestParameters.iCustomInsertionUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertSecurityGroupBySubscriberIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertSecurityGroupBySubscriberId(requestParameters: DefaultApiInsertSecurityGroupBySubscriberIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertSecurityGroupBySubscriberId(requestParameters.subscriberId, requestParameters.iInsertionSubscriberSecurityGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertSubmissionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertSubmissionByDocumentId(requestParameters: DefaultApiInsertSubmissionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertSubmissionByDocumentId(requestParameters.documentId, requestParameters.submittalUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertSubmissionByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertSubmissionByKey(requestParameters: DefaultApiInsertSubmissionByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertSubmissionByKey(requestParameters.key, requestParameters.submittalUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertSubscriberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertSubscriber(requestParameters: DefaultApiInsertSubscriberRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertSubscriber(requestParameters.iInsertionSubscriber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertUser(requestParameters: DefaultApiInsertUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertUser(requestParameters.iAddNewUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiInsertUserGroupByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertUserGroupByProjectId(requestParameters: DefaultApiInsertUserGroupByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).insertUserGroupByProjectId(requestParameters.projectId, requestParameters.userGroupInsertionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiListUserFileSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserFileSystem(requestParameters: DefaultApiListUserFileSystemRequest, options?: any) {
        return DefaultApiFp(this.configuration).listUserFileSystem(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiListUserFileSystemCompressedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserFileSystemCompressed(requestParameters: DefaultApiListUserFileSystemCompressedRequest, options?: any) {
        return DefaultApiFp(this.configuration).listUserFileSystemCompressed(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMarkNotificationAsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markNotificationAsRead(requestParameters: DefaultApiMarkNotificationAsReadRequest, options?: any) {
        return DefaultApiFp(this.configuration).markNotificationAsRead(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMarkNotificationsAsReadByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markNotificationsAsReadByUserId(requestParameters: DefaultApiMarkNotificationsAsReadByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).markNotificationsAsReadByUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMarkParserFileAsConformedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markParserFileAsConformed(requestParameters: DefaultApiMarkParserFileAsConformedRequest, options?: any) {
        return DefaultApiFp(this.configuration).markParserFileAsConformed(requestParameters.fileId, requestParameters.markParserFileAsConformedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiModifyDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public modifyDocumentById(requestParameters: DefaultApiModifyDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).modifyDocumentById(requestParameters.documentId, requestParameters.iModifyDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiModifyDocumentByIdWithResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public modifyDocumentByIdWithResponse(requestParameters: DefaultApiModifyDocumentByIdWithResponseRequest, options?: any) {
        return DefaultApiFp(this.configuration).modifyDocumentByIdWithResponse(requestParameters.documentId, requestParameters.iModifyDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiModifyDocumentsByBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public modifyDocumentsByBatch(requestParameters: DefaultApiModifyDocumentsByBatchRequest, options?: any) {
        return DefaultApiFp(this.configuration).modifyDocumentsByBatch(requestParameters.iModifyDocumentsByBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiModifyDocumentsByBatchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public modifyDocumentsByBatches(requestParameters: DefaultApiModifyDocumentsByBatchesRequest, options?: any) {
        return DefaultApiFp(this.configuration).modifyDocumentsByBatches(requestParameters.iModifyDocumentsByBatchesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiModifyUserFileSystemNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public modifyUserFileSystemNode(requestParameters: DefaultApiModifyUserFileSystemNodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).modifyUserFileSystemNode(requestParameters.projectId, requestParameters.modifyUserFileSystemNodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiNotReadyForSubmissionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notReadyForSubmissionByDocumentId(requestParameters: DefaultApiNotReadyForSubmissionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).notReadyForSubmissionByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiNotifyUsersOfAccessToDesignFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyUsersOfAccessToDesignFile(requestParameters: DefaultApiNotifyUsersOfAccessToDesignFileRequest, options?: any) {
        return DefaultApiFp(this.configuration).notifyUsersOfAccessToDesignFile(requestParameters.projectId, requestParameters.notifyUserAccessDesignFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPermanentlyDeleteProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public permanentlyDeleteProjectById(requestParameters: DefaultApiPermanentlyDeleteProjectByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).permanentlyDeleteProjectById(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefuseSubmissionByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refuseSubmissionByDocumentId(requestParameters: DefaultApiRefuseSubmissionByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).refuseSubmissionByDocumentId(requestParameters.documentId, requestParameters.rejectDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRemoveDocumentFromPackageByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeDocumentFromPackageByDocumentId(requestParameters: DefaultApiRemoveDocumentFromPackageByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).removeDocumentFromPackageByDocumentId(requestParameters.documentId, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removePlanHolderFromProjectByUserIdAndProjectId(requestParameters: DefaultApiRemovePlanHolderFromProjectByUserIdAndProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).removePlanHolderFromProjectByUserIdAndProjectId(requestParameters.userId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRemoveUserFromProjectByProjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeUserFromProjectByProjectId(requestParameters: DefaultApiRemoveUserFromProjectByProjectIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).removeUserFromProjectByProjectId(requestParameters.projectId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiReplaceDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public replaceDocumentById(requestParameters: DefaultApiReplaceDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).replaceDocumentById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiReplaceFileByDocumentIdAndFileIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public replaceFileByDocumentIdAndFileId(requestParameters: DefaultApiReplaceFileByDocumentIdAndFileIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).replaceFileByDocumentIdAndFileId(requestParameters.documentId, requestParameters.fileId, requestParameters.baseFileUploadParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiReplaceWebsiteSettingByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public replaceWebsiteSettingById(requestParameters: DefaultApiReplaceWebsiteSettingByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).replaceWebsiteSettingById(requestParameters.websiteSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetUser(options?: any) {
        return DefaultApiFp(this.configuration).resetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRespondToReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public respondToReviewByDocumentId(requestParameters: DefaultApiRespondToReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).respondToReviewByDocumentId(requestParameters.documentId, requestParameters.respondToReviewProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRestoreDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restoreDocumentById(requestParameters: DefaultApiRestoreDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).restoreDocumentById(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRestoreDocumentsByIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restoreDocumentsByIds(requestParameters: DefaultApiRestoreDocumentsByIdsRequest, options?: any) {
        return DefaultApiFp(this.configuration).restoreDocumentsByIds(requestParameters.restoreDocumentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRetractBidByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retractBidById(requestParameters: DefaultApiRetractBidByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).retractBidById(requestParameters.bidId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSendBidConnectFileDeliveryEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendBidConnectFileDeliveryEmail(requestParameters: DefaultApiSendBidConnectFileDeliveryEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendBidConnectFileDeliveryEmail(requestParameters.bidConnectFileDeliveryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSendDirectDesignFileDownloadEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendDirectDesignFileDownloadEmails(requestParameters: DefaultApiSendDirectDesignFileDownloadEmailsRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendDirectDesignFileDownloadEmails(requestParameters.projectId, requestParameters.sendPublicDesignFileDownloadLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSendDocumentPublicLinkEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendDocumentPublicLinkEmails(requestParameters: DefaultApiSendDocumentPublicLinkEmailsRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendDocumentPublicLinkEmails(requestParameters.documentId, requestParameters.emailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSendPrivateProjectInviteEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendPrivateProjectInviteEmails(requestParameters: DefaultApiSendPrivateProjectInviteEmailsRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendPrivateProjectInviteEmails(requestParameters.projectId, requestParameters.sendPrivateInviteEmailRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSendPublicFolderAccessToEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendPublicFolderAccessToEmails(requestParameters: DefaultApiSendPublicFolderAccessToEmailsRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendPublicFolderAccessToEmails(requestParameters.projectId, requestParameters.sendPublicLinkToEmailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiShareMultipleUserFileSystemObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shareMultipleUserFileSystemObject(requestParameters: DefaultApiShareMultipleUserFileSystemObjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).shareMultipleUserFileSystemObject(requestParameters.projectId, requestParameters.shareFileSystemObjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiShareUserFileSystemObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shareUserFileSystemObject(requestParameters: DefaultApiShareUserFileSystemObjectRequest, options?: any) {
        return DefaultApiFp(this.configuration).shareUserFileSystemObject(requestParameters.projectId, requestParameters.shareFileSystemObjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSkipAssignSubcontractorByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public skipAssignSubcontractorByDocumentId(requestParameters: DefaultApiSkipAssignSubcontractorByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).skipAssignSubcontractorByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSubcontractorRefuseAssignmentByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subcontractorRefuseAssignmentByDocumentId(requestParameters: DefaultApiSubcontractorRefuseAssignmentByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).subcontractorRefuseAssignmentByDocumentId(requestParameters.documentId, requestParameters.unauthenticatedUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSubmitAdditionalReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitAdditionalReviewByDocumentId(requestParameters: DefaultApiSubmitAdditionalReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).submitAdditionalReviewByDocumentId(requestParameters.documentId, requestParameters.additionalReviewProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSubmitAdditionalReviewByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitAdditionalReviewByKey(requestParameters: DefaultApiSubmitAdditionalReviewByKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).submitAdditionalReviewByKey(requestParameters.key, requestParameters.additionalReviewProps, requestParameters.consultantReviewDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSubmitToArchitectByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitToArchitectByDocumentId(requestParameters: DefaultApiSubmitToArchitectByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).submitToArchitectByDocumentId(requestParameters.documentId, requestParameters.submitToArchitectProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSyncProcoreDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncProcoreDocuments(requestParameters: DefaultApiSyncProcoreDocumentsRequest, options?: any) {
        return DefaultApiFp(this.configuration).syncProcoreDocuments(requestParameters.centerLineProjectId, requestParameters.documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiToggleRemindersByDocumentKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public toggleRemindersByDocumentKey(requestParameters: DefaultApiToggleRemindersByDocumentKeyRequest, options?: any) {
        return DefaultApiFp(this.configuration).toggleRemindersByDocumentKey(requestParameters.toggleRemindersProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUndoCompleteReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public undoCompleteReviewByDocumentId(requestParameters: DefaultApiUndoCompleteReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).undoCompleteReviewByDocumentId(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUnlockProjectsWithKeyByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unlockProjectsWithKeyByUserId(requestParameters: DefaultApiUnlockProjectsWithKeyByUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).unlockProjectsWithKeyByUserId(requestParameters.userId, requestParameters.unlockProjectsWithKeyParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUnsubscribePlanholderByProjectIdAndUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unsubscribePlanholderByProjectIdAndUserId(requestParameters: DefaultApiUnsubscribePlanholderByProjectIdAndUserIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).unsubscribePlanholderByProjectIdAndUserId(requestParameters.projectId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows for bluebeam to POST back the BAX file. This should only be used by REVU.
     * @param {DefaultApiUpdateBaxFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBaxFile(requestParameters: DefaultApiUpdateBaxFileRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateBaxFile(requestParameters.fileId, requestParameters.originalPdfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUpdateReviewByDocumentIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReviewByDocumentId(requestParameters: DefaultApiUpdateReviewByDocumentIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateReviewByDocumentId(requestParameters.documentId, requestParameters.updateReviewParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUploadToStagingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadToStaging(requestParameters: DefaultApiUploadToStagingRequest, options?: any) {
        return DefaultApiFp(this.configuration).uploadToStaging(requestParameters.s3StagingUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiWithdrawDocumentByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawDocumentById(requestParameters: DefaultApiWithdrawDocumentByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).withdrawDocumentById(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }
}


