import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import AddDesignFileDialog from './AddDesignFileDialog';
import { FileNode } from '../../api-client/autogenerated';
import ImportFromDocumentDialog, { ImportedFile } from './ImportFromDocumentDialog';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  acceptAll?: boolean;
  allowMultiple?: boolean;
  handleDesignUpload: (fileNodes: FileNode[]) => void;
  handleDocumentImport: (files: ImportedFile[]) => void;
  disableDesignUpload: boolean;
  disableDocumentImport: boolean;
}

export default function BrowseCenterlineDialog(props: Props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    acceptAll,
    allowMultiple,
    handleDesignUpload,
    handleDocumentImport,
    disableDesignUpload,
    disableDocumentImport,
  } = props;

  const [designFileDialogOpen, setDesignFileDialogOpen] = useState(false);
  const [importFileDialogOpen, setImportFileDialogOpen] = useState(false);

  const handleDesignAttach = (fileNodes: FileNode[]) => {
    handleDesignUpload(fileNodes);
    setDesignFileDialogOpen(false);
    handleClose();
  };

  const importFiles = (files: ImportedFile[]) => {
    handleDocumentImport(files);
    setImportFileDialogOpen(false);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} align="left">
            Browse Centerline
          </Typography>
          <IconButton
            style={{ right: '20px', top: '12px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', marginTop: 16, rowGap: 16 }}
        >
          <TooltipIfDisabledComponent
            title="Importing from the File Manager is not allowed for this upload"
            disabled={disableDesignUpload}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={disableDesignUpload}
              onClick={() => setDesignFileDialogOpen(true)}
            >
              Browse Design File Manager
            </Button>
          </TooltipIfDisabledComponent>
          <TooltipIfDisabledComponent
            title="Importing from Centerline documents is not allowed for this upload"
            disabled={disableDocumentImport}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={disableDocumentImport}
              onClick={() => setImportFileDialogOpen(true)}
            >
              Browse Bidding or Construction
            </Button>
          </TooltipIfDisabledComponent>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <AddDesignFileDialog
        open={designFileDialogOpen}
        handleClose={() => setDesignFileDialogOpen(false)}
        title="File Manager"
        acceptAll={acceptAll}
        singleFile={!allowMultiple}
        allowOnlyPdfs
        handleDesignAttach={handleDesignAttach}
      />
      <ImportFromDocumentDialog
        open={importFileDialogOpen}
        handleClose={() => setImportFileDialogOpen(false)}
        importFiles={importFiles}
        allowMultiple={allowMultiple}
      />
    </>
  );
}
