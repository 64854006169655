import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { canUploadToPublishingCenter, PublishType } from '../document-conforming-center/DCCUtils';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import Button from '@material-ui/core/Button';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import { IFile } from '../../api-client/autogenerated';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  file: IFile;
}

export default function SelectParserDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, file } = props;

  const history = useHistory();
  const project = useSelector(getProjectState);

  const [canConformDrawings, setCanConformDrawings] = useState(false);
  const [canConformSpecs, setCanConformSpecs] = useState(false);

  useEffect(() => {
    if (project && file) {
      canUploadToPublishingCenter(project, PublishType.Specifications).then((canConform) =>
        setCanConformSpecs(canConform && !file?.isConformedInSpecifications),
      );
      canUploadToPublishingCenter(project, PublishType.Drawings).then((canConform) =>
        setCanConformDrawings(canConform && !file?.isConformedInDrawings),
      );
    }
  }, [project, file]);

  const conform = (type: 'specs' | 'drawings' | 'both') => {
    if (!project) return;

    const data = { fileId: file.id, name: file.name };

    if (['both', 'specs'].includes(type)) {
      sessionStorage.setItem('parseSpecs', JSON.stringify(data));
    }

    if (['both', 'drawings'].includes(type)) {
      sessionStorage.setItem('parseDrawings', JSON.stringify(data));
    }

    history.push(`/main/projects/${project.id}/pub-center`);
  };

  const getDrawingsDisabledTooltip = () => {
    if (file?.isConformedInDrawings) {
      return 'This file has already been conformed';
    }

    return 'The current Drawings parser job must be completed or cleared';
  };

  const getSpecsDisabledTooltip = () => {
    if (file?.isConformedInSpecifications) {
      return 'This file has already been conformed';
    }

    return 'The current Specifications parser job must be completed or cleared';
  };

  const getBothDisabledTooltip = () => {
    if (file?.isConformedInDrawings || file?.isConformedInSpecifications) {
      return 'This file has already been conformed';
    }

    return 'One or both parser jobs must be completed or cleared';
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          Conform File
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent></DialogContent>

      <DialogActions style={{ padding: '0 12px 12px 12px' }}>
        <TooltipIfDisabledComponent
          title={getDrawingsDisabledTooltip()}
          disabled={!canConformDrawings}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!canConformDrawings}
            onClick={() => conform('drawings')}
          >
            Drawings
          </Button>
        </TooltipIfDisabledComponent>
        <TooltipIfDisabledComponent title={getSpecsDisabledTooltip()} disabled={!canConformSpecs}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!canConformSpecs}
            onClick={() => conform('specs')}
          >
            Specifications
          </Button>
        </TooltipIfDisabledComponent>
        <TooltipIfDisabledComponent
          title={getBothDisabledTooltip()}
          disabled={!canConformSpecs || !canConformDrawings}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!canConformSpecs || !canConformDrawings}
            onClick={() => conform('both')}
          >
            Both
          </Button>
        </TooltipIfDisabledComponent>
      </DialogActions>
    </Dialog>
  );
}
