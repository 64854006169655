import React, { useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import image1 from '../../images/studio_help_1.png';
import image2 from '../../images/studio_help_2.png';

const IMAGE1_WIDTH = 390;
const IMAGE1_HEIGHT = 658;
const IMAGE2_WIDTH = 534;
const IMAGE2_HEIGHT = 535;

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
};

export default function BluebeamStudioHelpDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ style: { minWidth: 540 } }}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          Upload Bluebeam Studio Activity Log
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex' }}>
        <div style={{ width: (400 / IMAGE1_HEIGHT) * IMAGE1_WIDTH, marginRight: 32 }}>
          <img
            src={image1}
            height={400}
            alt="Select 'Report' at the bottom of the Bluebeam Studio pane."
          />
          <Typography style={{ width: '100%' }}>
            Select "Report" at the bottom of the Bluebeam Studio pane.
          </Typography>
        </div>
        <div style={{ width: (400 / IMAGE2_HEIGHT) * IMAGE2_WIDTH }}>
          <img
            src={image2}
            height={400}
            alt='Click "OK", save the file on your computer, and upload it using the "Upload Bluebeam Studio Activity Log" button.'
          />
          <Typography>
            Click "OK", save the file on your computer, and upload it using the "Upload Bluebeam
            Studio Activity Log" button.
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
}
