import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useEffect, useState } from 'react';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import ConfidenceMatchesTable from './ConfidenceMatchesTable';
import { documentTypeToUrl, openInNewWindow } from '../../scripts/utils';
import {
  blueHighlight,
  DCCRow,
  LocalStorageKeyEnum,
  mergeRowsFromCache,
  PublishType,
  TableType,
  updateRowsInLocalStorage,
  yellowHighlight,
} from './DCCUtils';
import {
  DocumentTemplateType,
  FileCategoryType,
  IConformingCenterProjectSummary,
  IConformingDocumentsSummary,
  IFile,
  PublishStatusType,
} from '../../api-client/autogenerated';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import {
  getConformingCenterDocumentsByType,
  getConformingCenterSummaryByProjectId,
} from '../../models/api/dcc';
import FilePreviewDialog from '../dialogs/FilePreviewDialog';
import PublishedSectionsDialog from '../dialogs/PublishedSectionsDialog';
import DCCFileCard from './DCCFileCard';
import { SubmitButton, TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import { addSnackbar } from '../../features/snackbar/actions';
import { useHistory } from 'react-router-dom';
import {
  deleteDocumentsByIds,
  ModifyDocumentRow,
  modifyDocumentsIndividually,
} from '../../models/api/documents';
import FullscreenLoader from '../loader/FullscreenLoader';
import DCCNavigationBlocker from '../custom-components/DCCNavigationBlocker';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import CreatePlaceholdersDialog from './CreatePlaceholdersDialog';
import _ from 'lodash';
import { markParserFileAsConformed } from '../../models/api/files';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    titleStyle: {
      backgroundColor: '#1F34BC',
      borderRadius: '4px 4px 0px 0px',
    },
    cardStyle: {
      marginTop: 15,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cardHeader: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      borderBottom: '1px solid #D8D8D8',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    cardDivHeader: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    mobileButtonGroup: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    buttonMargins: {
      marginRight: 12,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        margin: '10px 0px',
      },
    },
    legend: {
      height: 16,
      width: 16,
      backgroundColor: blueHighlight,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    deleteButton: {
      padding: '0px 10px',
      backgroundColor: '#ED3F26',
      lineHeight: 1,
      color: 'white',
      height: 32,
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
    },
  }),
);

export default function DCCDrawings() {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width:900px)');

  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<TableType | null>(null);

  const [rows, setRows] = useState<DCCRow[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [limitHeight, setLimitHeight] = useState(true);

  const [deletedRows, setDeletedRows] = useState<DCCRow[]>([]);
  const [deletedSearchInput, setDeletedSearchInput] = useState('');

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [fileToView, setFileToView] = useState<File>();

  const [updateExistingDialogOpen, setUpdateExistingDialogOpen] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState<DCCRow>();
  const [rowToAssociate, setRowToAssociate] = useState<DCCRow>();

  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);
  const [asBuiltDialogOpen, setAsBuiltDialogOpen] = useState(false);

  const [projectSummary, setProjectSummary] = useState<IConformingCenterProjectSummary>();
  const [summary, setSummary] = useState<IConformingDocumentsSummary>();

  const checkedRows = rows.filter((r) => r.isChecked);
  const checkedDeletedRows = deletedRows.filter((r) => r.isChecked);

  useEffect(() => {
    updateRowsInLocalStorage(LocalStorageKeyEnum.DrawingsUnpublished, rows);
  }, [rows]);

  const fetchData = async (projectId: string) => {
    setIsLoading(true);
    const s = await getConformingCenterSummaryByProjectId(projectId);
    setProjectSummary(s);
    setSummary(s.drawingSummary);
    await fetchDocuments(projectId);
    return s;
  };

  const fetchDocuments = async (projectId: string) => {
    const documents = await getConformingCenterDocumentsByType(
      projectId,
      DocumentTemplateType.Drawings,
    );
    const unpublishedDocuments = documents.filter(
      (doc) => doc.document.publishStatus === PublishStatusType.Unpublished,
    );
    const newRows = unpublishedDocuments
      .filter(({ document }) => !deletedRows.map((r) => r.id).includes(document.id))
      .map<DCCRow>(({ document, isConflicting, isGoodMatch, conflictType }) => {
        const fileName = project?.files?.find((f) => f.id === document.parsedFromFileId)?.name;
        return {
          id: document.id,
          number: document.sheetNumber || '',
          title: document.title || '',
          pageNumber: document.pageNumber || undefined,
          fileName,
          pdfFileId: document.drawingsSectionFileId || '',
          parsedFromFileId: document.parsedFromFileId,
          isConflicting,
          conflictType,
          isGoodMatch,
          isChecked: false,
          associatedUserIds: [],
          associatedGroupIds: [],
        };
      });
    const mergedRows = mergeRowsFromCache(LocalStorageKeyEnum.DrawingsUnpublished, newRows);
    setRows(mergedRows);
    setIsLoading(false);
  };

  useEffect(() => {
    if (project) {
      fetchData(project.id);
    }
  }, [project]);

  const handleSaveOrPublish = async (willPublishSelected: boolean) => {
    if (!project) return;
    const rowsToSave = willPublishSelected ? [...checkedRows] : [...rows];
    if (
      rowsToSave.every((r) => r.associatedUserIds.length === 0 && r.associatedGroupIds.length === 0)
    ) {
      const proceed = window.confirm(
        'Are you sure you want to publish these documents without associating users?',
      );
      if (!proceed) return;
    }
    dispatch(blockNavigation());
    setIsSubmitting(TableType.Unpublished);
    const patches: ModifyDocumentRow[] = rowsToSave.map((row) => {
      return {
        documentId: row.id,
        modification: {
          patch: {
            isDraft: !willPublishSelected,
            sheetNumber: row.number,
            title: row.title,
            simplePackage: row.packageName,
          },
          followers: {
            addUserIds: row.associatedUserIds,
            addUserGroupIds: row.associatedGroupIds,
          },
        },
      };
    });
    await modifyDocumentsIndividually(patches);

    if (willPublishSelected) {
      const uniquePublishedDocumentParsedFromFileIds = _.uniq(
        rowsToSave.map((row) => row.parsedFromFileId).filter((id) => !!id) as string[],
      );

      await Promise.all(
        uniquePublishedDocumentParsedFromFileIds.map((fileId) =>
          markParserFileAsConformed(fileId, DocumentTemplateType.Drawings),
        ),
      );
    }

    dispatch(
      addSnackbar({
        id: Date.now(),
        message: willPublishSelected
          ? `Successfully published ${patches.length} documents`
          : 'Saved changes!',
        severity: 'success',
      }),
    );
    dispatch(allowNavigation());
    if (!willPublishSelected) {
      history.push(`/main/projects/${project.id}/pub-center`);
    } else {
      const newSummary = await fetchData(project.id);
      if (
        newSummary.drawingSummary.unpublished.numDocuments === 0 &&
        newSummary.numAsBuiltsBeforeUnpublishedWithPublishedDrawing > 0
      ) {
        setAsBuiltDialogOpen(true);
      }
    }
    setIsSubmitting(null);
  };

  const handleView = async (row: DCCRow) => {
    openInNewWindow(row.pdfFileId);
  };

  const handleDelete = async (tableType: TableType, fromDialog = false) => {
    if (tableType === TableType.Deleted) {
      const documentIdsToDelete = (fromDialog ? [...deletedRows] : [...checkedDeletedRows]).map(
        (row) => row.id,
      );

      const proceed = window.confirm(
        `Are you sure you want to permanently delete ${documentIdsToDelete.length} drawings? You must re-parse to create these items again.`,
      );
      if (!proceed) return;
      dispatch(blockNavigation());
      setIsSubmitting(TableType.Deleted);
      await deleteDocumentsByIds(documentIdsToDelete);
      setDeletedRows((prev) => prev.filter((row) => !documentIdsToDelete.includes(row.id)));
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully deleted ${documentIdsToDelete.length} items`,
          severity: 'success',
        }),
      );
      dispatch(allowNavigation());
      if (!fromDialog) {
        const newSummary = await fetchData(project!.id);
        if (
          newSummary.drawingSummary.unpublished.numDocuments === 0 &&
          newSummary.numAsBuiltsBeforeUnpublishedWithPublishedDrawing > 0
        ) {
          setAsBuiltDialogOpen(true);
        }
      }
      setIsSubmitting(null);
    } else {
      const rowsToDelete = [...rows].filter((row) => row.isChecked);
      setRows((prev) => prev.filter((p) => !rowsToDelete.map(({ id }) => id).includes(p.id)));
      setDeletedRows(rowsToDelete.map((row) => ({ ...row, isChecked: false })));
    }
  };

  const handleClearAll = () => {
    setDeletedRows(rows.map((r) => ({ ...r, isChecked: false })));
    setRows([]);
  };

  const handleRestore = () => {
    const rowsToRestore = [...deletedRows].filter((row) => row.isChecked);
    setDeletedRows((prev) => prev.filter((p) => !rowsToRestore.map(({ id }) => id).includes(p.id)));
    setRows((prev) => [...prev, ...rowsToRestore]);
  };

  const handleUpdateExisting = (row: DCCRow) => {
    setRowToUpdate(row);
    setUpdateExistingDialogOpen(true);
  };

  const areSameSheetNumbersSelected = checkedRows.some((row) =>
    checkedRows.some((row2) => row.id !== row2.id && row.number === row2.number),
  );

  const getPublishTooltipTitle = () => {
    if (checkedRows.length === 0) return 'You must select at least one item';
    if (areSameSheetNumbersSelected)
      return 'You may not publish two items with the same sheet number';
    if (checkedRows.some((row) => !row.number || !row.title))
      return 'You may not publish an item without a sheet number and title';
    return '';
  };

  const isPublishDisabled = () => {
    return (
      checkedRows.length === 0 ||
      areSameSheetNumbersSelected ||
      checkedRows.some((row) => !row.number || !row.title)
    );
  };

  const getCurrentFiles = (): IFile[] => {
    return (
      project?.files?.filter(
        (f) =>
          f.category === FileCategoryType.Drawings &&
          projectSummary?.summaryByParsedFromFileId[f.id] &&
          projectSummary?.summaryByParsedFromFileId[f.id].drawingSummary.unpublished.numDocuments >
            0,
      ) || []
    );
  };

  const handleDrawingsLink = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${documentTypeToUrl[DocumentTemplateType.Drawings]}`,
    );
  };

  const publishedCount = summary?.published.numDocuments || 0;
  const unpublishedCount = summary?.unpublished.numDocuments || 0;
  const totalCount = publishedCount + unpublishedCount;

  const getPageTitle = () => {
    if (publishedCount > 0) return 'Drawings Conforming Center';
    return 'Drawings Publishing Center';
  };

  const handleOpenAssociationsDialog = () => {
    if (checkedRows.length === 1) {
      setRowToAssociate(checkedRows[0]);
    } else {
      setRowToAssociate(undefined);
    }

    setAssociationDialogOpen(true);
  };

  const handleCloseAssociationsDialog = () => {
    setRowToAssociate(undefined);
    setAssociationDialogOpen(false);
  };

  const handleSetAssociatedUsers = (userIds: string[]) => {
    setRows((prev) =>
      prev.map((r) => {
        if (checkedRows.some((row) => row.id === r.id)) {
          return { ...r, associatedUserIds: userIds };
        }

        return r;
      }),
    );
  };

  const handleSetAssociatedGroups = (groupIds: string[]) => {
    setRows((prev) =>
      prev.map((r) => {
        if (checkedRows.some((row) => row.id === r.id)) {
          return { ...r, associatedGroupIds: groupIds };
        }

        return r;
      }),
    );
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <PageTitle title={getPageTitle()} />
      {!isLoading ? (
        <>
          <DCCFileCard type={PublishType.Drawings} files={getCurrentFiles()} />
          <Card className={classes.cardStyle}>
            <CardContent className={classes.cardHeader}>
              <Typography style={{ fontWeight: 500, fontSize: 20, lineHeight: '25px' }}>
                Published Drawings - {publishedCount}/{totalCount}
              </Typography>
            </CardContent>
            <Typography style={{ fontSize: 16, lineHeight: '21px', marginLeft: 16, marginTop: 8 }}>
              After publishing, be sure to associate design team users with the drawings for which
              they are responsible.
            </Typography>
            <Typography style={{ fontSize: 16, lineHeight: '21px', marginLeft: 16 }}>
              This can be done at the Drawings page.
            </Typography>
            <Button
              color="primary"
              size="large"
              onClick={handleDrawingsLink}
              endIcon={<ArrowForward />}
              style={{ marginLeft: 6 }}
            >
              Go to Drawings
            </Button>
          </Card>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography style={{ fontSize: 18, lineHeight: '25px' }}>
              Verify each sheet number and title. If anything needs correction, change it here.
            </Typography>
          </div>

          <Card className={classes.cardStyle}>
            <CardContent className={classes.cardHeader}>
              <div className={classes.cardDivHeader}>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 20,
                    marginRight: isMobile ? 0 : 20,
                    textAlign: 'center',
                  }}
                >
                  Unpublished Drawings - {rows.length}
                </Typography>
                <div className={classes.mobileButtonGroup}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={checkedRows.length === 0}
                    onClick={handleOpenAssociationsDialog}
                    style={{
                      padding: '0px 10px',
                      lineHeight: 1,
                      marginRight: 12,
                    }}
                  >
                    Associate Users
                  </Button>
                  <TooltipIfDisabledComponent
                    arrow
                    placement="top"
                    title={getPublishTooltipTitle()}
                    disabled={isPublishDisabled()}
                  >
                    <SubmitButton
                      color="primary"
                      variant="contained"
                      disabled={isPublishDisabled()}
                      onClick={() => handleSaveOrPublish(true)}
                      className={classes.buttonMargins}
                      style={{ padding: '0px 10px', lineHeight: 1 }}
                    >
                      Publish Selected to Centerline
                    </SubmitButton>
                  </TooltipIfDisabledComponent>
                  <Button
                    variant="contained"
                    disabled={checkedRows.length === 0}
                    onClick={() => handleDelete(TableType.Unpublished)}
                    className={classes.deleteButton}
                    style={{ marginRight: 12 }}
                  >
                    Move to Delete Queue
                  </Button>
                  <Button
                    variant="contained"
                    disabled={rows.length === 0}
                    onClick={handleClearAll}
                    className={classes.deleteButton}
                  >
                    Clear All
                  </Button>
                </div>
                <div style={{ display: 'inline-flex', flexGrow: 100 }} />

                <div>
                  {publishedCount > 0 && (
                    <div style={{ display: 'flex', marginBottom: 4 }}>
                      <div className={classes.legend} />
                      <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                        Updates existing version
                      </Typography>
                    </div>
                  )}
                  <div style={{ display: 'flex' }}>
                    <div className={classes.legend} style={{ backgroundColor: yellowHighlight }} />
                    <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                      Extra attention suggested
                    </Typography>
                  </div>
                </div>
              </div>
              <FormControl style={{ margin: '15px 0px' }}>
                <TextField
                  InputProps={{
                    style: { height: 25 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#B2B1B2' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  id="search"
                  placeholder="Search"
                  name="Search"
                  value={searchInput}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
              </FormControl>
            </CardContent>
            <CardContent style={{ padding: 0, overflowX: 'auto' }}>
              <ConfidenceMatchesTable
                type={PublishType.Drawings}
                tableType={TableType.Unpublished}
                summary={summary}
                searchResult={searchInput}
                rows={rows}
                setRows={setRows}
                view={handleView}
                limitHeight={limitHeight}
                updateExisting={publishedCount > 0 ? handleUpdateExisting : undefined}
                isLoading={isSubmitting === TableType.Unpublished}
              />
            </CardContent>
          </Card>
          <div
            className={classes.cardStyle}
            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 25 }}
          >
            {limitHeight ? (
              <Button color="primary" onClick={() => setLimitHeight((prev) => !prev)}>
                See All <ArrowForward />{' '}
              </Button>
            ) : (
              <Button color="primary" onClick={() => setLimitHeight((prev) => !prev)}>
                See Less <ArrowBack />{' '}
              </Button>
            )}
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography style={{ fontSize: 18, lineHeight: '25px' }}>
              Restore or permanently delete items here.
            </Typography>
          </div>

          <Card className={classes.cardStyle}>
            <CardContent className={classes.cardHeader}>
              <div className={classes.cardDivHeader}>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 20,
                    marginRight: isMobile ? 0 : 20,
                    textAlign: 'center',
                  }}
                >
                  Deleted Items - {deletedRows.length}
                </Typography>
                <div className={classes.mobileButtonGroup}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={checkedDeletedRows.length === 0}
                    onClick={handleRestore}
                    className={classes.buttonMargins}
                    style={{ padding: '0px 10px', lineHeight: 1 }}
                  >
                    Restore Selected
                  </Button>
                  <Button
                    variant="contained"
                    disabled={checkedDeletedRows.length === 0}
                    onClick={() => handleDelete(TableType.Deleted)}
                    className={classes.deleteButton}
                  >
                    Delete Selected
                  </Button>
                </div>
              </div>
              <FormControl style={{ margin: '15px 0px' }}>
                <TextField
                  InputProps={{
                    style: { height: 25 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#B2B1B2' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  id="search"
                  placeholder="Search"
                  name="Search"
                  value={deletedSearchInput}
                  onChange={(event) => setDeletedSearchInput(event.target.value)}
                />
              </FormControl>
            </CardContent>
            <CardContent style={{ padding: 0, overflowX: 'auto' }}>
              <ConfidenceMatchesTable
                type={PublishType.Drawings}
                tableType={TableType.Deleted}
                summary={summary}
                searchResult={deletedSearchInput}
                rows={deletedRows}
                setRows={setDeletedRows}
                view={handleView}
                limitHeight={false}
                isLoading={isSubmitting === TableType.Deleted}
              />
            </CardContent>
          </Card>
        </>
      ) : (
        <FullscreenLoader />
      )}
      <FilePreviewDialog
        open={viewDialogOpen}
        handleClose={() => {
          setViewDialogOpen(false);
          setTimeout(() => setFileToView(undefined), 100);
        }}
        file={fileToView}
      />
      {rowToUpdate && (
        <PublishedSectionsDialog
          type={PublishType.Drawings}
          open={updateExistingDialogOpen}
          handleClose={() => setUpdateExistingDialogOpen(false)}
          row={rowToUpdate}
          refreshData={() => fetchData(project!.id)}
        />
      )}
      <DCCNavigationBlocker
        shouldBlock={deletedRows.length > 0}
        deletedItemsCount={deletedRows.length}
        onDelete={() => handleDelete(TableType.Deleted, true)}
      />
      <ManagePermissionsDialog
        dialogOpen={associationDialogOpen}
        closeDialog={handleCloseAssociationsDialog}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={rowToAssociate?.associatedUserIds || []}
        associatedGroups={rowToAssociate?.associatedGroupIds || []}
        setAssociatedUsers={handleSetAssociatedUsers}
        setAssociatedGroups={handleSetAssociatedGroups}
      />
      {projectSummary ? (
        <CreatePlaceholdersDialog
          open={asBuiltDialogOpen}
          handleClose={() => setAsBuiltDialogOpen(false)}
          summary={projectSummary}
          type={DocumentTemplateType.AsBuilt}
        />
      ) : null}
    </main>
  );
}
