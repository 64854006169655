import axios, { AxiosResponse } from 'axios';
import { ApiClient } from '../../api-client/api-client';
import {
  AuthenticatedDownloadRequest,
  AuthenticatedUploadRequest,
  BimTreeNode,
  CropConfig,
  DocumentFileUploadRequest,
  EditFileParams,
  FileCategoryType,
  FileCategoryTypeCompletedBidTabulation,
  FileCategoryTypeDrawings,
  FileCategoryTypeSpecifications,
  FileNode,
  FilePermissionType,
  FileUploadRequestParams,
  GeneratePublicUserFileSystemLinkRequest,
  IOptionalFile,
  S3StagingUploadRequest,
  S3UploadResponse,
  ShareFileSystemObjectRequest,
  ShareFileSystemTarget,
  SimpleFileNode,
} from '../../api-client/autogenerated';
import { downloadFileFromLink, inflateObject } from '../../scripts/utils';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { Uploader } from '../../scripts/multipart-upload';

export type AuthenticatedUploadRequestWithFiles = {
  file: File;
} & AuthenticatedUploadRequest;

export type ImportFromDesignRequest = {
  ownerId: string;
  fullKey: string;
  fullFileName: string;
  projectId: string;
  isPublic?: boolean;
};

export const uploadS3File = async (
  s3Response: S3UploadResponse,
  file: File,
  onUploadProgress?: (progressEvent: { loaded: number; total?: number }) => void,
) => {
  if (s3Response.uploadLink) {
    await axios.put(s3Response.uploadLink, file, {
      headers: {
        'Content-Type': new URL(s3Response.uploadLink).searchParams.get('Content-Type'),
      },
      onUploadProgress,
    });
  } else if (s3Response.uploadId && s3Response.s3Key) {
    console.log('Uploading using multipart upload');
    const uploader = new Uploader({ file, threadsQuantity: 4 });
    onUploadProgress &&
      uploader.onProgress((progress) => {
        onUploadProgress({ loaded: progress.sent, total: progress.total });
      });
    await uploader.initialize(s3Response.uploadId, s3Response.s3Key, true);
  }
};

export const replaceFileByDocumentIdAndFileId = async (
  documentId: string,
  fileId: string,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.replaceFileByDocumentIdAndFileId({
    documentId,
    fileId,
    baseFileUploadParams: { useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE },
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const uploadGeneralDocumentFile = async (
  documentId: string,
  params: DocumentFileUploadRequest,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const res = await ApiClient.insertFileByDocumentId({
    documentId,
    documentFileUploadRequest: params,
  });
  await uploadS3File(res.data, file, onUploadProgress);
  return res.data;
};

export const importGeneralDocumentFileFromDesign = async (
  documentId: string,
  fileType: FileCategoryType,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.insertFileByDocumentId({
    documentId,
    documentFileUploadRequest: { fileType, ...params, useMultiPartUpload: false },
  }).then((res) => res.data);
};

export const uploadToStaging = async (
  params: S3StagingUploadRequest,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const res = await ApiClient.uploadToStaging({
    s3StagingUploadRequest: params,
  });
  await uploadS3File(res.data, file, onUploadProgress);
  return res.data;
};

export const editFileById = async (id: string, file: IOptionalFile) => {
  const res = await ApiClient.editFileById({ fileId: id, iOptionalFile: file });
  return res.data;
};

export const uploadProjectSpecificationFile = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
  enableParseSubmittals?: boolean,
) => {
  const response = await ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: {
      fileType: FileCategoryTypeSpecifications.Specifications,
      enableParseSubmittals,
      ...params,
    },
  });
  await uploadS3File(response.data.s3Response, file, onUploadProgress);
  return response.data;
};

export const importProjectSpecificationFileFromDesign = async (
  projectId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: {
      fileType: FileCategoryTypeSpecifications.Specifications,
      fullFileName: params.fullFileName,
      projectId,
      ownerId: params.ownerId,
      fullKey: params.fullKey,
      useMultiPartUpload: false,
    },
  }).then((res) => res.data);
};

export const uploadBidSpecificationFile = async (
  bidSetupId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getUploadLinkForSpecificationsByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data.s3Response, file, onUploadProgress);
  return response.data;
};
export const importBidSpecificationFileFromDesign = async (
  bidSetupId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.getUploadLinkForSpecificationsByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: { ...params, useMultiPartUpload: false },
  });
};

export const uploadProjectDrawingsFile = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: { fileType: FileCategoryTypeDrawings.Drawings, ...params },
  });
  await uploadS3File(response.data.s3Response, file, onUploadProgress);
  return response.data;
};

export const importProjectDrawingsFileFromDesign = async (
  projectId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: {
      fileType: FileCategoryTypeDrawings.Drawings,
      ...params,
      useMultiPartUpload: false,
    },
  }).then((res) => res.data);
};

export const beginDrawingsPreprocessor = async (projectId: string, fileId: string) => {
  return ApiClient.beginDrawingsPreprocessor({
    drawingsPreprocessorRequest: { projectId, fileId },
  }).then((res) => res.data);
};

export const beginDrawingsParser = async (
  fileId: string,
  originalFileId: string,
  titleBlockCropConfig: CropConfig,
  sheetNameCropConfig: CropConfig,
) => {
  return ApiClient.beginDrawingsParser({
    drawingsParserRequest: { fileId, originalFileId, titleBlockCropConfig, sheetNameCropConfig },
  }).then((res) => res.status);
};

export const beginSpecificationsParser = async (projectId: string, fileId: string) => {
  return ApiClient.beginSpecificationsParser({
    specificationsParserRequest: { projectId, fileId },
  }).then((res) => res.status);
};

export const uploadBidAddendasFile = async (
  bidSetupId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getUploadLinkForAddendasByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const importBidAddendasFileFromDesign = async (
  bidSetupId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.getUploadLinkForAddendasByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: { ...params, useMultiPartUpload: false },
  }).then((res) => res.data);
};

export const uploadBidDrawingsFile = async (
  bidSetupId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getUploadLinkForDrawingsByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data.s3Response, file, onUploadProgress);
  return response.data;
};

export const importBidDrawingsFileFromDesign = async (
  bidSetupId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.getUploadLinkForDrawingsByBidSetupId({
    bidSetupId,
    fileUploadRequestParams: { ...params, useMultiPartUpload: false },
  });
};

export const uploadBidTabulationsFile = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: {
      fileType: FileCategoryTypeCompletedBidTabulation.CompletedBidTabulation,
      ...params,
    },
  });
  await uploadS3File(response.data.s3Response, file, onUploadProgress);
  return response.data.s3Response;
};

export const importBidTabulationsFileFromDesign = async (
  projectId: string,
  params: ImportFromDesignRequest,
) => {
  const response = await ApiClient.getFileUploadLinkByProjectId({
    projectId,
    projectFileUploadRequestParams: {
      useMultiPartUpload: false,
      fileType: FileCategoryTypeCompletedBidTabulation.CompletedBidTabulation,
      ...params,
    },
  });

  return response.data.s3Response;
};

export const uploadFilesystemFile = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const res = await ApiClient.generateUserFileSystemUploadLink({
    projectId,
    authenticatedUploadRequest: {
      useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
      fullKey,
      ownerId,
    },
  });
  await uploadS3File(res.data, file, onUploadProgress);
  return res.data;
};

export const uploadMultipleFilesystemFiles = async (
  projectId: string,
  request: AuthenticatedUploadRequestWithFiles[],
  onUploadProgress?: (progressEvent: any) => void,
  resetUploadProgress?: (nextFile: File) => void,
) => {
  const authenticatedUploadRequest = request.map(
    ({ fullKey, ownerId, formatted, useMultiPartUpload }) => {
      return { fullKey, ownerId, formatted, useMultiPartUpload };
    },
  );
  const res = await ApiClient.generateMultipleUserFileSystemUploadLink({
    projectId,
    authenticatedUploadRequest,
  });
  console.log(`uploadMultipleFilesystemFiles called with response ${JSON.stringify(res.data)}`);
  for (let i = 0; i < res.data.length; i += 1) {
    const s3Response = res.data[i];
    if (s3Response.originalFullKey) {
      const file = request.find((r) => r.fullKey === s3Response.originalFullKey)?.file;
      if (file) {
        if (resetUploadProgress) resetUploadProgress(file);
        await uploadS3File(s3Response, file, onUploadProgress);
      }
    }
  }
  return res.data.map(({ updatedFileNode }) => updatedFileNode);
};

export const getMultipleFilesystemFileDownloadLinks = async (
  projectId: string,
  requests: AuthenticatedDownloadRequest[],
) => {
  return ApiClient.generateMultipleUserFileSystemFileDownloadLink({
    projectId,
    authenticatedDownloadRequest: requests,
  }).then((res) => res.data);
};

export const getFilesystemFileBlob = async (link: string) => {
  return axios
    .get(link, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((res: AxiosResponse<Blob>) => res.data);
};

export const downloadFilesystemFile = async (
  projectId: string,
  fullName: string,
  fullKey: string,
  isForViewing: boolean,
  ownerId: string,
) => {
  const response = await ApiClient.generateUserFileSystemFileDownloadLink({
    projectId,
    authenticatedDownloadRequest: {
      fullKey,
      ownerId,
      isForViewing,
    },
  });
  await downloadFileFromLink(response.data.downloadLink!, fullName);
};

export const downloadPublicFilesystemFile = async (
  projectId: string,
  fullName: string,
  fullKey: string,
  designFileShareId: string,
  publicAccessKey: string,
) => {
  const response = await ApiClient.generatePublicUserFileSystemFileDownloadLink({
    projectId,
    generatePublicUserFileSystemLinkRequest: {
      fullKey,
      designFileShareId,
      publicAccessKey,
    },
  });
  await downloadFileFromLink(response.data.downloadLink!, fullName);
};

export const getMultiplePublicFilesystemFileDownloadLinks = async (
  projectId: string,
  generatePublicUserFileSystemLinkRequest: GeneratePublicUserFileSystemLinkRequest[],
) => {
  return ApiClient.generateMultiplePublicUserFileSystemFileDownloadLink({
    projectId,
    generatePublicUserFileSystemLinkRequest,
  }).then((res) => res.data);
};

export const getFilesystemFileLink = async (
  projectId: string,
  fullKey: string,
  isForViewing: boolean,
  ownerId: string,
) => {
  return ApiClient.generateUserFileSystemFileDownloadLink({
    projectId,
    authenticatedDownloadRequest: { fullKey, ownerId, isForViewing },
  }).then((res) => res.data.downloadLink);
};

export const getPublicFilesystemFileLink = async (
  projectId: string,
  fullKey: string,
  publicAccessKey: string,
  designFileShareId: string,
) => {
  return ApiClient.generatePublicUserFileSystemFileDownloadLink({
    projectId,
    generatePublicUserFileSystemLinkRequest: { publicAccessKey, fullKey, designFileShareId },
  }).then((res) => res.data.downloadLink);
};

export const createFilesystemFolder = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
) => {
  const response = await ApiClient.createUserFileSystemFolder({
    projectId,
    authenticatedOtherFileSystemRequest: {
      fullKey,
      ownerId,
    },
  });
  return response.data;
};

export const getFilesystemFromProjectId = async (projectId: string) => {
  const request = await ApiClient.listUserFileSystemCompressed({ projectId });
  return inflateObject<FileNode>(request.data);
};

export const getUserFileSystemFileNode = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
) => {
  const request = await ApiClient.getUserFileSystemFileNode({
    projectId,
    authenticatedDownloadRequest: { isForViewing: false, fullKey, ownerId },
  });
  return request.data;
};

export const modifyUserFileSystemNode = (
  projectId: string,
  fileNode: FileNode,
  newName: string,
) => {
  return ApiClient.modifyUserFileSystemNode({
    projectId,
    modifyUserFileSystemNodeRequest: {
      fileNode: {
        ...fileNode,
        children: undefined, // unused and can send too much data
      },
      newName,
    },
  }).then((res) => res.data);
};

export const deleteUserFileSystemNode = (projectId: string, fileNode: FileNode) => {
  return ApiClient.deleteUserFileSystemNode({
    projectId,
    body: {
      fileNode: {
        ...fileNode,
        children: undefined, // unused and can send too much data
      },
    },
  });
};

export const getPublicUserFileSystemFileNode = async (
  projectId: string,
  designFileShareId: string,
  publicAccessKey: string,
) => {
  const request = await ApiClient.getPublicUserFileSystemFileNode({
    projectId,
    designFileShareId,
    publicAccessKey,
  });
  return request.data;
};

export const shareUserFileSystemObject = async (
  projectId: string,
  fileNodeKey: string,
  targetType: ShareFileSystemTarget,
  targetParams: {
    target?: string[];
  },
  filePermissions: FilePermissionType[],
) => {
  const { target } = targetParams;

  if (targetType != ShareFileSystemTarget.Everyone && !target)
    throw new Error('Target Ids must be defined for sharing a user file system object');

  const request = await ApiClient.shareUserFileSystemObject({
    projectId,
    shareFileSystemObjectRequest: {
      fileNodeKey,
      targetType,
      ...(target && { target }),
      filePermissions: filePermissions as FilePermissionType[],
    },
  });
  return request.data;
};

export const shareMultipleUserFileSystemObject = async (
  projectId: string,
  shareFileSystemObjectRequest: ShareFileSystemObjectRequest[],
) => {
  return ApiClient.shareMultipleUserFileSystemObject({
    projectId,
    shareFileSystemObjectRequest,
  }).then((res) => res.data);
};

export const getBIMTopFolders = async (projectId: string) => {
  const request = await ApiClient.getBIMTopFolders({ centerLineProjectId: projectId });
  return request.data;
};

export const getBIMTreeNode = async (projectId: string, treeNode: BimTreeNode) => {
  const request = await ApiClient.getBIMTreeNode({
    centerLineProjectId: projectId,
    bimTreeNode: treeNode,
  });
  return request.data;
};

export const downloadBIMFile = async (projectId: string, treeNode: BimTreeNode) => {
  const response1 = await ApiClient.generateBimFileDownloadLink({
    centerLineProjectId: projectId,
    bimTreeNode: treeNode,
  });

  await downloadFileFromLink(response1.data.downloadLink!, treeNode.name);
};

export const getZippedDocumentsLink = async (bidSetupId: string) => {
  return ApiClient.getZippedBidDocuments({ bidSetupId }).then((res) => res.data);
};

export const deleteFile = async (documentId: string, fileId: string) => {
  await ApiClient.modifyDocumentById({
    documentId,
    iModifyDocumentRequest: { deleteFileIds: [fileId] },
  });
};

export const updateFile = async (
  documentId: string,
  fileId: string,
  editFileParams: EditFileParams,
) => {
  return ApiClient.editFileByDocumentIdAndFileId({ documentId, fileId, editFileParams });
};

export const sendDirectDesignFileDownloadEmails = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
  emails: string[],
  formatted?: boolean,
) => {
  return ApiClient.sendDirectDesignFileDownloadEmails({
    projectId,
    sendPublicDesignFileDownloadLinkRequest: {
      isForViewing: false,
      fullKey,
      ownerId,
      emails,
      formatted,
    },
  });
};

export const getDirectDesignFileDownloadLink = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
  formatted?: boolean,
) => {
  return ApiClient.getDirectDesignFileDownloadLink({
    projectId,
    authenticatedDownloadRequest: { isForViewing: false, fullKey, ownerId, formatted },
  });
};

export const sendPublicFolderAccessToEmails = async (
  projectId: string,
  fullKey: string,
  ownerId: string,
  emails: string[],
) => {
  return ApiClient.sendPublicFolderAccessToEmails({
    projectId,
    sendPublicLinkToEmailsRequest: {
      emails,
      fileNode: {
        fullKey,
        ownerId,
        projectId,
      },
    },
  });
};

export const getDesignFileActions = async (
  projectId: string,
  fullKey: string,
  ownerUserId: string,
  page: number,
  pageSize = 50,
) => {
  return ApiClient.getDesignFileActionsByProjectAndFullKey({
    projectId,
    fullKey,
    ownerUserId,
    page,
    pageSize,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const notifyUsersOfAccessToDesignFile = async (
  projectId: string,
  fileNodes: SimpleFileNode[],
  subject: string,
  userIds?: string[],
  userGroupIds?: string[],
  description?: string,
) => {
  return ApiClient.notifyUsersOfAccessToDesignFile({
    projectId,
    notifyUserAccessDesignFileRequest: {
      subject,
      fileNodes,
      userIds,
      userGroupIds,
      description,
    },
  }).then((res) => res.data);
};

export const getTrashByProjectId = (projectId: string) => {
  return ApiClient.getTrashByProjectId({ projectId }).then((res) => res.data);
};

export const getDownloadLinkForTrashItem = (s3Key: string) => {
  return ApiClient.downloadTrashItemByProjectId({ getTrashItemRequest: { s3Key } }).then(
    (res) => res.data.downloadLink,
  );
};

export const downloadTrashItem = async (s3Key: string, fileName: string) => {
  const link = await getDownloadLinkForTrashItem(s3Key);

  await downloadFileFromLink(link, fileName);
};

export const deleteTrashItemsByProjectId = (projectId: string, s3Keys: string[]) => {
  return ApiClient.deleteTrashItemsByProjectId({
    projectId,
    deleteTrashItemsRequest: { s3Keys },
  }).then((res) => res.data);
};

export const emptyTrashByProjectId = (projectId: string) => {
  return ApiClient.emptyTrashByProjectId({ projectId }).then((res) => res.data);
};
