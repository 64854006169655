import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileObject } from 'material-ui-dropzone';
import Dropzone from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';
import { Add, AttachFile, Clear, HighlightOffRounded, Publish } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import PDFIcon from '../icons/PDF-icon';
import { canViewDesign, generateUniqueId } from '../../scripts/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addSnackbar } from '../../features/snackbar/actions';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import DesignIcon from '../icons/Design-icon';

// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { DocumentTemplateType, FileNode, FileNodeTypeEnum } from '../../api-client/autogenerated';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { getNavigationState } from '../../features/navigation/selectors';
import { getDocumentsType } from '../../features/documents/selectors';
import BrowseCenterlineDialog from './BrowseCenterlineDialog';
import { ImportedFile } from './ImportFromDocumentDialog';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

export type FileType = File | FileNode | ImportedFile;

interface FileUploadDialogProps {
  open: boolean;
  handleClose: (title?: string) => void;
  title: string;
  fileObjects?: FileObject[];
  newFiles?: FileType[];
  addFile?: (file: FileType) => void;
  addFiles?: (file: FileType[]) => void;
  removeFile: (file: FileType) => void;
  requireDocumentTitle?: boolean;
  canSubmit?: boolean;
  file?: FileType;
  inputComment?: string;
  handleChangeComment?: (event: any) => void;
  disableComments?: boolean;
  buttonType?: string;
  handleSubmit?: () => Promise<void>;
  uploadProgress?: number | undefined;
  allowMultiple?: boolean;
  errorMessage?: string;
  acceptAll?: boolean;
  allowedExtensions?: string[];
  uploadMessage?: string;
  disableDesignUpload?: boolean;
  enableDocumentImport?: boolean;
  requireFileName?: string;
  customMessage?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#464546',
    marginBottom: 4,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-16px',
    paddingLeft: 8,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: '7px',
    marginLeft: '7px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textfield: {
    width: '320px',
    height: '260px',
  },
  actions: {
    padding: '8px 29px 28px',
  },
  dragDropText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '0px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
  },
  browseFileButton: {
    border: '2px solid #0947B9',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#0947B9',
  },
  dropzoneStyling: {
    width: '100%',
    flexShrink: 0,
    background: '#F9F9F9',
    mixBlendMode: 'normal',
    border: '2px dashed #949494',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingBottom: 24,
  },
  file: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
    paddingLeft: 8,
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowLayout: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

const alwaysAllowedExtensions = ['.pdf', '.zip'];

const docTypesAllowAllExtensions = [
  DocumentTemplateType.MeetingMinutes,
  DocumentTemplateType.Task,
  DocumentTemplateType.MiscellaneousDocuments,
];

export default function FileUploadDialog(props: FileUploadDialogProps) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    title,
    newFiles,
    addFile,
    addFiles,
    removeFile,
    canSubmit = true,
    file,
    disableComments,
    buttonType = 'attach',
    inputComment,
    handleChangeComment,
    handleSubmit,
    uploadProgress,
    allowMultiple = true,
    errorMessage,
    uploadMessage,
    acceptAll,
    allowedExtensions,
    disableDesignUpload = false,
    enableDocumentImport = false,
    requireFileName,
    requireDocumentTitle = false,
    customMessage,
  } = props;

  const dispatch = useDispatch();

  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [browseCenterlineDialogOpen, setBrowseCenterlineDialogOpen] = useState(false);
  const [inputTitle, setInputTitle] = useState('');

  const security = useSelector(getCurrentSecurityGroup);
  const project = useSelector(getProjectState);
  const user = useSelector(getUserState);
  const docType = useSelector(getDocumentsType);
  const shouldBlockNavigation = useSelector(getNavigationState);

  useEffect(() => {
    setAlreadySubmitted(false);
  }, [open]);

  const checkInvalidFiles = async (files: File[]) => {
    let invalidFile: File | undefined = undefined;
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.type === 'application/pdf' && !(await checkPdf(file))) {
        invalidFile = file;
      }
    }
    if (invalidFile) {
      handleInvalidFile(invalidFile);
      return false;
    }
    return true;
  };

  const checkPdf = async (file: File) => {
    try {
      await getDocument({ data: new Int8Array(await file.arrayBuffer()) }).promise;
      return true;
    } catch (e: any) {
      return false;
    } finally {
    }
  };

  const getFileName = (file: FileType) => {
    if ((file as FileNode).relativeKey !== undefined) {
      return (file as FileNode).relativeKey;
    } else {
      return (file as File | ImportedFile).name;
    }
  };

  const handleInvalidFile = (file: File) => {
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span>File "{file.name}" is corrupt.</span>
            <span>Please upload a different file.</span>
          </div>
        ),
        severity: 'error',
      }),
    );
  };

  const getAllowedExtensions = () => {
    if (acceptAll) return undefined;
    if (docType && docTypesAllowAllExtensions.includes(docType)) return undefined;
    if (allowedExtensions) return allowedExtensions;
    return alwaysAllowedExtensions;
  };

  const filesHaveRequiredName = (files: File[]) => {
    if (!requireFileName) return true;
    return files.every((f) => f.name === requireFileName);
  };

  const onDropAccepted = async (files: File[]) => {
    if (requireFileName) {
      const hasSameName = filesHaveRequiredName(files);
      if (!hasSameName) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Selected file must have the same name as the file to be replaced',
            severity: 'warning',
            autoHideDuration: 15000,
          }),
        );
        return;
      }
    }
    const valid = await checkInvalidFiles(files);
    if (!valid) return;
    if (allowMultiple && addFiles) {
      addFiles(files);
    } else if (addFile) {
      if (!allowMultiple && newFiles && newFiles.length > 0) {
        removeFile(newFiles[0]);
        addFile(files[0]);
      } else {
        addFile(files[0]);
      }
    }
  };

  const handleDesignUpload = (fileNodes: FileNode[]) => {
    let validUpload = true;
    fileNodes.forEach((file) => {
      // disallows files that aren't PDFs when acceptAll is false.
      // disallows folder upload.
      if (
        (!acceptAll &&
          file.relativeKey?.substring(file.relativeKey?.lastIndexOf('.') + 1) !== 'pdf') ||
        file.type === FileNodeTypeEnum.Folder
      ) {
        validUpload = false;
      }
    });

    if (allowMultiple && addFiles && validUpload) {
      addFiles(fileNodes);
    } else if (addFile && validUpload) {
      if (!allowMultiple && newFiles && newFiles.length > 0) {
        removeFile(newFiles[0]);
        addFile(fileNodes[0]);
      } else {
        addFile(fileNodes[0]);
      }
    }
  };

  const handleDocumentImport = (files: ImportedFile[]) => {
    if (allowMultiple && addFiles) {
      addFiles(files);
    } else if (addFile) {
      if (!allowMultiple && !!newFiles?.length) {
        removeFile(files[0]);
        addFile(files[0]);
      } else {
        addFile(files[0]);
      }
    }
  };

  const onClose = () => {
    if (shouldBlockNavigation) return;
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" className={classes.root}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <span className={classes.title}>{title}</span>
          </div>
          <IconButton
            style={{ right: '16px', top: '16px', position: 'absolute' }}
            onClick={onClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <div className={classes.rowLayout}>
            <div className={classes.columnLayout} style={{ paddingRight: 16 }}>
              {requireDocumentTitle && (
                <>
                  <span className={classes.subtitle}>Set Document Title</span>
                  <TextField
                    size="small"
                    fullWidth
                    label="Title"
                    variant="outlined"
                    value={inputTitle}
                    onChange={(e) => setInputTitle(e.target.value)}
                    style={{ marginBottom: 8 }}
                  />
                </>
              )}
              <span className={classes.subtitle}>
                Attach Your {addFiles ? 'Files' : 'File'}
                {!!requireFileName && `: ${requireFileName}`}
              </span>
              {requireFileName && (
                <Typography style={{ fontSize: 16, marginTop: 8 }}>
                  Please upload the exact same file as you are
                  <br />
                  replacing.
                </Typography>
              )}
              <div className={classes.rowLayout} style={{ width: '100%', minWidth: 320 }}>
                <div className={classes.dropzoneStyling}>
                  <Dropzone accept={getAllowedExtensions()} onDropAccepted={onDropAccepted}>
                    {({ getRootProps, getInputProps }) => (
                      <div style={{ outline: 'none' }} {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p
                          className={classes.dragDropText}
                          style={{ paddingTop: 10, paddingBottom: 10 }}
                        >
                          Drag &amp; Drop files here
                        </p>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                          <p className={classes.dragDropText}>or</p>
                          <div style={{ width: 16 }} />
                          <Button className={classes.browseFileButton}>
                            <Add />
                            Browse My Computer
                          </Button>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>

                <div style={{ width: '16px', flexShrink: 0 }} />
              </div>
              {(!disableDesignUpload &&
                canViewDesign(project?.subscriber?.productPackage, security, user, project)) ||
              enableDocumentImport ? (
                <div
                  className={classes.rowLayout}
                  style={{ width: '100%', justifyContent: 'center', marginTop: 20 }}
                >
                  <p className={classes.dragDropText}>or</p>
                  <div style={{ width: 16 }} />
                  <Button
                    className={classes.browseFileButton}
                    onClick={() => setBrowseCenterlineDialogOpen(true)}
                    style={{ paddingLeft: 17, paddingRight: 17 }}
                  >
                    <DesignIcon fill="#0947B9" style={{ marginRight: 5 }} />
                    Browse Centerline
                  </Button>
                </div>
              ) : null}
              {!allowMultiple && !customMessage && (
                <div style={{ width: '100%', marginTop: 16 }}>Only one file upload allowed.</div>
              )}
              {customMessage}
              <div style={{ display: 'flex', padding: 10, flexWrap: 'wrap' }}>
                {newFiles?.map((f) => {
                  return (
                    <div
                      key={generateUniqueId()}
                      style={{
                        display: 'flex',
                        padding: 10,
                        margin: 5,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        overflow: 'hidden',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                      }}
                    >
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'inline',
                          whiteSpace: 'nowrap',
                          maxWidth: 512,
                        }}
                      >
                        {getFileName(f)}
                      </div>
                      <Button
                        onClick={() => removeFile(f)}
                        style={{ padding: 0, margin: 0, marginLeft: 1, minWidth: 0 }}
                      >
                        <Clear />
                      </Button>
                    </div>
                  );
                })}
              </div>

              {file ? (
                <div className={classes.file}>
                  <IconButton
                    onClick={() => removeFile(file)}
                    style={{ padding: '0px 8px 0px 0px' }}
                  >
                    <Clear />
                  </IconButton>
                  <PDFIcon />
                  <Typography className={classes.fileText}>
                    {(file as File | ImportedFile).name || (file as FileNode).relativeKey}
                  </Typography>
                </div>
              ) : null}
              {alreadySubmitted && uploadProgress !== undefined && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    paddingRight: 8,
                    paddingLeft: 8,
                    marginTop: 8,
                  }}
                >
                  <Typography style={{ fontWeight: 500 }}>{uploadMessage}</Typography>
                  <Typography style={{ marginBottom: 4, marginTop: 4 }}>
                    {uploadProgress}%
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{ height: 6, width: '100%' }}
                  />
                </div>
              )}
              {errorMessage && (
                <div
                  style={{
                    color: 'red',
                    width: '100%',
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </div>
            {disableComments ? null : (
              <TextField
                variant="outlined"
                multiline
                rows={12}
                value={inputComment}
                onChange={handleChangeComment}
                label="Comments..."
                InputLabelProps={{ style: { textTransform: 'capitalize' } }}
                InputProps={{ style: { padding: '10px', height: '100%' } }}
                className={classes.textfield}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {buttonType === 'attach' ? (
            <Button
              onClick={() => {
                handleClose(inputTitle);
                setInputTitle('');
              }}
              variant="contained"
              color="primary"
              disabled={!canSubmit || (requireDocumentTitle && !inputTitle)}
              startIcon={<AttachFile style={{ transform: 'scaleY(-1) rotate(45deg)' }} />}
            >
              ATTACH
            </Button>
          ) : (
            <Button
              onClick={async () => {
                setAlreadySubmitted(true);
                if (handleSubmit) await handleSubmit();
                else if (handleClose) handleClose(inputTitle);
                setInputTitle('');
              }}
              variant="contained"
              color="primary"
              disabled={!canSubmit || alreadySubmitted || (requireDocumentTitle && !inputTitle)}
              startIcon={<Publish style={{ marginBottom: 3 }} />}
            >
              Upload
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <BrowseCenterlineDialog
        open={browseCenterlineDialogOpen}
        handleClose={() => setBrowseCenterlineDialogOpen(false)}
        acceptAll={acceptAll}
        allowMultiple={allowMultiple}
        handleDesignUpload={handleDesignUpload}
        handleDocumentImport={handleDocumentImport}
        disableDesignUpload={disableDesignUpload}
        disableDocumentImport={!enableDocumentImport}
      />
    </>
  );
}
