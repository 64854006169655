import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { AttachFile, HighlightOffRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import { getProjectState } from '../../features/project/selectors';
import { FileNode, FileNodeTypeEnum, IProject } from '../../api-client/autogenerated';
import FileTree from '../design/FileTree';

// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface AddDesignFileDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  acceptAll?: boolean;
  handleDesignAttach?: (files: FileNode[]) => void;
  singleFile?: boolean;
  overrideProject?: IProject;
  allowOnlyPdfs?: boolean;
}

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 1500,
  },
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    marginBottom: '8px',
    paddingLeft: 8,
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#464546',
    paddingLeft: 8,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-16px',
  },
  contentContainer: {},
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textfield: {
    width: '320px',
    height: '260px',
  },
  actions: {
    padding: '8px 29px 28px',
  },
  dragDropText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '0px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
  },
  browseFileButton: {
    border: '2px solid #0947B9',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#0947B9',
  },
  dropzoneStyling: {
    width: '100%',
    flexShrink: 0,
    background: '#F9F9F9',
    mixBlendMode: 'normal',
    border: '2px dashed #949494',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingBottom: 24,
  },
  file: {
    display: 'inline-flex',
    marginTop: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
    paddingLeft: 8,
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowLayout: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

export default function AddDesignFileDialog(props: AddDesignFileDialogProps) {
  const classes = useStyles();
  const project = useSelector(getProjectState);

  const {
    open,
    handleClose,
    acceptAll,
    handleDesignAttach = (files: FileNode[]) => {},
    title,
    singleFile = false,
    overrideProject,
    allowOnlyPdfs,
  } = props;

  const [selectedFiles, setSelectedFiles] = useState<FileNode[]>([]);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const handleFileSelect = (files: FileNode[]) => {
    setSelectedFiles(files);
    let fileCount = 0;
    files.forEach((file) => {
      if (file.type === FileNodeTypeEnum.File) {
        fileCount++;
      }
    });
    setSelectedFilesCount(fileCount);
  };

  useEffect(() => {
    // TO-DO: proper managment of which files are newly selected
    setCanSubmit(selectedFiles.length > 0);
  }, [selectedFiles]);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        className={classes.root}
      >
        <DialogTitle>
          <div className={classes.titleContainer}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subtitle}>Attach Your Files</span>
          </div>
          <IconButton
            style={{ right: '24px', top: '24px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexDirection: 'column' }} className={classes.contentContainer}>
          <div>
            <FileTree
              allowOnlyPdfs={allowOnlyPdfs}
              project={overrideProject || project}
              small
              type="Project Files"
              handleFileSelect={handleFileSelect}
              disableSelectAll
              singleFile={singleFile}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {acceptAll ? (
            <div>{selectedFilesCount} files selected</div>
          ) : (
            <div>{selectedFilesCount} files selected (Only PDFs allowed)</div>
          )}
          <Button
            onClick={() => {
              handleDesignAttach(selectedFiles);
              setSelectedFiles([]);
              handleClose();
            }}
            variant="contained"
            color="primary"
            disabled={!canSubmit}
            startIcon={<AttachFile style={{ transform: 'scaleY(-1) rotate(45deg)' }} />}
          >
            ATTACH
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
