import React from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, ButtonProps, InputBase, Theme, Tooltip, TooltipProps } from '@material-ui/core';
import { Clear, Publish } from '@material-ui/icons';

import BluebeamIcon from '../icons/Bluebeam-icon';
import DownloadIcon from '../icons/Download-icon';
import ProcoreIcon from '../icons/ProcoreIcon';
import { ToggleButton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  procore: {
    background: '#F28B00',
    '&:hover': {
      background: '#FF9C17',
    },
  },
}));

export const SubmitButton = withStyles(() => ({
  root: {
    height: 32,
    color: 'white',
    backgroundColor: '#128750',
    '&:hover': {
      backgroundColor: '#2BB073',
    },
  },
  outlinedPrimary: {
    height: 32,
    color: '#128750',
    backgroundColor: 'inherit',
    borderColor: '#128750',
    '&:hover': {
      borderColor: '#2BB073',
      color: 'white',
    },
  },
  disabled: {
    backgroundColor: 'lightgray',
  },
}))(Button);

export const RedToggleButton = withStyles(() => ({
  root: {
    transition: '0.2s',
    '&$selected': {
      color: 'white',
      backgroundColor: '#ED3F26',
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
    },
  },

  selected: {},
}))(ToggleButton);

export const GreenToggleButton = withStyles(() => ({
  root: {
    transition: '0.2s',
    '&$selected': {
      color: 'white',
      backgroundColor: '#128750',
      '&:hover': {
        backgroundColor: '#2BB073',
      },
    },
  },

  selected: {},
}))(ToggleButton);

export const RedButton = withStyles(() => ({
  root: {
    height: 32,
    color: '#ED3F26',
    '&:hover': {
      color: '#FF5D45',
    },
  },
}))(Button);

export const BluebeamButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<BluebeamIcon fill="white" width={16} height={16} />}
      {...rest}
    >
      {children || 'MARKUP IN BLUEBEAM'}
    </Button>
  );
};

export const OpenInBluebeamButton = (props: any) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<BluebeamIcon fill="white" width={16} height={16} />}
      {...props}
    >
      OPEN IN BLUEBEAM
    </Button>
  );
};

export const DownloadButton = (props: any) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      style={{ height: '32px' }}
      startIcon={<DownloadIcon fill="#2C69D6" />}
      {...props}
    >
      Download
    </Button>
  );
};

export const ProcoreButton = (props: any) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ marginRight: '16px' }}
      startIcon={<ProcoreIcon width={16} height={16} />}
      classes={{ containedPrimary: classes.procore }}
      {...props}
    >
      {props.children || 'IMPORT FROM PROCORE'}
    </Button>
  );
};

export const TooltipIfDisabledComponent = (
  props: TooltipProps & {
    spanStyle?: React.CSSProperties;
    disabled: boolean;
    maxWidth?: number | string;
  },
) => {
  const { disabled, spanStyle, title, children, maxWidth, ...rest } = props;
  return disabled && title ? (
    <Tooltip arrow placement="top" title={title} {...rest}>
      <span style={{ width: '100%', textAlign: 'center', maxWidth, ...spanStyle }}>{children}</span>
    </Tooltip>
  ) : (
    children
  );
};

export const CancelButton = (props: ButtonProps) => {
  const { children, style, ...rest } = props;

  return (
    <Button
      variant="outlined"
      style={{
        height: '32px',
        padding: '0px 20px',
        color: '#949494',
        border: '2px solid #949494',
        ...style,
      }}
      startIcon={<Clear fill="#949494" />}
      {...rest}
    >
      {children || 'CANCEL'}
    </Button>
  );
};

export const UploadButton = (props: any) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      style={{ height: '32px', width: 170 }}
      startIcon={<Publish fill="#2C69D6" />}
      {...props}
    >
      UPLOAD
    </Button>
  );
};

export const SmallButton = withStyles(() => ({
  root: {
    paddingLeft: 4,
    paddingRight: 4,
  },
}))(Button);

export const MediumButton = withStyles(() => ({
  root: {
    paddingLeft: 8,
    paddingRight: 14,
  },
}))(Button);

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 8,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);
